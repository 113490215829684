import React from 'react';
import PropTypes from 'prop-types';

function RetryMessage({ isSaving }) {
  const timerRef = React.useRef();
  const [show, setShow] = React.useState(isSaving);
  React.useEffect(() => {
    clearTimeout(timerRef.current);
    if (isSaving) {
      timerRef.current = setTimeout(() => {
        setShow(true);
      }, 3000);
      return;
    }
    setShow(false);
  }, [isSaving]);
  if (!show) return null;
  return (
    <div className="text-sm mt-1 text-red-500 font-bold">
      <i className="fa fa-exclamation-triangle mr-1" />
      Saving changes is taking longer than usual. Re-input your answer to try
      again.
    </div>
  );
}

RetryMessage.propTypes = {
  isSaving: PropTypes.bool.isRequired,
};

export default RetryMessage;
