import React from "react";
import PropTypes from "prop-types";
import { showModal } from "modules/common/components/AlertModal";
import GenericView, { ViewItem } from "modules/common/components/GenericView";
import FormSubmitted from "modules/users/components/FormSubmitted";

// eslint-disable-next-line
const DynamicQuestionsModal = ({ formId, data, name }) => {
  return (
    <div className="modal-body">
      {name === "contact" && (
        <GenericView data={data} labelWidth="92%">
          <ViewItem
            name="email_address"
            label="Email Address"
            className="font-weight-bold"
            isLabel
          />
          {data?.latest_trip_mobile_number ? (
            <ViewItem
              name="latest_trip_mobile_number"
              label="Mobile Phone"
              className="font-weight-bold"
              isLabel
            />
          ) : (
            <ViewItem
              name="mobile_number"
              label="Mobile Phone"
              className="font-weight-bold"
              isLabel
            />
          )}

          <ViewItem
            name="work_phone"
            label="Work Phone"
            className="font-weight-bold"
            isLabel
          />
          <ViewItem
            name="home_phone"
            label="Home Phone"
            className="font-weight-bold"
            isLabel
          />
        </GenericView>
      )}
      {name === "medical" && (
        <FormSubmitted id={formId} data={data} name={name} />
      )}
    </div>
  );
};

DynamicQuestionsModal.propTypes = {
  formId: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
};

export const showDynamicQuestionsModal = (formId, label, data, name) => {
  showModal({
    title: label,
    className: "modal-lg",
    closeToggle: true,
    content: () => (
      <DynamicQuestionsModal formId={formId} data={data} name={name} />
    ),
  });
};

export default null;
