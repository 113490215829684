import React from "react";
import PropTypes from "prop-types";
import { req, useApiGet } from "react-reqq";
import AnswerQuestions from "modules/assessment/components/for-answering/AnswerQuestions";
import * as actions from "modules/assessment/actions";
import * as c from "modules/assessment/constants";
import GenericView, {
  ViewItem,
  ViewItemVaccination,
} from "modules/common/components/GenericView";

const useAssessment = (id) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const form = useApiGet(c.FORM_ITEM, {});
  const questions = useApiGet(c.ASSESSMENT_QUESTION_LIST, []);
  const answers = useApiGet(c.FORM_ANSWERS, {});
  React.useEffect(() => {
    if (!id) {
      req.set(c.FORM_ITEM, {});
      req.set(c.ASSESSMENT_QUESTION_LIST, []);
      req.set(c.FORM_ANSWERS, {});
      setIsLoading(false);
      return () => {};
    }
    setIsLoading(true);
    actions.selectForm(
      id,
      (res) => {
        actions.listAssessmentQuestion(
          res?.response?.data?.attributes?.assessment_uuid,
          { per_page: 100 },
          () => {
            actions.selectFormAnswer(id, () => {
              setIsLoading(false);
            });
          },
          () => {
            setIsLoading(false);
          }
        );
      },
      () => {
        setIsLoading(false);
      }
    );
    return () => {
      req.set(c.FORM_ITEM, {});
      req.set(c.ASSESSMENT_QUESTION_LIST, []);
      req.set(c.FORM_ANSWERS, {});
    };
  }, [id]);

  return [isLoading, form, questions, answers];
};

function FormSubmitted({ id, data, name }) {
  const [isLoading, , questions] = useAssessment(id);
  const questionsWithSteps = questions?.filter((q) => q?.meta?.group);

  return (
    <div>
      {questions.length < 1 ? (
        <div className="text-muted text-center">
          {isLoading ? "Loading..." : "No submitted"}
        </div>
      ) : (
        <>
          <AnswerQuestions questions={questionsWithSteps} readOnly />
          {name === "medical" && (
            <GenericView data={data} labelWidth="92%">
              <ViewItemVaccination
                label="Requirement for Proof of COVID-19 Vaccination for Covered Individuals (not a U.S. citizen, U.S. national, lawful permanent resident, or an immigrant)"
                value={data?.is_fully_vaccinated}
              />
              <ViewItem
                name="will_be_tested_for_covid"
                label="Will you be tested for corona virus (Covid-19) using a nasopharyngeal swab/PCR test 72 hours before your arrival into CNMI ?"
                className="font-weight-bold text-center"
                isLabel
              />
              <ViewItem
                name="has_household_in_contact_within_14_days"
                label="Any household member(s) been in contact with an individual experiencing fever, cough and/or respiratory problems in the past 14 days?"
                className="font-weight-bold text-center"
                isLabel
              />
              <ViewItem
                name="has_household_sick_within_14_days"
                label="Any others sick within your family/group in the past 14 days?"
                className="font-weight-bold text-center"
                isLabel
              />
              <ViewItem
                name="has_taken_fever_meds_within_14_days"
                label="Did you take anti-fever medication during the last 4-6 hours?"
                className="font-weight-bold text-center"
                isLabel
              />
              <ViewItem
                name="has_tested_positive_for_covid"
                label="Have you ever tested positive for corona virus (Covid-19) ?"
                className="font-weight-bold text-center"
                isLabel
              />
              <ViewItem
                name="has_visited_clinics_within_14_days"
                label="Did you visit any health worker, hospital, clinic, or nursing home in the past 14 days?"
                className="font-weight-bold text-center"
                isLabel
              />
              <ViewItem
                name="has_visited_farms_within_14_days"
                label="Did you visit any poultry farm or animal market in the past 14 days?"
                className="font-weight-bold text-center"
                isLabel
              />
            </GenericView>
          )}
        </>
      )}
    </div>
  );
}

FormSubmitted.defaultProps = {
  id: null,
  data: {},
  name: "",
};

FormSubmitted.propTypes = {
  id: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  data: PropTypes.instanceOf(Object),
  name: PropTypes.string,
};

export default FormSubmitted;
