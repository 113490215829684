import React from "react";
import PropTypes from "prop-types";

function FormToggleSwitch({ onToggle, disabled, toggled, label, id }) {
  return (
    <div className="custom-control custom-switch">
      <input
        name="toggle"
        type="checkbox"
        className="custom-control-input"
        onChange={(e) => onToggle(e.target.checked)}
        checked={toggled}
        id={`toggle-${id}`}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={`toggle-${label}`} />
    </div>
  );
}

FormToggleSwitch.defaultProps = {
  disabled: false,
  toggled: false,
  label: "",
  id: "",
};

FormToggleSwitch.propTypes = {
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  toggled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
};

export default FormToggleSwitch;
