/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from "react";
import _ from "lodash";
// import InputMask from "react-input-mask";
import DateRangePicker from "../components/DateRangePicker";
import NumberRangePicker from "../components/NumberRangePicker";
// import PropTypes from 'prop-types';

const handleFocusNext = (nextId) => (e) => {
  if (e.key === "Enter") {
    const elem = document.getElementById(nextId);
    if (elem) {
      e.preventDefault();
      e.stopPropagation();
      if (typeof elem.focus === "function") elem.focus();
    }
  }
};

export default (WrappedComponent) => {
  const hocInlineForm = (p) => {
    const [formData, setFormData] = useState({});
    const handleChangeInput = (key) => ({ target }) => {
      setFormData({ ...formData, [key]: target.value });
    };
    const handleChangeSelect = ({ key, nextId }) => ({ target }) => {
      setFormData({ ...formData, [key]: target.value });
      if (nextId) {
        const elem = document.getElementById(nextId);
        if (elem && typeof elem.focus === "function") elem.focus();
      }
    };
    const handleChangeDateRange = ({ key_from, key_to, nextId }) => (value) => {
      setFormData({
        ...formData,
        [key_from]: value.from,
        [key_to]: value.to,
      });
      if (nextId) {
        const elem = document.getElementById(nextId);
        if (elem && typeof elem.focus === "function") elem.focus();
      }
    };
    const createLabel = useCallback(
      ({ label, labelWidth }, { required }) => (
        <div className="input-group-prepend">
          <div className="input-group-text" style={{ width: labelWidth || 80 }}>
            <small>
              {label}
              {required && <sup className="text-danger">*</sup>}
            </small>
          </div>
        </div>
      ),
      []
    );
    const createInput = useCallback(
      (config = {}, props = {}) => (
        <div className="input-group mb-1">
          {createLabel(config, props)}
          <input
            className="form-control"
            onChange={handleChangeInput(config.key)}
            value={_.get(formData, `${config.key}`) || ""}
            onKeyPress={config.nextId ? handleFocusNext(config.nextId) : null}
            {...props}
          />
        </div>
      ),
      [formData]
    );
    const createMobileInput = useCallback(
      (config = {}, props = {}) => (
        <div className="input-group mb-1">
          {createLabel(config, props)}
          <input
            className="form-control"
            onChange={handleChangeInput(config.key)}
            value={_.get(formData, `${config.key}`) || ""}
            onKeyPress={config.nextId ? handleFocusNext(config.nextId) : null}
            {...props}
          />
        </div>
      ),
      [formData]
    );
    const createSelect = useCallback(
      (config = {}, options, props = {}) => (
        <div className="input-group mb-1">
          {config.label && createLabel(config, props)}
          <select
            className="form-control"
            onChange={handleChangeSelect(config)}
            value={_.get(formData, `${config.key}`) || ""}
            {...props}
          >
            {!_.isEmpty(props.placeholder) ? (
              <option value="">{props.placeholder}</option>
            ) : (
              <option value="">- Select -</option>
            )}
            {options.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </div>
      ),
      [formData]
    );
    const createDateRange = useCallback(
      (config = {}, props = {}) => (
        <div className="input-group mb-1">
          {createLabel(config, props)}
          <DateRangePicker
            onChange={handleChangeDateRange(config)}
            valueFrom={_.get(formData, `${config.key_from}`) || ""}
            valueTo={_.get(formData, `${config.key_to}`) || ""}
            dropdownStyle={config.dropdownStyle || {}}
          />
        </div>
      ),
      [formData]
    );
    const createNumberRange = useCallback(
      (config = {}, props = {}) => (
        <div className="input-group mb-1">
          {createLabel(config, props)}
          <NumberRangePicker
            onChange={handleChangeDateRange(config)}
            valueFrom={_.get(formData, `${config.key_from}`) || ""}
            valueTo={_.get(formData, `${config.key_to}`) || ""}
          />
        </div>
      ),
      [formData]
    );
    return (
      <WrappedComponent
        {...p}
        createInput={createInput}
        createMobileInput={createMobileInput}
        createSelect={createSelect}
        createDateRange={createDateRange}
        createNumberRange={createNumberRange}
        formData={formData}
        setFormData={setFormData}
      />
    );
  };
  return React.memo(hocInlineForm);
};
