import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Cookie from "js-cookie";

function PageNotFound() {
  const token = Cookie.get("token");
  const history = useHistory();
  useEffect(() => {
    if (!token) {
      history.push("/signin");
    }
  }, [token]);

  return (
    <div className="text-center">
      <div className="error mx-auto" data-text="404">
        404
      </div>
      <p className="lead text-gray-800 mb-5">Page Not Found</p>
      <Link to="/">← Back Home</Link>
    </div>
  );
}

export default PageNotFound;
