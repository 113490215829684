import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { formatDate } from "../helper";

const ViewContext = React.createContext();

export const renderArray = (path) => (row) => {
  const raw = _.get(row, path) || [];
  if (!Array.isArray(raw)) {
    return <span className="text-danger">- Invalid Format -</span>;
  }
  return (_.get(row, path) || []).join(", ");
};

export const renderDate =
  (path, format = "MM/DD/YYYY") =>
  (row) => {
    return formatDate(_.get(row, path), format);
  };

export const ViewItem = ({ label, name, className, isLabel }) => {
  const { data, labelWidth } = React.useContext(ViewContext);
  const val = _.isEmpty(_.get(data, name)) ? "-" : _.get(data, name);
  const value = typeof name === "function" ? name(data) : val;
  return (
    <tr>
      <td className="label" width={labelWidth}>
        {isLabel ? (
          <span className="text-semibold">{label}</span>
        ) : (
          <small>{label}</small>
        )}
      </td>
      <td className={`answer ${className}`}>
        {(_.isEmpty(`${value}`) || typeof value === "object") &&
        typeof name !== "function" ? (
          <span>&nbsp;</span>
        ) : (
          value
        )}
      </td>
    </tr>
  );
};

ViewItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
  isLabel: PropTypes.bool,
};

ViewItem.defaultProps = {
  className: "",
  isLabel: false,
};

export const ViewDivider = ({ label }) => {
  return (
    <tr>
      <td>
        <b className="text-dark">{label}</b>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
};

ViewDivider.propTypes = {
  label: PropTypes.string,
};

ViewDivider.defaultProps = {
  label: PropTypes.defaultProps,
};

export const ViewItemVaccination = ({ label, value }) => {
  const valueName = value ? "Fully Vaccinated" : "Not Fully Vaccinated";
  return (
    <tr className="my-2">
      <td className="label w-100 d-block">
        <span className="text-semibold">{label}</span>
      </td>
      <select
        name="vaccination"
        className="form-control mb-2"
        disabled
        value={valueName || ""}
      >
        <option key={valueName} value={valueName} disabled>
          {valueName}
        </option>
      </select>
    </tr>
  );
};

ViewItemVaccination.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
};

ViewItemVaccination.defaultProps = {
  label: "",
  value: 0,
};

function GenericView({ data, labelWidth, children }) {
  return (
    <ViewContext.Provider value={{ data, labelWidth }}>
      <table className="table table-borderless table-sm mb-0">
        <tbody>{children}</tbody>
      </table>
    </ViewContext.Provider>
  );
}

GenericView.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object),
  labelWidth: PropTypes.string,
};

GenericView.defaultProps = {
  children: null,
  labelWidth: "auto",
};

export default React.memo(GenericView);
