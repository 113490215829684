import React from "react";
import PropTypes from "prop-types";
import FormInput from "modules/common/form/FormInput";
import FormEditor from "modules/common/form/FormEditor";
import { useApiLoading } from "react-reqq";
import _ from "lodash";
import * as actions from "../actions";
import * as c from "../constants";

function StatusManagementModal({ data, onClose }) {
  const isLoading = useApiLoading(c.SYMPTOMS, "put");
  const [form, setForm] = React.useState({
    code: _.get(data, "attributes.code") || "",
    name: _.get(data, "attributes.name") || "",
    description: _.get(data, "attributes.description") || "",
    points: _.get(data, "attributes.points") || "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.updateStatus(data.id, form, onClose);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col-3">
            <FormInput
              name="code"
              label="Code"
              onChange={setForm}
              value={form.code}
              readOnly
            />
          </div>
          <div className="col-7">
            <FormInput
              name="name"
              label="Name"
              onChange={setForm}
              value={form.name}
              readOnly
            />
          </div>
          <div className="col-2">
            <FormInput
              name="points"
              label="Points"
              onChange={setForm}
              value={form.points}
            />
          </div>
        </div>
        <FormEditor
          name="description"
          label="Description"
          onChange={setForm}
          value={form.description}
        />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary mr-1"
          type="submit"
          disabled={isLoading}
        >
          Save
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </form>
  );
}

StatusManagementModal.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default StatusManagementModal;
