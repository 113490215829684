import React from "react";
import { Helmet } from "react-helmet";
import { showModal } from "modules/common/components/AlertModal";
import { ResponsiveBar } from "@nivo/bar";
import GenericTable from "modules/common/components/GenericTable";
import { ResponsivePie } from "@nivo/pie";
import CreateChartReport from "../modals/CreateChartReport";

function SummaryContainer() {
  const [chartList, setChart] = React.useState();
  const [field, setField] = React.useState();

  const colChart = React.useMemo(() => {
    const modalChartReports = () => {
      showModal({
        title: "Add to Dashboard",
        className: "modal-md",
        content: (onClose) => (
          <CreateChartReport
            onClose={onClose}
            setChart={setChart}
            setField={setField}
          />
        ),
        closeToggle: true,
      });
    };

    return (
      <div className="mt-3 mr-3">
        <div
          className="d-flex flex-column flex-grow-1 py-4"
          style={{ borderStyle: "dashed", borderRadius: "3px" }}
        >
          <div className="p-3 border-b d-flex align-items-center justify-content-center">
            <div className="col-6 text-center">
              <i
                className="fa fa-chart-bar mr-1 mb-2"
                style={{
                  padding: "25px",
                  borderRadius: "9999px",
                  background: "lightgray",
                }}
              />
              <h4 className="font-weight-bold">Create Report</h4>
              <p>
                Add a new customs report to your dashboard to get insights
                custom to your data
              </p>
              <button
                className="btn btn-primary btn-sm py-2 px-2 mr-1"
                onClick={modalChartReports}
                type="button"
              >
                {chartList ? "Update Report" : "Add Report"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [showModal, chartList]);

  const TABLE_FORMAT = [
    { key: "label", label: "VALUE", width: 200 },
    { key: "value", label: "COUNT", width: 70 },
  ];

  const Graph = React.useMemo(() => {
    if (!field || !chartList) return null;
    const { chart, keys } = chartList;
    const { type } = field?.data?.attributes;

    let show = null;
    if (type === "DATE") {
      show = (
        <div className="card mt-3 ml-3" style={{ height: 300 }}>
          <ResponsiveBar
            layout="vertical"
            data={chart || []}
            keys={keys || []}
            indexBy="label"
            margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
            padding={0.25}
            minValue={0}
            colors={["#176AB9", "#17B978"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            groupMode="grouped"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -30,
              legend: field?.label,
              legendPosition: "middle",
              legendOffset: 37,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={14}
            labelSkipHeight={14}
            // labelTextColor={{ from: "color", modifiers: [["darker", "2"]] }}
            labelTextColor="#fff"
            legends={[]}
            animate
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      );
    }
    if (type === "SHORT_ANS" || type === "PARAGRAPH") {
      show = (
        <div className="card mt-3 ml-3" style={{ height: 300 }}>
          <span className="text-center font-weight-bold py-2">
            {field?.label}
          </span>
          <GenericTable
            isLoading={!chartList}
            format={TABLE_FORMAT}
            data={chartList?.chart}
          />
        </div>
      );
    }
    if (type === "MULTIPLE_CHOICE") {
      show = (
        <div className="card mt-3 ml-3" style={{ height: 300 }}>
          <ResponsiveBar
            layout="vertical"
            data={chart || []}
            keys={keys || []}
            indexBy="label"
            margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
            padding={0.25}
            minValue={0}
            colors={["#176AB9", "#17B978"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            groupMode="grouped"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -30,
              legend: field?.label,
              legendPosition: "middle",
              legendOffset: 37,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={14}
            labelSkipHeight={14}
            // labelTextColor={{ from: "color", modifiers: [["darker", "2"]] }}
            labelTextColor="#fff"
            legends={[]}
            animate
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      );
    }
    if (type === "CHECKBOX" || type?.includes("DROPDOWN")) {
      show = (
        <div className="card mt-3 ml-3" style={{ height: 300 }}>
          <ResponsivePie
            data={chart || []}
            margin={{ top: 0, right: 80, bottom: 25, left: 25 }}
            innerRadius={0.3}
            colors={{ scheme: "nivo" }}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            enableRadialLabels={false}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={2}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: "color" }}
            enableSlicesLabels
            sliceLabel="percentage"
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#fff"
            animate
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: -20,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: 10,
                itemHeight: 15,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 10,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      );
    }

    return show;
  }, [field, chartList]);

  return (
    <>
      <Helmet>
        <title>Summary - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column mt-3">
        <div className="row">
          {Graph && <div className="col-6">{Graph || null}</div>}
          <div className={Graph ? "col-6" : "col-6"}>{colChart}</div>
        </div>
      </div>
    </>
  );
}

export default SummaryContainer;
