import React from "react";
import PropTypes from "prop-types";
import uploadcare from "uploadcare-widget";
import { formatNumber, autoCropFace } from "../helper";

const formatProgress = (progress) => `${formatNumber(progress * 100)}%`;

const localizeUrl = (file) => {
  const arr = file.name.split(".");
  const extension = arr.pop();
  const filename = arr.join(".").replace(/[^a-zA-Z0-9_]/g, "");
  const newFilename = `${filename}.${extension}`;
  return `localhost:8000/${file.uuid}/${newFilename}`; // For chaging custom domain with uploadcare
};

const wait = (t) => new Promise((r) => setTimeout(r, t));

function FormUploadButton({
  name,
  onChange,
  label,
  value,
  tabs,
  disabled,
  imageOnly,
  useOriginalFormat,
  ...rest
}) {
  const [isUploading, setIsUploading] = React.useState(false);
  const [isCroppping, setIsCroppping] = React.useState(false);
  const [progress, setProgress] = React.useState(null);
  const updateProgress = (row) => {
    setProgress(row.progress || 0);
  };
  const autoCrop = async (url) => {
    setIsCroppping(true);
    const newUrl = await autoCropFace(url);
    setIsCroppping(false);
    setIsUploading(false);
    onChange((prev) => ({ ...prev, [name]: newUrl }));
  };
  const uploaded = async (res) => {
    if (rest.crop === "face") {
      autoCrop(res.originalUrl);
      return;
    }
    setProgress(1);
    await wait(5000);
    setIsUploading(false);
    const newUrl = useOriginalFormat ? res.cdnUrl : localizeUrl(res);
    onChange((prev) => ({ ...prev, [name]: newUrl }));
  };
  const handleUpload = () => {
    setIsUploading(true);
    setProgress(null);
    uploadcare
      .openDialog(null, {
        publicKey: process.env.REACT_APP_UPLOADCARE_KEY,
        tabs,
        crop: rest.crop === "face" ? false : rest.crop || false,
        resize: rest.resize || false,
        imageOnly,
      })
      .done((file) => {
        setProgress(0);
        file.progress(updateProgress).done(uploaded);
      })
      .fail(() => {
        setIsUploading(false);
      });
  };
  return (
    <>
      <button
        disabled={isUploading || disabled}
        type="button"
        onClick={handleUpload}
        className="btn btn-sm btn-primary btn-block"
        style={{ position: "relative", height: 31, padding: 0 }}
      >
        {typeof progress === "number" && isUploading ? (
          <div className="progress" style={{ height: 29 }}>
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              style={{ width: formatProgress(progress) }}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="1"
            >
              {isCroppping ? "Cropping..." : formatProgress(progress)}
            </div>
          </div>
        ) : (
          <span>
            <i className="fa fa-upload mr-1" /> {label}
          </span>
        )}
      </button>
    </>
  );
}

FormUploadButton.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  tabs: PropTypes.instanceOf(Array),
  resize: PropTypes.string,
  imageOnly: PropTypes.bool,
  useOriginalFormat: PropTypes.bool,
};

FormUploadButton.defaultProps = {
  tabs: ["file", "camera"],
  label: "Upload",
  disabled: false,
  resize: "",
  imageOnly: true,
  useOriginalFormat: true,
};

export default FormUploadButton;
