import React from "react";
import { useApiGet } from "react-reqq";
import _ from "lodash";
import PropTypes from "prop-types";
import { fieldsList } from "../actions";

function FormSurveyFields({ onChange, name, report_type }) {
  const fields = useApiGet("SELECT_SURVEY_FORM_FIELDS.data", []);
  const [form, setForm] = React.useState([]);
  const [collapse, setCollapse] = React.useState(false);
  const filteredData = fields?.filter((f) => f?.attributes?.value) || [];

  const handleToggleField =
    (field) =>
    ({ target }) => {
      setForm(
        target.checked
          ? form.concat([`${field?.attributes?.value}`])
          : form.filter((x) => `${x}` !== `${field?.attributes?.value}`)
      );
    };

  const handleToggleCollapse = (e) => {
    e.preventDefault();
    setCollapse(!collapse);
  };
  React.useEffect(() => {
    if (!report_type?.value) return;
    fieldsList({ report_type: report_type?.value });
  }, [report_type]);

  React.useEffect(() => {
    onChange((state) => ({
      ...state,
      [name]: form,
    }));
  }, [form]);

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <a href="/" onClick={handleToggleCollapse}>
          {filteredData?.length > 0 ? (
            <span className="mr-1">
              {collapse ? (
                <i className="fa fa-chevron-circle-down" />
              ) : (
                <i className="fa fa-chevron-circle-up" />
              )}
            </span>
          ) : (
            <span className="mr-1">
              <i className="fa fa-minus-circle" />
            </span>
          )}
          {filteredData?.length > 0 ? "Select Fields:" : "No Fields"}
        </a>
      </div>

      {collapse && (
        <div className="row">
          {filteredData?.map((field) => (
            <label
              className="mr-3 col-5"
              key={field?.id}
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "2px",
                fontSize: "12px",
              }}
            >
              <input
                type="checkbox"
                onChange={handleToggleField(field)}
                checked={form?.indexOf(`${field?.attributes?.value}`) > -1}
              />
              <span>{_.capitalize(field?.attributes?.label)}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
}

FormSurveyFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  report_type: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
};

export default FormSurveyFields;
