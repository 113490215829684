import { useEffect } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq";
import { listRegistrant, showRegistrantById } from "./actions";

export const useRegistrantById = (id) => {
  const isLoading = useApiLoading("REGISTRANT_BY_ID", "get");
  const data = useApiGet("REGISTRANT_BY_ID", {});
  useEffect(() => {
    if (!id) return () => {};
    showRegistrantById(id);
    return () => {
      req.set("REGISTRANT_BY_ID", null);
    };
  }, [id]);
  return [isLoading, data];
};

export const useSearchRegistrantList = (params) => {
  const isLoading = useApiLoading("REGISTRANT_LIST/search", "get");
  const data = useApiGet("REGISTRANT_LIST/search", []);
  const pager = useApiGet("REGISTRANT_PAGER/search", {});
  useEffect(() => {
    listRegistrant("search", params);
  }, [params]);
  return [isLoading, data, pager];
};

export const useRegistrantList = (params) => {
  const isLoading = useApiLoading("REGISTRANT_LIST/list", "get");
  const data = useApiGet("REGISTRANT_LIST/list", []);
  const pager = useApiGet("REGISTRANT_PAGER/list", {});

  useEffect(() => {
    listRegistrant("list", params);
  }, [params]);
  return [isLoading, data, pager];
};
