import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GenericTable from "modules/common/components/GenericTable";
import PropTypes from "prop-types";
import { useTripList } from "travel-marianas-modules/trips/hooks";
import TripsFilter from "../trips/TripsFilter";

function RegistrantTripHistory({ registrant }) {
  const location = useLocation();
  const history = useHistory();
  const [filter, setFilter] = useState({});
  const [isLoading, list] = useTripList({
    ...filter,
    registrant_uuid: registrant.id,
  });
  const mappedList = list.map((trip) => ({ ...registrant, ...trip }));

  const tableFormat = useMemo(
    () => [
      { key: "date_of_arrival", label: "Arrival Date" },
      { key: "origin_country", label: "Origin" },
      {
        key: (row) => (
          <span>
            {" "}
            {row?.latest_trip_mobile_number || row.contact_number || "--"}{" "}
          </span>
        ),
        label: "Contact Number",
      },
      {
        key: (row) => (
          <div>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() =>
                history.push({
                  pathname: `/trips/${row.id}`,
                  state: { from: location.pathname },
                })
              }
              type="button"
            >
              Details
            </button>
          </div>
        ),
        label: "",
        width: 60,
      },
    ],
    []
  );

  return (
    <div>
      <div className="p-3">
        <TripsFilter onSubmit={setFilter} value={filter} />
      </div>
      <div style={{ height: 600 }}>
        <GenericTable
          isLoading={isLoading}
          format={tableFormat}
          data={mappedList}
        />
      </div>
    </div>
  );
}

RegistrantTripHistory.defaultProps = {
  registrant: {},
};

RegistrantTripHistory.propTypes = {
  registrant: PropTypes.instanceOf(Object),
};

export default RegistrantTripHistory;
