import React from 'react';
import PropTypes from 'prop-types';
import { useApiLoading } from 'react-reqq';
import { debounce, isEqual, omit } from 'lodash';
import FormInput from "modules/common/form/FormInput";
// import QuestionAdvanceOptions, {
//   QuestionAdvanceLabel,
// } from './QuestionAdvanceOptions';
import * as actions from '../actions';
import * as c from '../constants';

const buildData = ({ id, ...rest }) => {
  return rest;
};

function QuestionUploadUrl({ data, index, editMode, answer, assessmentId }) {
  const isApiSaving = useApiLoading(
    `${c.ASSESSMENT_QUESTION_ITEM}/${data.id}`,
    'put'
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const [form, setForm] = React.useState(buildData(data));
  const canSave = React.useMemo(() => {
    const oldData = buildData(data);
    return !isEqual(omit(oldData, ['sequence']), omit(form, ['sequence']));
  }, [form, data]);
  // eslint-disable-next-line
  const save = React.useCallback(
    debounce((newValue) => {
      const oldData = buildData(data);
      if (isEqual(omit(oldData, ['sequence']), omit(newValue, ['sequence']))) {
        // console.log('skip cuz same!');
        return;
      }
      setIsSaving(true);
      actions.updateAssessmentQuestion(assessmentId, data.id, newValue, () =>
        setIsSaving(false)
      );
    }, 1), // }, 1500),
    [assessmentId]
  );
  const handleSave = () => {
    save(form);
  };
  // React.useEffect(() => {
  //   save(form);
  // }, [form, save]);
  return (
    <div className="card card-body mb-2">
      <div>
        {!editMode && (
          <div className="inline-block float-right space-x-1">
            <button
              className="btn outline-primary xs"
              type="button"
              disabled={isSaving || isApiSaving}
              onClick={() => actions.updateQuestionUp(assessmentId, data)}
            >
              <i className="fa fa-chevron-up" />
            </button>
            <button
              className="btn outline-primary xs"
              type="button"
              disabled={isSaving || isApiSaving}
              onClick={() => actions.updateQuestionDown(assessmentId, data)}
            >
              <i className="fa fa-chevron-down" />
            </button>
            {isSaving || isApiSaving ? (
              <button className="btn success xs" type="button" disabled>
                <i className="fa fa-spinner fa-spin" /> Save
              </button>
            ) : (
              <button
                className="btn success xs"
                type="button"
                onClick={handleSave}
                disabled={!canSave}
              >
                <i className="fa fa-save" /> Save
              </button>
            )}
            <button
              className="btn outline-danger xs"
              type="button"
              onClick={() =>
                actions.deleteAssessmentQuestion(assessmentId, data.id)
              }
              disabled={isSaving || isApiSaving}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        )}
        {editMode ? (
          <>
            <div>
              <p className="flex">
                <b>{form.question}</b>{' '}
                {!form.required && <span className="ml-1">(Optional)</span>}
                <span className="ml-auto">
                  <span>(Upload URL)</span>
                </span>
              </p>
            </div>
            <div>
              <input type="file" value={answer?.value || ''} style={{ pointerEvents: 'none' }} />
            </div>
            {/* <QuestionAdvanceLabel value={data.meta} /> */}
          </>
        ) : (
          <>
            <div className="mb-2">
              <span className="mr-2">#{index}</span>
              <span className="badge badge-dark mr-2">
                Upload URL
              </span>
              <label>
                <input
                  type="checkbox"
                  onChange={({ target }) =>
                    setForm((state) => ({ ...state, required: target.checked }))
                  }
                  readOnly={editMode}
                  checked={form.required}
                />{' '}
                Required
              </label>
            </div>
            <div className="flex space-x-2">
              <div className="flex-grow">
                <FormInput
                  name="question"
                  onChange={setForm}
                  placeholder="Enter Question..."
                  value={form.question}
                />
              </div>
            </div>
          </>
        )}
        {/* {!editMode && (
          <QuestionAdvanceOptions
            id={data.id}
            onChange={setForm}
            value={form.meta}
          />
        )} */}
      </div>
    </div>
  );
}

QuestionUploadUrl.defaultProps = {
  editMode: false,
  answer: null,
  assessmentId: '',
};

QuestionUploadUrl.propTypes = {
  assessmentId: PropTypes.string,
  editMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  answer: PropTypes.instanceOf(Object),
};

export default QuestionUploadUrl;
