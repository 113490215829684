import React from "react";
import PropTypes from "prop-types";
import { showModal } from "modules/common/components/AlertModal";
import { isEmpty } from "lodash";
import GenericView, { ViewItem } from "modules/common/components/GenericView";
import userThumb from "assets/img/thumb-user-admin.png";
import { useRegistrantMedicalCert } from "modules/users/hooks";
import { useGlobalSettings } from "modules/data-source/hooks";
import VaccinationPassportModal from "./modals/VaccinationPassportModal";
import VaccinationCertificateModal from "./modals/VaccinationCertificateModal";

const HealthStatusBadge = ({ value }) => {
  if (value === "GOOD")
    return <div className="badge badge-info">Good Condition</div>;
  if (value === "MILD")
    return <div className="badge badge-warning">Mild Condition</div>;
  if (value === "SEVERE")
    return <div className="badge badge-danger">Severe Condition</div>;
  return (
    <div className="badge badge-danger" style={{ opacity: 0 }}>
      -
    </div>
  );
};

HealthStatusBadge.defaultProps = {
  value: "",
};

HealthStatusBadge.propTypes = {
  value: PropTypes.string,
};

const SymptomsModal = ({ data }) => {
  const symptoms = data?.health_symptoms || [];
  return (
    <div className="modal-body">
      <ul className="mb-0">
        {symptoms.length < 1 ? (
          <li className="text-center italic text-muted">
            There are no symptoms
          </li>
        ) : (
          symptoms.map((v) => <li key={v}>{v}</li>)
        )}
      </ul>
    </div>
  );
};

SymptomsModal.defaultProps = {
  data: {},
};

SymptomsModal.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export const showSymptomsModal = (data) => {
  showModal({
    title: `${data.first_name || "User"}'s Symptoms`,
    className: "modal-sm",
    closeToggle: true,
    content: () => <SymptomsModal data={data} />,
  });
};

export const PersonalPhoto = ({ data }) => (
  <div className="d-flex flex-column align-items-center">
    <div className="mb-1" style={{ width: 128 }}>
      <img
        className="img img-thumbnail img-fluid"
        src={data?.photo_url || userThumb}
        alt=""
      />
    </div>
    <div className="d-flex justify-content-center">
      <HealthStatusBadge value={data?.health_status} />
    </div>
  </div>
);

PersonalPhoto.defaultProps = {
  data: {},
};

PersonalPhoto.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export const PersonalInfo = ({ data }) => (
  <>
    <div>
      <label className="font-weight-bold">Personal Info</label>
    </div>
    <div>
      <GenericView data={data} labelWidth="90px">
        <ViewItem name="first_name" label="First Name" />
        <ViewItem name="middle_name" label="Middle Name" />
        <ViewItem name="last_name" label="Last Name" />
      </GenericView>
    </div>
  </>
);

PersonalInfo.defaultProps = {
  data: {},
};

PersonalInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export const PersonalInfo2 = ({ data, noHeader }) => (
  <>
    {!noHeader ? (
      <div>
        <label className="font-weight-bold">&nbsp;</label>
      </div>
    ) : null}
    <div>
      <GenericView data={data} labelWidth="90px">
        <ViewItem name="gender" label="Gender" />
        <ViewItem name="birth_date" label="Birth Date" />
        <ViewItem name="age" label="Age" />
      </GenericView>
    </div>
  </>
);

PersonalInfo2.defaultProps = {
  data: {},
  noHeader: false,
};

PersonalInfo2.propTypes = {
  data: PropTypes.instanceOf(Object),
  noHeader: PropTypes.bool,
};

export const ContactInfo = ({ data }) => {
  return (
    <>
      <div>
        <label className="font-weight-bold">Contact Info</label>
      </div>
      <div>
        <GenericView data={data} labelWidth="116px">
          {data?.mobile_phone ? (
            <ViewItem name="mobile_phone" label="Mobile Number" />
          ) : (
            <ViewItem name="mobile_number" label="Mobile Number" />
          )}
          <ViewItem name="email" label="Email Address" />
          <ViewItem name="company_name" label="Company Name" />
        </GenericView>
      </div>
    </>
  );
};

ContactInfo.defaultProps = {
  data: {},
};

ContactInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export const OtherInfo = ({ data, isLoading }) => {
  const [
    ,
    medicalCertificateData,
    medicalCertificateList,
  ] = useRegistrantMedicalCert(data?.registrant_id);

  const showVaccinationPassport = () => {
    showModal({
      title: "COVID-19 Vaccination Record Card",
      className: "modal-md",
      closeToggle: true,
      content: (onClose) => (
        <VaccinationPassportModal
          onClose={onClose}
          profile={data}
          medicalCertificateList={medicalCertificateList}
        />
      ),
    });
  };
  const showVaccinationCertificate = () => {
    showModal({
      title: " ",
      className: "modal-md",
      closeToggle: true,
      content: (onClose) => (
        <VaccinationCertificateModal
          onClose={onClose}
          profile={data}
          medicalCertificateList={medicalCertificateList}
        />
      ),
    });
  };

  const loadMedical = React.useMemo(() => {
    if (!isEmpty(medicalCertificateData)) return false;
    return isEmpty(medicalCertificateData);
  }, [medicalCertificateData]);
  const medicalDoses = medicalCertificateList.filter((med) => med?.dose);

  return (
    <>
      <div>
        <label className="font-weight-bold">Other Info</label>
      </div>
      <div className="d-flex flex-column">
        <button
          className="btn btn-outline-primary btn-sm mb-1"
          type="button"
          disabled={!data?.id}
          onClick={() => showSymptomsModal(data)}
        >
          Symptoms({(data?.health_symptoms || []).length})
        </button>
        <button
          className="btn btn-outline-primary btn-sm mb-1"
          type="button"
          disabled={isLoading || isEmpty(medicalDoses)}
          onClick={showVaccinationPassport}
        >
          Vaccine QR
        </button>
        <button
          className="btn btn-outline-primary btn-sm mb-1"
          type="button"
          disabled={isLoading || loadMedical}
          onClick={showVaccinationCertificate}
        >
          Vaccine Certificate
        </button>
      </div>
    </>
  );
};

OtherInfo.defaultProps = {
  data: {},
  isLoading: false,
};

OtherInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
};

function RegistrantProfile({ data }) {
  const [globalSettings, isLoading] = useGlobalSettings();
  const enabledSettings = globalSettings?.filter((g) => g?.typed_value);

  return (
    <div className="d-flex flex-wrap">
      <div className="px-2" style={{ minWidth: "126px" }}>
        <PersonalPhoto data={data} />
      </div>
      <div className="px-2" style={{ minWidth: "170px" }}>
        <PersonalInfo data={data} />
      </div>
      <div className="px-2" style={{ minWidth: "170px" }}>
        <PersonalInfo2 data={data} />
      </div>
      <div className="px-2" style={{ minWidth: "300px" }}>
        <ContactInfo data={data} />
      </div>
      <div className="px-2">
        <OtherInfo
          data={data}
          settings={enabledSettings}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

RegistrantProfile.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default RegistrantProfile;
