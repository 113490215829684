import React from "react";
// import PropTypes from 'prop-types';

export const useIsConnected = () => {
  const [isOnline, setIsOnline] = React.useState(true);
  React.useEffect(() => {
    const onOnline = () => {
      setIsOnline(true);
    };
    const onOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);
    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, []);
  return [isOnline];
};

function ConnectionInfo() {
  const [isOnline] = useIsConnected();
  if (isOnline) return null;
  return (
    <div className="text-center bg-danger text-white">
      <small>Looks like you are disconnected from the internet!</small>
    </div>
  );
}

ConnectionInfo.propTypes = {};

export default ConnectionInfo;
