import React from "react";
import _ from "lodash";
import {
  SUPER_ADMIN_ACCESS_KEY as SUPER_ADMIN,
  ADMIN_ACCESS_KEY as ADMIN,
  SUB_USER_ACCESS_KEY as SUB_USER,
  AUTHENTICATION,
} from "modules/auth/constants";
import { useApiGet } from "react-reqq";

export const MODULES = [
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/dashboard",
    access: [SUPER_ADMIN, ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "logbook",
    name: "Logbook",
    path: "/logbook",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "lb-digital",
    name: "Digital Logbook",
    path: "/logbook/lb-digital",
    access: [ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "lb-list",
    name: "Logbook Summary",
    path: "/logbook/lb-list",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "lb-entry-points",
    name: "Logbook Entry Points",
    path: "/logbook/lb-entry-points",
    access: [ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "lb-export",
    name: "Logbook Export",
    path: "/logbook/lb-export",
    access: [ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "contact-tracing",
    name: "Contact Tracing",
    path: "/contact-tracing",
    access: [SUPER_ADMIN, ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "reports",
    name: "Reports",
    path: "/reports",
    access: [SUPER_ADMIN, ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "registrants",
    name: "Travelers",
    path: "/registrants",
    access: [SUPER_ADMIN, ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "trips",
    name: "Trips",
    path: "/trips",
    access: [SUPER_ADMIN, ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "special-survey",
    name: "Special Survey",
    path: "/special-survey",
    access: [SUPER_ADMIN, ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "content-management",
    name: "Content-management",
    path: "/content-management",
    access: [SUPER_ADMIN, ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "acl-sub-accounts",
    name: "Sub Accounts",
    path: "/sub-accounts",
    access: [ADMIN],
    visibleWithoutAccess: true,
  },
  {
    key: "scanner",
    name: "Scanner",
    path: "/scanner",
    access: [ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
];

// {
//   key: "logbook-dashboard",
//   name: "Logbook Dashboard",
//   path: "/logbook",
//   access: [SUPER_ADMIN, ADMIN],
//   visibleWithoutAccess: true,
// },

// {
//   key: "front-liner",
//   name: "Logbook Scanner",
//   path: "/logbook/front-liner",
//   access: [SUPER_ADMIN, ADMIN],
//   visibleWithoutAccess: true,
//   children: [
//     {
//       key: "front-liner-create",
//       name: "Create",
//     },
//     {
//       key: "front-liner-update",
//       name: "Update",
//     },
//     {
//       key: "front-liner-delete",
//       name: "Delete",
//     },
//   ],
// },

/*
export const MODULES = [
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/",
    children: [
      {
        key: "dashboard-activity-meter",
        name: "Activity Meter",
      },
      {
        key: "dashboard-view-condition-detail",
        name: "View Condition Detail",
      },
      {
        key: "dashboard-view-marker-detail",
        name: "View Marker Detail",
      },
      // {
      //   key: "dashboard-regional",
      //   name: "View Regional",
      // },
    ],
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "logbook-dashboard",
    name: "Digital Logbook Dashboard",
    path: "/logbook",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "logbook",
    name: "Digital Logbook",
    path: "/logbook/list",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "front-liner",
    name: "Digital Logbook Scanner",
    path: "/logbook/front-liner",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
    children: [
      {
        key: "front-liner-create",
        name: "Create",
      },
      {
        key: "front-liner-update",
        name: "Update",
      },
      {
        key: "front-liner-delete",
        name: "Delete",
      },
    ],
  },
  {
    key: "covid-dashboard",
    name: "Covid-19 Status",
    path: "/covid-19",
    access: [SUPER_ADMIN], // ADMIN, SUB_USER
    visibleWithoutAccess: true,
  },
  {
    key: "world-dashboard",
    name: "World",
    path: "/visualizer",
    access: [SUPER_ADMIN], // ADMIN, SUB_USER
    visibleWithoutAccess: true,
  },
  {
    key: "reports",
    name: "Reports",
    path: "/reports",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
    // children: [
    //   {
    //     key: "digital-contact-tracing-search",
    //     name: "Search",
    //   },
    // ],
  },
  {
    key: "digital-contact-tracing",
    name: "Digital Contact Tracing",
    path: "/contact-tracing",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
    children: [
      {
        key: "digital-contact-tracing-search",
        name: "Search",
      },
    ],
  },
  // {
  //   key: "manual-contact-tracing",
  //   name: "Manual Contact Tracing",
  //   path: "/manual-contact-tracing",
  //   access: [SUPER_ADMIN, ADMIN, SUB_USER],
  //   visibleWithoutAccess: true,
  //   children: [
  //     {
  //       key: "manual-contact-tracing-search",
  //       name: "Search",
  //     },
  //     {
  //       key: "manual-contact-tracing-full-info",
  //       name: "View Full Information",
  //     },
  //   ],
  // },
  {
    key: "scan-area",
    name: "Scan Area",
    path: "/scan-area",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
    children: [
      {
        key: "scan-area-view-pin",
        name: "View Pin",
      },
    ],
  },
  {
    key: "registrants-dashboard",
    name: "Registrants Dashboard",
    path: "/users",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "registrants",
    name: "Registrants Management",
    path: "/users/list",
    children: [
      {
        key: "registrant-contact-tracing",
        name: "Contact Tracing",
      },
      {
        key: "registrant-family",
        name: "Family",
      },
      {
        key: "registrant-history",
        name: "History",
      },
    ],
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "registrants-search",
    name: "Registrants Search",
    path: "/users/search",
    children: [],
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "cases-dashboard",
    name: "Cases Dashboard",
    path: "/cases",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    // visibleWithoutAccess: true,
  },
  {
    key: "cases",
    name: "Case Management",
    path: "/cases/list",
    children: [
      // {
      //   key: "case-filter-by-lgu",
      //   name: "Filter by LGU",
      // },
      {
        key: "case-create",
        name: "Create",
      },
      {
        key: "case-edit",
        name: "Edit",
      },
      {
        key: "case-delete",
        name: "Delete",
      },
      {
        key: "case-print",
        name: "Print",
      },
      {
        key: "case-link-unlink",
        name: "View Linked",
      },
      {
        key: "case-update-history",
        name: "Add Findings",
      },
      // {
      //   key: "case-add-traced-contact",
      //   name: "Add Traced Contact",
      // },
      {
        key: "case-add-location-history",
        name: "Add Place of Contact",
      },
      {
        key: "case-contact-network",
        name: "View Contact Network",
      },
      {
        key: "case-traced-location",
        name: "View Traced Movement",
      },
      {
        key: "case-update-history-0001",
        name: "Set Findings (Probable)",
      },
      {
        key: "case-update-history-0003",
        name: "Set Findings (Confirmed)",
      },
      {
        key: "case-update-history-0004",
        name: "Set Findings (Cleared)",
      },
    ],
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  // {
  //   key: "case-management-dashboard",
  //   name: "Covid Kaya Summary",
  //   path: "/cases/case-management-dashboard",
  //   access: [SUPER_ADMIN, ADMIN],
  // },
  // {
  //   key: "case-management",
  //   name: "Covid Kaya Management",
  //   path: "/cases/case-management",
  //   children: [
  //     {
  //       key: "case-management-create",
  //       name: "Create",
  //     },
  //     {
  //       key: "case-management-update",
  //       name: "Update",
  //     },
  //     {
  //       key: "case-management-delete",
  //       name: "Delete",
  //     },
  //     {
  //       key: "case-management-logs",
  //       name: "Logs",
  //     },
  //     {
  //       key: "case-management-print",
  //       name: "Print",
  //     },
  //   ],
  //   access: [SUPER_ADMIN, ADMIN],
  //   // visibleWithoutAccess: true,
  // },
  // {
  //   key: "case-management-encoder",
  //   name: "Covid Kaya Encoder",
  //   path: "/cases/case-management-encoder",
  //   access: [SUPER_ADMIN, ADMIN],
  // },
  {
    key: "cases-data-management",
    name: "Case Data Management",
    path: "/cases/data-management",
    children: [
      {
        key: "cases-data-management-upload-data",
        name: "Upload Data",
      },
      {
        key: "cases-data-management-always-show-upload-data-modal",
        name: "Always show Quick Upload",
      },
    ],
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
  },
  {
    key: "employees",
    name: "Employees",
    note: "For Private Only",
    access: [SUPER_ADMIN, ADMIN, SUB_USER],
    visibleWithoutAccess: true,
    path: "/employees",
    special_condition: (profile) => _.get(profile, "category") === "PRIVATE",
  },
  {
    key: "data-source",
    name: "Data Source",
    access: [SUPER_ADMIN],
    visibleWithoutAccess: false,
    path: "/data-source",
  },
  {
    key: "content-management",
    name: "Content Management",
    path: "/content-management",
    access: [SUPER_ADMIN],
    visibleWithoutAccess: false,
  },
  {
    key: "access-control",
    name: "Access Control",
    path: "/access-control",
    access: [SUPER_ADMIN],
    visibleWithoutAccess: false,
  },
  {
    key: "acl-sub-accounts",
    name: "Sub Accounts",
    path: "/access-control/sub-accounts",
    access: [ADMIN],
    visibleWithoutAccess: true,
  },
  // {
  //   key: "acl-contact-tracer",
  //   name: "Contact Tracer",
  //   path: "/access-control/tracer",
  //   access: [ADMIN, SUB_USER],
  //   visibleWithoutAccess: true,
  //   children: [
  //     {
  //       key: "acl-contact-tracer-create",
  //       name: "Add",
  //     },
  //     {
  //       key: "acl-contact-tracer-update",
  //       name: "Edit",
  //     },
  //     {
  //       key: "acl-contact-tracer-delete",
  //       name: "Delete",
  //     },
  //     {
  //       key: "acl-contact-tracer-assign-case",
  //       name: "Assign Case",
  //     },
  //   ],
  // },
];
*/

const emptyArray = [];

const specialCondition = (item, profile) => {
  if (!item.special_condition) return true;
  if (typeof item.special_condition === "function")
    return item.special_condition(profile);
  return true;
};

export const useAclModules = (access_key) => {
  const [modules, setModules] = React.useState([]);
  const { profile } = useApiGet(AUTHENTICATION, {});
  const isSuperAdmin = profile.access_control === SUPER_ADMIN;
  const allowedPerms = _.get(profile, "acl.permission") || emptyArray;

  React.useEffect(() => {
    const newModules = MODULES.filter(
      (item) =>
        item.access.indexOf(access_key) > -1 &&
        (allowedPerms.indexOf(item.key) > -1 || isSuperAdmin)
    ).map((item) => ({
      ...item,
      children: (item.children || []).filter(
        (sub) => allowedPerms.indexOf(sub.key) > -1 || isSuperAdmin
      ),
    }));
    setModules(newModules);
  }, [allowedPerms, isSuperAdmin]);
  return modules;
};

export const useSuperAdminReadOnly = () => {
  const { profile } = useApiGet(AUTHENTICATION, {});
  const isSuperAdmin = profile.access_control === SUPER_ADMIN;
  const [isReadOnly, setIsReadOnly] = React.useState(true);
  React.useEffect(() => {
    const perms = _.get(profile, "acl.permission") || [];
    setIsReadOnly(perms.length > 0);
  }, [profile]);
  return isSuperAdmin && isReadOnly;
};

export const useAclKeys = () => {
  const { profile } = useApiGet(AUTHENTICATION, {});
  const [modules, setModules] = React.useState([]);
  React.useEffect(() => {
    const perms = _.get(profile, "acl.permission") || [];

    const newModules = MODULES.filter(
      (item) =>
        specialCondition(item, profile) &&
        item.access.indexOf(profile.access_control) > -1 &&
        (perms.indexOf(item.key) > -1 ||
          [SUPER_ADMIN].indexOf(profile.access_control) > -1)
    );

    setModules(newModules.map((item) => item.key));
  }, [profile]);
  return modules;
};

export const useAclKeysSidebar = () => {
  const { profile } = useApiGet(AUTHENTICATION, {});
  const [modules, setModules] = React.useState([]);
  React.useEffect(() => {
    const newModules = MODULES.filter(
      (item) =>
        item.visibleWithoutAccess ||
        [SUPER_ADMIN].indexOf(profile.access_control)
    );
    setModules(newModules.map((item) => item.key));
  }, [profile]);
  return modules;
};

export const findModule = (key) => MODULES.find((item) => item.key === key);
