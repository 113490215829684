import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { HeaderTitle } from "modules/common/components/Header";
import {
  ContactInfo,
  OtherInfo,
  PersonalInfo,
  PersonalInfo2,
  PersonalPhoto,
} from "../registrants/RegistrantProfile";
import { useRegistrantById } from "../registrants/hooks";
import { useTripById } from "./hooks";
import {
  EmploymentInformation,
  FlightVesselInformation,
  IslandDestination,
  OtherForms,
  TravelInformation,
} from "./TripInfo";

function TripPage() {
  const location = useLocation();
  const match = useRouteMatch();
  const [, trip] = useTripById(match.params.id);
  const [, registrant] = useRegistrantById(trip?.registrant?.id);
  const combineTripInfo = React.useMemo(() => ({ ...registrant, ...trip }), [
    registrant,
    trip,
  ]);
  return (
    <>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Trip Details</div>
      </HeaderTitle>
      <div className="mb-3 d-flex align-items-center">
        <Link
          className="btn btn-outline-primary"
          to={location?.state?.from || "/trips"}
        >
          Go Back
        </Link>
        <div className="ml-2">
          <div className="font-weight-bold">Trip Details</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card p-3 mb-3">
            <div className="row">
              <div className="col-6">
                <div className="mb-4">
                  <TravelInformation data={combineTripInfo} />
                </div>
                <div className="mb-4">
                  <EmploymentInformation data={combineTripInfo} />
                </div>
                <div className="mb-0">
                  <OtherForms data={combineTripInfo} />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-4">
                  <FlightVesselInformation data={combineTripInfo} />
                </div>
                <div className="mb-0">
                  <IslandDestination data={combineTripInfo} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card p-3 mb-3">
            <div className="form-group">
              <PersonalPhoto data={combineTripInfo} />
            </div>
            <div className="form-group">
              <PersonalInfo data={combineTripInfo} />
              <PersonalInfo2 data={combineTripInfo} noHeader />
            </div>
            <div className="form-group">
              <ContactInfo data={combineTripInfo} />
            </div>
            <div className="form-group">
              <OtherInfo data={registrant} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TripPage;
