/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { req } from "react-reqq";
import _ from "lodash";

function FormEntryPointSelect({
  name,
  onChange,
  label,
  value,
  defaultOption,
  otherPayload,
  ...rest
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const loadOptions = React.useCallback(
    _.debounce((search, callback) => {
      setIsLoading(true);
      req.get({
        key: "LIST_ENTRY_POINT_ASYNC",
        url: "/api/v1/mng/location-qr-code",
        params: { keyword: search, per_page: 1000, ...otherPayload },
        onSuccess: (res) => {
          setIsLoading(false);
          const newOptions = (_.get(res, "response.data") || []).map(
            (item) => ({
              value: _.get(item, "attributes.uuid"),
              label: `${_.get(item, "attributes.type")} - ${_.get(
                item,
                "attributes.label"
              )}`,
            })
          );
          callback(newOptions);
        },
      });
    }, 500),
    [value]
  );
  const handleChange = (newValue) => {
    onChange((prev) => ({ ...prev, [name]: newValue || [] }));
  };
  return (
    <>
      {label && <small>{label}</small>}
      <AsyncSelect
        // isMulti
        isLoading={isLoading}
        placeholder="Search Entry Point..."
        cacheOptions
        onChange={handleChange}
        defaultOptions
        loadOptions={loadOptions}
        value={value}
        {...rest}
      />
    </>
  );
}

FormEntryPointSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  defaultOption: PropTypes.bool,
  label: PropTypes.string,
  otherPayload: PropTypes.instanceOf(Object),
};

FormEntryPointSelect.defaultProps = {
  defaultOption: true,
  label: "",
  otherPayload: {},
};

export default FormEntryPointSelect;
