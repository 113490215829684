import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { useApiGet, useApiLoading } from "react-reqq";
import moment from "moment";
import DayPicker from "modules/common/components/DayPicker";
import * as actions from "../actions";
import * as c from "../constants";

// const getStartEndOfMonth = (date) => ({
//   start: date.clone().startOf("month"),
//   end: date.clone().endOf("month"),
// });

function BarChart({ id, label, action: actionOverride, layout, marginLeft }) {
  const { chart, keys } = useApiGet(`${c.BAR_CHART}/${id}`, {});
  const isLoading = useApiLoading(`${c.BAR_CHART}/${id}`, "get");
  const [filter, setFilter] = React.useState({
    date: moment().format("YYYY-MM-DD"),
  });
  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: 10,
        },
      },
    },
  };
  React.useEffect(() => {
    const action = actionOverride[id];
    // const { start, end } = getStartEndOfMonth(moment(filter.date));
    const payload = {
      // from: start.format('YYYY-MM-DD'),
      // to: end.format('YYYY-MM-DD'),
    };
    if (typeof action === "function") action(payload);
  }, [id, filter, actionOverride]);
  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="p-3 border-b d-flex align-items-center justify-content-between">
        <div>
          <span className="font-weight-bold">{label}</span>
          {isLoading && <span className="text-muted ml-2">Loading...</span>}
        </div>
        {false && (
          <div>
            <DayPicker
              name="date"
              onChange={setFilter}
              value={filter.date}
              format="MMM YYYY"
              labelWidth={90}
              increment={[1, "month"]}
            />
          </div>
        )}
      </div>
      <div className="flex-grow-1 position-relative">
        {(!chart || isLoading) && (
          <div className="position-absolute h-100 w-100 bg-white">
            <div className="text-center mt-5">
              {isLoading ? "Loading..." : "No Data"}
            </div>
          </div>
        )}
        <ResponsiveBar
          layout={layout}
          data={chart || []}
          keys={keys || []}
          indexBy="label"
          margin={{ top: 10, right: 10, bottom: 70, left: marginLeft }}
          padding={0.15}
          minValue={0}
          colors={["#176AB9", "#17B978"]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          groupMode="grouped"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -30,
            legend: label,
            legendPosition: "middle",
            legendOffset: 55,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={14}
          labelSkipHeight={14}
          // labelTextColor={{ from: "color", modifiers: [["darker", "2"]] }}
          labelTextColor="#fff"
          legends={[]}
          animate
          motionStiffness={90}
          motionDamping={15}
          theme={theme}
        />
      </div>
    </div>
  );
}

BarChart.defaultProps = {
  action: actions.barChart,
  layout: "vertical",
  marginLeft: 50,
};

BarChart.propTypes = {
  marginLeft: PropTypes.number,
  layout: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.instanceOf(Object),
};

export default BarChart;
