import React from 'react';
import PropTypes from 'prop-types';
import FormTextArea from 'modules/common/form/FormTextArea';
import { useApiGet } from 'react-reqq';
import { FORM_ANSWERS } from 'modules/assessment/constants';
import { setAnswer } from './actions';
import { useIsSaving } from './hooks';
import RetryMessage from './RetryMessage';

function QuestionParagraph({ data, readOnly }) {
  const value = useApiGet(`${FORM_ANSWERS}.${data.id}.value`, '');
  const [isSaving, setIsSaving] = React.useState(false);
  useIsSaving(data.id, isSaving);
  const setValue = (x) => {
    const { value: newValue } = x();
    setIsSaving(true);
    setAnswer(
      {
        [data.id]: {
          value: newValue,
        },
      },
      () => setIsSaving(false)
    );
  };
  return (
    <div className="p-2 rounded shadow-md w-full bg-white">
      <div className="float-right text-green-500">
        <i
          className={`fa fa-spin fa-spinner ${
            isSaving ? 'visible' : 'invisible'
          }`}
        />
      </div>
      <div className="mb-2 text-base font-bold">
        <span className="text-semibold">{data.question}</span>
        {!data.required && (
          <span className="ml-2 text-gray-500">(Optional)</span>
        )}
      </div>
      <FormTextArea
        name="value"
        onChange={setValue}
        value={value}
        icon={null}
        required={data.required}
        readOnly={readOnly}
      />
      <RetryMessage isSaving={isSaving} />
    </div>
  );
}

QuestionParagraph.defaultProps = {
  readOnly: false,
};

QuestionParagraph.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  readOnly: PropTypes.bool,
};

export default QuestionParagraph;
