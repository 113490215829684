import React from "react";
import PropTypes from "prop-types";
import { useApiLoading } from "react-reqq";
import FormInput from "modules/common/form/FormInput";
import _ from "lodash";
import * as actions from "../actions";
import * as c from "../constants";

function ChangePasswordModal({ value, onClose }) {
  const isLoading = useApiLoading(c.CHANGE_PASSWORD, "put");
  const [form, setForm] = React.useState({
    old_password: _.get(value, "old_password") || "",
    password: "",
    password_confirmation: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.changePassword(form, onClose);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          {!_.get(value, "old_password") && (
            <div className="col-12 form-group">
              <FormInput
                name="old_password"
                label="Current Password"
                onChange={setForm}
                value={form.old_password}
                // required
                autoFocus
                type="password"
                required
              />
            </div>
          )}
          <div className="col-12 form-group">
            <FormInput
              name="password"
              label="New Password"
              onChange={setForm}
              value={form.password}
              type="password"
              required
            />
          </div>
          <div className="col-12 form-group">
            <FormInput
              name="password_confirmation"
              label="New Password Confirmation"
              onChange={setForm}
              value={form.password_confirmation}
              type="password"
              required
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-primary" type="submit" disabled={isLoading}>
          Change Password
        </button>
        <button
          className="btn btn-outline-primary ml-1"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

ChangePasswordModal.propTypes = {
  value: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
};

ChangePasswordModal.defaultProps = {
  value: {},
};

export default ChangePasswordModal;
