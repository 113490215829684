import React from 'react';

function UserNoSelected() {
  return (
    <div className="border rounded bg-white flex-grow-1 d-flex justify-content-center align-items-center">
      No Selected.
    </div>
  );
}

export default UserNoSelected;
