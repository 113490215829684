import React from "react";
import PropTypes from "prop-types";
import { useApiLoading } from "react-reqq";
import FormInput from "modules/common/form/FormInput";
// eslint-disable-next-line import/no-cycle
import * as actions from "../actions";
import * as c from "../constants";

function MfaLoginModal({ payload, onClose }) {
  const isLoading = useApiLoading(c.MFA, "post");
  const [form, setForm] = React.useState({
    code: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.loginWithMFA(
      {
        ...payload,
        code: form.code,
      },
      () => {
        setForm({ code: "" });
      },
      onClose
    );
  };
  const [what, setWhat] = React.useState(false);
  const toggleWhat = (e) => {
    e.preventDefault();
    setWhat((state) => !state);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 form-group">
            <FormInput
              name="code"
              label="Please enter MFA 6 Digit PIN"
              onChange={setForm}
              value={form.code}
              autoFocus
            />
          </div>
        </div>
        <div>
          <a href="/" onClick={toggleWhat}>
            Where do I get the 6 digit pin?
          </a>
        </div>
        {what && (
          <div>
            <ol>
              <li>Open your MFA Authentication App</li>
              <li>Use the 6 digit pin currently displayed under StaySafe</li>
            </ol>
            <div className="text-danger">
              Imporant: The 6-digit pin changes every couple of seconds, make
              sure to use the currently displayed 6-digit pin.
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button className="btn btn-danger" type="submit" disabled={isLoading}>
          Submit
        </button>
        <button
          className="btn btn-outline-primary ml-1"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

MfaLoginModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
};

export default MfaLoginModal;
