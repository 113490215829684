import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ReportContainer from "./ReportContainer";
import ReportPrint from "./ReportPrint";

function ReportRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.url} component={ReportContainer} />
      <Route path={`${match.url}/:id/print`} component={ReportPrint} />
    </Switch>
  );
}

ReportRoutes.propTypes = {};

export default ReportRoutes;
