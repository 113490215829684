import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

function GenericDetail({ format, data, label, labelWidth }) {
  return (
    <table className="table table-striped table-borderless table-sm mb-0">
      {label ? (
        <thead>
          <tr>
            <td colSpan="2">{label}</td>
          </tr>
        </thead>
      ) : null}
      <tbody>
        {format.map((x) => {
          const val =
            typeof _.get(data, x.key) === "undefined"
              ? "-"
              : _.get(data, x.key);
          const value = typeof x.key === "function" ? x.key(data) : val;
          return (
            <tr key={typeof x.key !== "function" ? x.key : x.label}>
              <td className="label" width={labelWidth}>
                <small>{x.label}</small>
              </td>
              <td className={`answer ${x.className}`}>
                {(_.isEmpty(`${value}`) || typeof value === "object") &&
                typeof x.key !== "function" ? (
                  <span>&nbsp;</span>
                ) : (
                  value
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

GenericDetail.propTypes = {
  label: PropTypes.string,
  data: PropTypes.instanceOf(Object).isRequired,
  format: PropTypes.instanceOf(Array).isRequired,
  labelWidth: PropTypes.string,
};

GenericDetail.defaultProps = {
  label: "",
  labelWidth: "auto",
};

export default React.memo(GenericDetail);
