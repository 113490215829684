import _ from "lodash";
import { req } from "react-reqq";
import { ToastSuccess } from "modules/common/components/toast";
import * as c from "./constants";

export const listModuleGroup = () =>
  req.get({
    key: c.MODULE_GROUP,
    url: "/api/mng/acl/module_group",
    transform: (res) => res.data,
    cache: true,
  });

export const listRole = (onSuccess) =>
  req.list({
    key: c.ROLE,
    url: "/api/mng/acl/role",
    transform: (res) => res.data,
    onSuccess,
  });

export const selectRole = (role_id) =>
  req.show({
    key: c.ROLE,
    url: `/api/mng/acl/role/${role_id}`,
    transform: (res) => {
      const permissions = _.get(res, "data.permissions") || [];
      const keys = {};
      _.forOwn(permissions, (v) => {
        keys[v.code] = true;
      });
      req.set(c.ROLE_KEYS, keys);
      return res.data;
    },
    onError: () => {
      req.set(c.ROLE_KEYS, null);
    },
  });

export const addRole = (payload, onSuccess) =>
  req.post({
    key: c.ROLE,
    url: "/api/mng/acl/role",
    payload,
    onSuccess,
  });

export const updateRole = (id, payload, onSuccess) =>
  req.put({
    key: c.ROLE,
    url: `/api/mng/acl/role/${id}`,
    payload,
    onSuccess,
  });

export const removeRole = (id, onSuccess) =>
  req.remove({
    key: c.ROLE,
    url: `/api/mng/acl/role/${id}`,
    onSuccess,
  });

export const syncRolePermissions = (id, payload, onSuccess) =>
  req.put({
    key: c.ROLE,
    url: `/api/mng/acl/role/${id}/sync_permission`,
    payload,
    onSuccess,
  });

export const syncUserRole = (user_id, payload, onSuccess) =>
  req.put({
    key: c.ROLE,
    url: `/api/mng/acl/user/${user_id}/role/sync`,
    payload,
    onSuccess,
  });

export const listAdmin = (params) =>
  req.get({
    key: c.LIST_ADMIN,
    url: "/api/v1/mng/administrators",
    params,
    transform: (res) => {
      req.set(`${c.LIST_ADMIN}/pager`, _.get(res, "meta.pagination") || {});
      return _.get(res, "data") || [];
    },
  });

export const selectAdmin = (user_id) => {
  req.set(
    c.SELECT_ADMIN,
    (state) =>
      (_.get(state, `${c.LIST_ADMIN}`) || []).find(
        (x) => `${_.get(x, "attributes.uuid")}` === `${user_id}`
      ) || {}
  );
  req.get({
    key: c.SELECT_ADMIN,
    url: `/api/v1/mng/administrators/${user_id}`,
    transform: (res) => res.data || {},
  });
};

export const createAdmin = (payload, onSuccess, withMessage = true) => {
  req.post({
    key: c.SELECT_ADMIN,
    url: "/api/v1/mng/administrators",
    payload,
    onSuccess: (res) => {
      if (withMessage) ToastSuccess("Created!");
      // const newId = _.get(res, "response.data.attributes.uuid");
      const data = _.get(res, "response.data");
      listAdmin();
      if (onSuccess) onSuccess(data);
    },
  });
};

export const updateAdmin = (
  user_id,
  payload,
  onSuccess,
  withMessage = true
) => {
  req.put({
    key: c.SELECT_ADMIN,
    url: `/api/v1/mng/administrators/${user_id}`,
    payload,
    onSuccess: (res) => {
      if (withMessage) ToastSuccess("Updated!");
      req.set(c.LIST_ADMIN, (state) => {
        const oldList = _.get(state, c.LIST_ADMIN) || [];
        return oldList.map((item) =>
          _.get(item, "attributes.uuid") === user_id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
      req.set(c.SELECT_ADMIN, (state) => {
        const oldData = _.get(state, c.SELECT_ADMIN) || {};
        return {
          ...oldData,
          attributes: { ...(oldData.attributes || {}), ...payload },
        };
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const updateAdminAcl = (
  user_id,
  payload,
  onSuccess,
  withMessage = true
) => {
  req.put({
    key: c.SELECT_ADMIN,
    url: `/api/v1/mng/administrators/${user_id}/acl`,
    payload,
    onSuccess: (res) => {
      if (withMessage) ToastSuccess("Updated!");
      selectAdmin(user_id);
      req.set(c.SELECT_ADMIN, (state) => {
        const oldValue = state[c.SELECT_ADMIN] || {};
        return {
          ...oldValue,
          attributes: { ...(oldValue.attributes || {}), acl: payload.acl },
        };
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const resetPasswordAdmin = (user_id, payload, onSuccess) => {
  req.put({
    key: c.RESET_PW_ADMIN,
    url: `/api/v1/mng/administrators/${user_id}/reset-password`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Password updated!");
      if (onSuccess) onSuccess(res);
    },
  });
};

export const removeAdmin = (user_id, onSuccess) => {
  req.remove({
    key: c.SELECT_ADMIN,
    url: `/api/v1/mng/administrators/${user_id}`,
    onSuccess: (res) => {
      req.set(c.SELECT_ADMIN, {});
      req.set(c.LIST_ADMIN, (state) => {
        const oldList = _.get(state, c.LIST_ADMIN) || [];
        return oldList.filter(
          (x) => `${_.get(x, "attributes.uuid")}` !== `${user_id}`
        );
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const listSubUser = (params) =>
  req.get({
    key: c.LIST_SUB_USER,
    url: "/api/v1/mng/sub-administrators",
    params,
    transform: (res) => {
      req.set(`${c.LIST_SUB_USER}/pager`, _.get(res, "meta.pagination") || {});
      return _.get(res, "data") || [];
    },
  });

export const selectSubUser = (user_id) => {
  req.set(
    c.SELECT_SUB_USER,
    (state) =>
      (_.get(state, `${c.LIST_SUB_USER}`) || []).find(
        (x) => `${_.get(x, "attributes.uuid")}` === `${user_id}`
      ) || {}
  );
  req.get({
    key: c.SELECT_SUB_USER,
    url: `/api/v1/mng/sub-administrators/${user_id}`,
    transform: (res) => res.data || {},
  });
};

export const createSubUser = (payload, onSuccess, withMessage = true) => {
  req.post({
    key: c.SELECT_SUB_USER,
    url: "/api/v1/mng/sub-administrators",
    payload,
    onSuccess: (res) => {
      if (withMessage) ToastSuccess("Created!");
      // const newId = _.get(res, "response.data.attributes.uuid");
      const data = _.get(res, "response.data");
      listSubUser();
      if (onSuccess) onSuccess(data);
    },
  });
};

export const updateSubUser = (
  user_id,
  payload,
  onSuccess,
  withMessage = false
) => {
  req.put({
    key: c.SELECT_SUB_USER,
    url: `/api/v1/mng/sub-administrators/${user_id}`,
    payload,
    onSuccess: (res) => {
      if (withMessage) ToastSuccess("Updated!");
      req.set(c.LIST_SUB_USER, (state) => {
        const oldList = _.get(state, c.LIST_SUB_USER) || [];
        return oldList.map((item) =>
          _.get(item, "attributes.uuid") === user_id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
      req.set(c.SELECT_SUB_USER, (state) => {
        const oldData = _.get(state, c.SELECT_SUB_USER) || {};
        return {
          ...oldData,
          attributes: { ...(oldData.attributes || {}), ...payload },
        };
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const updateSubUserAcl = (
  user_id,
  payload,
  onSuccess,
  withMessage = true
) => {
  req.put({
    key: c.SELECT_SUB_USER,
    url: `/api/v1/mng/sub-administrators/${user_id}/acl`,
    payload,
    onSuccess: (res) => {
      if (withMessage) ToastSuccess("Updated!");
      selectSubUser(user_id);
      req.set(c.SELECT_SUB_USER, (state) => {
        const oldValue = state[c.SELECT_SUB_USER] || {};
        return {
          ...oldValue,
          attributes: { ...(oldValue.attributes || {}), acl: payload.acl },
        };
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const resetPasswordSubUser = (user_id, payload, onSuccess) => {
  req.put({
    key: c.RESET_PW_SUB_USER,
    url: `/api/v1/mng/sub-administrators/${user_id}/reset-password`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Password updated!");
      if (onSuccess) onSuccess(res);
    },
  });
};

export const removeSubUser = (user_id, onSuccess) => {
  req.remove({
    key: c.SELECT_SUB_USER,
    url: `/api/v1/mng/sub-administrators/${user_id}`,
    onSuccess: (res) => {
      req.set(c.SELECT_SUB_USER, {});
      req.set(c.LIST_SUB_USER, (state) => {
        const oldList = _.get(state, c.LIST_SUB_USER) || [];
        return oldList.filter(
          (x) => `${_.get(x, "attributes.uuid")}` !== `${user_id}`
        );
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const updateUsersAcl = (payload, onSuccess) => {
  req.post({
    key: c.UPDATE_USERS_ACL,
    url: "/api/v1/mng/administrators/acl",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!", res);
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });
};
