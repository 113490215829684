import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withInlineForm from "modules/common/hoc/withInlineForm";

const INIT_FILTER = {
  keyword: "",
  page: 1,
};

function UserFilter({
  onSubmit,
  isLoading,
  createInput,
  formData,
  setFormData,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData, page: 1 });
  };
  useEffect(() => {
    onSubmit(INIT_FILTER);
    setFormData(INIT_FILTER);
  }, []);
  return (
    <>
      <form className="p-3" onSubmit={handleSubmit}>
        {createInput({ key: "keyword", label: "Keyword", labelWidth: 90 })}
        <div className="mt-1">
          <button
            className="btn btn-primary btn-block"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="spinner-grow spinner-grow-sm mr-1" />
            ) : (
              <i className="fa fa-search mr-1" />
            )}
            Search
          </button>
        </div>
      </form>
    </>
  );
}

UserFilter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  createInput: PropTypes.func.isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default withInlineForm(UserFilter);
