/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

function FormMultipleSelect({
  name,
  onChange,
  label,
  value,
  options,
  isReadOnly,
}) {
  const [id] = React.useState(shortid.generate());
  const handleToggle = (row) => ({ target }) => {
    const newValue = Array.isArray(value) ? value : [];
    if (row === "None") {
      onChange((prev) => ({
        ...prev,
        [name]: ["None"],
      }));
      return;
    }
    onChange((prev) => ({
      ...prev,
      [name]: target.checked
        ? newValue.filter((item) => item !== "None").concat([row])
        : newValue.filter((item) => item !== row),
    }));
  };
  return (
    <>
      {label && <small>{label}</small>}
      {isReadOnly ? (
        <p className="form-control-plaintext">
          {Array.isArray(value)
            ? value
                .map(
                  (item) =>
                    (options.find((opt) => `${opt.value}` === `${item}`) || {})
                      .label || "-"
                )
                .join(", ")
            : "-"}
          &nbsp;
        </p>
      ) : (
        <div
          className="d-flex flex-wrap compressable"
          style={{ minHeight: 34 }}
        >
          {options.map((x) => (
            <div
              key={`${name}-${x.value}`}
              className="border rounded mr-1 mb-1 p-1 bg-light"
            >
              <div
                className="custom-control custom-checkbox"
                style={{ paddingLeft: "1.4rem" }}
              >
                <input
                  checked={
                    Array.isArray(value) ? value.indexOf(x.value) > -1 : false
                  }
                  onChange={handleToggle(x.value)}
                  className="custom-control-input"
                  type="checkbox"
                  id={`${name}-${x.label}-${id}`}
                />
                <label
                  htmlFor={`${name}-${x.label}-${id}`}
                  className="custom-control-label"
                >
                  {x.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

FormMultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
    .isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

FormMultipleSelect.defaultProps = {
  isReadOnly: false,
  label: "",
};

export default FormMultipleSelect;
