import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { formatNumber } from "../helper";

function GenericList({
  isLoading,
  list,
  pager,
  renderRow,
  selected: selectedId,
  onSelectRow,
  onChangePage,
  selectedKey,
}) {
  const handleSelectRow = (row) => (e) => {
    e.preventDefault();
    onSelectRow(row);
  };
  const handleChangePage = (newPage) => () => {
    onChangePage(newPage);
  };
  useEffect(() => {
    if (list.length > 0 && !selectedId) {
      onSelectRow(_.get(list, "0"));
    }
  }, [list, selectedId]);
  return (
    <>
      <div className="border box-fit-wrapper flex-grow-1">
        <div className="box-fit-overflow">
          <div className="list-group">
            {list.length < 1 ? (
              <div className="text-center text-muted">
                <small>
                  {isLoading ? "Loading..." : "There are no items."}
                </small>
              </div>
            ) : (
              list.map((x) => (
                <a
                  href="/"
                  className={`list-group-item list-group-item-action p-0 d-flex align-items-center overflow-hidden${
                    `${selectedId}` === `${_.get(x, selectedKey)}`
                      ? " active"
                      : ""
                  }`}
                  onClick={handleSelectRow(x)}
                  key={_.get(x, selectedKey)}
                >
                  {renderRow(x)}
                </a>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center p-2">
        <small className="text-muted">
          {formatNumber(pager.total || "0", 0)} item(s)
        </small>
        {!_.isEmpty(pager) && (pager.total_pages || pager.last_page) > 1 && (
          <div>
            <button
              className="btn btn-sm btn-outline-primary"
              type="button"
              disabled={isLoading}
              onClick={handleChangePage(pager.current_page - 1)}
            >
              Prev
            </button>
            <button
              className="btn btn-sm btn-outline-primary mx-1"
              type="button"
              disabled={isLoading}
            >
              {pager.current_page} of {pager.total_pages || pager.last_page}
            </button>
            <button
              className="btn btn-sm btn-outline-primary"
              type="button"
              disabled={isLoading || pager.current_page === pager.last_page}
              onClick={handleChangePage(pager.current_page + 1)}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </>
  );
}

GenericList.propTypes = {
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(Array).isRequired,
  pager: PropTypes.instanceOf(Object),
  selected: PropTypes.string,
  onSelectRow: PropTypes.func,
  renderRow: PropTypes.func.isRequired,
  onChangePage: PropTypes.func,
  selectedKey: PropTypes.string,
};

GenericList.defaultProps = {
  isLoading: false,
  onChangePage: () => {},
  selectedKey: "id",
  pager: {},
  selected: "",
  onSelectRow: () => {},
};

export default React.memo(GenericList);
