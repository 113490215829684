import React from 'react';

function AccessDenied() {
  return (
    <div className="text-left p-2">
      <div className="error mx-auto" data-text="ACCESS DENIED" style={{ fontSize: 36, width: 'auto' }}>
        ACCESS DENIED
      </div>
      <p className="lead text-gray-800 mb-5">You have no permission to access this module</p>
    </div>
  );
}

export default AccessDenied;
