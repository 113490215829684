import React from "react";
import PropTypes from "prop-types";
import { QuestionsContext } from "../constants";
import QuestionDate from "./QuestionDate";
import QuestionShortAns from "./QuestionShortAns";
import QuestionParagraph from "./QuestionParagraph";
import QuestionMultipleChoice from "./QuestionMultipleChoice";
import QuestionCheckbox from "./QuestionCheckbox";
import QuestionDropdown from "./QuestionDropdown";
import QuestionDropdownDataset from "./QuestionDropdownDataset";
import QuestionDropdownDatasetChild from "./QuestionDropdownDatasetChild";
import QuestionUploadUrl from "./QuestionUploadUrl";

function Questions({ isLoading, assessmentId, questions, editMode, answers }) {
  if (isLoading && questions.length < 1) {
    return (
      <>
        <div className="text-center text-muted">Loading questions...</div>
      </>
    );
  }
  return (
    <QuestionsContext.Provider value={{ questions }}>
      {questions.length < 1 ? (
        <div className="text-center p-5">
          <div className="text-gray-600">There are no questions.</div>
        </div>
      ) : (
        questions.map((item) => {
          if (item.type === "DATE")
            return (
              <QuestionDate
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "SHORT_ANS")
            return (
              <QuestionShortAns
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "PARAGRAPH")
            return (
              <QuestionParagraph
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "MULTIPLE_CHOICE")
            return (
              <QuestionMultipleChoice
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "CHECKBOX")
            return (
              <QuestionCheckbox
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "DROPDOWN")
            return (
              <QuestionDropdown
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "DROPDOWN_DATASET")
            return (
              <QuestionDropdownDataset
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "DROPDOWN_DATASET_CHILD")
            return (
              <QuestionDropdownDatasetChild
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (item.type === "UPLOAD_URL")
            return (
              <QuestionUploadUrl
                key={item.id}
                assessmentId={assessmentId}
                data={item}
                index={item?.sequence}
                editMode={editMode}
                answer={answers[item.id]}
              />
            );
          if (editMode) {
            return (
              <div className="p-3 text-center" key={item.id}>
                Unknown Question Type ({item.type || "n/a"})
              </div>
            );
          }
          return null;
        })
      )}
    </QuestionsContext.Provider>
  );
}

Questions.defaultProps = {
  assessmentId: "",
  editMode: false,
  isLoading: false,
  answers: {},
};

Questions.propTypes = {
  assessmentId: PropTypes.string,
  questions: PropTypes.instanceOf(Array).isRequired,
  answers: PropTypes.instanceOf(Object),
  editMode: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Questions;
