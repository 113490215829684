import React from "react";
import { motion } from "framer-motion";

import { useIntersectionObserver } from "modules/common/helper";

import Why1 from "assets/img/landing/home/why/ourself.svg";
import Why2 from "assets/img/landing/home/why/love-ones.svg";
import Why3 from "assets/img/landing/home/why/community.svg";

import "../../styles/main.scss";

const contents = [
  {
    title: "Protect Ourself",
    img: Why1,
    content:
      "We can protect ourselves from COVID-19 by maintaining social distance. Safe Travels Marianas will remind you that you have been exposed to a possible COVID-19 case.",
  },
  {
    title: "Protect Our Loved Ones",
    img: Why2,
    content:
      "We can protect our loved ones by sharing them this good news. The more people will use Safe Travels Marianas the more we can protect each other.",
  },
  {
    title: "Protect Our Community",
    img: Why3,
    content:
      "We can contribute to the national level tracing of COVID-19 by using Safe Travels Marianas in our own communities. Let's help each other, let's beat COVID-19!",
  },
];

export const cardVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

export default () => {
  const [control, ref] = useIntersectionObserver();
  return (
    <section className="landing__home-why-container landing-container">
      <h1 className="landing__home-why-title landing-heading-1">
        Why Safe Travels Marianas
      </h1>
      <div ref={ref} className="landing__home-why-img-parent-container">
        {contents.map(({ title, content, img }) => (
          <figure
            key={title}
            className="landing__home-why-img-container landing-text-center"
          >
            <motion.img
              src={img}
              alt="Why"
              className="landing__home-why-img"
              variants={cardVariants}
              initial="hidden"
              animate={control}
              transition={{ duration: 0.6 }}
            />

            <figcaption className="landing__home-why-img-content-container">
              <h4 className="landing__home-why-img-content-title landing-heading-4">
                {title}
              </h4>
              <p className="landing__home-why-img-content-description landing-paragraph">
                {content}
              </p>
            </figcaption>
          </figure>
        ))}
      </div>
    </section>
  );
};
