import React from "react";
import { NavLink } from "react-router-dom";

import Approve from "assets/img/landing/home/pre-footer/approve-with-eagle.png";
import Own from "assets/img/landing/home/pre-footer/own@2x.png";
import Support from "assets/img/landing/home/pre-footer/support@2x.png";
import MainLogo from "assets/img/landing/home/pre-footer/main-logo@2x.png";

import "../styles/main.scss";

const logos = [
  { img: Approve, title: "Approved By" },
  { img: Own, title: "Owned By" },
  { img: Support, title: "Supported By" },
];

export default () => (
  <section className="landing__prefooter">
    <div className="landing__prefooter-title-parent-container">
      <div className="landing__prefooter-title-container landing-container">
        {logos.map(({ title }, idx) => (
          <h6 key={title} className={`landing__prefooter-title--${idx + 1}`}>
            {title}
          </h6>
        ))}
      </div>
    </div>
    <figure className="landing__prefooter-logo-parent-container landing-container">
      {logos.map(({ img, title }, idx) => (
        <figure
          key={img}
          className={`landing__prefooter-logo-container--${idx + 1}`}
        >
          <figcaption>{title}</figcaption>
          <img
            src={img}
            alt={title}
            className={`landing__prefooter-logo landing__prefooter-logo--${
              idx + 1
            }`}
          />
        </figure>
      ))}
    </figure>
    <figure className="landing__prefooter-main-logo-container">
      <NavLink to="/">
        <img
          src={MainLogo}
          alt="Main"
          className="landing__prefooter-main-logo"
        />
      </NavLink>
    </figure>
    <ul className="landing__prefooter-links-container">
      <li className="landing__prefooter-link">
        <NavLink to="how-to-register">How To Register</NavLink>
      </li>
      <li className="landing__prefooter-link">
        <NavLink to="/solutions">Solutions</NavLink>
      </li>
      <li className="landing__prefooter-link">
        <NavLink to="faq">FAQ</NavLink>
      </li>
    </ul>
  </section>
);
