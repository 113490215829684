import React from "react";
import GenericTable from "modules/common/components/GenericTable";
import { useApiGet, useApiLoading } from "react-reqq";
import AssessmentSelected from "./AssessmentSelected";
import { useAssessmentList } from "../hooks";
import { countryDataset } from "../actions";

function AssessmentContainer() {
  const countries = useApiGet(`COUNTRY_DATASET`, []);
  const isLoadingCountries = useApiLoading(`COUNTRY_DATASET`, "get");
  const [selected, setSelected] = React.useState(null);
  const selectFirst = React.useCallback(
    (assessments) => {
      const definedList = assessments?.filter((l) => l.is_system_defined);
      setSelected(definedList[0]);
    },
    [setSelected]
  );
  const [isLoading, list] = useAssessmentList(
    { is_system_defined: 1 },
    selectFirst
  );
  const assessmentList = list?.filter((l) => l.is_system_defined);
  React.useEffect(() => {
    countryDataset("COUNTRY");
  }, []);

  React.useEffect(() => {
    countryDataset("COUNTRY");
  }, []);

  const getTableFormat = React.useCallback(
    () => [
      { key: "title", label: "NAME" },
      {
        label: "Validity",
        key: (row) => {
          return (
            <div>
              <span
                className={`badge ${
                  row?.validity_status === "ACTIVE"
                    ? "badge-success"
                    : "badge-danger"
                } mr-2`}
              >
                {row?.validity_status}
              </span>
            </div>
          );
        },
        width: 80,
      },
      {
        label: "Status",
        key: (row) => {
          return (
            <div>
              <span
                className={`badge ${
                  row?.status === "PUBLISHED" ? "badge-success" : "badge-danger"
                } mr-2`}
              >
                {row?.status}
              </span>
            </div>
          );
        },
        width: 80,
      },
    ],
    []
  );

  return (
    <div className="pt-2 bg-light row d-flex justify-content-between m-0 flex-grow-1">
      <div className="col-6 card card-body px-3" style={{ maxWidth: "49%" }}>
        <div className="box-fit-wrapper w-100 flex-grow-1">
          <div className="box-fit-overflow">
            <GenericTable
              isLoading={isLoading}
              format={getTableFormat()}
              data={assessmentList}
              selected={selected}
              rowHeight={42}
              onRowClick={
                typeof setSelected === "function"
                  ? ({ rowData }) => setSelected(rowData)
                  : null
              }
            />
          </div>
        </div>
      </div>
      <div className="col-6 card card-body px-3" style={{ maxWidth: "49%" }}>
        {selected?.id ? (
          <div className="row mx-0">
            <div className="col">
              <AssessmentSelected
                id={selected?.id}
                countries={countries}
                isLoadingCountries={isLoadingCountries}
              />
            </div>
          </div>
        ) : (
          <div className="row mx-0">
            <div className="col">
              <div className="text-muted text-center">
                {isLoading ? "Loading..." : "No active form"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

AssessmentContainer.propTypes = {};

export default AssessmentContainer;
