import { req } from 'react-reqq';
import * as c from './constants';

export const setGlobal = (data) => req.set(c.GLOBAL, data);

export const toggleSidebar = (toggled) => req.set(c.SIDEBAR, { toggled });

export const unlock = (payload, onSuccess, onError) => req.post({
  key: 'ACCESS_CONTROL_UNLOCK',
  url: '/api/auth',
  payload,
  onSuccess,
  onError,
});
