import { req } from "react-reqq";
import { ToastSuccess } from "modules/common/components/toast";
import * as c from "./constants";

export const getIntegration = (user_id, params) =>
  req.get({
    key: c.INTEGRATION,
    url: `/api/v1/mng/administrators/${user_id}/integrate`,
    params,
    transform: (res) => {
      return res.data;
    },
  });

export const addIntegration = (user_id, payload, onSuccess) =>
  req.post({
    key: c.INTEGRATION,
    url: `/api/v1/mng/administrators/${user_id}/integrate`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      getIntegration(user_id);
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const enableIntegration = (user_id, payload, onSuccess) => {
  req.get({
    key: `${c.INTEGRATION}/update`,
    url: `/api/v1/mng/administrators/${user_id}/integrate/activate`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      getIntegration(user_id);
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });
};

export const disableIntegration = (user_id, payload, onSuccess) => {
  req.get({
    key: `${c.INTEGRATION}/update`,
    url: `/api/v1/mng/administrators/${user_id}/integrate/deactivate`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      getIntegration(user_id);
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });
};

export const deleteIntegration = (user_id, onSuccess) => {
  req.remove({
    key: c.INTEGRATION,
    url: `/api/v1/mng/administrators/${user_id}/integrate`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      getIntegration(user_id);
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });
};

export const getMyIntegration = () => {
  req.get({
    key: c.MY_INTEGRATION,
    url: "/api/v1/my/integration",
    transform: (res) => {
      return res.data;
    },
  });
};
