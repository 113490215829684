import React from 'react'
import qs from "query-string";
// import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';

const PATHS = {
  web: 'https://www.safetravelsmarianas.com',
};

function ShareRedirect() {
  const location = useLocation();
  React.useEffect(() => {
    const query = qs.parse(location.search);
    const redirect_url = PATHS[query?.id];
    if (redirect_url) {
      window.location.href = redirect_url
    };
  }, []);
  return (
    <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0, left: 0, background: '#fff', zIndex: 9999 }}>
      <div className="p-1">Redirecting...Please wait</div>
    </div>
  );
}

ShareRedirect.propTypes = {

}

export default ShareRedirect

