import React from "react";
import PropTypes from "prop-types";
// import FitContent from 'modules/common/components/FitContent';
import AssessmentQuestionsContainer from "./AssessmentQuestionsContainer";
import AssessmentHeader from "../components/AssessmentHeader";
import { useAssessmentById } from "../hooks";

const SHOW_DETAILS = false;

function AssessmentSelected({ id, isLoadingCountries, countries }) {
  const [isLoading, data] = useAssessmentById(id);
  return (
    <>
      {SHOW_DETAILS ? (
        <div className="mb-4">
          <AssessmentHeader isLoading={isLoading} data={data} />
        </div>
      ) : null}
      {id === data.id ? (
        <div className="nav-tabs-content flex-grow flex">
          <AssessmentQuestionsContainer
            id={id}
            data={data}
            countries={countries}
            isLoadingCountries={isLoadingCountries}
          />
        </div>
      ) : (
        <div className="text-center text-muted p-3">
          {isLoading ? "Loading..." : "No content selected."}
        </div>
      )}
    </>
  );
}

AssessmentSelected.propTypes = {
  id: PropTypes.string.isRequired,
  isLoadingCountries: PropTypes.bool.isRequired,
  countries: PropTypes.instanceOf(Array).isRequired,
};

export default AssessmentSelected;
