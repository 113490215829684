import { useHistory } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { HeaderTitle } from "modules/common/components/Header";
import Pagination from "modules/common/components/Pagination";
import GenericTable from "modules/common/components/GenericTable";
import TripsFilter from "./TripsFilter";
import { useTripList } from "./hooks";
import { listTrip } from "./actions";

function TripsContainer() {
  const history = useHistory();
  const [filter, setFilter] = useState({});
  const [isLoading, list, pager] = useTripList(filter);
  const tableFormat = useMemo(
    () => [
      { key: "date_of_arrival", label: "Arrival Date" },
      { key: "registrant.full_name", label: "Name" },
      {
        key: (row) => <span>{row?.origin_country || row?.origin || "--"}</span>,
        label: "Origin",
      },
      { key: "destination", label: "Destination" },
      {
        key: (row) => <span> {row.contact_number} </span>,
        label: "Mobile Number",
      },
      {
        key: (row) => (
          <div>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => history.push(`/trips/${row.id}`)}
              type="button"
            >
              Details
            </button>
          </div>
        ),
        label: "",
        width: 60,
      },
    ],
    []
  );

  const handleChangePage = (newPage) => {
    setFilter({ ...filter, page: newPage });
    listTrip("list", { ...filter, page: newPage });
  };
  return (
    <>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Trips</div>
      </HeaderTitle>
      <div className="card">
        <div className="p-3">
          <TripsFilter onSubmit={setFilter} value={filter} />
        </div>
        <div style={{ height: 580 }}>
          <GenericTable
            isLoading={isLoading}
            format={tableFormat}
            data={list}
          />
        </div>
        <Pagination
          data={pager}
          isLoading={isLoading}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
}

export default TripsContainer;
