import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";
import InputSwitch from "modules/common/components/InputSwitch";
import scannerBorder from "assets/img/scanner-border.png";

function QrScanner({
  onScan,
  isLoading,
  qrReaderClass,
  showViewFinder,
  setEnableCamera,
  enableCamera,
}) {
  const [error, setError] = useState(null);

  const [value, setValue] = useState(null);
  const handleScan = (newVal) => {
    if (!enableCamera) return;
    setValue(newVal);
  };
  useEffect(() => {
    if (!value) return;
    onScan(value);
  }, [value]);

  return (
    <div
      className="position-relative form-group h-100 mb-0"
      style={{ borderRadius: "20px", overflow: "hidden" }}
    >
      <div className="position-absolute p-2" style={{ top: 0, right: 0 }}>
        <InputSwitch
          onChange={setEnableCamera}
          checked={enableCamera}
          onLabel=""
        />
      </div>
      <div className="embed-responsive embed-responsive-1by1 rounded h-100 overflow-hidden position-relative">
        <div className="embed-responsive-item" style={{ background: "#000" }}>
          <div
            className="position-absolute d-flex justify-content-center align-items-center h-100 w-100"
            style={{
              transition: "all 500ms ease-out 0s",
              opacity: !enableCamera || error ? 1 : 0,
              background: "#000",
            }}
          >
            {error ? (
              <div className="text-white">{error}</div>
            ) : (
              <div className="text-white">
                {enableCamera ? (
                  <div className="d-flex align-items-center mr-2">
                    <div className="spinner-grow text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div>Loading...</div>
                  </div>
                ) : (
                  <>
                    {isLoading ? (
                      <div className="d-flex align-items-center mr-2">
                        <div className="spinner-grow text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div>Scanning...</div>
                      </div>
                    ) : (
                      "QR Scanner Disabled"
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div
            className="relative"
            style={{
              transition: "all 500ms ease-out 0s",
              opacity: enableCamera ? 1 : 0,
            }}
          >
            <img
              style={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: "50%",
                left: "50%",
                height: "40%",
                width: "70%",
                zIndex: 9999,
              }}
              src={scannerBorder}
              alt="Scanner square"
            />
            {enableCamera ? (
              <QrReader
                // facingMode="environment"
                className={qrReaderClass}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  margin: "auto",
                }}
                delay={200}
                onScan={handleScan}
                onError={(err) =>
                  setError(err?.message || "Unable to open camera")
                }
                showViewFinder={showViewFinder}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

QrScanner.defaultProps = {
  isLoading: false,
  showViewFinder: false,
  qrReaderClass: "",
};

QrScanner.propTypes = {
  onScan: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showViewFinder: PropTypes.bool,
  qrReaderClass: PropTypes.string,
  enableCamera: PropTypes.bool.isRequired,
  setEnableCamera: PropTypes.func.isRequired,
};

export default QrScanner;
