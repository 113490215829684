import React from "react";
import { req } from "react-reqq";
import { history } from "index";
import _ from "lodash";
import Cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import { ToastSuccess, ToastWarn } from "modules/common/components/toast";
import { toLngLat, toBorder } from "modules/common/helper";
import { parseMapBounds } from "modules/access-control/constants";
import { showModal } from "modules/common/components/AlertModal";
// eslint-disable-next-line import/no-cycle
import MfaLoginModal from "./modals/MfaLoginModal";
// eslint-disable-next-line import/no-cycle
import ProfileModal from "./modals/ProfileModal";
import * as c from "./constants";

const getProfileExtra = (token) => {
  try {
    const decoded = jwtDecode(token);
    return {
      access_control: `${decoded.prf}`,
    };
  } catch (err) {
    return {};
  }
};

export const logout = () => {
  req.post({
    key: c.LOGIN,
    url: "/api/v1/mng/logout",
    // onSuccess: (res, state) => {
    onSuccess: () => {
      const isSuperAdmin = true;
      const interval = localStorage.getItem("logbook-recent");
      // _.get(state, `${c.AUTHENTICATION}.profile.access_control`) ===
      // c.SUPER_ADMIN_ACCESS_KEY;
      Cookie.remove("token");
      sessionStorage.removeItem("profile");
      clearInterval(interval);
      localStorage.clear();
      req.clearCache();
      req.reset();
      req.set(c.AUTHENTICATION, { isAuthenticated: false });
      history.push(
        isSuperAdmin
          ? "/signin"
          : {
              pathname: "/redirect",
              state: { url: process.env.REACT_APP_SITE_URL },
            }
      );
    },
    onError: () => {
      Cookie.remove("token");
      sessionStorage.removeItem("profile");
      localStorage.clear();
      req.clearCache();
      req.reset();
      req.set(c.AUTHENTICATION, { isAuthenticated: false });
      history.push("/signin");
    },
  });
};

export const getProfile = () => {
  req.get({
    key: "PROFILE",
    url: "/api/v1/mng/profile",
    onSuccess: (res) => {
      const { profile_type, map_bounds, geoloc, ...rest } =
        _.get(res, "response.data.attributes") || {};
      const token = Cookie.get("token");
      const profileExtra = getProfileExtra(token);
      const profile = {
        ...rest,
        ...toLngLat(geoloc),
        map_bounds: parseMapBounds(map_bounds),
        map_poly: toBorder(_.get(rest, "municipality.border")),
        full_name: rest.name || `${rest.first_name} ${rest.last_name}`,
        initials:
          typeof rest.name === "string"
            ? `${_.get(rest, "name.0")}`
            : `${_.get(rest, "first_name.0")}${_.get(rest, "last_name.0")}`,
      };
      sessionStorage.setItem("profile", JSON.stringify(profile));
      const data = {
        isAuthenticated: true,
        profile: {
          ...profile,
          ...profileExtra,
        },
        permissions: [],
        modules: [],
      };
      req.set(c.AUTHENTICATION, data);

      if (
        profile.access_control === c.ADMIN_ACCESS_KEY && // eslint-disable-line
        profile.is_first_login === 0 &&
        _.isEmpty(profile.coordinates_geoloc)
      ) {
        ToastWarn("Please complete your profile!");
        showModal({
          title: "Edit Profile",
          content: (onClose) => (
            <ProfileModal data={profile} onClose={onClose} />
          ),
        });
      }
    },
    onError: () => {
      ToastWarn("Session has expired!");
      logout();
    },
  });
};

export const updateProfile = (payload, onSuccess) => {
  req.put({
    key: "PROFILE",
    url: "/api/v1/mng/profile",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Saved!");
      getProfile();
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });
};

export const checkAuth = () => {
  const token = Cookie.get("token");
  const profileExtra = getProfileExtra(token);
  const profile = JSON.parse(sessionStorage.getItem("profile") || "{}");
  const modules = JSON.parse(Cookie.get("access-modules") || "[]");
  const permissions = JSON.parse(Cookie.get("access-perms") || "[]");
  const isAuthenticated = !!token;
  if (_.isEmpty(profile) && isAuthenticated) {
    getProfile();
    return;
  }
  const data = {
    isAuthenticated,
    profile: {
      ...profile,
      ...profileExtra,
    },
    permissions,
    modules,
  };
  req.set(c.AUTHENTICATION, data);
};

const onLoginSuccess = (payload, onSuccess) => (res) => {
  const {
    is_mfa_enabled,
    token,
    is_first_login,
    geoloc,
    map_bounds,
    profile_type,
    fb_token,
    ...profile
  } = _.get(res, "response") || {};

  if (`${is_mfa_enabled}` === "1" && !payload.code) {
    showModal({
      title: "MFA",
      content: (onClose) => (
        <MfaLoginModal onClose={onClose} payload={payload} />
      ),
    });
    return;
  }

  const profileExtra = getProfileExtra(token);
  const newProfile = {
    ...profile,
    ...profileExtra,
    ...toLngLat(geoloc),
    is_mfa_enabled,
    map_bounds: parseMapBounds(map_bounds),
    map_poly: toBorder(_.get(profile, "municipality.border")),
    full_name: profile.name || `${profile.first_name} ${profile.last_name}`,
    initials:
      typeof profile.name === "string"
        ? `${_.get(profile, "name.0")}`
        : `${_.get(profile, "first_name.0")}${_.get(profile, "last_name.0")}`,
  };

  Cookie.set("token", token);
  sessionStorage.setItem("fb_token", JSON.stringify(fb_token));
  sessionStorage.setItem("profile", JSON.stringify(newProfile));
  req.set(c.AUTHENTICATION, {
    isAuthenticated: true,
    profile: {
      ...profile,
      ...newProfile,
    },
    permissions: [],
    modules: [],
  });
  ToastSuccess("Logged In!");
  onSuccess({
    is_first_login,
  });
  const isSuperAdmin =
    profileExtra?.access_control === c.SUPER_ADMIN_ACCESS_KEY;
  // const isAdmin = access_control === c.ADMIN_ACCESS_KEY;
  const homeRoute = isSuperAdmin
    ? "/dashboard" // bofore "/"
    : _.get(profile, "acl.home_route") || "/profile";
  history.push(homeRoute);
};

export const loginWithMFA = (payload, onError, onSuccess) => {
  req.post({
    key: c.MFA,
    url: "/api/v1/mng/login/mfa",
    payload,
    onSuccess: onLoginSuccess(payload, onSuccess),
    onError,
  });
};

export const login = (payload, onSuccess) => {
  req.post({
    key: c.LOGIN,
    url: "/api/v1/mng/login",
    payload,
    onSuccess: onLoginSuccess(payload, onSuccess),
  });
};

export const changePassword = (payload, onSuccess) =>
  req.put({
    key: c.CHANGE_PASSWORD,
    url: "/api/v1/mng/profile/update-password",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Password Updated!");
      getProfile();
      if (onSuccess) onSuccess(res);
    },
  });

export const getMfaQr = () => {
  req.get({
    key: c.MFA_QR,
    url: "/api/v1/mng/profile/mfa/qr",
    transform: (res) => _.get(res, "qr_url"),
  });
};

export const enableMfa = (payload, onSuccess) => {
  req.post({
    key: c.MFA,
    url: "/api/v1/mng/profile/mfa",
    payload,
    onSuccess: (res) => {
      ToastSuccess("MFA has been enabled!");
      getProfile();
      if (onSuccess) onSuccess(res);
    },
  });
};

export const disableMfa = (payload, onSuccess) => {
  req.remove({
    key: c.MFA,
    url: "/api/v1/mng/profile/mfa",
    payload,
    onSuccess: (res) => {
      ToastSuccess("MFA has been disabled!");
      getProfile();
      if (onSuccess) onSuccess(res);
    },
  });
};

export const forgotPassword = (payload, onSuccess) => {
  req.post({
    key: c.FORGOT_PASSWORD,
    url: "/api/v1/reset-password",
    payload,
    onSuccess: (res) => {
      ToastSuccess(
        _.get(res, "response.message") || "Reset link sent to your email!"
      );
      if (onSuccess) onSuccess(res);
    },
  });
};

export const resetPassword = (payload, onSuccess) => {
  req.post({
    key: c.RESET_PASSWORD,
    url: "/api/v1/reset-password/verify",
    payload,
    onSuccess: (res) => {
      ToastSuccess(_.get(res, "response.message") || "You can now login!");
      if (onSuccess) onSuccess(res);
    },
  });
};

export const confirmPassword = (payload, onSuccess, onError) => {
  req.post({
    key: c.LOGIN,
    url: "/api/v1/mng/login",
    payload,
    onSuccess,
    onError,
  });
};

export const applyForAdminAccess = (payload, onSuccess, onError) => {
  req.post({
    key: c.APPLY_FOR_ADMIN_ACCESS,
    url: "/api/v1/access-request",
    payload,
    onSuccess,
    onError,
  });
};
