import React from "react";
import PropTypes from "prop-types";
import FormUploadButton from "modules/common/form/FormUploadButton";
import FormEditor from "modules/common/form/FormEditor";
import FormSelect from "modules/common/form/FormSelect";
import FormInput from "modules/common/form/FormInput";
import Img from "modules/common/components/Img";
import _ from "lodash";
import { useApiLoading } from "react-reqq";
import * as actions from "../actions";
import * as c from "../constants";

function SymptomsModal({ data, onClose }) {
  const isNew = _.isEmpty(data);
  const isLoading = useApiLoading(c.SYMPTOMS, isNew ? "post" : "put");
  const [form, setForm] = React.useState({
    icon: _.get(data, "attributes.icon") || "",
    category: _.get(data, "attributes.category") || "",
    code: _.get(data, "attributes.code") || "",
    sequence: _.get(data, "attributes.sequence") || "",
    points: _.get(data, "attributes.points") || "",
    title: _.get(data, "attributes.title") || "",
    sub_title: _.get(data, "attributes.sub_title") || "",
    description: _.get(data, "attributes.description") || "",
    source_url: _.get(data, "attributes.source_url") || "",
    status: _.get(data, "attributes.status") || "ACTIVE",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNew) {
      actions.addSymptoms(form, onClose);
      return;
    }
    actions.updateSymptoms(data.id, form, onClose);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col-2">
            <div>
              <Img
                className="img w-100 img-fluid border p-1 rounded shadow-sm"
                src={form.icon}
                defaultSrc="https://via.placeholder.com/128?text=IMG"
              />
            </div>
            <FormUploadButton
              name="icon"
              label="Icon"
              onChange={setForm}
              value={form.icon}
              resize="x64"
            />
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-4 form-group">
                <FormSelect
                  name="category"
                  label="Category"
                  onChange={setForm}
                  value={form.category}
                  options={c.CATEGORY_OPTIONS}
                  defaultOption={false}
                  required
                />
              </div>
              <div className="col-4 form-group">
                <FormInput
                  name="code"
                  label="Code"
                  onChange={setForm}
                  value={form.code}
                  required
                />
              </div>
              <div className="col-2 form-group">
                <FormInput
                  name="sequence"
                  label="Order"
                  onChange={setForm}
                  value={form.sequence}
                  type="number"
                  required
                />
              </div>
              <div className="col-2 form-group">
                <FormSelect
                  name="status"
                  label="Status"
                  onChange={setForm}
                  value={form.status}
                  options={c.STATUS_OPTIONS}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5 form-group">
                <div className="form-group">
                  <FormInput
                    name="title"
                    label="Symptom"
                    onChange={setForm}
                    value={form.title}
                    required
                  />
                </div>
              </div>
              <div className="col-5 form-group">
                <div className="form-group">
                  <FormInput
                    name="sub_title"
                    label="Subtitle"
                    onChange={setForm}
                    value={form.sub_title}
                    required
                  />
                </div>
              </div>
              <div className="col-2 form-group">
                <div className="form-group">
                  <FormInput
                    name="points"
                    label="Points"
                    onChange={setForm}
                    value={form.points}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormEditor
          name="description"
          label="Health Tips"
          onChange={setForm}
          value={form.description}
        />
        <div className="form-group">
          <FormInput
            name="source_url"
            label="Source"
            onChange={setForm}
            value={form.source_url}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary mr-1"
          type="submit"
          disabled={isLoading}
        >
          {isNew ? "Add" : "Save"}
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </form>
  );
}

SymptomsModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
};

SymptomsModal.defaultProps = {
  data: {},
};

export default SymptomsModal;
