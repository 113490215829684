import React from "react";
import PropTypes from "prop-types";
import FormInput from "modules/common/form/FormInput";
import FormSelect from "modules/common/form/FormSelect";
import { useApiLoading } from "react-reqq";
import _ from "lodash";
import * as actions from "../actions";
import * as c from "../constants";

function VaccineModal({ data, onClose }) {
  const isNew = _.isEmpty(data);
  const isLoading = useApiLoading(c.VACCINE_MANAGEMENT, isNew ? "post" : "put");
  const [form, setForm] = React.useState({
    label: _.get(data, "attributes.label") || "",
    status: _.get(data, "attributes.status") || "PUBLISHED",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNew) {
      actions.addVaccine(form, onClose);
      return;
    }
    actions.updateVaccine(_.get(data, "id"), form, onClose);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col form-group">
            <FormInput
              name="label"
              label="Name"
              onChange={setForm}
              value={form.label}
              required
            />
          </div>
          <div className="col-4 form-group">
            <FormSelect
              name="status"
              label="Status"
              onChange={setForm}
              value={form.status}
              options={c.VACCINE_STATUS_OPTIONS}
              defaultOption={false}
              required
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary mr-1"
          type="submit"
          disabled={isLoading}
        >
          Save
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </form>
  );
}

VaccineModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
};

VaccineModal.defaultProps = {
  data: {},
};

export default VaccineModal;
