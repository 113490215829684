import React from "react";
import PropTypes from "prop-types";
import AdminTerms from "../components/AdminTerms";

function AdminTermsModal({ onSubmit, onClose }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <form className="p-3" onSubmit={handleSubmit}>
      <div
        className="mb-2 box-scrollable"
        style={{
          minHeight: "410px",
          maxHeight: "410px",
          overflow: "auto",
          fontSize: "small",
          boxShadow: "0 -25px 20px -20px #00000033 inset",
        }}
      >
        <AdminTerms />
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-custom  btn-primary  text-center font-weight-bold text-uppercase mr-2 px-4"
          type="submit"
        >
          I Agree
        </button>
        <button
          className="btn btn-custom text-uppercase font-weight-bold btn-default"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

AdminTermsModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdminTermsModal;
