import React from "react";
import PropTypes from "prop-types";
// import FormSelect from "modules/common/form/FormSelect";
import FormInput from "modules/common/form/FormInput";
import FormMobileNumber from "modules/common/form/FormMobileNumber";
import FormAddressMap2 from "modules/common/form/FormAddressMap2";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { objectToUpperCase } from "modules/common/helper";
import { ToastWarn, ToastSuccess } from "modules/common/components/toast";
import { useApiLoading } from "react-reqq";
import ReCaptcha from "modules/common/components/ReCaptcha";
import { showModal } from "modules/common/components/AlertModal";
import AdminTermsModal from "../modals/AdminTermsModal";
import * as c from "../constants";
import * as actions from "../actions";

const INIT_FORM = {
  register_as: "COMPANY", // "COMPANY",
  name: "", // "TEST COMPANY",
  branch: "", // "MANILA",
  address: "", // "METRO MANILA",
  representative_name: "", // "DEMO DEV",
  designation: "", // "TESTER",
  email: "", // "dev0000@email.com",
  mobile_number: "", // "639000000000",
  password: "", // "123123123",
  password_confirmation: "", // "123123123",
  tac: false, // true,
  captcha: "", // "",
};

const Register = ({ value }) => {
  const history = useHistory();
  const isLoading = useApiLoading(c.APPLY_FOR_ADMIN_ACCESS, "post");
  const [form, setForm] = React.useState({ ...INIT_FORM, ...value });
  const [form2, setForm2] = React.useState({
    address: "",
    barangay: "",
    geoloc: "",
    municipality: "",
    province: "",
    region: "",
  });

  const [resetCaptcha, setResetCaptcha] = React.useState(new Date().getTime());

  const handleSubmit = (e) => {
    e.preventDefault();
    const { geoloc, region, province, municipality, barangay, ...rest } = form2;
    const { email_confirmation, ...form1 } = form;

    if (email_confirmation !== form.email) {
      ToastWarn("The confirmation email must match!");
      return;
    }

    const payload = {
      coordinates_geoloc: geoloc || "",
      coordinates_region: region || "",
      coordinates_province: province || "",
      coordinates_municipality: municipality || "",
      coordinates_barangay: barangay || "",
      ...objectToUpperCase(form1, [
        "email",
        "address",
        "password",
        "password_confirmation",
      ]),
      ...rest,
    };
    if (_.isEmpty(form.captcha)) {
      ToastWarn("Please validate captcha");
      return;
    }
    actions.applyForAdminAccess(
      payload,
      () => {
        ToastSuccess("Please check your email, and activate your account!");
        history.push("/signin");
      },
      () => {
        setResetCaptcha(new Date().getTime());
      }
    );
  };

  const handleViewTerms = (e) => {
    e.preventDefault();
    showModal({
      title: "Terms and Conditions",
      content: (onClose) => (
        <AdminTermsModal
          onSubmit={() => {
            setForm((state) => ({
              ...state,
              tac: true,
            }));
            onClose();
          }}
          onClose={onClose}
        />
      ),
    });
  };

  const requestAs = React.useMemo(
    () =>
      c.REQUEST_OPTIONS.find((item) => item.value === form.register_as) || {},
    [form.register_as]
  );
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group mb-2">
            <FormInput
              label="Register As"
              onChange={setForm}
              name="register_as"
              required
              value={form.register_as || ""}
              // options={c.REQUEST_OPTIONS}
              // isSearchable={false}
              disabled
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            label={requestAs.name}
            onChange={setForm}
            name="name"
            required
            value={form.name || ""}
            placeholder={_.get(requestAs, "example") || ""}
          />
        </div>
        <div className="col-sm-6">
          <FormInput
            label="Address"
            onChange={setForm}
            name="address"
            value={form.address}
            className="form-control form-custom"
          />
          {/* <small>
            Address
            <span className="text-danger ml-1">*</span>
          </small>
          <FormAddressMap2 label="Address" onChange={setForm2} value={form2} /> */}
        </div>
        <div className="col-sm-6">
          {/* <FormInput
            label="Complete Address"
            onChange={setForm}
            name="address"
            value={form.address}
            className="form-control form-custom"
          /> */}
          <small>
            Coordinates
            <span className="text-danger ml-1">*</span>
          </small>
          <FormAddressMap2
            label="Set Coordinates"
            onChange={setForm2}
            value={form2}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            label="Branch"
            onChange={setForm}
            name="branch"
            value={form.branch}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            label="Representative's Name"
            placeholder="ex. Juan Dela Cruz"
            onChange={setForm}
            name="representative_name"
            required
            value={form.representative_name || ""}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormMobileNumber
            autoComplete="off"
            name="mobile_number"
            label="Enter Valid Mobile Number"
            required
            onChange={setForm}
            value={form.mobile_number || ""}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            label="Position / Designation"
            onChange={setForm}
            name="designation"
            required
            value={form.designation}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            label="Enter Valid Email (For Account Activation)"
            type="email"
            className="form-control form-custom"
            placeholder="ex. juan@companyname.com"
            onChange={setForm}
            name="email"
            required
            value={form.email || ""}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            label="Confirm Email"
            type="email"
            className="form-control form-custom"
            onChange={setForm}
            name="email_confirmation"
            required
            value={form.email_confirmation || ""}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            name="password"
            label="Password"
            type="password"
            className="form-control form-custom"
            onChange={setForm}
            value={form.password || ""}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormInput
            name="password_confirmation"
            label="Confirm Password"
            type="password"
            className="form-control form-custom"
            onChange={setForm}
            value={form.password_confirmation}
            required
          />
        </div>
        <div className="col-sm-12 mb-2">
          <div className="p-2 rounded">
            <ReCaptcha
              align="center"
              key={resetCaptcha}
              name="captcha"
              onVerify={setForm}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 d-flex align-items-center">
          <label>
            <input
              className="mr-1"
              type="checkbox"
              onChange={({ target }) =>
                setForm((state) => ({ ...state, tac: target.checked }))
              }
              checked={form.tac}
              required
            />
            I agree with the{" "}
            <a
              href="/terms"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleViewTerms}
            >
              Terms and Conditions
            </a>
            .
          </label>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group text-right">
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={isLoading}
            >
              Request Access
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

Register.defaultProps = {
  value: {},
};

Register.propTypes = {
  value: PropTypes.instanceOf(Object),
};

export default Register;
