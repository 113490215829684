import React, { useState, useCallback } from "react";
import { useApiGet, useApiLoading } from "react-reqq";
import { Helmet } from "react-helmet";

import img_brand from "assets/img/logo_vertical.svg";
import FormEntryPointSelect from "modules/logbook/components/FormEntryPointSelect";
import FormInput from "modules/common/form/FormInput";
import GraphicsAllowed from "assets/img/graphics-allowed.svg";
import GraphicsNotAllowed from "assets/img/graphics-not-allowed.svg";
import GraphicsExit from "assets/img/graphics-exit.svg";
import QrScanner from "./components/QrCodeScanner";
import {
  CurveBackground,
  ExclamationCircle,
  LeftArrowIcon,
  ScanIcon,
} from "./components/Icons";

import * as c from "./constants";
import * as actions from "./actions";

import "./styles.scss";

const graphics = {
  ALLOWED: GraphicsAllowed,
  "NOT ALLOWED": GraphicsNotAllowed,
  EXIT: GraphicsExit,
};

function getQrValue(value) {
  try {
    return JSON.parse(value)?.id;
  } catch {
    return value || "";
  }
}

const FORMS_INITIAL_STATE = {
  uuid: "",
  location_qr_code_uuid: { label: "", value: "" },
};
const index = () => {
  const [forms, setForms] = useState(FORMS_INITIAL_STATE);
  const [response, setResponse] = useState({
    message: "",
    type: "",
    step: 1,
    isManual: false,
  });
  const [enableCamera, setEnableCamera] = useState(false);
  const [scan, setScan] = useState(false);

  const qrOptions = useApiGet(`LIST_ENTRY_POINT_ASYNC`, []);
  const isScanConfirmLoading = useApiLoading(c.SCAN_TRIP_QR_CONFIRM, "post");
  const isScanLoading = useApiLoading(c.SCAN_QR, "get");

  const baseCallback = useCallback(() => {
    setEnableCamera(false);
    setScan(false);
    setResponse({
      message: "",
      type: "",
      step: 1,
      isManual: false,
    });
  }, []);

  const generalCallback = useCallback(() => {
    setScan(true);
    setResponse({
      message: "",
      type: "",
      step: 2,
      isManual: false,
    });
    setForms({
      ...forms,
      uuid: "",
    });
  }, [forms]);

  const errorBaseCallback = useCallback((message, isManual = false) => {
    setResponse({
      message,
      type: "error",
      step: 2,
      isManual,
    });
    setEnableCamera(false);
    setScan(true);
  }, []);

  const scanErrorCallback = useCallback(
    (message) => {
      errorBaseCallback(message);
      setForms({
        ...forms,
        uuid: "",
      });
    },
    [forms]
  );

  const handleClear = () => {
    setForms(FORMS_INITIAL_STATE);
    baseCallback();
  };

  const handleBack = () => {
    if (response?.isManual) {
      baseCallback();
      return;
    }
    if (response?.step === 3) {
      setEnableCamera(false);
      generalCallback();
      return;
    }
    setForms({
      ...forms,
      uuid: "",
    });
    baseCallback();
  };

  const handleScanAgain = () => {
    if (response?.isManual) {
      baseCallback();
      return;
    }
    setEnableCamera(true);
    generalCallback();
  };

  const handleDone = useCallback(() => {
    baseCallback();
    setForms({
      ...forms,
      uuid: "",
    });
  }, [forms]);

  const handleConfirm = useCallback(() => {
    const onSuccess = (res) => {
      setResponse({
        message: res?.message,
        type: "done",
        step: 4,
        isManual: false,
        isAllowed: res?.allowed_entry || res?.qr_type,
      });
      setEnableCamera(false);
      setScan(true);
    };
    const onError = (message) => {
      if (response?.isManual) {
        errorBaseCallback(message, true);
      } else {
        scanErrorCallback(message);
      }
    };
    const payload = {
      ...forms,
      location_qr_code_uuid: forms?.location_qr_code_uuid?.value,
    };
    actions.scanTripQrConfirm(payload, onSuccess, onError);
  }, [forms, response]);

  const handleManualQrCode = useCallback(() => {
    const onSuccess = (message) => {
      setResponse({ message, type: "confirm", step: 3, isManual: true });
      setEnableCamera(false);
      setScan(true);
    };
    const onError = (message) => {
      errorBaseCallback(message, true);
    };

    actions.scanQr({ uuid: forms?.uuid }, onSuccess, onError);
  }, [forms]);

  const handleScan = useCallback(
    (value) => {
      const uuid = getQrValue(value) || "";
      if (!uuid) return;
      setForms({
        ...forms,
        uuid,
      });

      const onSuccess = (message) => {
        setResponse({ message, type: "confirm", step: 3, isManual: false });
        setEnableCamera(false);
        setScan(true);
      };
      const onError = (message) => {
        scanErrorCallback(message);
      };

      actions.scanQr({ uuid }, onSuccess, onError);
    },
    [forms]
  );

  const handleShowScanner = () => {
    generalCallback();
  };

  return (
    <>
      <Helmet>
        <title>Scanner - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="scanner__container">
        <div className="scanner__inner-container">
          {!scan ? (
            <>
              <CurveBackground className="scanner__header-logo" />
              <button
                type="button"
                className="scanner__scan-icon-btn"
                onClick={handleShowScanner}
                disabled={!forms?.location_qr_code_uuid?.value.length}
                style={{
                  cursor: !forms?.location_qr_code_uuid?.value.length
                    ? "not-allowed"
                    : "",
                }}
              >
                <ScanIcon className="scanner__scan-icon" />
                {!forms?.location_qr_code_uuid?.value.length && (
                  <div className="scanner__scan-icon-tooltip">
                    Entry point is required
                  </div>
                )}
              </button>
              <img
                className="scanner__main-logo"
                src={img_brand}
                alt="Header logo"
              />
              <div className="scanner__card-initial-container">
                <p className="scanner__card-initial-instruction">
                  Before scanning, kindly designate an entry point. If the
                  scanner isn&apos;t functioning, manually enter the QR code ID
                  in the field below, and then press the submit button
                </p>
                <div className="scanner__card-initial-forms-container">
                  <FormEntryPointSelect
                    name="location_qr_code_uuid"
                    onChange={setForms}
                    label="Entry Point *"
                    placeholder="- Select -"
                    disabled={isScanLoading}
                    otherPayload={{ status: "ACTIVE" }}
                    value={
                      forms.location_qr_code_uuid || {
                        value: "",
                        label: "",
                      }
                    }
                    options={qrOptions}
                  />

                  <div className="scanner__input-qr-code-container">
                    <FormInput
                      className="form-control text-uppercase scanner__initial-qr-code-input"
                      style={{
                        background: !forms?.location_qr_code_uuid?.value.length
                          ? "#f1f1f4"
                          : "",
                        cursor: !forms?.location_qr_code_uuid?.value.length
                          ? "not-allowed"
                          : "",
                      }}
                      name="uuid"
                      label="QR Code ID *"
                      disabled={!forms?.location_qr_code_uuid?.value.length}
                      onChange={setForms}
                      value={forms?.uuid || ""}
                    />
                    {!forms?.location_qr_code_uuid?.value.length && (
                      <div className="scanner__initial-input-qr-code-tooltip">
                        Entry point field is required.
                      </div>
                    )}
                  </div>
                  <div className="scanner__initial-btn-container">
                    <button
                      className="scanner__initial-btn-submit"
                      type="button"
                      onClick={handleManualQrCode}
                      disabled={
                        !forms?.uuid.length ||
                        !forms?.location_qr_code_uuid?.value.length
                      }
                    >
                      {isScanLoading ? (
                        <div className="d-flex align-items-center ">
                          <div
                            className="spinner-grow text-light mr-2"
                            role="status"
                            style={{ width: "1.4rem", height: "1.4rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                          <span>Validating...</span>
                        </div>
                      ) : (
                        <span className="text-light">Submit</span>
                      )}
                      {!forms?.uuid.length ||
                      !forms?.location_qr_code_uuid?.value.length ? (
                        <div className="scanner__initial-btn-submit-tooltip">
                          Entry Point and Qr Code Id is required
                        </div>
                      ) : null}
                    </button>
                    <button
                      className="scanner__initial-btn-clear"
                      type="button"
                      // disabled={isScanLoading}
                      onClick={handleClear}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="scanner__qr-wrapper">
              {response?.type !== "done" && (
                <button
                  type="button"
                  className="scanner__back-btn"
                  onClick={handleBack}
                >
                  <span className="scanner__back-icon-container">
                    <LeftArrowIcon />
                  </span>
                  <span className="scanner__back-btn-label">Back</span>
                </button>
              )}
              {!isScanLoading && response?.message ? (
                <div
                  className={`scanner__card-container ${
                    response?.type === "error"
                      ? "scanner__card-container-black"
                      : "scanner__card-container-white"
                  }`}
                >
                  <div className="scanner__card">
                    {response?.type === "error" && (
                      <div className="scanner__card-response-container scanner__card-again-container">
                        <p
                          className={`scanner__card-response-regular ${
                            response?.type === "error"
                              ? "scanner__card-response-white"
                              : "scanner__card-response-black"
                          }`}
                        >
                          {response.message}
                        </p>

                        <button
                          type="button"
                          onClick={handleScanAgain}
                          className="scanner__card-btn scanner__card-btn-again"
                        >
                          {response?.isManual
                            ? "Enter QR ID again"
                            : " Scan again"}
                        </button>
                      </div>
                    )}

                    {response?.type === "confirm" && (
                      <div className="scanner__card-response-container scanner__card-confirm-container">
                        <div className="scanner__card-response-with-logo-confirm-container">
                          <figure className="scanner__response-main-logo-confirm-container">
                            <img
                              className="scanner__response-main-logo-confirm"
                              src={img_brand}
                              alt="Header logo"
                            />
                          </figure>
                          <p
                            className={`scanner__response-confirm ${
                              response?.type === "error"
                                ? "scanner__card-response-white"
                                : "scanner__card-response-black"
                            }`}
                          >
                            {response.message}
                          </p>
                        </div>

                        <ScanWarning />

                        <button
                          type="button"
                          onClick={handleConfirm}
                          className="scanner__card-btn scanner__card-btn-confirm d-flex align-items-center justify-content-center"
                        >
                          {isScanConfirmLoading ? (
                            <div className="d-flex align-items-center">
                              <div
                                className="spinner-grow text-light mr-2"
                                role="status"
                                style={{ width: "1.2rem", height: "1.2rem" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                              <span>Validating...</span>
                            </div>
                          ) : (
                            <span>
                              {forms.location_qr_code_uuid.label.includes(
                                "EXIT"
                              )
                                ? "Confirm Exit"
                                : "Confirm Visit"}
                            </span>
                          )}
                        </button>
                      </div>
                    )}

                    {response?.type === "done" && (
                      <div className="scanner__card-response-container scanner__card-done-container">
                        <div className="scanner__card-response-with-logo-done-container">
                          <figure className="scanner__response-main-logo-done-container">
                            <img
                              className="scanner__response-main-logo-done"
                              src={graphics[response?.isAllowed]}
                              alt="Header logo"
                            />
                          </figure>
                          <h6
                            className={`scanner__response-${
                              response?.isAllowed === "NOT ALLOWED"
                                ? "not-allowed"
                                : "allowed"
                            }`}
                          >
                            {response?.isAllowed}
                          </h6>
                          <p
                            className={`scanner__response-done ${
                              response?.type === "error"
                                ? "scanner__card-response-white"
                                : "scanner__card-response-black"
                            }`}
                          >
                            {response.message}
                          </p>
                        </div>
                        <button
                          type="button"
                          onClick={handleDone}
                          className="scanner__card-btn scanner__card-btn-done"
                        >
                          Done
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <QrScanner
                  qrReaderClass="scanner__qr-container"
                  onScan={handleScan}
                  isLoading={isScanLoading}
                  setEnableCamera={setEnableCamera}
                  enableCamera={enableCamera}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default index;

function ScanWarning() {
  return (
    <div className="scanner__card-warning-container">
      <span className="scanner__card-warning-icon-container">
        <ExclamationCircle />
      </span>
      <p className="scanner__card-warning-message">
        I confirm that I am visiting this establishment, and my QR Code
        information will be logged to this establishment.
      </p>
    </div>
  );
}
