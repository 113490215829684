import React from "react";
// import PropTypes from 'prop-types';
import Helmet from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { HeaderTitle } from "modules/common/components/Header";

function ReportPrint() {
  const history = useHistory();
  // const match = useRouteMatch();
  const { id } = useParams();
  const iframeRef = React.useRef();
  return (
    <>
      <Helmet>
        <title>Module Reports - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Module Reports</div>
      </HeaderTitle>
      <div className="d-flex justify-content-start align-items-center p-1">
        <button
          className="btn btn-outline-primary"
          type="button"
          // onClick={() => history.push((match.url || '').replace(`/${id}/print`, ''))}
          onClick={() => history.goBack()}
        >
          Go Back
        </button>
        <div className="pl-2">
          <div className="font-weight-bold">Print</div>
        </div>
      </div>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column position-relative overflow-hidden">
        <div
          className="position-absolute p-2"
          style={{ top: 0, right: 0, zIndex: 10 }}
        >
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => iframeRef.current.contentWindow.print()}
          >
            <i className="fa fa-print mr-1" />
            Print
          </button>
        </div>
        <iframe
          ref={iframeRef}
          className="position-absolute"
          frameBorder="0"
          height="100%"
          width="100%"
          src={`/report-print/${id}`}
          title="Reports Print"
        />
      </div>
    </>
  );
}

ReportPrint.propTypes = {};

export default ReportPrint;
