/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import GenericTable from "modules/common/components/GenericTable";
import { HeaderTitle } from "modules/common/components/Header";
import { showModal } from "modules/common/components/AlertModal";
import Img from "modules/common/components/Img";
import { useApiGet, useApiLoading } from "react-reqq";
import _ from "lodash";
import DOMPurify from "dompurify";
import { confirmAlert } from "modules/common/components/Alert";
import SymptomsModal from "../modals/SymptomsModal";
import * as c from "../constants";
import * as actions from "../actions";

const renderIcon = (row) => (
  <Img
    className="img img-fluid w-100 border rounded p-1 bg-white shadow-sm"
    src={_.get(row, "attributes.icon")}
    defaultSrc="https://via.placeholder.com/128"
  />
);

function SymptomsManagement() {
  const isLoading = useApiLoading(c.SYMPTOMS, "get");
  const data = useApiGet(c.SYMPTOMS, []);

  const handleOpenAddModal = () => {
    showModal({
      title: `Add Symptoms`,
      className: "modal-lg",
      content: (onClose) => <SymptomsModal onClose={onClose} />,
    });
  };

  const handleOpenEdit = (row) => () => {
    showModal({
      title: `Edit ${_.get(row, "attributes.title")}`,
      className: "modal-lg",
      content: (onClose) => <SymptomsModal data={row} onClose={onClose} />,
    });
  };

  const handleDelete = (row) => () => {
    confirmAlert({
      content: "Are you sure you want to remove?",
      onYes: (onClose) => {
        actions.deleteSymptoms(row.id, onClose);
      },
    });
  };

  const handleOpenHealthTips = (row) => () => {
    const desc = _.get(row, "attributes.description");
    showModal({
      title: "Preview",
      content: (onClose) => (
        <>
          <div className="modal-body">
            {_.isEmpty(desc) ? (
              <div className="text-center text-muted">No data.</div>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc) }}
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-outline-primary"
              onClick={onClose}
              type="button"
            >
              Close
            </button>
          </div>
        </>
      ),
    });
  };

  const renderAction = React.useCallback(
    (row) => (
      <div>
        <button
          className="btn btn-outline-primary btn-sm mr-1"
          type="button"
          onClick={handleOpenEdit(row)}
        >
          <i className="fa fa-edit" />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          type="button"
          onClick={handleDelete(row)}
        >
          <i className="fa fa-trash" />
        </button>
      </div>
    ),
    []
  );
  const renderHealthTips = React.useCallback(
    (row) => (
      <div>
        <button
          className="btn btn-outline-primary btn-sm btn-block"
          type="button"
          onClick={handleOpenHealthTips(row)}
        >
          <i className="fa fa-eye" /> Preview
        </button>
      </div>
    ),
    []
  );
  const getTableFormat = React.useCallback(
    () => [
      { key: renderAction, label: "Action", width: 80 },
      { key: renderIcon, label: "Icon", width: 70 },
      { key: "attributes.category", label: "Category", width: 100 },
      { key: "attributes.code", label: "Code", width: 150 },
      { key: "attributes.points", label: "Points", width: 100 },
      { key: "attributes.title", label: "Symptom" },
      { key: "attributes.sub_title", label: "Sub Title" },
      { key: "attributes.status", label: "Status", width: 70 },
      {
        key: "attributes.sequence",
        label: "Order",
        width: 50,
        className: "text-center",
      },
      { key: renderHealthTips, label: "Health Tips", width: 100 },
    ],
    []
  );

  React.useEffect(() => {
    actions.listSymptoms({ paginate: 0 });
  }, []);
  return (
    <>
      <Helmet>
        <title>Symptoms Management - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">
          Symptoms Management
        </div>
      </HeaderTitle>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column">
        <div className="d-flex justify-content-end p-1">
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleOpenAddModal}
          >
            <i className="fa fa-plus mr-1" />
            Add
          </button>
        </div>
        <div className="box-fit-wrapper w-100 flex-grow-1">
          <div className="box-fit-overflow">
            <GenericTable
              isLoading={isLoading}
              format={getTableFormat()}
              data={data}
              rowHeight={100}
            />
          </div>
        </div>
      </div>
    </>
  );
}

SymptomsManagement.propTypes = {};

export default SymptomsManagement;
