import React from "react";
import {
  useRouteMatch,
  NavLink,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { HeaderTitle } from "modules/common/components/Header";
import PageNotFound from "modules/common/containers/PageNotFound";
import ReportContainer from "modules/special-survey/containers/ReportContainer";
import SummaryContainer from "modules/special-survey/containers/SummaryContainer";

function ContentManagementContainer() {
  const match = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Special Survey - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Special Survey</div>
      </HeaderTitle>
      <ul
        className="nav nav-tabs pl-1"
        style={{
          overflowX: "auto",
          overflowY: "hidden",
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
        }}
      >
        <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/reports`}>
            <i className="fas fa-fw fa-cloud-download-alt mr-1" />
            Reports
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/summary`}>
            <i className="fa fa-list mr-1" />
            Summary
          </NavLink>
        </li>
      </ul>
      <div className="bg-white mx-0 border-top-0 flex-grow-1 d-flex flex-column">
        <Switch>
          <Route path={`${match.path}/reports`} component={ReportContainer} />
          <Route path={`${match.path}/summary`} component={SummaryContainer} />
          <Route exact path={match.path}>
            <Redirect to={`${match.path}/reports`} />
          </Route>
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </>
  );
}

ContentManagementContainer.propTypes = {};

export default ContentManagementContainer;
