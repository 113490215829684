import React from 'react';
import PropTypes from 'prop-types';
import { useApiGet } from 'react-reqq';
import { each } from 'lodash';
import { FORM_ANSWERS, NONE_LIST } from 'modules/assessment/constants';
import { setAnswer } from './actions';
import { useIsSaving } from './hooks';
import RetryMessage from './RetryMessage';

function QuestionCheckbox({ data, readOnly }) {
  const value = useApiGet(`${FORM_ANSWERS}.${data.id}`, '');
  const [isSaving, setIsSaving] = React.useState(false);
  useIsSaving(data.id, isSaving);
  const handleSelect = (option, checked) => (e) => {
    e.preventDefault();
    if (readOnly) return;
    let newValue = {};
    each(
      {
        ...value,
        [option.label]: checked,
      },
      (v, k) => {
        if (v) newValue[k] = v;
      }
    );
    if (NONE_LIST.indexOf((option.label || '').toLowerCase()) > -1 && checked) {
      newValue = {
        [option.label]: true,
      };
    }
    if (NONE_LIST.indexOf((option.label || '').toLowerCase()) < 0 && checked) {
      each(newValue, (v, k) => {
        if (NONE_LIST.indexOf((k || '').toLowerCase()) > -1) delete newValue[k];
      });
    }
    setIsSaving(true);
    setAnswer(
      {
        [data.id]: newValue,
      },
      () => setIsSaving(false)
    );
  };
  return (
    <div className="p-2 rounded shadow-md w-full bg-white">
      <div className="float-right text-green-500">
        <i
          className={`fa fa-spin fa-spinner ${
            isSaving ? 'visible' : 'invisible'
          }`}
        />
      </div>
      <div className="mb-2 text-base font-bold">
        <span className="text-semibold">{data.question}</span>
        {!data.required && (
          <span className="ml-2 text-gray-500">(Optional)</span>
        )}
      </div>
      <div className="text-sm font-semibold divide-gray-100 divide-dashed divide-y">
        <input
          className="position-absolute"
          style={{ opacity: 0 }}
          required={data.required}
          onChange={() => {}}
          value={value ? '1' : ''}
        />
        {(data?.options || [])
          // .filter((v) => !readOnly ? true : (value[v.label]))
          .map((option) => (
          <a
            key={option.value}
            href="/"
            className="d-flex align-items-center p-2 w-full text-gray-900 hover:bg-gray-100 rounded-lg"
            onClick={handleSelect(option, !value[option.label])}
          >
            <span>{option.label}</span>
            <div className="h-8 w-8 ml-auto text-2xl">
              {!value[option.label] ? (
                <i className="far fa-square text-primary-300" />
              ) : (
                <i className="fa fa-check-square text-primary-500" />
              )}
            </div>
          </a>
        ))}
      </div>
      <RetryMessage isSaving={isSaving} />
    </div>
  );
}

QuestionCheckbox.defaultProps = {
  readOnly: false,
};

QuestionCheckbox.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  readOnly: PropTypes.bool,
};

export default QuestionCheckbox;
