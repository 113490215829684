import _ from "lodash";
import { req } from "react-reqq";
// import { ToastSuccess } from "modules/common/components/toast";
import * as t from "./transforms";
import * as c from "./constants";

export const getCaseCount = ({ cache = false, ...params }) =>
  req.get({
    key: c.CASE_COUNT,
    url: "/api/v1/mng/cases-count",
    params,
    transform: (res) => {
      const data = {
        confirmed: `${_.get(res, "data.attributes.confirmed", "")}`,
        puis: `${_.get(res, "data.attributes.puis", "")}`,
        pums: `${_.get(res, "data.attributes.pums", "")}`,
        recovered: `${_.get(res, "data.attributes.recovered", "")}`,
        deaths: `${_.get(res, "data.attributes.deaths", "")}`,
        test_conducted: `${_.get(res, "data.attributes.test_conducted", "")}`,
      };
      return data;
    },
    cache,
  });

export const updateCaseCount = (payload) => {
  req.put({
    key: c.CASE_COUNT,
    url: "/api/v1/mng/cases-count",
    payload,
  });
  req.set(c.CASE_COUNT, payload);
};

const MAP_SETTINGS_LABEL = {
  ADD_MEMBER_COUNT: "Auto-Deleted Count",
  SMS_BLAST_OTP_RECIPIENT: "SMS Blast OTP Recipient",
};

const MAP_SETTINGS_CAN_EDIT = {
  ADD_MEMBER_COUNT: false,
  SMS_BLAST_OTP_RECIPIENT: true,
};

export const getGlobalSettings = ({ cache, ...params }) => {
  req.get({
    key: c.GLOBAL_SETTINGS,
    url: "/api/v1/mng/settings",
    params,
    transform: (res) => {
      const data = _.get(res, "data") || [];
      const flat = {};
      _.forOwn(data, (v) => {
        flat[_.get(v, "attributes.code")] = _.get(v, "attributes.value");
      });
      req.set(`${c.GLOBAL_SETTINGS}/flat`, flat);
      return data.map((item) => ({
        code: _.get(item, "id"),
        label:
          MAP_SETTINGS_LABEL[_.get(item, "attributes.code")] ||
          _.get(item, "attributes.code"),
        value: _.get(item, "attributes.value"),
        type: _.get(item, "attribbutes.type"),
        human_label: _.get(item, "attributes.label"),
        typed_value: _.get(item, "attributes.typed_value"),
        editable: MAP_SETTINGS_CAN_EDIT[_.get(item, "attributes.code")],
      }));
    },
    cache,
  });
};

export const updateGlobalSettings = (type, payload) => {
  req.put({
    key: c.GLOBAL_SETTINGS,
    url: `/api/v1/mng/settings/${type}`,
    payload,
    onSuccess: () => {
      getGlobalSettings({});
    },
  });
  req.set(c.GLOBAL_SETTINGS, (state) => {
    const oldState = state[c.GLOBAL_SETTINGS] || [];
    return oldState.map((item) =>
      item.type === type
        ? {
            ...item,
            ...payload,
          }
        : item
    );
  });
};

export const listByAgeGroup = ({ cache = false, ...params }) =>
  req.get({
    key: c.BY_AGE_GROUP,
    url: "/api/v1/mng/cases-by-age",
    params,
    transform: (res) => {
      const data = (_.get(res, "data") || []).map((item) => ({
        key: `${item.id}`,
        label: _.get(item, "attributes.name"),
        male: `${_.get(item, "attributes.male", "")}`,
        female: `${_.get(item, "attributes.female", "")}`,
      }));
      return data;
    },
    cache,
  });

export const updateByAgeGroup = (id, payload) => {
  req.put({
    key: c.BY_AGE_GROUP,
    url: `/api/v1/mng/cases-by-age/${id}`,
    payload,
  });
  req.set(c.BY_AGE_GROUP, (state) => {
    const oldData = state[c.BY_AGE_GROUP] || [];
    const newData = oldData.map((item) =>
      item.key === id ? { ...item, ...payload } : item
    );
    return newData;
  });
};

export const listCaseTrend = ({ cache = false, ...params }) =>
  req.get({
    key: c.CASE_TREND,
    url: "/api/v1/mng/cases-trend",
    params,
    transform: (res) => res.data || [],
    onSuccess: (res) => {
      t.caseTrendToChart(res);
    },
    cache,
  });

export const createCaseTrend = (payload, onSuccess) =>
  req.post({
    key: c.CASE_TREND,
    url: "/api/v1/mng/cases-trend",
    payload,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      listCaseTrend({});
    },
  });

export const updateCaseTrend = (id, payload, onSuccess) => {
  req.put({
    key: c.CASE_TREND,
    url: `/api/v1/mng/cases-trend/${id}`,
    payload,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      req.set(c.CASE_TREND, (state) => {
        const oldData = state[c.CASE_TREND] || [];
        const newData = oldData.map((item) =>
          `${item.id}` === `${id}`
            ? {
                ...item,
                attributes: { ...(item.attributes || {}), ...payload },
              }
            : item
        );
        return newData;
      });
    },
  });
};

export const removeCaseTrend = (id, onSuccess) => {
  req.remove({
    key: c.CASE_TREND,
    url: `/api/v1/mng/cases-trend/${id}`,
    onSuccess: () => {
      req.set(c.CASE_TREND, (state) => {
        const oldData = state[c.CASE_TREND] || [];
        const newData = oldData.filter((item) => `${item.id}` !== `${id}`);
        return newData;
      });
      if (onSuccess) onSuccess();
    },
  });
};

export const listByCity = ({ cache = false, ...params }) =>
  req.get({
    key: c.BY_CITY,
    url: "/api/v1/mng/cases-by-city",
    params: { per_page: 1640, ...params },
    transform: (res) => res.data || [],
    onSuccess: (res) => {
      t.caseCityToChart(res);
    },
    cache,
  });

export const createByCity = (payload, onSuccess) =>
  req.post({
    key: c.BY_CITY,
    url: "/api/v1/mng/cases-by-city",
    payload,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      listByCity({});
    },
  });

export const updateByCity = (id, payload, onSuccess) => {
  req.put({
    key: c.BY_CITY,
    url: `/api/v1/mng/cases-by-city/${id}`,
    payload,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      req.set(c.BY_CITY, (state) => {
        const oldData = state[c.BY_CITY] || [];
        const newData = oldData.map((item) =>
          `${_.get(item, "attributes.municipality_code")}` === `${id}`
            ? {
                ...item,
                attributes: { ...(item.attributes || {}), ...payload },
              }
            : item
        );
        return newData;
      });
    },
  });
};

export const removeByCity = (id, onSuccess) => {
  req.remove({
    key: c.BY_CITY,
    url: `/api/v1/mng/cases-by-city/${id}`,
    onSuccess: () => {
      req.set(c.BY_CITY, (state) => {
        const oldData = state[c.BY_CITY] || [];
        const newData = oldData.filter(
          (item) => `${_.get(item, "attributes.municipality_code")}` !== `${id}`
        );
        return newData;
      });
      if (onSuccess) onSuccess();
    },
  });
};

export const listByCountry = ({ cache = false, ...params }) =>
  req.get({
    key: c.BY_COUNTRY,
    url: "/api/v1/mng/cases-by-country",
    params,
    transform: (res) => res.data || [],
    onSuccess: (res) => {
      t.byCountryToChart(res);
    },
    cache,
  });

export const createByCountry = (payload, onSuccess) =>
  req.post({
    key: c.BY_COUNTRY,
    url: "/api/v1/mng/cases-by-country",
    payload,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      listByCountry({});
    },
  });

export const updateByCountry = (id, payload, onSuccess) => {
  req.put({
    key: c.BY_COUNTRY,
    url: `/api/v1/mng/cases-by-country/${id}`,
    payload,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      req.set(c.BY_COUNTRY, (state) => {
        const oldData = state[c.BY_COUNTRY] || [];
        const newData = oldData.map((item) =>
          `${item.id}` === `${id}`
            ? {
                ...item,
                attributes: { ...(item.attributes || {}), ...payload },
              }
            : item
        );
        return newData;
      });
    },
  });
};

export const removeByCountry = (id, onSuccess) => {
  req.remove({
    key: c.BY_COUNTRY,
    url: `/api/v1/mng/cases-by-country/${id}`,
    onSuccess: () => {
      req.set(c.BY_COUNTRY, (state) => {
        const oldData = state[c.BY_COUNTRY] || [];
        const newData = oldData.filter((item) => `${item.id}` !== `${id}`);
        return newData;
      });
      if (onSuccess) onSuccess();
    },
  });
};
