/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import PropTypes from 'prop-types';
// import { showModal } from 'modules/common/components/Modal';
// import HowToDeleteAccountModal from 'modules/digital-logbook/modals/HowToDeleteAccountModal';

function AdminTerms() {
  // const email = 'help@safetravelsmarianas.com';
  // const handleDelete = (e) => {
  //   e.preventDefault();
  //   showModal({
  //     title: 'Delete or Deactivate your account',
  //     content: (onClose) => <HowToDeleteAccountModal onClose={onClose} />,
  //   });
  // };
  return (
    <div className="prose space-y-3">
      <h1 className="text-lg"><a href="https://www.safetravelsmarianas.com/"><span>TERMS OF USE &amp; PRIVACY NOTICE</span></a></h1>
      <p><strong><span>Introduction</span></strong></p>
      <p><span>Welcome to www.safetravelsmarianas.com&nbsp; and </span><a href="http://www.staysafemarianas.com"><span>www.staysafemarianas.com</span></a><span> (collectively the "Site"), owned and operated by the Commonwealth of the Northern Government and the CNMI Office of the Governor&rsquo;s COVID Task Force (collectively &ldquo;CNMI Government&rdquo;).&nbsp;&nbsp;</span></p>
      <p><span>The CNMI Government is committed to providing a safe and positive experience to all users on our Site. To help us do that, we need you to follow a few basic rules when you&rsquo;re here. Don&rsquo;t worry, it&rsquo;s not very complicated.</span></p>
      <p><span>Also, by accessing </span><a href="http://www.safetravelsmarianas"><span>www.safetravelsmarianas</span></a><span> and www.staysafemarianas.com you agree to the following Terms of Use. These Terms of Use are effective as of December 1, 2021.</span></p>
      <p><strong><span>Using </span></strong><a href="http://www.safetravelsmarianas"><strong><span>www.safetravelsmarianas</span></strong></a><strong><span> and www.staysafemarianas.com</span></strong></p>
      <p><span>You accept </span><a href="http://www.safetravelsmarianas"><strong><span>www.safetravelsmarianas</span></strong></a><strong><span> and www.staysafemarianas.com</span></strong><span> "as-is" and choose to use it at your own risk. Despite the prohibitions below, </span><a href="http://www.safetravelsmarianas"><strong><span>www.safetravelsmarianas</span></strong></a><strong><span> and www.staysafemarianas.com</span></strong><span> may contain inaccurate, inappropriate, or possibly offensive material, and we assume no responsibility or liability for such material.</span></p>
      <p><span>When using our Site, you must comply with all applicable laws, including federal, state, and local laws, the laws of your jurisdiction, and laws regarding the transmission of technical data.</span></p>
      <p><span>You also agree not to:</span></p>
      <ul>
        <li aria-level="1"><span>Display, send, receive, or store obscene or inappropriate content.</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Threaten, harass, stalk, defame, or defraud any person or entity.</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Violate copyright, trademark, or other intellectual property laws.</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Advertise, promote, endorse, or market, directly or indirectly, any third-party commercial products, services, solutions, or other technologies.</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Attempt to collect, store, or publish personally identifiable information (a) without the owner&rsquo;s knowledge and consent or (b) of a minor under the age of thirteen (13) in any circumstance.</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Distribute unwanted, unsolicited, or harassing mass email or other messages, promotions, advertising, or solicitations (&ldquo;spam&rdquo;).</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Send deceptive or false source-identifying information, including &ldquo;spoofing&rdquo; or &ldquo;phishing.&rdquo;</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Access or use any application, system, service, tool, data, account, network, or content without authorization or for unintended purposes.</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Disable, disrupt, circumvent, interfere with, or otherwise violate the security of the Site.</span></li>
      </ul>
      <ul>
        <li aria-level="1"><span>Attack, abuse, interfere with, intercept, disrupt, or exploit any users, systems, or services, regardless of how accomplished and notwithstanding anything to the contrary in these Terms, including but not limited to Denial of Service (DoS), monitoring, crawling, spamming, using bots or scripts, or distributing malware (such as viruses, Trojan horses, worms, spyware, or adware).</span></li>
      </ul>
      <p><span>Engage in or promote any illegal or criminal activity such as child pornography, gambling, or piracy.</span></p>
      <p><span>Authorize, permit, enable, induce, or encourage any third party to do any of the above.</span></p>
      <p><span>If you violate these Terms, your access to the Site may be terminated immediately and without notice.</span></p>
      <p><span>In other words, just be respectful and use the Site for its intended purpose.</span></p>
      <p><br /><br /></p>
      <p><strong><span>Abusing </span></strong><a href="http://www.safetravelsmarianas"><strong><span>www.safetravelsmarianas</span></strong></a><strong><span> and www.staysafemarianas.com</span></strong></p>
      <p><span>We may limit or terminate our service, remove hosted content and take technical and legal steps to keep users off www.safetravelsmarianas and www.staysafemarianas.com if we think they are creating problems or acting inconsistently with the letter or spirit of our policies. Please report problems, offensive content and policy violations by emailing us at info@safetravelsmarianas.com</span></p>
      <p><strong><span>Liability</span></strong></p>
      <p><span>To the extent legally permitted we expressly disclaim all warranties, representations and conditions, express or implied, including those of quality, merchantability, merchantable quality, durability, fitness for a particular purpose and those arising by statute. We are not liable for any loss, whether of money (including profit), goodwill, or reputation, or any special, indirect, or consequential damages arising out of your use of www.safetravelsmarianas and www.staysafemarianas.com even if you advise us or we could reasonably foresee the possibility of any such damage occurring. Some jurisdictions do not allow the disclaimer of warranties or exclusion of damages, so such disclaimers and exclusions may not apply to you. Despite the previous paragraph, if we are found to be liable, our liability to you or any third party (whether in contract, tort, negligence, strict liability in tort, by statute or otherwise) is limited to fifty US dollars ($50).</span></p>
      <p><br /><br /></p>
      <p><strong><span>Indemnity</span></strong></p>
      <p><span>You will indemnify and hold us (and our officers, directors, agents, subsidiaries, joint ventures, and employees) harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of this Agreement, or your violation of any law or the rights of a third party.</span></p>
      <p><strong><span>Personal Information</span></strong></p>
      <p><span>By using www.safetravelsmarianas.com and www.staysafemarianas.com, you agree to the collection, transfer, storage and use of your personal information by the CNMI Government on servers located in United States of America, as further described in our privacy policy.</span></p>
      <p><strong><span>General</span></strong></p>
      <p><span>These terms and the other policies posted on www.safetravelsmarianas.com and www.staysafemarianas.com constitute the entire agreement between www.safetravelsmarianas.com and </span><a href="http://www.staysafemarianas.com"><span>www.staysafemarianas.com</span></a><span> and you, superseding any conflicting parts of any prior agreements. This agreement is governed by the laws of the Commonwealth of the Northern Mariana Islands as they apply to agreements entered into and to be performed entirely within the Commonwealth of the Northern Mariana Islands between&nbsp; residents, without regard to conflict of law provisions. You agree that any claim or dispute you may have against the Commonwealth of the Northern Mariana Islands must be resolved by a court located in the Commonwealth of the Northern Mariana Islands courts. If we don't enforce any particular provision, we are not waiving our right to do so later. If a court strikes any of these terms, the remaining terms will survive. We may automatically assign this agreement in our sole discretion in accordance with the notice provision below. Except for reporting policy violations and intellectual property infringements, any legal notices from you to us shall be served on CNMI Government&rsquo;s registered agent. We will send notices to you via the email address that you provide, or by registered mail to the address you provide. Notices sent by registered mail will be deemed received five days following the date of mailing. We may update these terms at any time, with updates taking effect 30 days after they are initially posted on the Site. No other amendment to these terms will be effective unless made in writing, signed by users and by us.</span></p>
      <p><strong><span>PRIVACY STATEMENT</span></strong></p>
      <p><span>Safe Travels Marianas is the Saipan&rsquo;s official health condition reporting, and contact tracing system that empowers the public in the fight against COVID-19. By aggregating all reports, SafeTravels Marianas enables the Government to immediately respond to peoples&rsquo; needs and make informed and timely decisions to keep its citizens safe in the midst of this pandemic. The system, which is made available in desktop and mobile versions, aims to complement the management of COVID-19 cases, to remind individuals in observing physical distancing measures, to record and monitor individual&rsquo;s COVID-19 symptoms, and to augment manual contact tracing processes</span></p>
      <p><span>Any reference to &ldquo;WE&rdquo; or &ldquo;OUR&rdquo; means the SafeTravelsMarianas.com website and/or&mdash;collectively&mdash;the State Government and its partner institutions.</span></p>
      <p><span>This Privacy Notice explains what information we collect, process, and share; why we do so; and your rights with regard to your information.</span></p>
      <p><span>We may amend this Privacy Notice from time to time. When certain changes require your consent, we shall communicate the changes to you and ask for your consent. Otherwise, changes take effect immediately upon posting.</span></p>
      <h4><strong><span>WHAT INFORMATION WE COLLECT</span></strong></h4>
      <p><span>We at www.SafeTravelsMarianas.com are advocates of data privacy, and ensure that we will only require what is completely necessary for the application to perform its functions. No information will be collected from our users until they have agreed to the terms and conditions provided in our privacy policy and have given their consent. To register an account, all we require from the user is their mobile number. The mobile numbers are used as a verification tool through a One Time Password (OTP) functionality and contact information for their respective LGUs to communicate with them if they are tagged as a COVID-19 positive. Upon account creation, the users may input a brief Health Declaration Checklist and report if they are experiencing any common symptoms of COVID-19. SafeTravelsMarianas.com does not require users to submit any other personal information when creating their profile, but users have the option of adding an alias if they wish to do so.</span></p>
      <p><span>When citizens visit an establishment with SafeTravelsMarianas.com digital logbook feature, their name, date of birth, gender, residence, company name (optional), and address (optional), are collected to create a QR Code ID. To enable this functionality, they will be asked if they allow the information they have provided to be accessed by the SafeTravelsMarianas.com digital logbook feature. When you use the SafeTravelsMarianas.com web app, the following information may also be obtained:</span></p>
      <ul>
        <li aria-level="1"><span>Information contained in any communication or report that you submit to SafeTravelsMarianas.com, including metadata associated with such communication; and</span></li>
        <li aria-level="1"><span>Information that you post to SafeTravelsMarianas.com or submit for publication on the internet, including your nickname/alias, and the content of your post/s</span></li>
      </ul>
      <table className="border">
        <tbody>
          <tr>
            <td className="border px-2 py-1" colSpan="2">
              <p><strong><span>App Permission Matrix</span></strong></p>
            </td>
          </tr>
          <tr>
            <td className="border px-2 py-1">
              <p><span>Scan QR Code (Optional)</span></p>
            </td>
            <td className="border px-2 py-1">
              <p><span>The app will only access your camera for QR code scanning and logbook purposes only. No pictures or videos will be saved.</span></p>
            </td>
          </tr>
        </tbody>
      </table>
      <h4><strong><span>CHILDREN&rsquo;S PRIVACY</span></strong></h4>
      <p><span>We do not knowingly collect or solicit personal information from children below thirteen (13) years of age, and we do not knowingly allow such persons to use SafeTravelsMarianas.com. In the event that we learn that we collected personal information from children below thirteen (13) years old without verified parental consent, we will immediately delete the information with notice. If you believe that we might have collected such information, please contact us immediately through the contact information below.</span></p>
      <h4><strong><span>WHY WE COLLECT YOUR INFORMATION</span></strong></h4>
      <ul>
        <li aria-level="1"><span>We collect your information to enable you to report your health condition and provide you with basic medical information and the recommended actions of the Government's based on your condition.</span></li>
        <li aria-level="1"><span>Provide you with information on emergency contact details, medical services, distribution of relief goods and other forms of government assistance, and Government alerts and announcements.</span></li>
        <li aria-level="1"><span>Your mobile number is collected to prevent fraud through a One-Time-Password (OTP) and for cybersecurity purposes.</span></li>
        <li aria-level="1"><span>Send you SMS notification related to Safe Travels Marianas.</span></li>
        <li aria-level="1"><span>Information collected via the electronic logbook QR code function is paramount for establishments to conduct proper and efficient contact tracing.</span></li>
        <li aria-level="1"><span>Ensure that SafeTravelsMarianas.com works as it should and you are able to browse SafeTravelsMarianas.com safely and securely. We also collect your information to track bugs, errors, and usage statistics. In case of any security incident or data breach, we may also use your information in our investigative reporting.</span></li>
      </ul>
      <h4><strong><span>HOW WE USE, SHARE, AND RETAIN YOUR INFORMATION</span></strong></h4>
      <p><strong><span>Use</span></strong></p>
      <p><span>When we can, we submit collected information in an aggregated manner to ensure Users&rsquo; privacy. We use your information to compile reports for the Government to inform its policies and actions in response to the COVID-19 pandemic. The information provided will be used to deliver and provide the following actions:</span></p>
      <p><span>Manual Contact Tracing - upon the LGU designating a contact tracing team (CTT) and use the information from the Safe Travels Marianas for the following:</span></p>
      <ul>
        <li aria-level="1"><span>i. maintaining and updating a masterlist of citizens assigned to be contact traced,</span></li>
        <li aria-level="1"><span>ii. locating close contacts, profile, assess, and manage them through the assistant of Covid taskforce response team, local police officers, Bureau of Fire Protection (BFP) staff.</span></li>
        <li aria-level="1"><span>iii. profiling, assessing symptoms and managing close contacts through health and allied health workers within the CTT, assisted by staff from local population office and disaster risk reduction management offices, and</span></li>
        <li aria-level="1"><span>iv. ensuring safety, welfare of CTTS and close contacts through the local police officers and members of the AFP.</span></li>
      </ul>
      <p><span>We use your information to maintain and improve SafeTravelsMarianas.com. Only authorized personnel of SafeTravelsMarianas.com have access to your information on how you use SafeTravelsMarianas.com. The third-party provider of our data centers does not have access to information that will enable your identification.</span></p>
      <p><span>Retention</span></p>
      <p><span>Manual Contact Tracing data shall be retained within 60 days to cover end-to-end processes for contact tracing for first, second, and third generation of close contacts, most especially for contact tracing processes in areas with community transmission.</span></p>
      <p><span>Digital logbook data are handled with the highest degree of confidentiality and shall and will only be retained in the backend within 30 days.</span></p>
      <p><span>Users may request to have their data deleted at any given time throughout the retention period by contacting us; refer to the link provided below.</span></p>
      <p><span>Destruction</span></p>
      <p><span>The use of COVID-19 data in SafeTravelsMarianas.com shall conform to existing and applicable rules as stipulated. Following are guidelines on destruction of data in SafeTravelsMarianas.com:</span></p>
      <ul>
        <li aria-level="1"><span>Users can completely delete their registered data within the platform.</span></li>
        <li aria-level="1"><span>Manual contact tracing data shall be destroyed after a 60 day period. All other processing of information which will be required.</span></li>
        <li aria-level="1"><span>Digital Contact Tracing Data shall be destroyed after 30 days or any time that it is required by private establishments.</span></li>
      </ul>
      <p><span>Should you wish to have your Personal Information deleted and destroyed or you wish to withdraw your consent in the processing of your personal information or delete/deactivate your account, you may do so by clicking the link below.</span>
        {/* <a href="https://www.safetravelsmarianas.com/" onClick={handleDelete}><span>Delete or Deactivate your account</span></a> */}
      </p>
      <h4><strong><span>COOKIES</span></strong></h4>
      <p><span>Cookies are small files that we use to make your use of SafeTravelsMarianas.com better. We store cookies on your device that are necessary for the operation of this website, to learn your preferences, and understand how you interact with SafeTravelsMarianas.com. For all other types of cookies we need your permission.</span></p>
      <h4><strong><span>HOW WE SECURE YOUR INFORMATION</span></strong></h4>
      <p><span>We implement reasonable and appropriate physical, technical, and organizational measures to prevent the loss, destruction, misuse, or alteration of your information. While there is no guarantee that information sent over the internet is fully secured, we keep and protect your information using a secured server behind a firewall, encryption, and security controls on servers in the United States of America.</span></p>
      <h4><strong><span>EXERCISING YOUR RIGHTS</span></strong></h4>
      <p><span>The Data Privacy Act affords you the following rights with regards to your personal data/information:</span></p>
      <ul>
        <li aria-level="1"><span>i. To be informed whether Personal Data pertaining to him or her shall be, are being, or have been processed;</span></li>
        <li aria-level="1"><span>ii. To reasonable access to any Personal Data collected and processed in duration of employment;</span></li>
        <li aria-level="1"><span>iii. To suspend, withdraw, or order the blocking, removal, or destruction of Personal Data from the relevant company&rsquo;s filing system;</span></li>
        <li aria-level="1"><span>iv. To dispute the inaccuracy or error in Personal Data, and the relevant company shall correct it immediately and accordingly, upon the request unless the request is vexatious or otherwise unreasonable;</span></li>
        <li aria-level="1"><span>v. Where the Data Subject&rsquo;s Personal Data is processed through electronic means and in a structured and commonly used format, the Data Subject has the right to obtain a copy of such data in an electronic or structured format that is commonly used and allows for further use by the Data Subject;</span></li>
        <li aria-level="1"><span>vi. The Data Subject has the right to be indemnified for any damages sustained pursuant to the provisions of the Data Privacy Act or Other Privacy Laws</span></li>
      </ul>
      <p><span>If you want to exercise your rights or learn more about how </span><a href="http://www.staysafemarianas"><span>www.StaySafeMarianas</span></a><span> and www.SafeTravelsMarianas.com processes your information, please contact our Data Protection Officer at info@safetravelsmarianas.com.</span></p>
    </div>
  );
}

AdminTerms.propTypes = {};

AdminTerms.defaultProps = {};

export default AdminTerms;
