import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { req, useApiGet } from "react-reqq";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useProfile } from "modules/auth/hooks";
import {
  USER_TYPE_BY_ACCESS_KEY,
  AUTHENTICATION,
} from "modules/auth/constants";
import * as actions from "../actions";
import * as c from "../constants";

export const HeaderTitle = ({ children }) => {
  const { setHeader } = React.useContext(c.HeaderContext);
  React.useEffect(() => {
    setHeader(children);
    return () => {
      setHeader(null);
    };
  }, []);
  return null;
};

HeaderTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]).isRequired,
};

function Header() {
  const { header } = React.useContext(c.HeaderContext);
  const auth = useProfile();
  const municipality = _.get(auth, "profile.municipality");
  const sidebarToggled = useApiGet(`${c.SIDEBAR}.toggled`, false);
  const [toggle, setToggle] = useState([]);
  const isUserMenuToggled = toggle.indexOf("user-menu") > -1;
  const handleToggleSidebar = () => {
    actions.toggleSidebar(!sidebarToggled);
  };
  const handleToggle = (key) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setToggle(
      isUserMenuToggled ? toggle.filter((x) => x !== key) : toggle.concat([key])
    );
  };
  const clearToggle = (e) => {
    if (
      typeof e.target.className === "string" &&
      e.target.className.indexOf("dropdown-toggle") > -1
    )
      return;
    setToggle([]);
  };
  const handleReload = async (e) => {
    e.preventDefault();
    sessionStorage.removeItem("profile");
    localStorage.clear();
    await req.clearCache();
    window.location.reload();
  };
  useEffect(() => {
    document.addEventListener("click", clearToggle);
    return () => document.removeEventListener("click", clearToggle);
  }, []);
  const { profile } = useApiGet(AUTHENTICATION, {});
  const isMfaEnabled = `${_.get(profile, "is_mfa_enabled")}` === "1";
  return (
    <nav className="navbar navbar-expand bg-light topbar static-top px-4">
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
        onClick={handleToggleSidebar}
        type="button"
      >
        <i className="fa fa-bars" />
      </button>
      <div>{header}</div>
      {/* <div className="position-relative ml-auto mr-3">
        <i className="fa fa-2x fa-bell" />
        <span className="position-absolute" style={{
          right: '-10px',
          top: '-5px',
          background: '#fff',
          borderRadius: '50%',
          height: '18px',
          width: '18px',
          textAlign: 'center',
        }}>
          {isMfaEnabled
            ? <i className="text-success fa fa-check-circle" />
            : <i className="text-danger fa fa-exclamation-triangle" />}
        </span>
      </div> */}
      {isMfaEnabled ? (
        <div className="ml-auto mt-4">
          <small className="mr-1">
            <Link
              className="font-weight-bold text-success d-flex align-items-center"
              to={{ pathname: `/profile` }}
            >
              <i className="fa fa-lock mr-1" />
              <div>MFA Enabled</div>
            </Link>
          </small>
        </div>
      ) : (
        <div className="ml-auto mt-4">
          <small className="mr-1">
            <Link
              className="font-weight-bold text-danger d-flex align-items-center"
              to={{ pathname: `/profile`, state: { mfaModal: true } }}
            >
              <i className="fa fa-exclamation-triangle fa-2x mr-1" />
              <div>
                Add security to your account
                <br />
                Click here to activate Multi-factor authentication(MFA)
              </div>
            </Link>
          </small>
        </div>
      )}
      <ul className="navbar-nav ml-1">
        <li
          className={`nav-item dropdown no-arrow${
            isUserMenuToggled ? " show" : ""
          }`}
        >
          <a
            className="nav-link dropdown-toggle text-gray-600"
            href="/"
            onClick={handleToggle("user-menu")}
          >
            <span className="mr-2 d-none d-lg-inline small text-right">
              <div className="text-uppercase">
                {_.get(auth, "profile.full_name") || "USER"}
              </div>
              <div>
                {!_.isEmpty(municipality) && (
                  <span className="mr-1">
                    ({_.get(municipality, "name") || "-"})
                  </span>
                )}
                <b>
                  {USER_TYPE_BY_ACCESS_KEY[
                    _.get(auth, "profile.access_control")
                  ] || "UNKNOWN TYPE"}
                </b>
              </div>
              <span>
                <small>
                  {process.env.REACT_APP_TITLE} {c.VERSION_NUMBER}
                </small>
              </span>
            </span>
            <img
              className="img-profile rounded-circle"
              src={`https://via.placeholder.com/32?text=${
                _.get(auth, "profile.initials") || "USR"
              }`}
              alt="User"
            />
          </a>
          <div
            className={`dropdown-menu dropdown-menu-right shadow animated--grow-in${
              isUserMenuToggled ? " show" : ""
            }`}
          >
            <Link className="dropdown-item" to="/profile">
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-600" />
              Profile
            </Link>
            <div className="dropdown-divider" />
            <Link className="dropdown-item" to="/developers">
              <i className="fas fa-user-tie fa-sm fa-fw mr-2 text-gray-600" />
              Developers
            </Link>
            <div className="dropdown-divider" />
            <a className="dropdown-item" href="/" onClick={handleReload}>
              <i className="fas fa-sync fa-sm fa-fw mr-2 text-gray-600" />
              Clear cache &amp; reload
            </a>
            <div className="dropdown-divider" />
            <Link className="dropdown-item" to="/logout">
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-600" />
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
