import React, { Suspense } from "react";
import { Helmet } from "react-helmet";

import _ from "lodash";
import Logout from "modules/auth/containers/Logout";
import Header from "modules/common/components/Header";
import Sidebar from "modules/common/components/Sidebar";
import SiteLogin from "modules/auth/containers/SiteLogin";
import DeferLoader from "modules/common/components/DeferLoader";
import TripRoutes from "travel-marianas-modules/trips/TripRoutes";
import AccessDenied from "modules/common/containers/AccessDenied";
import PageNotFound from "modules/common/containers/PageNotFound";
import ReportRoutes from "modules/report/containers/ReportRoutes";
import SpecialSurveyRoutes from "modules/special-survey/containers/SpecialSurveyRoutes";
import ErrorBoundary from "modules/common/containers/ErrorBoundary";
import ProfileContainer from "modules/auth/containers/ProfileContainer";
import DevelopersContainer from "modules/auth/containers/DevelopersContainer";
import RegistrantRoutes from "travel-marianas-modules/registrants/RegistrantRoutes";
import AclAdminContainer from "modules/access-control/containers/AclAdminContainer";
import DashboardContainer from "travel-marianas-modules/dashboard/DashboardContainer";
import ContentManagementContainer from "modules/content-management/containers/ContentManagementContainer";
import UsersContainer from "modules/access-control/containers/UsersContainer";
import Scanner from "modules/scanner";

import { AclContext } from "modules/common/context";
import { useApiGet, useApiLoading } from "react-reqq";
import { useAclKeys } from "modules/access-control/acl";
import { HeaderContext } from "modules/common/constants";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import { AUTHENTICATION, SUPER_ADMIN_ACCESS_KEY } from "modules/auth/constants";

function Private() {
  const [header, setHeader] = React.useState(null);
  const modules = useAclKeys();

  const location = useLocation();
  const { profile } = useApiGet(AUTHENTICATION, {});
  const isProfileLoading = useApiLoading("PROFILE", "get");
  const isSuperAdmin = profile.access_control === SUPER_ADMIN_ACCESS_KEY;

  const perms = _.get(profile, "acl.permission") || [];
  const acl = React.useCallback(
    (id) => {
      if (isSuperAdmin) return true;
      if (typeof id === "string") {
        return perms.indexOf(id) > -1;
      }
      if (Array.isArray(id)) {
        return perms.some((i) => id.includes(i));
      }
      return false;
    },
    [perms]
  );

  if (location.pathname === "/authenticate") {
    return <SiteLogin />;
  }

  if (_.isEmpty(modules)) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Switch>
          <Route path="/logout" component={Logout} />
          {isProfileLoading && (
            <Route>
              <div>
                <span className="spinner-bar" />
              </div>
            </Route>
          )}
          <Route>
            <DeferLoader delay={1000}>
              <div className="text-center">
                <h4 className="mb-0">Permission denied!</h4>
                <div className="mb-3">
                  <p className="text-muted">
                    {/* Your session has expired and you need to login again. */}
                    We apologize, but you do not have permission to access the
                    modules in the system.
                    <br />
                    {/* If this error persist, Please contact your account
                    administrator. */}
                    Please contact the Administrator to arrange viewing.
                  </p>
                </div>
                <Link to="/logout">Click here to login.</Link>
              </div>
            </DeferLoader>
          </Route>
        </Switch>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Login - {process.env.REACT_APP_TITLE}</title>
        <meta name="viewport" content="width=1366, initial-scale=1" />
      </Helmet>
      <HeaderContext.Provider value={{ header, setHeader }}>
        <AclContext.Provider value={{ acl }}>
          <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="rounded-wrapper">
              <div id="content" className="d-flex flex-column">
                <Header />
                <div className="flex-grow-1 bg-light" style={{ zIndex: 1 }}>
                  <div className="content-main d-flex flex-column px-3 pb-3">
                    <ErrorBoundary>
                      <Suspense
                        fallback={
                          <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                            <span className="spinner-grow text-primary" />
                          </div>
                        }
                      >
                        <Switch>
                          <Route path="/logout" component={Logout} />
                          <Route
                            path="/dashboard"
                            component={DashboardContainer}
                          />
                          {/* <Route path="/logbook" component={LogbookModules} />
                          <Route
                            path="/contact-tracing"
                            component={ContactTracingContainer}
                          /> */}
                          <Route
                            path="/registrants"
                            component={RegistrantRoutes}
                          />
                          <Route path="/trips" component={TripRoutes} />
                          <Route path="/reports" component={ReportRoutes} />
                          <Route
                            path="/special-survey"
                            component={SpecialSurveyRoutes}
                          />
                          <Route path="/profile" component={ProfileContainer} />
                          <Route
                            path="/content-management"
                            component={ContentManagementContainer}
                          />
                          <Route
                            path="/access-control"
                            component={
                              isSuperAdmin ? AclAdminContainer : AccessDenied
                            }
                          />
                          <Route path="/sub-accounts">
                            <UsersContainer type="subuser" />
                          </Route>
                          <Route path="/scanner" component={Scanner} />
                          <Route
                            path="/developers"
                            component={DevelopersContainer}
                          />
                          <Route component={PageNotFound} />
                        </Switch>
                      </Suspense>
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AclContext.Provider>
      </HeaderContext.Provider>
    </>
  );
}

export default Private;
