/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { GoogleMap as GMap, useLoadScript } from "@react-google-maps/api";
import { MAP_STYLE_WHITE } from "modules/dashboard/constants";
import { loadAPI } from "../helper";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const GOOGLE_LIBRARIES = ["geometry", "drawing", "places", "visualization"];

function GoogleMap({
  children,
  options: defaultOptions,
  onLoad: handleLoaded,
  zoom,
  center,
  ...props
}) {
  const [options] = React.useState({
    disableDefaultUI: true,
    scaleControl: true,
    zoomControl: true,
    minZoom: 2,
    styles: MAP_STYLE_WHITE,
    ...defaultOptions,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: GOOGLE_LIBRARIES,
  });
  const [mapInstance, setMapInstance] = React.useState(null);
  const [isClustererApiLoaded, setIsClustererApiLoaded] = React.useState(false);
  const onLoad = React.useCallback((map) => {
    setMapInstance(map);
  });
  React.useEffect(() => {
    if (!mapInstance || !isClustererApiLoaded) return;
    if (typeof handleLoaded === "function") handleLoaded(mapInstance);
  }, [mapInstance, isClustererApiLoaded]);

  React.useEffect(() => {
    loadAPI(
      "https://polyfill.io/v3/polyfill.min.js?features=default",
      () => {
        loadAPI(
          "https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js",
          () => {
            setIsClustererApiLoaded(true);
          },
          () => {}
        );
      },
      () => {}
    );
  }, []);

  if (!isLoaded || loadError) {
    return (
      <div
        style={{ height: `100%` }}
        className="d-flex justify-content-center align-items-center"
      >
        {loadError
          ? "Map cannot be loaded right now, sorry."
          : "Loading maps..."}
      </div>
    );
  }
  return (
    <GMap
      mapContainerClassName="h-100"
      options={options}
      onLoad={onLoad}
      zoom={zoom}
      center={center}
      {...props}
    >
      {children}
    </GMap>
  );
}

GoogleMap.propTypes = {
  children: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  onLoad: PropTypes.func,
  zoom: PropTypes.number,
  center: PropTypes.instanceOf(Object),
};

GoogleMap.defaultProps = {
  children: <div />,
  onLoad: () => {},
  options: {},
  zoom: 2,
  center: { lat: 40, lng: 0 },
};

export default GoogleMap;
