import React from "react";
import { motion } from "framer-motion";

import {
  useIntersectionObserver,
  useWindowDimensions,
} from "modules/common/helper";

import Step1 from "assets/img/landing/register/business/business-step-1.svg";
import Step2 from "assets/img/landing/register/business/business-step-2.svg";
import Step3 from "assets/img/landing/register/business/business-step-3.svg";
import Step4 from "assets/img/landing/register/business/business-step-4.svg";
import Step5 from "assets/img/landing/register/business/business-step-5.svg";
import Step6 from "assets/img/landing/register/business/business-step-6.svg";

import { cardVariants } from "../home/Why";

import "../../styles/main.scss";

const steps = [
  {
    title: "Step 1",
    description:
      "Log In to https://www.safetravelsmarianas.com/ and click on register as Business",
    img: Step1,
  },
  {
    title: "Step 2",
    description: "Enter Your Information for your establishment",
    img: Step2,
  },
  {
    title: "Step 3",
    description: "Activate your account via email & Log into the account",
    img: Step3,
  },
  {
    title: "Step 4",
    description: "Create entry points Entrance/Exit Print QR Code",
    img: Step4,
  },
  {
    title: "Step 5",
    description: "Place QR code on doors Train staff on how to use QR Code",
    img: Step5,
  },
  {
    title: "Step 6",
    description:
      "Add scanner account if needed scanner account will allow you to scan visits",
    img: Step6,
  },
];

export default () => {
  const [control, ref] = useIntersectionObserver();

  return (
    <div
      ref={ref}
      className="landing__how-business-container landing-container"
    >
      <h1 className="landing__how-business-title landing-heading-1">
        Business Establishments
      </h1>
      <div className="landing__how-business-cards-container">
        {steps.slice(0, 3).map(({ title, description, img }) => (
          <figure key={title} className="landing__how-business-card-container">
            <motion.img
              src={img}
              alt={`Business ${title}`}
              className="landing__how-business-card-img"
              variants={cardVariants}
              initial="hidden"
              animate={control}
              transition={{ duration: 0.6 }}
            />
            <figcaption className="landing__how-business-card-caption-container">
              <h4 className="landing__how-business-card-subtitle landing-heading-4">
                {title}
              </h4>
              <p className="landing__how-business-card-paragraph landing-paragraph">
                {description}
              </p>
            </figcaption>
          </figure>
        ))}
        <RowTwo />
      </div>
    </div>
  );
};

function RowTwo() {
  const { width } = useWindowDimensions();
  const [control, ref] = useIntersectionObserver(
    width <= 900 ? "700px 0px" : "-300px 0px"
  );
  return (
    <>
      {steps.slice(3, 6).map(({ title, description, img }) => (
        <figure
          ref={ref}
          key={title}
          className="landing__how-business-card-container"
        >
          <motion.img
            src={img}
            alt={`Business ${title}`}
            className="landing__how-business-card-img"
            variants={cardVariants}
            initial="hidden"
            animate={control}
            transition={{ duration: 0.6 }}
          />
          <figcaption className="landing__how-business-card-caption-container">
            <h4 className="landing__how-business-card-subtitle landing-heading-4">
              {title}
            </h4>
            <p className="landing__how-business-card-paragraph landing-paragraph">
              {description}
            </p>
          </figcaption>
        </figure>
      ))}
    </>
  );
}
