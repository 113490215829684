import React, { useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useApiLoading, useApiGet } from "react-reqq";

import { AUTHENTICATION } from "modules/auth/constants";
import { ToastSuccess } from "modules/common/components/toast";
import { removeNull, generate } from "modules/common/helper";
import withForm from "modules/common/hoc/withForm";
import FormInput from "modules/common/form/FormInput";
import FormMobileNumber from "modules/common/form/FormMobileNumber";
import FormSelect from "modules/common/form/FormSelect";
import { MODULES } from "../acl";

import { orgModules, companyModules, mvaModules } from "../containers/UserAccessControl";
// import FormMapBounds from "modules/common/form/FormMapBounds";

import * as c from "../constants";
import * as actions from "../actions";

const userTypeOptions = [
  { label: "Admin", value: "ORGANIZATION" },
  { label: "Establishment", value: "COMPANY" },
  { label: "MVA", value: "MVA" },
];

const ACTION_UPDATE_ACL = {
  admin: actions.updateAdminAcl,
  subuser: actions.updateSubUserAcl,
};

const CONST_KEY = {
  admin: c.SELECT_ADMIN,
  subuser: c.SELECT_SUB_USER,
};

const ACTION_SELECT = {
  admin: actions.selectAdmin,
  subuser: actions.selectSubUser,
};

const ACTION_CREATE = {
  admin: actions.createAdmin,
  subuser: actions.createSubUser,
};

const ACTION_UPDATE = {
  admin: actions.updateAdmin,
  subuser: actions.updateSubUser,
};

const formatMunicipality = (raw) => {
  if (_.isEmpty(raw || {})) return {};
  return {
    border: _.get(raw, "border"),
    label: _.get(raw, "name"),
    value: _.get(raw, "code"),
  };
};

const getAclPayload = (
  data,
  form,
  actionType,
  currentUserType,
  subUserPermission
) => {
  const selectedUserType = data?.attributes?.type;
  const isSelectedUserTypeChanged = selectedUserType !== form?.type;
  let adminPermission = selectedUserType === "ORGANIZATION" ? orgModules : companyModules;
  let newPermissions =
    currentUserType === "admin" ? adminPermission : subUserPermission;
  if (selectedUserType === 'MVA') { // for MVA user type 
    adminPermission = mvaModules;
    newPermissions = adminPermission;
  }
  const home_route =
    (MODULES.find((row) => newPermissions.indexOf(row.key) > -1) || {}).path ||
    "/profile";
  let payload = {
    acl: {
      home_route,
      permission: newPermissions,
      template_name: "Custom",
    },
  };

  if (actionType === "update") {
    if (selectedUserType === form?.type) {
      payload = {
        acl: data?.attributes?.acl,
      };
    }
    if (
      currentUserType === "admin" &&
      selectedUserType === "COMPANY" &&
      isSelectedUserTypeChanged
    ) {
      payload = {
        acl: {
          home_route:
            (MODULES.find((row) => orgModules.indexOf(row.key) > -1) || {})
              .path || "/profile",
          permission: orgModules,
          template_name: "Custom",
        },
      };
    }
    if (
      currentUserType === "admin" &&
      selectedUserType === "ORGANIZATION" &&
      isSelectedUserTypeChanged
    ) {
      payload = {
        acl: {
          home_route:
            (MODULES.find((row) => companyModules.indexOf(row.key) > -1) || {})
              .path || "/profile",
          permission: companyModules,
          template_name: "Custom",
        },
      };
    }
    if (
      currentUserType === "admin" &&
      selectedUserType === "MVA" &&
      isSelectedUserTypeChanged
    ) {
      payload = {
        acl: {
          home_route:
            (MODULES.find((row) => mvaModules.indexOf(row.key) > -1) || {})
              .path || "/profile",
          permission: mvaModules,
          template_name: "Custom",
        },
      };
    }
  }
  return payload;
};

const DEFAULT_MAP_BOUNDS = `{"bounds":{"east":130.6600275,"north":19.7610407,"south":3.6346296,"west":114.583481},"zoom":5}`;

function UserModal({ match, value, type, filter, onClose }) {
  const history = useHistory();
  const isNew = !_.get(value, "id");
  const getType = type === "subuser" ? "COMPANY" : "";

  const { profile } = useApiGet(AUTHENTICATION, {});
  const isLoading = useApiLoading(CONST_KEY[type], isNew ? "post" : "put");
  const isAclUpdateLoading = useApiLoading(CONST_KEY[type], "put");

  const [adminForm, setAdminForm] = React.useState({
    category: _.get(value, "attributes.category") || "",
    type: _.get(value, "attributes.type") || getType,
    representative_name: _.get(value, "attributes.representative_name") || "",
    representative_position:
      _.get(value, "attributes.representative_position") || "",
    other_contact_number: _.get(value, "attributes.other_contact_number") || "",
    notes: _.get(value, "attributes.notes") || "",
    address: _.get(value, "attributes.address") || "",
    sub_account_max_count:
      _.get(value, "attributes.sub_account_max_count") || "0",
    contact_tracer_max_count:
      _.get(value, "attributes.contact_tracer_max_count") || "0",
    front_liner_max_count:
      _.get(value, "attributes.front_liner_max_count") || "0",
  });

  const [form, setForm] = React.useState({
    name: _.get(value, "attributes.name") || "",
    sub_category: _.get(value, "attributes.sub_category") || "",
    mobile_number: _.get(value, "attributes.mobile_number") || "",
    email: _.get(value, "attributes.email") || "",
    password: isNew ? generate(8) : null,
  });

  const [mapForm] = React.useState({
    // const [mapForm, setMapForm] = React.useState({
    geoloc: _.get(value, "attributes.geoloc") || "15.202316,145.7089154",
    map_bounds: _.get(value, "attributes.map_bounds") || DEFAULT_MAP_BOUNDS,
    locality: _.get(value, "attributes.locality") || "n/a",

    municipality: formatMunicipality(_.get(value, "attributes.municipality")),
  });

  const subUserPermission = useMemo(
    () =>
      companyModules?.filter(
        (item) =>
          profile?.acl?.permission?.includes(item) &&
          item !== "acl-sub-accounts"
      ),
    [profile]
  );

  const handleRandomizePw = (e) => {
    e.preventDefault();
    setForm((state) => ({ ...state, password: generate(8) }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = removeNull({
      ...form,
      ...filter,
      geoloc: mapForm.geoloc,
      map_bounds: mapForm.map_bounds,
      locality: mapForm.locality,
      municipality_code: _.get(mapForm, "municipality.value") || "",
      password_confirmation: form.password,
    });
    if (type === "admin" || type === "subuser") {
      payload = {
        ...payload,
        ...adminForm,
      };
      if (adminForm.category === "PRIVATE") {
        payload = {
          ...payload,
        };
      }
    }
    if (isNew) {
      if (ACTION_CREATE[type]) {
        ACTION_CREATE[type](
          payload,
          (data) => {
            const newId = data?.attributes?.uuid;
            const aclPayload = getAclPayload(
              data,
              adminForm,
              "create",
              type,
              subUserPermission
            );

            if (ACTION_UPDATE_ACL[type]) {
              ACTION_UPDATE_ACL[type](
                newId,
                aclPayload,
                () => {
                  ToastSuccess("Created!");
                  history.push(`${match.url}/${newId}`);
                  onClose();
                },
                false
              );
            }
          },
          false
        );
      }
      return;
    }

    const uuid = _.get(value, "attributes.uuid");

    if (ACTION_UPDATE[type]) {
      ACTION_UPDATE[type](uuid, payload, () => {
        const aclPayload = getAclPayload(
          value,
          adminForm,
          "update",
          type,
          subUserPermission
        );
        if (ACTION_UPDATE_ACL[type]) {
          ACTION_UPDATE_ACL[type](
            uuid,
            aclPayload,
            () => {
              ToastSuccess("Updated!");
              ACTION_SELECT[type](uuid);
              onClose();
            },
            false
          );
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col form-group">
            <FormInput
              className="form-control text-uppercase"
              name="name"
              label="Access Name"
              onChange={setForm}
              value={form.name}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col form-group">
            <FormInput
              className="form-control text-uppercase"
              name="representative_name"
              label="Representative's Name"
              onChange={setAdminForm}
              value={adminForm.representative_name}
              required
            />
          </div>
          <div className="col form-group">
            <FormInput
              className="form-control text-uppercase"
              name="representative_position"
              label="Representative's Position"
              onChange={setAdminForm}
              value={adminForm.representative_position}
              required
            />
          </div>
          {type === "admin" && (
            <div className="col form-group">
              <FormSelect
                name="type"
                className="form-control text-uppercase"
                onChange={setAdminForm}
                label="User Type"
                // placeholder="Select User Type"
                value={adminForm?.type || ""}
                options={userTypeOptions}
                required
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-4 form-group">
            <FormMobileNumber
              name="mobile_number"
              label="Mobile Number"
              onChange={setForm}
              value={form.mobile_number}
              required
            />
          </div>
          <div className="col form-group">
            <FormInput
              name="email"
              label="Email"
              onChange={setForm}
              value={form.email}
              type="email"
              required
            />
          </div>
          {isNew && (
            <div className="col">
              <FormInput
                name="password"
                label="Default Password"
                onChange={setForm}
                value={form.password || ""}
                // type="password"
                // required
              />
              <small>
                <a href="/" onClick={handleRandomizePw}>
                  <i className="fa fa-dice mr-1" />
                  Randomize
                </a>
              </small>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary"
          type="submit"
          disabled={isLoading || isAclUpdateLoading}
        >
          {(isLoading || isAclUpdateLoading) && (
            <span className="spinner-grow spinner-grow-sm mr-1" />
          )}
          {isNew ? "Create" : "Save Changes"}
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

UserModal.propTypes = {
  match: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  filter: PropTypes.instanceOf(Object),
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

UserModal.defaultProps = {
  match: {},
  value: {},
  filter: {},
};

export default React.memo(withForm(UserModal));
