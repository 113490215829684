/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
// import { Helmet } from "react-helmet";
// import { HeaderTitle } from "modules/common/components/Header";
import { useApiLoading, useApiGet } from "react-reqq";
import { isEmpty } from "lodash";
import { confirmAlert } from "modules/common/components/Alert";
import { ToastSuccess } from "modules/common/components/toast";
import * as actions from "../actions";
import * as c from "../constants";

function IntegrationManagement({ userId }) {
  const isLoading = useApiLoading(c.INTEGRATION, "get");
  const data = useApiGet(c.INTEGRATION, {});

  const handleOpenAdd = () => {
    actions.addIntegration(userId, { status: "ACTIVE" });
  };

  const handleCopyToClipboard = (e) => {
    e.preventDefault();
    const input = document.createElement("input");
    input.setAttribute("value", data?.attributes?.key);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    ToastSuccess("Copied to clipboard!");
  };

  const handleEnable = () => {
    actions.enableIntegration(userId);
  };

  const handleDisable = () => {
    actions.disableIntegration(userId);
  };

  const handleDelete = () => {
    confirmAlert({
      content: "Are you sure you want to remove?",
      onYes: (onClose) => {
        actions.deleteIntegration(userId, onClose);
      },
    });
  };

  React.useEffect(() => {
    actions.getIntegration(userId, {});
  }, [userId]);

  const isEnabled = data?.attributes?.status === "ACTIVE";

  return (
    <>
      {/* <Helmet>
        <title>Integration Management - {process.env.REACT_APP_TITLE}</title>
      </Helmet> */}
      {/* <HeaderTitle>
        <div className="font-weight-bold text-gray-600">
          Integration Management
        </div>
      </HeaderTitle> */}
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column">
        <div className="d-flex justify-content-between p-1">
          {isEmpty(data) ? (
            <div>
              <button
                className="btn btn-primary mb-1"
                type="button"
                onClick={handleOpenAdd}
                disabled={isLoading}
              >
                Create API Key
              </button>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="px-3 pt-3 rounded">
                {!isEnabled ? (
                  <div>
                    <pre>
                      <code>
                        API KEY:{" "}
                        <span
                          className="badge badge-light"
                          style={{ color: "#d6d6d6" }}
                        >
                          {data?.attributes?.key}
                        </span>
                      </code>
                    </pre>
                  </div>
                ) : (
                  <div>
                    <pre>
                      <code>
                        API KEY:{" "}
                        <span className="badge badge-dark">
                          {data?.attributes?.key}
                        </span>
                      </code>
                    </pre>
                    <pre>
                      <code>
                        Secret:{" "}
                        <span
                          className="badge badge-light"
                          style={{ color: "#d6d6d6" }}
                        >
                          {data?.attributes?.secret || "- Secured -"}
                        </span>
                      </code>
                    </pre>
                  </div>
                )}
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-info btn-sm mr-1"
                  type="button"
                  onClick={handleCopyToClipboard}
                  disabled={!isEnabled}
                >
                  <i className="fa fa-clipboard" /> Copy to Clipboard
                </button>
                {!isEnabled ? (
                  <button
                    className="btn btn-success btn-sm mr-1"
                    type="button"
                    onClick={handleEnable}
                  >
                    <i className="fa fa-edit" /> Enable API Key
                  </button>
                ) : (
                  <button
                    className="btn btn-warning btn-sm mr-1"
                    type="button"
                    onClick={handleDisable}
                  >
                    <i className="fa fa-edit" /> Disable API Key
                  </button>
                )}
                <button
                  className="btn btn-outline-danger btn-sm"
                  type="button"
                  onClick={handleDelete}
                >
                  <i className="fa fa-trash" /> Delete API Key
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

IntegrationManagement.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default IntegrationManagement;
