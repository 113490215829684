import React from "react";
import PropTypes from "prop-types";
import FormInput from "modules/common/form/FormInput";
// import FormInputMask from 'modules/common/form/FormInputMask';
import { useApiGet } from "react-reqq";
import { FORM_ANSWERS } from "modules/assessment/constants";
import RetryMessage from "./RetryMessage";
import { setAnswer } from "./actions";
import { useIsSaving } from "./hooks";

function QuestionShortAns({ data, readOnly }) {
  const value = useApiGet(`${FORM_ANSWERS}.${data.id}.value`, "");
  const [isSaving, setIsSaving] = React.useState(false);
  useIsSaving(data.id, isSaving);
  const setValue = (x) => {
    const { value: newValue } = x();
    setIsSaving(true);
    setAnswer(
      {
        [data.id]: {
          value: newValue,
        },
      },
      () => setIsSaving(false)
    );
  };
  const renderInput = () => {
    const inputType = data?.meta?.input_type;
    if (inputType === "number") {
      return (
        <FormInput
          name="value"
          onChange={setValue}
          value={value}
          icon={null}
          required={data.required}
          readOnly={readOnly}
          type="number"
        />
      );
    }
    if (inputType === "mask") {
      return (
        // <FormInputMask
        <FormInput
          name="value"
          onChange={setValue}
          value={value}
          icon={null}
          required={data.required}
          readOnly={readOnly}
          mask={data?.meta?.input_mask}
        />
      );
    }
    if (inputType === "pattern") {
      return (
        <FormInput
          name="value"
          onChange={setValue}
          value={value}
          icon={null}
          required={data.required}
          readOnly={readOnly}
          pattern={data?.meta?.input_pattern}
          title={data?.meta?.input_pattern_label}
        />
      );
    }
    return (
      <FormInput
        name="value"
        onChange={setValue}
        value={value}
        icon={null}
        required={data.required}
        readOnly={readOnly}
      />
    );
  };
  return (
    <div className="p-2 rounded shadow-md w-full bg-white">
      <div className="float-right text-green-500">
        <i
          className={`fa fa-spin fa-spinner ${
            isSaving ? "visible" : "invisible"
          }`}
        />
      </div>
      <div className="mb-2 text-base font-bold">
        <span className="text-semibold">{data.question}</span>
        {!data.required && (
          <span className="ml-2 text-gray-500">(Optional)</span>
        )}
      </div>
      {renderInput()}
      <RetryMessage isSaving={isSaving} />
    </div>
  );
}

QuestionShortAns.defaultProps = {
  readOnly: false,
};

QuestionShortAns.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  readOnly: PropTypes.bool,
};

export default QuestionShortAns;
