import { req } from "react-reqq";
import _ from "lodash";
import { ToastSuccess } from "modules/common/components/toast";
import * as c from "./constants";

export const listStatus = (params) =>
  req.get({
    key: c.STATUS,
    url: "/api/v1/mng/health-status",
    params,
    transform: (res) => {
      const data = _.get(res, "data") || [];
      return data;
    },
  });

export const updateStatus = (id, payload, onSuccess) =>
  req.put({
    key: c.STATUS,
    url: `/api/v1/mng/health-status/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      listStatus();
      if (onSuccess) onSuccess(res);
    },
  });

export const listSymptoms = (params) =>
  req.get({
    key: c.SYMPTOMS,
    url: "/api/v1/mng/symptoms",
    params: _.omit(params, ["cache"]),
    transform: (res) => {
      const data = _.get(res, "data") || [];
      req.set(
        `${c.SYMPTOMS}/options`,
        data.map((item) => ({
          label: _.get(item, "attributes.title"),
          value: _.get(item, "attributes.code"),
        }))
      );
      return data;
    },
    cache: params.cache,
  });

export const addSymptoms = (payload, onSuccess) =>
  req.post({
    key: c.SYMPTOMS,
    url: "/api/v1/mng/symptoms",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Added!");
      listSymptoms({});
      if (onSuccess) onSuccess(res);
    },
  });

export const updateSymptoms = (id, payload, onSuccess) =>
  req.put({
    key: c.SYMPTOMS,
    url: `/api/v1/mng/symptoms/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      listSymptoms({});
      if (onSuccess) onSuccess(res);
    },
  });

export const deleteSymptoms = (id, onSuccess) =>
  req.remove({
    key: c.SYMPTOMS,
    url: `/api/v1/mng/symptoms/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Deleted!");
      listSymptoms({});
      if (onSuccess) onSuccess(res);
    },
  });

export const listCompany = (params) =>
  req.get({
    key: c.COMPANY,
    url: "/api/v1/mng/companies",
    params,
    transform: (res) => {
      req.set(`${c.COMPANY}/pager`, _.get(res, "meta.pagination") || {});
      return res.data;
    },
  });

export const addCompany = (payload, onSuccess) =>
  req.post({
    key: c.COMPANY,
    url: "/api/v1/mng/companies",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listCompany();
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateCompany = (id, payload, onSuccess) => {
  req.put({
    key: c.COMPANY,
    url: `/api/v1/mng/companies/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.COMPANY, (state) => {
        const oldList = _.get(state, c.COMPANY) || [];
        return oldList.map((item) =>
          _.get(item, "attributes.uuid") === id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
    },
  });
};

export const deleteCompany = (id, onSuccess) => {
  req.remove({
    key: c.COMPANY,
    url: `/api/v1/mng/companies/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.COMPANY, (state) => {
        const oldList = _.get(state, c.COMPANY) || [];
        return oldList.filter((item) => _.get(item, "attributes.uuid") !== id);
      });
    },
  });
};

export const listCaseStatus = (params, cache) =>
  req.get({
    key: c.CASE_STATUS,
    url: "/api/v1/mng/case-status",
    params,
    transform: (res) => {
      req.set(`${c.CASE_STATUS}/pager`, _.get(res, "meta.pagination") || {});
      req.set(
        `${c.CASE_STATUS}/options`,
        (res.data || []).map((item) => ({
          label: _.get(item, "attributes.name"),
          value: _.get(item, "attributes.code"),
          color: _.get(item, "attributes.color"),
        }))
      );
      return res.data;
    },
    cache,
  });

export const addCaseStatus = (payload, onSuccess) =>
  req.post({
    key: c.CASE_STATUS,
    url: "/api/v1/mng/case-status",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listCaseStatus();
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateCaseStatus = (id, payload, onSuccess) => {
  req.put({
    key: c.CASE_STATUS,
    url: `/api/v1/mng/case-status/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.CASE_STATUS, (state) => {
        const oldList = _.get(state, c.CASE_STATUS) || [];
        return oldList.map((item) =>
          _.get(item, "id") === id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
    },
  });
};

export const deleteCaseStatus = (id, onSuccess) => {
  req.remove({
    key: c.CASE_STATUS,
    url: `/api/v1/mng/case-status/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.CASE_STATUS, (state) => {
        const oldList = _.get(state, c.CASE_STATUS) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};

export const listMessengerBotResponse = (params) =>
  req.get({
    key: c.MESSENGER_BOT_RESPONSE,
    url: "/api/v1/mng/chat-templates",
    params,
    transform: (res) => {
      req.set(
        `${c.MESSENGER_BOT_RESPONSE}/pager`,
        _.get(res, "meta.pagination") || {}
      );
      return res.data;
    },
  });

export const addMessengerBotResponse = (payload, onSuccess) =>
  req.post({
    key: c.MESSENGER_BOT_RESPONSE,
    url: "/api/v1/mng/chat-templates",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listMessengerBotResponse();
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateMessengerBotResponse = (id, payload, onSuccess) => {
  req.put({
    key: c.MESSENGER_BOT_RESPONSE,
    url: `/api/v1/mng/chat-templates/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.MESSENGER_BOT_RESPONSE, (state) => {
        const oldList = _.get(state, c.MESSENGER_BOT_RESPONSE) || [];
        return oldList.map((item) =>
          _.get(item, "id") === id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
    },
  });
};

export const deleteMessengerBotResponse = (id, onSuccess) => {
  req.remove({
    key: c.MESSENGER_BOT_RESPONSE,
    url: `/api/v1/mng/chat-templates/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.MESSENGER_BOT_RESPONSE, (state) => {
        const oldList = _.get(state, c.MESSENGER_BOT_RESPONSE) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};

export const listLocationType = ({ cache, ...params }) =>
  req.get({
    key: c.LOCATION_TYPE,
    url: "/api/v1/mng/case-location-type",
    params,
    transform: (res) => {
      const data = res.data || [];
      req.set(`${c.LOCATION_TYPE}/pager`, _.get(res, "meta.pagination") || {});
      req.set(
        `${c.LOCATION_TYPE}/options`,
        data.map((item) => ({
          value: `${_.get(item, "id")}`,
          label: _.get(item, "attributes.name"),
        }))
      );
      return data;
    },
    cache,
  });

export const addLocationType = (payload, onSuccess) =>
  req.post({
    key: c.LOCATION_TYPE,
    url: "/api/v1/mng/case-location-type",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listLocationType({});
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateLocationType = (id, payload, onSuccess) => {
  req.put({
    key: c.LOCATION_TYPE,
    url: `/api/v1/mng/case-location-type/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.LOCATION_TYPE, (state) => {
        const oldList = _.get(state, c.LOCATION_TYPE) || [];
        return oldList.map((item) =>
          _.get(item, "id") === id
            ? _.get(res, "response.data") || {
              ...item,
              attributes: { ...item.attributes, ...payload },
            }
            : item
        );
      });
    },
  });
};

export const deleteLocationType = (id, onSuccess) => {
  req.remove({
    key: c.LOCATION_TYPE,
    url: `/api/v1/mng/case-location-type/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.LOCATION_TYPE, (state) => {
        const oldList = _.get(state, c.LOCATION_TYPE) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};

export const listHealthChecklist = ({ cache, ...params }) =>
  req.get({
    key: c.HEALTH_CHECKLIST,
    url: "/api/v1/mng/health-checklist",
    params,
    transform: (res) => {
      const data = res.data || [];
      req.set(
        `${c.HEALTH_CHECKLIST}/pager`,
        _.get(res, "meta.pagination") || {}
      );
      req.set(
        `${c.HEALTH_CHECKLIST}/options`,
        data.map((item) => ({
          value: `${_.get(item, "id")}`,
          label: _.get(item, "attributes.name"),
        }))
      );
      return data;
    },
    cache,
  });

export const addHealthChecklist = (payload, onSuccess) =>
  req.post({
    key: c.HEALTH_CHECKLIST,
    url: "/api/v1/mng/health-checklist",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listHealthChecklist({});
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateHealthChecklist = (id, payload, onSuccess) => {
  req.put({
    key: c.HEALTH_CHECKLIST,
    url: `/api/v1/mng/health-checklist/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.HEALTH_CHECKLIST, (state) => {
        const oldList = _.get(state, c.HEALTH_CHECKLIST) || [];
        return oldList.map((item) =>
          _.get(item, "id") === id
            ? _.get(res, "response.data") || {
              ...item,
              attributes: { ...item.attributes, ...payload },
            }
            : item
        );
      });
    },
  });
};

export const deleteHealthChecklist = (id, onSuccess) => {
  req.remove({
    key: c.HEALTH_CHECKLIST,
    url: `/api/v1/mng/health-checklist/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.HEALTH_CHECKLIST, (state) => {
        const oldList = _.get(state, c.HEALTH_CHECKLIST) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};

export const listFacility = (params) =>
  req.get({
    key: c.FACILITY_MANAGEMENT,
    url: "/api/v1/mng/facility",
    params,
    transform: (res) => {
      req.set(
        `${c.FACILITY_MANAGEMENT}/pager`,
        _.get(res, "meta.pagination") || {}
      );
      return res.data;
    },
  });

export const addFacility = (payload, onSuccess) =>
  req.post({
    key: c.FACILITY_MANAGEMENT,
    url: "/api/v1/mng/facility",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listFacility();
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateFacility = (id, payload, onSuccess) => {
  req.put({
    key: c.FACILITY_MANAGEMENT,
    url: `/api/v1/mng/facility/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.FACILITY_MANAGEMENT, (state) => {
        const oldList = _.get(state, c.FACILITY_MANAGEMENT) || [];
        return oldList.map((item) =>
          _.get(item, "id") === id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
    },
  });
};

export const deleteFacility = (id, onSuccess) => {
  req.remove({
    key: c.FACILITY_MANAGEMENT,
    url: `/api/v1/mng/facility/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.FACILITY_MANAGEMENT, (state) => {
        const oldList = _.get(state, c.FACILITY_MANAGEMENT) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};

export const listPurpose = (params) =>
  req.get({
    key: c.PURPOSE_MANAGEMENT,
    url: "/api/v1/mng/purpose",
    params,
    transform: (res) => {
      req.set(
        `${c.PURPOSE_MANAGEMENT}/pager`,
        _.get(res, "meta.pagination") || {}
      );
      return res.data;
    },
  });

export const addPurpose = (payload, onSuccess) =>
  req.post({
    key: c.PURPOSE_MANAGEMENT,
    url: "/api/v1/mng/purpose",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listPurpose();
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updatePurpose = (id, payload, onSuccess) => {
  req.put({
    key: c.PURPOSE_MANAGEMENT,
    url: `/api/v1/mng/purpose/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.PURPOSE_MANAGEMENT, (state) => {
        const oldList = _.get(state, c.PURPOSE_MANAGEMENT) || [];
        return oldList.map((item) =>
          _.get(item, "id") === id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
    },
  });
};

export const deletePurpose = (id, onSuccess) => {
  req.remove({
    key: c.PURPOSE_MANAGEMENT,
    url: `/api/v1/mng/purpose/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.PURPOSE_MANAGEMENT, (state) => {
        const oldList = _.get(state, c.PURPOSE_MANAGEMENT) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};

export const listVaccine = (params) =>
  req.get({
    key: c.VACCINE_MANAGEMENT,
    url: "/api/v1/mng/products",
    params,
    transform: (res) => {
      req.set(
        `${c.VACCINE_MANAGEMENT}/pager`,
        _.get(res, "meta.pagination") || {}
      );
      return res.data;
    },
  });

export const addVaccine = (payload, onSuccess) =>
  req.post({
    key: c.VACCINE_MANAGEMENT,
    url: "/api/v1/mng/products",
    payload,
    onSuccess: (res) => {
      ToastSuccess("Created!");
      listVaccine();
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateVaccine = (id, payload, onSuccess) => {
  req.put({
    key: c.VACCINE_MANAGEMENT,
    url: `/api/v1/mng/products/${id}`,
    payload,
    onSuccess: (res) => {
      ToastSuccess("Updated!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.VACCINE_MANAGEMENT, (state) => {
        const oldList = _.get(state, c.VACCINE_MANAGEMENT) || [];
        return oldList.map((item) =>
          _.get(item, "id") === id
            ? { ...item, attributes: { ...item.attributes, ...payload } }
            : item
        );
      });
    },
  });
};

export const deleteVaccine = (id, onSuccess) => {
  req.remove({
    key: c.VACCINE_MANAGEMENT,
    url: `/api/v1/mng/products/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(c.VACCINE_MANAGEMENT, (state) => {
        const oldList = _.get(state, c.VACCINE_MANAGEMENT) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};
