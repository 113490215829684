import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Nav from "./Nav";
import Learn from "./Learn";
import PreFooter from "./PreFooter";
import Footer from "./Footer";

import "../styles/main.scss";

const Template = ({ children }) => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  return (
    <>
      <Nav />
      {currentPath !== "/" && <OrangeBg />}
      {children}
      {currentPath !== "/how-to-register" && <Learn />}
      <PreFooter />
      <Footer />
    </>
  );
};

Template.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Template;

function OrangeBg() {
  const history = useHistory();
  const currentPath = history.location.pathname;

  return (
    <div className="landing__orange-bg-container">
      <h6 className="landing__orange-bg-title">
        {currentPath === "/how-to-register" ? "How to Register" : "FAQs?"}
      </h6>
    </div>
  );
}
