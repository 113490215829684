import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useApiLoading } from "react-reqq";
import { generate } from "modules/common/helper";
import FormInput from "modules/common/form/FormInput";
import { confirmAlert } from "modules/common/components/Alert";
import * as actions from "../actions";
import * as c from "../constants";

const CONST_KEY = {
  admin: c.RESET_PW_ADMIN,
  subuser: c.RESET_PW_SUB_USER,
};

const ACTION_RESET_PASSWORD = {
  admin: actions.resetPasswordAdmin,
  subuser: actions.resetPasswordSubUser,
};

function ResetUserPwModal({ data, type, onClose }) {
  const isLoading = useApiLoading(CONST_KEY[type], "");
  const [form, setForm] = React.useState({
    password: generate(8),
  });
  const handleRandomizePw = (e) => {
    e.preventDefault();
    setForm((state) => ({ ...state, password: generate(8) }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Reset Password",
      content: "Are you sure you want to reset password?",
      onYes: (closeAlert) => {
        closeAlert();
        const payload = {
          password: form.password,
          password_confirmation: form.password,
        };
        if (ACTION_RESET_PASSWORD[type]) {
          ACTION_RESET_PASSWORD[type](
            _.get(data, "attributes.uuid"),
            payload,
            onClose
          );
        }
      },
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="form-group">
          <FormInput
            name="password"
            label="New password"
            onChange={setForm}
            value={form.password}
          />
          <small>
            <a href="/" onClick={handleRandomizePw}>
              <i className="fa fa-dice mr-1" />
              Randomize
            </a>
          </small>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-primary" type="submit" disabled={isLoading}>
          {isLoading ? "Please wait..." : "Reset Password"}
        </button>
        <button
          className="btn btn-outline-primary ml-2"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

ResetUserPwModal.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResetUserPwModal;
