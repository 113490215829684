import React from "react";
import PropTypes from "prop-types";

function Lightbox({ src, onClose }) {
  React.useEffect(() => {
    const closeModal = (e) => {
      if (e.key === "Escape") onClose();
    };
    document.addEventListener("keyup", closeModal);
    document.addEventListener("click", onClose);
    return () => {
      document.removeEventListener("keyup", closeModal);
      document.removeEventListener("click", onClose);
    };
  }, []);
  return (
    <div className="lightbox-image">
      <img src={src} alt="lightbox" style={{ cursor: "pointer" }} />
    </div>
  );
}

Lightbox.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Lightbox;
