import { req } from "react-reqq";

// import { ToastSuccess, ToastError } from "modules/common/components/toast";

import * as c from "./constants";

export const scanTripQrConfirm = (payload, onSuccess, onError) =>
  req.post({
    key: c.SCAN_TRIP_QR_CONFIRM,
    url: "/api/v1/integrator/scan_trip_qr/confirm",
    payload,
    onSuccess: (res) => {
      // ToastSuccess(res?.response?.message, { autoClose: 20000 });
      if (typeof onSuccess === "function") onSuccess(res?.response);
    },
    onError: (res) => {
      // ToastError(res?.response?.message);
      if (typeof onError === "function") onError(res?.response?.message);
    },
  });

export const scanQr = (params, onSuccess, onError) => {
  req.get({
    key: c.SCAN_QR,
    url: "/api/v1/integrator/scan_trip_qr",
    params,
    onSuccess: (res) => {
      // ToastSuccess(res?.response?.message, { autoClose: 20000 });
      if (typeof onSuccess === "function")
        onSuccess(res?.response?.data?.attributes?.full_name);
    },
    onError: (res) => {
      // ToastError(res?.response?.message);
      if (typeof onError === "function") onError(res?.response?.message);
    },
  });
};

export default {};
