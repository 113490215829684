import { useEffect } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq";
import * as actions from "./actions";
import * as c from "./constants";

export const useGenerateReports = () => {
  const isLoading = useApiLoading(c.GENERATE_REPORTS, "post");
  return [isLoading, actions.generateReports];
};

export const useDownloadReports = () => {
  const isLoading = useApiLoading(c.SELECT_REPORTS, "get");
  return [isLoading, actions.downloadSelectedReports];
};

export const useListReports = (filter) => {
  const isLoading = useApiLoading(c.LIST_REPORTS, "get");
  const data = useApiGet(c.LIST_REPORTS, []);
  const pager = useApiGet(c.PAGER_REPORTS, {});
  useEffect(() => {
    actions.listReports(filter);
    return () => {
      req.set(c.LIST_REPORTS, []);
      req.set(c.PAGER_REPORTS, {});
    };
  }, [filter]);
  return [isLoading, data, pager];
};
