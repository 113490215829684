import React from "react";
// import PropTypes from 'prop-types';
import {
  useRouteMatch,
  NavLink,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { HeaderTitle } from "modules/common/components/Header";
import PageNotFound from "modules/common/containers/PageNotFound";
import UserTripContainer from "modules/access-control/containers/UserTripContainer";
// import ContactGroupContainer from "modules/sms-blast/containers/ContactGroupContainer";
import AssessmentContainer from "modules/assessment/containers/AssessmentContainer";
import SurveyAssessmentContainer from "modules/assessment/containers/SurveyAssessmentContainer";
import DatasetManagement from "travel-marianas-modules/dataset/DatasetManagement";
import NestedDatasetManagement from "travel-marianas-modules/dataset/NestedDatasetManagement";
import StatusManagement from "./StatusManagement";
import SymptomsManagement from "./SymptomsManagement";
// import CompanyManagement from "./CompanyManagement";
// import CaseStatusManagement from "./CaseStatusManagement";
// import MessengerBotResponseManagement from "./MessengerBotResponseManagement";
// import LocationTypeManagement from "./LocationTypeManagement";
// import HealthChecklist from "./HealthChecklist";
// import FacilityManagement from "./FacilityManagement";
// import PurposeManagement from "./PurposeManagement";
import VaccineManagement from "./VaccineManagement";

function ContentManagementContainer() {
  const match = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Content Management - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Content Management</div>
      </HeaderTitle>
      <div className="d-flex card bg-white mx-0 border-2 mb-2 py-1">
        <ul
          className="nav nav-tabs pl-1"
          style={{
            overflowX: "auto",
            overflowY: "hidden",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
          }}
        >
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/status`}>
              <i className="fa fa-user-check mr-1" />
              Conditions
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/assessment`}>
              <i className="fa fa-mobile mr-1" />
              Forms Management
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/survey-assessment`}>
              <i className="fa fa-list mr-1" />
              Special Survey Form
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/symptoms`}>
              <i className="fas fa-user-injured mr-1" />
              Symptoms
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/trips`}>
              <i className="fa fa-list mr-1" />
              Trips
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/vaccine`}>
              <i className="fa fa-list mr-1" />
              Vaccines
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/country`}>
              <i className="fa fa-flag mr-1" />
              Country
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/ethnicity`}>
              <i className="fa fa-flag mr-1" />
              Ethnicity
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/occupation`}>
              <i className="fa fa-flag mr-1" />
              Occupation
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/island`}>
              <i className="fa fa-flag mr-1" />
              Island/Villages
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/airlines`}>
              <i className="fa fa-flag mr-1" />
              Airlines/Seat No.
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/vessel`}>
              <i className="fa fa-flag mr-1" />
              Vessel/Seat No.
            </NavLink>
          </li>
          {/* <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/company`}>
            <i className="fa fa-building mr-1" />
            Company
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/case-status`}>
            <i className="fa fa-building mr-1" />
            Case Status
          </NavLink>
        </li> */}
          {/* <li className="nav-item">
          <NavLink
            className="nav-link"
            to={`${match.url}/messenger-bot-response`}
          >
            <i className="fa fa-robot mr-1" />
            Msgr Bot Response
          </NavLink>
        </li> */}
          {/* <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/location-type`}>
            <i className="fa fa-map-marker mr-1" />
            Location Type
          </NavLink>
        </li> */}
          {/* <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/contact-group`}>
            <i className="fa fa-mobile-alt mr-1" />
            Contact Group
          </NavLink>
        </li> */}
          {/* <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/health-checklist`}>
            <i className="fa fa-clipboard-check mr-1" />
            Health Checklist
          </NavLink>
        </li> */}
          {/* <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/facility`}>
            <i className="fa fa-clipboard-check mr-1" />
            Facilities
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`${match.url}/purpose`}>
            <i className="fa fa-list mr-1" />
            Purpose
          </NavLink>
        </li> */}
        </ul>
      </div>
      <div className="bg-white mx-0 border-top-0 flex-grow-1 d-flex flex-column">
        <Switch>
          {/* <Route path={`${match.path}/company`} component={CompanyManagement} />
          <Route
            path={`${match.path}/case-status`}
            component={CaseStatusManagement}
          />
          <Route
            path={`${match.path}/messenger-bot-response`}
            component={MessengerBotResponseManagement}
          />
          <Route
            path={`${match.path}/location-type`}
            component={LocationTypeManagement}
          />
          <Route
            path={`${match.path}/contact-group`}
            component={ContactGroupContainer}
          />
          <Route
            path={`${match.path}/health-checklist`}
            component={HealthChecklist}
          />
          <Route
            path={`${match.path}/facility`}
            component={FacilityManagement}
          />
          <Route path={`${match.path}/purpose`} component={PurposeManagement} /> */}
          <Route path={`${match.path}/status`} component={StatusManagement} />
          <Route
            path={`${match.path}/symptoms`}
            component={SymptomsManagement}
          />
          <Route path={`${match.path}/vaccine`} component={VaccineManagement} />
          <Route path={`${match.path}/trips`} component={UserTripContainer} />
          <Route
            path={`${match.path}/assessment`}
            component={AssessmentContainer}
          />
          <Route
            path={`${match.path}/survey-assessment`}
            component={SurveyAssessmentContainer}
          />

          <Route path={`${match.path}/country`}>
            <DatasetManagement key="COUNTRY" code="COUNTRY" label="Country" />
          </Route>
          <Route path={`${match.path}/ethnicity`}>
            <DatasetManagement
              key="ETHNICITY"
              code="ETHNICITY"
              label="Ethnicity"
            />
          </Route>
          <Route path={`${match.path}/occupation`}>
            <DatasetManagement
              key="OCCUPATION"
              code="OCCUPATION"
              label="Occupation"
            />
          </Route>
          <Route path={`${match.path}/island`}>
            <NestedDatasetManagement
              key="ISLAND"
              code="ISLAND"
              label="Island"
              subLabel="Village"
              subLabelPrefix="Villages of "
            />
          </Route>
          <Route path={`${match.path}/airlines`}>
            <NestedDatasetManagement
              key="AIRLINES"
              code="AIRLINES"
              label="Airlines"
              subLabel="Airline Flight Number"
              subChildLabel="Flight Seat Number"
              subLabelPrefix="Flight Numbers of "
              subChildLabelPrefix="Seat Numbers of "
              hasSubChild
            />
          </Route>
          <Route path={`${match.path}/vessel`}>
            <NestedDatasetManagement
              key="VESSEL"
              code="VESSEL"
              label="Vessel"
              subLabel="Vessel Seat Number of"
              subLabelPrefix="Seats of "
            />
          </Route>

          <Route exact path={match.path}>
            <Redirect to={`${match.path}/status`} />
          </Route>
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </>
  );
}

ContentManagementContainer.propTypes = {};

export default ContentManagementContainer;
