import React from "react";
import PropTypes from "prop-types";

const KEY =
  process.env.REACT_APP_RECAPTCHA_KEY ||
  "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

const loadAPI = (id, src) =>
  new Promise((cb) => {
    const fjs = document.getElementsByTagName("script")[0];
    const js = document.createElement("script");

    if (document.getElementById(id)) {
      cb();
      return;
    }

    js.id = id;
    js.src = src;
    js.onload = cb;
    fjs.parentNode.insertBefore(js, fjs);
  });

const wait = (t) => new Promise((r) => setTimeout(r, t));

const styles = {
  wrapper: {
    position: "relative",
    width: "304px",
    height: "78px",
  },
  loader: {
    position: "absolute",
    width: "302px",
    height: "76px",
    background: "#f9f9f9",
    border: "1px solid #d3d3d3",
    borderRadius: "3px",
    boxShadow: "0px 0px 1px 0px #d3d3d3",
  },
};

const getWrapperStyle = (align) => {
  let margin = "0";
  if (align === "left") {
    margin = "0px 0px 0px 0px";
  }
  if (align === "center") {
    margin = "0px auto";
  }
  if (align === "right") {
    margin = "0px 0px 0px auto";
  }
  return {
    margin,
  };
};

let retries = 10;

const ReCaptcha = ({ name, align, onVerify }) => {
  const [isReady, setIsReady] = React.useState();
  const [captchaTimeout, setCaptchaTimeout] = React.useState(false);
  const verify = (x) => {
    onVerify((state) => ({ ...state, [name]: x }));
  };
  const init = async () => {
    setTimeout(() => {
      setCaptchaTimeout(true);
    }, 15000);
    try {
      await loadAPI(
        "g-captcha",
        "https://www.google.com/recaptcha/api.js?render=explicit"
      );
      const g = grecaptcha; // eslint-disable-line
      await wait(100);
      g.render("g-recaptcha", {
        sitekey: KEY,
        callback: verify,
      });
      await wait(280);
      setIsReady(true);
    } catch (err) {
      if (retries > 0) {
        retries -= 1;
        init();
      }
    }
  };
  React.useEffect(() => {
    init();
  }, []);
  return (
    <div style={{ ...styles.wrapper, ...getWrapperStyle(align) }}>
      {!isReady && (
        <div
          className="d-flex justify-content-center align-items-center text-muted"
          style={styles.loader}
        >
          {!captchaTimeout ? (
            <small className="text-center">
              <div>Loading captcha...</div>
            </small>
          ) : (
            <small className="text-center">
              <div>Unable to load captcha...</div>
              <div>Are you connected to the internet?</div>
            </small>
          )}
        </div>
      )}
      <div id="g-recaptcha" />
    </div>
  );
};

ReCaptcha.propTypes = {
  name: PropTypes.string.isRequired,
  onVerify: PropTypes.func.isRequired,
  align: PropTypes.oneOf(["left", "center", "right"]),
};

ReCaptcha.defaultProps = {
  align: "left",
};

export default React.memo(ReCaptcha);
