import React from 'react';
import PropTypes from 'prop-types';
import { each, isEmpty } from 'lodash';
import { useApiGet } from 'react-reqq';
import { FORM_ANSWERS, NONE_LIST } from 'modules/assessment/constants';
import QuestionShortAns from './QuestionShortAns';
import QuestionParagraph from './QuestionParagraph';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionCheckbox from './QuestionCheckbox';
import QuestionDropdown from './QuestionDropdown';

const removeNoneAndFalse = (ans) => {
  const newAns = {};
  each(ans, (v, k) => {
    if (NONE_LIST.indexOf((k || '').toLowerCase()) < 0 && v !== false)
      newAns[k] = v;
  });
  return newAns;
};

function AnswerQuestions({ questions, readOnly }) {
  const answers = useApiGet(FORM_ANSWERS, {});
  return (
    <div className="space-y-2">
      {questions.map((question) => {
        if (
          !isEmpty(question?.meta?.parent_id) &&
          !isEmpty(question?.meta?.parent_answer)
        ) {
          const parentType = (
            questions.find((x) => x.id === question?.meta?.parent_id) || {}
          ).type;
          const cleanAnswers = removeNoneAndFalse(
            answers[question?.meta?.parent_id]
          );
          const checkboxShowAdv = !(
            question?.meta?.parent_answer === '_ALL_BUT_NOT_NONE' &&
            Object.keys(cleanAnswers).length < 1
          );
          const multipleChoiceShowAdv = !!(answers[question?.meta?.parent_id] ||
            {})[question?.meta?.parent_answer];
          if (!checkboxShowAdv && parentType === 'CHECKBOX') return null;
          if (!multipleChoiceShowAdv && parentType === 'MULTIPLE_CHOICE')
            return null;
        }
        if (question.type === 'SHORT_ANS')
          return (
            <QuestionShortAns
              key={question.id}
              data={question}
              readOnly={readOnly}
            />
          );
        if (question.type === 'PARAGRAPH')
          return (
            <QuestionParagraph
              key={question.id}
              data={question}
              readOnly={readOnly}
            />
          );
        if (question.type === 'MULTIPLE_CHOICE')
          return (
            <QuestionMultipleChoice
              key={question.id}
              data={question}
              readOnly={readOnly}
            />
          );
        if (question.type === 'CHECKBOX')
          return (
            <QuestionCheckbox
              key={question.id}
              data={question}
              readOnly={readOnly}
            />
          );
        if (question.type === 'DROPDOWN')
          return (
            <QuestionDropdown
              key={question.id}
              data={question}
              readOnly={readOnly}
            />
          );
        return <div key={question.id} />;
      })}
    </div>
  );
}

AnswerQuestions.defaultProps = {
  readOnly: false,
};

AnswerQuestions.propTypes = {
  questions: PropTypes.instanceOf(Array).isRequired,
  readOnly: PropTypes.bool,
};

export default AnswerQuestions;
