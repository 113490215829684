import React from 'react';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import TraceExportData from '../components/TraceExportData';
import TraceExportItems from '../components/TraceExportItems';

function TraceExportForm() {
  const { id } = useParams();
  React.useEffect(() => {
    document.body.className = "print";
  }, []);
  return (
    <div style={{
      margin: '0 auto',
      width: '21.0cm',
      // height: '29.7cm',
    }}>
      <div className="text-center mb-3">
        <h4>Contact Trace Export</h4>
      </div>
      <TraceExportData id={id} print />
      <TraceExportItems id={id} print />
    </div>
  );
}

TraceExportForm.propTypes = {}

export default TraceExportForm
