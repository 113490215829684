import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { useApiGet } from "react-reqq";
import { Link, NavLink, Redirect, Route, Switch } from "react-router-dom";
import logo_horizontal from "assets/img/logo_horizontal.svg";
import logo_vertical from "assets/img/logo_vertical.svg";
import { AUTHENTICATION, SUPER_ADMIN_ACCESS_KEY } from "modules/auth/constants";
import { useAclKeys } from "modules/access-control/acl";
import { findModule } from "../../access-control/acl";
import * as actions from "../actions";
import * as c from "../constants";

const SubMenuCaret = ({ path }) => (
  <Switch>
    <Route path={path}>
      <i className="fa fa-caret-down ml-auto mr-0" />
    </Route>
    <Route>
      <i className="fa fa-caret-left ml-auto mr-0" />
    </Route>
  </Switch>
);

SubMenuCaret.propTypes = {
  path: PropTypes.string.isRequired,
};

function Sidebar() {
  // const history = useHistory();
  // const { acl } = React.useContext(AclContext);
  const modules = useAclKeys();
  const { profile } = useApiGet(AUTHENTICATION, {});
  const isSuperAdmin = profile.access_control === SUPER_ADMIN_ACCESS_KEY;
  const sidebarToggled = useApiGet(`${c.SIDEBAR}.toggled`, false);
  const handleToggleSidebar = () => {
    actions.toggleSidebar(!sidebarToggled);
  };

  const hasAccess = (key) => modules.indexOf(key) > -1;

  const homeRoute = React.useMemo(() => {
    const path = _.get(findModule(modules[0]), "path");
    if (path) return <Redirect to={path} />;
    return null;
  }, [modules]);

  return (
    <ul
      className={`navbar-nav bg-white sidebar accordion ml-2${
        !sidebarToggled ? " toggled" : ""
      }`}
    >
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div className="sidebar-brand-icon">
          <img
            src={!sidebarToggled ? logo_vertical : logo_horizontal}
            alt={process.env.REACT_APP_TITLE}
          />
        </div>
      </Link>

      {hasAccess("dashboard") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/dashboard"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-tachometer-alt" />
              <span>Dashboard</span>
            </div>
          </NavLink>
        </li>
      ) : null}
      {/* {hasAccess("logbook") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/logbook"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-clipboard-list" />
              <span>Logbook</span>
            </div>
          </NavLink>
        </li>
      ) : null}
      {hasAccess("contact-tracing") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/contact-tracing"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-project-diagram" />
              <span>Contact Tracing</span>
            </div>
          </NavLink>
        </li>
      ) : null} */}

      {hasAccess("reports") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/reports"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-cloud-download-alt" />
              <span>Reports</span>
            </div>
          </NavLink>
        </li>
      ) : null}

      {hasAccess("registrants") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/registrants"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-users" />
              <span>Travelers</span>
            </div>
          </NavLink>
        </li>
      ) : null}

      {hasAccess("trips") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/trips"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-plane-arrival" />
              <span>Trips</span>
            </div>
          </NavLink>
        </li>
      ) : null}

      {hasAccess("scanner") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/scanner"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fa fa-qrcode" />
              <span>Scanner</span>
            </div>
          </NavLink>
        </li>
      ) : null}
      {hasAccess("acl-sub-accounts") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/sub-accounts"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-users-cog" />
              <span>Sub Accounts</span>
            </div>
          </NavLink>
        </li>
      ) : null}

      {hasAccess("special-survey") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/special-survey"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-clipboard" />
              <span>Special Survey</span>
            </div>
          </NavLink>
        </li>
      ) : null}

      {hasAccess("content-management") ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/content-management"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-list" />
              <span>Content Management</span>
            </div>
          </NavLink>
        </li>
      ) : null}

      {isSuperAdmin ? (
        <li className="nav-item">
          <NavLink
            className="nav-link text-gray-500"
            to="/access-control"
            activeClassName="nav-active font-weight-bold"
          >
            <div className="nav-item-inside d-flex align-items-center">
              <i className="fas fa-fw fa-users-cog" />
              <span>Access Control</span>
            </div>
          </NavLink>
        </li>
      ) : null}

      <Route exact path="/">
        {homeRoute}
      </Route>

      {/* <hr className="sidebar-divider d-none d-md-block" /> */}
      <div className="text-center d-none d-md-inline mt-2">
        <button
          className="rounded-circle border-0"
          id="sidebarToggle"
          type="button"
          onClick={handleToggleSidebar}
          label="toggle"
        />
      </div>
    </ul>
  );
}

export default Sidebar;
