import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import FormSelect from "modules/common/form/FormSelect";
import { ADMIN_ACCESS_KEY } from "modules/auth/constants";
import { useAclModules } from "../acl";
import * as c from "../constants";

function AclTemplateOptions({ onChange, value }) {
  const modules = useAclModules(ADMIN_ACCESS_KEY);
  const [allKeys, setAllKeys] = React.useState([]);
  const handleChange = (getValue) => {
    const { valueKey } = getValue();
    if (valueKey === "all") {
      onChange(allKeys);
      return;
    }
    const selected =
      c.ACL_OPTIONS.find((item) => item.value === valueKey) || {};
    onChange(selected.acl || []);
  };
  const findValue = React.useCallback(() => {
    if (value.length >= allKeys.length) return "all";
    const selected = c.ACL_OPTIONS.find((item) => item.acl === value) || {};
    return selected.value || "";
  }, [value, allKeys]);
  React.useEffect(() => {
    const newAllKeys = [];
    _.each(modules, (row) => {
      newAllKeys.push(row.key);
      if (!_.isEmpty(row.children)) {
        _.each(row.children, (sub) => {
          newAllKeys.push(sub.key);
        });
      }
    });
    setAllKeys(newAllKeys);
  }, [modules]);
  return (
    <FormSelect
      name="valueKey"
      onChange={handleChange}
      placeholder="- Custom Template -"
      value={findValue()}
      options={c.ACL_OPTIONS}
    />
  );
}

AclTemplateOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Array),
};

AclTemplateOptions.defaultProps = {
  value: [],
};

export default AclTemplateOptions;
