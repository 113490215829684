import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import FormInput from "modules/common/form/FormInput";
import FormSelect from 'modules/common/form/FormSelect';
import { STATUS_OPTIONS } from '../constants';

export const INIT_FILTER = {
  keyword: '',
  status: '',
};

function AssessmentFilter({ onChange }) {
  const [form, setForm] = React.useState(INIT_FILTER);
  const handleSubmit = (e) => {
    e.preventDefault();
    onChange((state) => ({
      ...state,
      ...form,
    }));
  };
  React.useEffect(() => {
    onChange((state) => {
      return isEqual(state, form)
        ? state
        : {
            ...state,
            ...form,
          };
    });
    // eslint-disable-next-line
  }, [form.status, onChange]);
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-2">
        <FormInput
          name="keyword"
          onChange={setForm}
          value={form.keyword}
          label="Search"
          icon={<i className="fa fa-search" />}
        />
      </div>
      <div className="mb-2">
        <FormSelect
          name="status"
          onChange={setForm}
          value={form.status}
          label="Filter By"
          icon={<i className="fa fa-filter" />}
          options={STATUS_OPTIONS}
        />
      </div>
    </form>
  );
}

AssessmentFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default AssessmentFilter;
