/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import shortid from "shortid";
import { each } from "lodash";

function UploadCSV({ isLoading, setForm }) {
  const [parsedCsvData, setParsedCsvData] = React.useState([]);
  const parseFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setParsedCsvData(results.data);
      },
    });
  };

  React.useEffect(() => {
    each(parsedCsvData, (parsed) => {
      setForm((state) => ({
        ...state,
        options: (state.options || []).concat([
          {
            label: parsed?.data,
            description: "",
            value: shortid.generate(),
          },
        ]),
      }));
    });
  }, [parsedCsvData]);

  const onDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      parseFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: isLoading,
    accept: { "text/csv": [".csv"] },
    onDrop,
  });

  return (
    <section>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p className="mb-0">
          {/* Drag &apos;n&apos; drop some files here, or click to select files */}
          Drag and drop some files here
        </p>
      </div>
      <div
        className="mt-2 py-3 px-1"
        style={{ background: "#fefce8", borderRadius: 5 }}
      >
        <div className="col">
          <span className="d-block">
            <b>Note: </b> Only .CSV file is accepted.
          </span>
          <span>Format must be 1st row/column HEADER value of "data"</span>
        </div>
      </div>
    </section>
  );
}

UploadCSV.defaultProps = {
  isLoading: false,
  setForm: null,
};

UploadCSV.propTypes = {
  setForm: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default UploadCSV;
