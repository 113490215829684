import React from "react";
import { motion } from "framer-motion";

import "../../styles/main.scss";

const ios = [
  "Step 1: On your iOS device, open Safari",
  "Step 2: Go to a website with a PWA that you want to install.",
  `Step 3: Then tap the 'Share' button, scroll down and tap 'Add to Home Screen'`,
  "Step 4: Enter the name for the app then tap add.",
  "Step 5: The PWA will show up on your home screen like a native iOS app",
];

const chrome = [
  "Step 1: On your Android device, open Chrome.",
  "Step 2: Go to a website with a PWA that you want to install.",
  "Step 3: Tap Install.",
  "Step 4: Follow the on-screen instructions.",
];

const windows = [
  "Step 1: On your computer, open Chrome.",
  "Step 2: Go to a website you want to install.",
  "Step 3: At the top right of the address bar, click Install.",
  "Step 4: Follow the on-screen instructions to install the PWA.",
];

export const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export default () => (
  <motion.div
    className="landing__how-faq-content-container landing__how-faq-installation-container"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    transition={{ duration: 1 }}
  >
    <div className="landing__how-faq-content-column-container landing__how-faq-content-installation-column-container">
      <div className="landing__how-faq-ios-container">
        <h3 className="landing__how-faq-title">
          How to install www.SafeTravelMarianas.com’s Progressive Web App (PWA)
          on a smartphone?
        </h3>
        <h4 className="landing__how-faq-subtitle">Safari (iOS)</h4>
        {ios.map((label) => (
          <p key={label} className="landing__how-faq-paragraph">
            {label}
          </p>
        ))}
      </div>

      <div className="landing__how-faq-chrome-container">
        <h4 className="landing__how-faq-subtitle">Chrome or other (Android)</h4>
        {chrome.map((label) => (
          <p key={label} className="landing__how-faq-paragraph">
            {label}
          </p>
        ))}
      </div>
    </div>

    <div className="landing__how-faq-content-column-container landing__how-faq-content-installation-column-container">
      <div className="landing__how-faq-windows-container">
        <h3 className="landing__how-faq-title">
          How to install www.SafeTravelMarianas.com’s Progressive Web App
          (PWA)App on Computer?
        </h3>
        <h4 className="landing__how-faq-subtitle">Windows/Mac</h4>
        {windows.map((label) => (
          <p key={label} className="landing__how-faq-paragraph">
            {label}
          </p>
        ))}
      </div>
    </div>
  </motion.div>
);
