import { useEffect } from 'react';
import { req } from 'react-reqq';
import { ASSESSMENT_SAVING } from './constants';

export const useIsSaving = (id, isSaving) => {
  useEffect(() => {
    return () => {
      req.set(ASSESSMENT_SAVING, (state) => {
        const newState = { ...(state[ASSESSMENT_SAVING] || {}) };
        delete newState[id];
        return newState;
      });
    };
  }, [id]);
  useEffect(() => {
    req.set(ASSESSMENT_SAVING, (state) => {
      const newState = { ...(state[ASSESSMENT_SAVING] || {}) };
      if (isSaving) newState[id] = true;
      if (!isSaving) delete newState[id];
      return newState;
    });
  }, [id, isSaving]);
};

export const foo = () => {};
