import React from "react";
import PropTypes from "prop-types";

function DeferLoader({ delay, children }) {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  if (loading) return <div />;
  return <>{children}</>;
}

DeferLoader.propTypes = {
  delay: PropTypes.number,
  children: PropTypes.instanceOf(Object),
};

DeferLoader.defaultProps = {
  delay: 1000,
  children: null,
};

export default DeferLoader;
