import React from "react";
import { motion } from "framer-motion";

import { containerVariants } from "./Installation";

import "../../styles/main.scss";

export default () => (
  <motion.div
    className="landing__how-faq-content-container"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    transition={{ duration: 1 }}
  >
    <div className="landing__how-faq-content-column-container">
      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          How do I register as a Foreign Traveler/ Resident / Non-Resident ?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          Go to www.safetravelsmarianas.com and click on the proceed link. Refer
          to the simple steps provided on the www.staysafemarianas.com
          establishment page.
        </p>
      </div>

      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">Do QR Codes expire?</h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          We recommend only using the QR codes on user accounts within the web
          progressive application. Do not give your QR code to any user other
          than yourself or approved family members added to your user accounts.
          Your backup printed or saved QR codes must be kept safe. The
          system-generated QR Codes are self-generating and self-maintaining
          anonymizes the user id that identifies users in the Software.
        </p>
      </div>
    </div>

    <div className="landing__how-faq-content-column-container">
      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          My QR code is stolen, How can I do it?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          A user is responsible for their own security codes. Please contact
          info@safetravelsmarianas.com in reporting an incident of a stolen/lost
          physical QR code.
        </p>
      </div>
    </div>
  </motion.div>
);
