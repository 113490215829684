import React from "react";
import Helmet from "react-helmet";
import { HeaderTitle } from "modules/common/components/Header";
// import UserIntegration from "modules/integration/containers/UserIntegration";
import SecuritySettings from "../components/SecuritySettings";
import Profile from "../components/Profile";
// import PropTypes from 'prop-types';

function ProfileContainer() {
  return (
    <>
      <Helmet>
        <title>Profile - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Profile</div>
      </HeaderTitle>
      <div className="mx-0 d-flex flex-column align-items-center">
        <div className="bg-white rounded border shadow m-2 col-md-6 py-3">
          <Profile />
        </div>
        <div className="bg-white rounded border shadow m-2 col-md-6 py-3">
          <SecuritySettings />
        </div>
        {/* <div className="bg-white rounded border shadow m-2 col-md-6 py-3">
          <UserIntegration />
        </div> */}
      </div>
    </>
  );
}

ProfileContainer.propTypes = {};

export default ProfileContainer;
