import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { render } from "react-dom";
import { store, history } from "index";
import _ from "lodash";
import { req } from "react-reqq";
import GoogleMaps from "modules/dashboard/maps/GoogleMaps";

const modalUniqueID = "address-map-marker";

const DEFAULT_VIEW = {
  zoom: +10,
  center: {
    lat: 15.202316,
    lng: 145.7089154,
  },
};

export const formatAddress = (data) => {
  const arr = [
    _.get(data, "region.ADM1_EN"),
    _.get(data, "province.ADM2_EN"),
    _.get(data, "municipality.ADM3_EN"),
    _.get(data, "barangay.ADM4_EN"),
  ].filter((n) => !_.isEmpty(n || ""));
  return arr.length < 1 ? "n/a" : arr.join(", ");
};

const Picker = ({ onSubmit, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const getCodes = React.useCallback(
    _.debounce((lat, lng) => {
      req.get({
        key: "GET_ADDRESS_CODES",
        url: () => "https://geojson.multidemos.com/get-address-codes",
        params: {
          geoloc: `${lat},${lng}`,
        },
        onSuccess: (res) => {
          const newCodes = _.get(res, "response.codes");
          setIsLoading(false);
          setData({
            geoloc: `${lat},${lng}`,
            ...newCodes,
          });
        },
        onError: () => {
          setIsLoading(true);
        },
      });
    }, 2000),
    []
  );
  const handleMapChange = React.useCallback((map) => {
    try {
      setIsLoading(true);
      const { lat, lng } = map.getCenter().toJSON();
      getCodes(lat, lng);
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  }, []);
  const handleSubmit = () => {
    onSubmit(data);
  };
  return (
    <>
      <div className="modal-body">
        <div className="embed-responsive embed-responsive-4by3 position-relative">
          <div className="embed-responsive-item">
            <div className="map-center-pin" />
            <GoogleMaps
              view={DEFAULT_VIEW}
              withSearch
              onDragEnd={handleMapChange}
            />
          </div>
        </div>
        <div>
          <small>You have selected:</small>
          <p className="form-control-plaintext">
            {isLoading ? "Identifying location..." : formatAddress(data)}
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Submit
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

Picker.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const showAddressMapPicker = (options = {}) => {
  const opt = {
    title: "Select Location",
    className: "",
    backdrop: false,
    onSubmit: (data, close) => close(),
    ...options,
  };
  const rootElement = document.getElementById(modalUniqueID);
  if (!rootElement) {
    alert("Alert Marker not found!"); // eslint-disable-line
    return;
  }
  const prevClassName = document.body.className;
  document.body.className = "modal-open";
  const prevElem = document.activeElement;
  const close = () => {
    document.body.className = prevClassName;
    render(<div id={modalUniqueID} />, rootElement);
    setTimeout(() => {
      try {
        prevElem.focus();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };
  const submit = (data) => {
    opt.onSubmit(data, close);
  };

  render(
    <Provider store={store}>
      <Router history={history}>
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", paddingRight: 15 }}
        >
          <div className={`modal-dialog ${opt.className}`} role="document">
            <div className="modal-content">
              {!!opt.title && typeof opt.title === "string" && (
                <div className={`modal-header ${opt.headerClassName}`}>
                  <h5 className="modal-title">{opt.title}</h5>
                </div>
              )}
              {typeof opt.title === "function" && (
                <div className={`modal-header ${opt.headerClassName}`}>
                  {opt.title()}
                </div>
              )}
              <Picker onSubmit={submit} onClose={close} />
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade show${
            !opt.backdrop ? " bg-hidden" : ""
          }`}
        />
      </Router>
    </Provider>,
    rootElement
  );
};

export const AddressMapMarker = () => <div id={modalUniqueID} />;
