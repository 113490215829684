/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { Helmet } from "react-helmet";
import { HeaderTitle } from "modules/common/components/Header";
import Pagination from "modules/common/components/Pagination";
import { confirmAlert } from "modules/common/components/Alert";
import { usePersistState } from "modules/common/helper";
import { showModal } from "modules/common/components/AlertModal";
import GenericTable from "modules/common/components/GenericTable";
import GenerateSurveyReports from "../modals/GenerateSurveyReports";
import { useSurveyReports } from "../hooks";
import * as actions from "../actions";
import SurveyKeywordFilter from "../components/SurveyKeywordFilter";

function ReportContainer() {
  const [filter, setFilter] = usePersistState(
    "special-survey-filter",
    { page: 1 },
    false
  );
  const [isLoading, data, pager] = useSurveyReports(filter);

  const handleReload = () => {
    actions.surveyReports();
  };

  const handleDelete = React.useCallback((uuid) => {
    confirmAlert({
      content: "Are you sure you want to delete?",
      onYes: (onClose) => actions.removeSelectedSurveyReport(uuid, onClose),
    });
  }, []);

  const getTableFormat = React.useMemo(() => {
    const handleDownload = (row) => () => {
      actions.downloadReport(row?.id, row?.file_name);
    };

    return [
      { key: "created_at", label: "Created At" },
      { key: "file_name", label: "File Name" },
      { key: "status", label: "Status", width: 80 },
      {
        key: "download_count",
        label: "# Download",
        className: "text-center",
      },
      {
        label: "Action",
        key: (row) => {
          return (
            <div>
              <button
                className="btn btn-sm btn-outline-primary mx-1"
                type="button"
                disabled={row?.status !== "PROCESSED"}
                style={{
                  cursor:
                    row?.status !== "PROCESSED" ? "not-allowed" : "pointer",
                }}
                onClick={handleDownload(row)}
              >
                <i className="fa fa-download mr-1" />
                Export
              </button>
              <button
                className="btn btn-sm btn-outline-danger mx-1"
                type="button"
                onClick={() => handleDelete(row?.uuid)}
              >
                <i className="fa fa-trash mr-1" />
              </button>
            </div>
          );
        },
        width: 130,
      },
    ];
  }, []);

  const fetchList = (newFilter) => {
    setFilter({ ...newFilter, page: 1 });
    actions.surveyReports({ ...newFilter, page: 1 });
  };

  const modalGenerateSurveyReports = () => {
    showModal({
      title: "Generate Special Survey Reports",
      className: "modal-lg",
      content: (onClose) => (
        <GenerateSurveyReports defaultForm={filter} onClose={onClose} />
      ),
    });
  };

  return (
    <>
      <Helmet>
        <title>Special Survey - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Reports</div>
      </HeaderTitle>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column">
        <div className="form-inline p-2 d-flex justify-content-between">
          <SurveyKeywordFilter isLoading={isLoading} onSubmit={fetchList} />
          <div>
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={handleReload}
              disabled={isLoading}
            >
              <i className="fa fa-sync mr-1" />
              Reload
            </button>
            <button
              className="btn btn-primary "
              type="button"
              onClick={modalGenerateSurveyReports}
              disabled={isLoading}
            >
              Generate Reports
            </button>
          </div>
        </div>
        <div className="box-fit-wrapper w-100 flex-grow-1">
          <div className="box-fit-overflow">
            <GenericTable
              format={getTableFormat}
              data={data}
              isLoading={isLoading}
              rowHeight={42}
            />
          </div>
        </div>
        <Pagination
          data={pager}
          isLoading={isLoading}
          onChange={(newPage) => setFilter({ ...filter, page: newPage })}
        />
      </div>
    </>
  );
}

export default ReportContainer;
