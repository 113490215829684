import React from "react";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { HeaderTitle } from "modules/common/components/Header";
// import { useTripById } from "travel-marianas-modules/trips/hooks";
import { useRegistrantById } from "./hooks";
import RegistrantCurrentTrip from "./RegistrantCurrentTrip";
import RegistrantProfile from "./RegistrantProfile";
import RegistrantTripHistory from "./RegistrantTripHistory";

function RegistrantPage() {
  const location = useLocation();
  const match = useRouteMatch();
  // const [, trip] = useTripById(match.params.id);
  const [, registrant] = useRegistrantById(match.params.id);
  // const combineTripInfo = React.useMemo(() => ({ ...registrant, ...trip }), [
  //   registrant,
  //   trip,
  // ]);

  return (
    <>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Traveler Details</div>
      </HeaderTitle>
      <div className="mb-3 d-flex align-items-center">
        <Link
          className="btn btn-outline-primary"
          to={location?.state?.from || "/registrants"}
        >
          Go Back
        </Link>

        <div className="ml-2">
          {registrant ? (
            <>
              <div className="font-weight-bold">
                {registrant.first_name}&apos;s Profile
              </div>
              <div className="font-weight-normal text-xs underline">
                {registrant.id}
              </div>
            </>
          ) : (
            <div className="font-weight-bold">Loading...</div>
          )}
        </div>
      </div>
      <div className="card p-3 mb-3">
        <RegistrantProfile data={registrant} />
      </div>
      <div className="d-flex card bg-white mx-0 border-2 mb-2 py-1">
        <ul
          className="nav nav-tabs pl-1"
          style={{
            overflowX: "auto",
            overflowY: "hidden",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
            alignItems: "center",
            borderBottom: 0,
          }}
        >
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/active-trip`}>
              Latest Trip
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/trips`}>
              Trip History
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="tab-content">
        <div className="tab-pane show active bg-white border-left border-right border-bottom rounded-bottom">
          <Switch>
            <Route path={`${match.path}/active-trip`}>
              {registrant?.current_trip_id ? (
                <RegistrantCurrentTrip
                  tripId={registrant?.current_trip_id}
                  registrant={registrant}
                />
              ) : (
                <div className="p-3">
                  <div className="text-muted font-italic text-center">
                    There are no trips.
                  </div>
                </div>
              )}
            </Route>
            <Route path={`${match.path}/trips`}>
              <RegistrantTripHistory registrant={registrant} />
            </Route>
            <Route exact path={match.path}>
              <Redirect to={`${match.url}/active-trip`} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}

export default RegistrantPage;
