import React from "react";
// import PropTypes from 'prop-types';
import { useLocation, useHistory } from "react-router-dom";
import { req } from "react-reqq";
import qs from "query-string";

const wait = (t) => new Promise((r) => setTimeout(r, t));

function VerifyEmail() {
  const location = useLocation();
  const history = useHistory();
  const [isValidating, setIsValidating] = React.useState(true);
  const [message, setMessage] = React.useState("Activating account...");
  React.useEffect(() => {
    const init = async () => {
      try {
        const { token, email, success } = qs.parse(location.search);

        if (token && email) {
          sessionStorage.removeItem("profile");
          localStorage.clear();
          await req.clearCache();

          req.post({
            key: "VERIFY_EMAIL",
            url: "/api/v1/access-request/verify-email",
            payload: {
              token,
              email,
            },
            onSuccess: () => {
              window.location.href = "/verify-email?success=1";
            },
            onError: () => {
              setMessage("Email verification link is invalid!");
              history.push("/verify-email?success=0");
            },
          });
          return;
        }
        if (+success === 1) {
          setIsValidating(false);
          setMessage(
            "Account activated! You may now login. Redirecting to login page..."
          );
          await wait(4000);
          history.push("/");
          return;
        }
        setIsValidating(false);
        setMessage("Email verification link is invalid!");
      } catch (err) {
        setIsValidating(false);
        setMessage("Email verification link is invalid!");
        history.push("/verify-email?success=0");
      }
    };
    init();
  }, [location.search]);
  return (
    <div className="pt-5">
      <div className="text-center">
        <div>
          {isValidating ? (
            <i className="fa fa-spin fa-circle-notch fa-2x" />
          ) : (
            <i className="fa fa-check-circle text-success fa-2x" />
          )}
        </div>
        <div>{message}</div>
      </div>
    </div>
  );
}

VerifyEmail.propTypes = {};

export default VerifyEmail;
