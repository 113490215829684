import React from "react";
import { useApiGet, useApiLoading } from "react-reqq";
import { omit } from "lodash";
import PropTypes from "prop-types";
import FormSelect from "modules/common/form/FormSelect";
import { addAssessmentQuestion, appendAssessmentQuestion } from "../actions";
import { transformAssessmentQuestion } from "../transformers";

import {
  ASSESSMENT_QUESTION_ITEM,
  ASSESSMENT_QUESTION_PAGER,
} from "../constants";

function AddPanel({ assessmentId, onClose, index }) {
  const isLoading = useApiLoading(ASSESSMENT_QUESTION_ITEM, "post");
  const [form, setForm] = React.useState({ question: "" });
  const pager = useApiGet(ASSESSMENT_QUESTION_PAGER, {});

  const handleAdd = (type) => () => {
    let append = null;
    const payload = {
      ...omit(transformAssessmentQuestion(), ["id"]),
      type,
      question: `Question ${index ? index + 1 : pager.total + 1}`,
      sequence: index ? index + 1 : pager.total + 1,
    };

    if (index) {
      append = appendAssessmentQuestion(assessmentId, payload);
    } else {
      append = addAssessmentQuestion(assessmentId, payload);
    }
    if (index) onClose();
    return append;
  };

  const QUESTION_OPTIONS = [
    { label: "SHORT ANSWER", value: "SHORT_ANS" },
    { label: "DATE", value: "DATE" },
    { label: "PARAGRAPH", value: "PARAGRAPH" },
    { label: "MULTIPLE CHOICE", value: "MULTIPLE_CHOICE" },
    { label: "CHECKBOX", value: "CHECKBOX" },
    { label: "DROPDOWN", value: "DROPDOWN" },
    { label: "DATASET", value: "DROPDOWN_DATASET" },
    { label: "DATASET CHILD", value: "DROPDOWN_DATASET_CHILD" },
    // { label: "UPLOAD URL", value: "UPLOAD_URL" },
  ];
  return (
    <div
      className="card card-header mb-3 d-flex align-items-center justify-content-center"
      style={{ background: "#fff" }}
    >
      <div className="row">
        <div className="col d-flex align-items-center justify-content-center">
          <span
            className="mr-2 mb-0 text-primary font-weight-bold"
            style={{ width: "180px" }}
          >
            Add Question
          </span>
          <FormSelect
            name="question"
            onChange={setForm}
            value={form?.question || ""}
            options={QUESTION_OPTIONS}
            // label="Filter By"
          />
          <button
            className="btn btn-primary ml-2"
            type="button"
            disabled={isLoading || !form?.question}
            onClick={handleAdd(form?.question)}
          >
            Add
          </button>
          {index && (
            <button
              className="btn btn-outline-primary ml-1"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

AddPanel.defaultProps = {
  onClose: null,
  index: false,
};

AddPanel.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default AddPanel;
