/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import GenericTable from "modules/common/components/GenericTable";
import { HeaderTitle } from "modules/common/components/Header";
import { showModal } from "modules/common/components/AlertModal";
import Pagination from "modules/common/components/Pagination";
import { useApiLoading, useApiGet } from "react-reqq";
import _ from "lodash";
import { confirmAlert } from "modules/common/components/Alert";
import DatasetFilter from "./DatasetFilter";
import DatasetModal from "./DatasetModal";
import * as actions from "./actions";
import * as c from "./constants";

function DatasetManagement({
  code,
  label,
  noBackground,
  selected,
  onRowSelect,
  noOverflow,
}) {
  const isLoading = useApiLoading(`${c.DATASET}_${code}`, "get");
  const [filter, setFilter] = React.useState({ page: 1 });
  const data = useApiGet(`${c.DATASET}_${code}`, []);
  const pager = useApiGet(`${c.DATASET}_${code}/pager`, []);

  const handleOpenAdd = () => {
    showModal({
      title: `Add ${label}`,
      content: (onClose) => <DatasetModal code={code} onClose={onClose} />,
    });
  };

  const handleOpenEdit = (row) => () => {
    showModal({
      title: `Edit ${label}`,
      content: (onClose) => (
        <DatasetModal code={code} data={row} onClose={onClose} />
      ),
    });
  };

  const handleDelete = (row) => () => {
    confirmAlert({
      content: "Are you sure you want to remove?",
      onYes: (onClose) => {
        actions.deleteDataset(code, _.get(row, "id"), onClose);
      },
    });
  };

  const renderAction = React.useCallback(
    (row) => (
      <div>
        <button
          className="btn btn-outline-primary btn-sm mr-1"
          type="button"
          onClick={handleOpenEdit(row)}
        >
          <i className="fa fa-edit" />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          type="button"
          onClick={handleDelete(row)}
        >
          <i className="fa fa-trash" />
        </button>
      </div>
    ),
    []
  );

  const getTableFormat = React.useCallback(
    () => [
      { key: renderAction, label: "Action", width: 80 },
      // { key: "sort_priority", label: "Sort Priority" },
      { key: "title", label: "Title" },
      { key: "description", label: "Description" },
      { key: "status_label", label: "Status", width: 100 },
    ],
    []
  );

  const handleSearch = useCallback(
    (newFilter) => {
      setFilter(newFilter);
      actions.listDataset(code, newFilter);
    },
    [code]
  );

  const handleChangePage = (newPage) => {
    setFilter({ ...filter, page: newPage });
    actions.listDataset(code, { ...filter, page: newPage });
  };

  return (
    <>
      <Helmet>
        <title>
          {label} - {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">{label}</div>
      </HeaderTitle>
      <div
        className={
          noBackground
            ? "flex-grow-1 d-flex flex-column"
            : "bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column"
        }
      >
        <div className="d-flex justify-content-between p-1">
          <DatasetFilter
            isLoading={isLoading}
            onSubmit={handleSearch}
            code={code}
          />
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleOpenAdd}
            >
              Add
            </button>
          </div>
        </div>
        {noOverflow ? (
          <div className="w-100 flex-grow-1">
            <GenericTable
              isLoading={isLoading}
              format={getTableFormat()}
              data={data}
              selected={selected}
              onRowClick={
                typeof onRowSelect === "function"
                  ? ({ rowData }) => onRowSelect(rowData)
                  : null
              }
            />
          </div>
        ) : (
          <div className="box-fit-wrapper w-100 flex-grow-1">
            <div className="box-fit-overflow">
              <GenericTable
                isLoading={isLoading}
                format={getTableFormat()}
                data={data}
                selected={selected}
                onRowClick={
                  typeof onRowSelect === "function"
                    ? ({ rowData }) => onRowSelect(rowData)
                    : null
                }
              />
            </div>
          </div>
        )}

        <Pagination
          data={pager}
          isLoading={isLoading}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
}

DatasetManagement.defaultProps = {
  label: "Dataset",
  noBackground: false,
  selected: null,
  onRowSelect: null,
  noOverflow: false,
};

DatasetManagement.propTypes = {
  noBackground: PropTypes.bool,
  noOverflow: PropTypes.bool,
  code: PropTypes.string.isRequired,
  label: PropTypes.string,

  selected: PropTypes.instanceOf(Object),
  onRowSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default DatasetManagement;
