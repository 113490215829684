import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withInlineForm from "modules/common/hoc/withInlineForm";

function DatasetFilter({ onSubmit, isLoading, code }) {
  const [filter, setFilter] = useState({ keyword: "", page: 1 });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(filter);
  };

  useEffect(() => {
    onSubmit(filter);
  }, [code]);

  return (
    <form className="form-inline" onSubmit={handleSubmit}>
      <div className="input-group mr-1">
        <div className="input-group-prepend">
          <div className="input-group-text" style={{ width: 75 }}>
            <small>Keyword</small>
          </div>
        </div>
        <input
          type="text"
          className="form-control"
          onChange={(e) => setFilter({ ...filter, keyword: e.target.value })}
          value={filter?.keyword || ""}
        />
      </div>

      <div>
        <button
          className="btn btn-primary btn-block"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="spinner-grow spinner-grow-sm mr-1" />
          ) : (
            <i className="fa fa-search mr-1" />
          )}
          Search
        </button>
      </div>
    </form>
  );
}

DatasetFilter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withInlineForm(DatasetFilter);
