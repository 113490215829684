import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
// import FormInput from "modules/common/form/FormInput";
import FilterWrapper, { useDebounceEffect } from '../components/FilterWrapper';

const KEY_LABEL = {
  keyword: 'Keyword',
  health_status: 'Health Status',
}

function RegistrantFilter({ isLoading, onSubmit, value }) {
  const filterRef = useRef();
  const [filter, setFilter] = useState(value);
  useDebounceEffect(onSubmit, filter);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFilter(filter);
  //   filterRef.current.setDropdown(false);
  // }
  // const handleClear = () => {
  //   setFilter({});
  // }
  return (
    <FilterWrapper ref={filterRef} isLoading={isLoading} onChange={setFilter} value={filter} keyLabel={KEY_LABEL} placeholder="Filter Group">
      {/* <form onSubmit={handleSubmit}>
        <div className="form-group">
          <FormInput name="keyword" onChange={setFilter} value={filter.keyword} label="Keyword" />
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary btn-sm mr-2" type="submit">Apply</button>
          <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleClear}>Clear</button>
        </div>
      </form> */}
    </FilterWrapper>
  )
}

RegistrantFilter.defaultProps = {
  isLoading: false,
  value: {},
};

RegistrantFilter.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object)
}

export default RegistrantFilter
