import React from "react";
// import PropTypes from 'prop-types';
import { useLocation, useHistory } from "react-router-dom";
import { get } from "lodash";

function RedirectPage() {
  const location = useLocation();
  const history = useHistory();
  const [authMessage] = React.useState("Redirecting...");
  React.useEffect(() => {
    const url = get(location, "state.url");
    if (!url) {
      history.push("/signin");
      return;
    }
    window.location.href = url;
  }, [location.search]);
  return (
    <div className="pt-5">
      <div className="text-center">
        <div>
          <i className="fa fa-spin fa-circle-notch fa-2x" />
        </div>
        <div>{authMessage}</div>
      </div>
    </div>
  );
}

RedirectPage.propTypes = {};

export default RedirectPage;
