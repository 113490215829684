/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { ToastWarn } from "./toast";

function NumberRangePicker({ onChange, valueFrom, valueTo }) {
  const startRef = React.useRef();
  const endRef = React.useRef();
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const toggler = (e) => {
    try {
      const elem = document.getElementById("number-range-picker");
      if (!elem.contains(e.target)) {
        //  && (_.get(e, 'target.className') || '').indexOf('react-datepicker__') < 0) {
        setToggleDropdown(false);
      }
    } catch (err) {
      // do nothing
    }
  };
  const handleToggleDropdown = () => {
    if (toggleDropdown) return;
    setToggleDropdown(true);
    setStartValue(null);
    setEndValue(null);
    window.addEventListener("click", toggler);
  };
  React.useEffect(
    () => () => {
      try {
        window.removeEventListener("click", toggler, false);
      } catch (err) {
        console.log(err); // eslint-disable-line
      }
    },
    []
  );
  const handleClear = () => {
    setStartValue(null);
    setEndValue(null);
    setToggleDropdown(false);
    onChange({
      from: "",
      to: "",
    });
  };
  const handleConfirm = () => {
    if (startValue > endValue) {
      startRef.current.focus();
      ToastWarn("From value must be lower than the To value!");
      return;
    }
    if (startValue < 0) {
      startRef.current.focus();
      ToastWarn("From value cannot be less than 0!");
      return;
    }
    if (_.isEmpty(startValue || "") && _.isEmpty(endValue || "")) {
      handleClear();
      return;
    }
    if (_.isEmpty(startValue || "") || _.isEmpty(endValue || "")) {
      startRef.current.focus();
      ToastWarn("Please set range!");
      return;
    }
    setToggleDropdown(false);
    onChange({
      from: startValue,
      to: endValue,
    });
  };
  const handleNext = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      endRef.current.focus();
    }
  };
  const handleSubmit = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      handleConfirm();
    }
  };
  React.useEffect(() => {
    try {
      if (!toggleDropdown) {
        window.removeEventListener("click", toggler, true);
      }
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  }, [toggleDropdown]);
  React.useEffect(() => {
    if (_.isEmpty(valueFrom) || _.isEmpty(valueTo)) return;
    setStartValue(valueFrom);
    setEndValue(valueTo);
  }, [valueFrom, valueTo]);
  return (
    <div id="number-range-picker" className="d-flex">
      <div
        className="d-flex align-items-center border-bottom border-top px-2 text-center"
        style={{ minWidth: 90 }}
      >
        {toggleDropdown ? (
          <small>Please Select</small>
        ) : (
          <small>
            {!_.isEmpty(valueFrom) && !_.isEmpty(valueTo)
              ? `${startValue} ~ ${endValue}`
              : "All Range"}
          </small>
        )}
      </div>
      <button
        className="btn btn-outline-primary dropdown-toggle"
        type="button"
        onClick={handleToggleDropdown}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />
      <div
        className={`dropdown-menu dropdown-menu-right shadow animated--grow-in${
          toggleDropdown ? " show" : ""
        }`}
      >
        {toggleDropdown ? (
          <div
            className="d-flex align-items-center px-1 mb-2"
            style={{ minHeight: 35 }}
          >
            <div className="flex-grow-1 text-center">
              <small>From</small>
              <input
                ref={startRef}
                autoFocus
                className="form-control"
                type="number"
                onChange={({ target }) => setStartValue(target.value)}
                onKeyDown={handleNext}
                value={startValue || ""}
              />
            </div>
            <div className="mx-2 font-weight-bold mt-3">~</div>
            <div className="flex-grow-1 text-center">
              <small>To</small>
              <input
                ref={endRef}
                className="form-control"
                type="number"
                onChange={({ target }) => setEndValue(target.value)}
                onKeyDown={handleSubmit}
                value={endValue || ""}
              />
            </div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center px-1 mb-2"
            style={{ minHeight: 35 }}
          />
        )}
        <div className="px-1 d-flex justify-content-center">
          <button
            className="btn btn-primary btn-sm mr-2"
            type="button"
            onClick={handleConfirm}
          >
            Confirm
          </button>
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}

NumberRangePicker.propTypes = {
  onChange: PropTypes.instanceOf(Object).isRequired,
  valueFrom: PropTypes.string.isRequired,
  valueTo: PropTypes.string.isRequired,
};

export default NumberRangePicker;
