import { useEffect } from "react";
import { useApiGet, useApiLoading } from "react-reqq";
import { listTrip, showTripById } from "./actions";

export const useTripById = (id) => {
  const isLoading = useApiLoading("TRIP_BY_ID", "get");
  const data = useApiGet("TRIP_BY_ID", {});
  useEffect(() => {
    if (!id) return;
    showTripById(id);
  }, [id]);
  return [isLoading, data];
};

export const useTripList = (params) => {
  const isLoading = useApiLoading("TRIP_LIST/list", "get");
  const data = useApiGet("TRIP_LIST/list", []);
  const pager = useApiGet("TRIP_PAGER/list", {});
  useEffect(() => {
    listTrip("list", params);
  }, [params?.keyword, params?.registrant_uuid]);
  return [isLoading, data, pager];
};
