import { useEffect } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq";

const LIST_REGISTRANT_MEDICAL_CERT = "REGISTRANT_MEDICAL_CERT_LIST";
const SELECTED_REGISTRANT_MEDICAL_CERT = "REGISTRANT_MEDICAL_CERT_ITEM";
const PATCH_REGISTRANT_VERIFICATION_STATUS = "REGISTRANT_MEDICAL_CERT_PATCH";
const REGISTRANT_FORM = "REGISTRANT_FORM";

const MAP_DOSE_LABEL = {
  "1": "1st Dose",
  "2": "2nd Dose",
};

const transformMedCert = (raw) => ({
  id: raw?.id,
  establishment: raw?.attributes?.establishment,
  document_number: raw?.attributes?.document_number,
  dose: raw?.attributes?.dose,
  dose_label: MAP_DOSE_LABEL[raw?.attributes?.dose] || "Other",
  name: raw?.attributes?.name,
  patient_number: raw?.attributes?.patient_number,
  lot_number: raw?.attributes?.lot_number,
  file_url: raw?.attributes?.file_url,
  document_issuance_date: raw?.attributes?.document_issuance_date,
  updated_at: raw?.attributes?.updated_at,
  status: raw?.attributes?.status,
  type: raw?.attributes?.type,
});

const fetchRegistrantMedicalCert = (registrant_id) => {
  req.get({
    key: LIST_REGISTRANT_MEDICAL_CERT,
    url: "/api/v1/mng/medical-certificates",
    params: {
      registrant_id,
      order_by: "created_at",
      sort_by: "desc",
    },
    transform: (res) => (res?.data || []).map(transformMedCert),
    onSuccess: (res) => {
      const item = (res?.response?.data || []).map(transformMedCert)[0] || {};
      req.set(SELECTED_REGISTRANT_MEDICAL_CERT, item);
    },
  });
};

const updateRegistrantVerificationStatus = (
  registrant_id,
  payload,
  onSuccess
) => {
  req.patch({
    key: PATCH_REGISTRANT_VERIFICATION_STATUS,
    url: `/api/v1/mng/registrants/${registrant_id}/verification-status`,
    payload,
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });
};

export const useRegistrantMedicalCert = (registrant_id) => {
  const isLoading = useApiLoading(LIST_REGISTRANT_MEDICAL_CERT, "get");
  const data = useApiGet(SELECTED_REGISTRANT_MEDICAL_CERT, {});
  const list = useApiGet(LIST_REGISTRANT_MEDICAL_CERT, []);
  useEffect(() => {
    fetchRegistrantMedicalCert(registrant_id);
    return () => {
      req.set(LIST_REGISTRANT_MEDICAL_CERT, []);
      req.set(SELECTED_REGISTRANT_MEDICAL_CERT, {});
    };
  }, [registrant_id]);
  return [isLoading, data, list];
};

export const useUpdateRegistrantVerificationStatus = () => {
  const isLoading = useApiLoading(
    PATCH_REGISTRANT_VERIFICATION_STATUS,
    "patch"
  );
  return [isLoading, updateRegistrantVerificationStatus];
};

export const useRegistrantForms = (registrant_id) => {
  const isLoading = useApiLoading(REGISTRANT_FORM, "get");
  const data = useApiGet(REGISTRANT_FORM, {});
  useEffect(() => {
    if (!registrant_id) {
      req.set(REGISTRANT_FORM, {});
      return () => {};
    }
    req.get({
      key: REGISTRANT_FORM,
      url: "/api/v1/mng/forms",
      params: { user_id: registrant_id, status: "SUBMITTED" },
      transform: () => {
        return {}; // WIP: FORMAT FORMS
      },
    });
    return () => {
      req.set(REGISTRANT_FORM, {});
    };
  }, [registrant_id]);
  return [isLoading, data];
};
