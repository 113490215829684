export const DASHBOARD_COUNT = "DASHBOARD/dashboard_count";
export const DASHBOARD_CONDITIONS_CHART =
  "DASHBOARD/dashboard_conditions_chart";
export const DASHBOARD_REGISTRANTS_TOTAL =
  "DASHBOARD/dashboard_registrants_total";
export const DASHBOARD_CONDITIONS_TOTAL =
  "DASHBOARD/dashboard_conditions_total";
export const DASHBOARD_CONDITIONS_MAP = "DASHBOARD/dashboard_conditions_map";
export const DASHBOARD_SYMPTOMS_CHART = "DASHBOARD/dashboard_symptoms_chart";
export const DASHBOARD_CONDITIONS_CHART_ACCUMULATIVE =
  "DASHBOARD/dashboard_conditions_chart_accumulative";
export const DASHBOARD_BY_AGE_GROUP = "DASHBOARD/dashboard_by_age_group";
export const DASHBOARD_BY_GENDER = "DASHBOARD/by_gender";

export const TREND_COLORS = {
  cases: [
    // "#e74a3b",
    "#f34336",
    "#9c27b0",
    "#3f51b5",
    "#00bcd3",
    "#4caf50",
    "#ccdb39",
    "#fec007",
    "#795548",
    "#607d8b",
    "#e81e63",
    "#673ab7",
    "#2196f2",
    "#009688",
    "#8bc24a",
    "#feea3b",
    "#fe5722",
    "#9e9e9e",
    "#374046",
  ],
  deaths: [
    // "#333",
    "#f34336",
    "#9c27b0",
    "#3f51b5",
    "#00bcd3",
    "#4caf50",
    "#ccdb39",
    "#fec007",
    "#795548",
    "#607d8b",
    "#e81e63",
    "#673ab7",
    "#2196f2",
    "#009688",
    "#8bc24a",
    "#feea3b",
    "#fe5722",
    "#9e9e9e",
    "#374046",
  ],
};

export const COLOR_GROUP = [
  "#f34336",
  "#9c27b0",
  "#3f51b5",
  "#00bcd3",
  "#4caf50",
  "#ccdb39",
  "#fec007",
  "#795548",
  "#607d8b",
  "#e81e63",
  "#673ab7",
  "#2196f2",
  "#009688",
  "#8bc24a",
  "#feea3b",
  "#fe5722",
  "#9e9e9e",
  "#374046",
];

export const MAP_STYLE = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const MAP_STYLE_WHITE = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const MAP_STYPE_ASSASIN_CREED = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 13,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#144b53",
      },
      {
        lightness: 14,
      },
      {
        weight: 1.4,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#08304b",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#0c4152",
      },
      {
        lightness: 5,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#0b434f",
      },
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#0b3d51",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        color: "#146474",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#021019",
      },
    ],
  },
];

export const COUNTRIES = [
  {
    value: "AD",
    label: "Andorra",
    lng: 1.601554,
    lat: 42.546245,
  },
  {
    value: "AE",
    label: "United Arab Emirates",
    lng: 53.847818,
    lat: 23.424076,
  },
  {
    value: "AF",
    label: "Afghanistan",
    lng: 67.709953,
    lat: 33.93911,
  },
  {
    value: "AG",
    label: "Antigua and Barbuda",
    lng: -61.796428,
    lat: 17.060816,
  },
  {
    value: "AI",
    label: "Anguilla",
    lng: -63.068615,
    lat: 18.220554,
  },
  {
    value: "AL",
    label: "Albania",
    lng: 20.168331,
    lat: 41.153332,
  },
  {
    value: "AM",
    label: "Armenia",
    lng: 45.038189,
    lat: 40.069099,
  },
  {
    value: "AN",
    label: "Netherlands Antilles",
    lng: -69.060087,
    lat: 12.226079,
  },
  {
    value: "AO",
    label: "Angola",
    lng: 17.873887,
    lat: -11.202692,
  },
  {
    value: "AQ",
    label: "Antarctica",
    lng: -0.071389,
    lat: -75.250973,
  },
  {
    value: "AR",
    label: "Argentina",
    lng: -63.616672,
    lat: -38.416097,
  },
  {
    value: "AS",
    label: "American Samoa",
    lng: -170.132217,
    lat: -14.270972,
  },
  {
    value: "AT",
    label: "Austria",
    lng: 14.550072,
    lat: 47.516231,
  },
  {
    value: "AU",
    label: "Australia",
    lng: 133.775136,
    lat: -25.274398,
  },
  {
    value: "AW",
    label: "Aruba",
    lng: -69.968338,
    lat: 12.52111,
  },
  {
    value: "AZ",
    label: "Azerbaijan",
    lng: 47.576927,
    lat: 40.143105,
  },
  {
    value: "BA",
    label: "Bosnia and Herzegovina",
    lng: 17.679076,
    lat: 43.915886,
  },
  {
    value: "BB",
    label: "Barbados",
    lng: -59.543198,
    lat: 13.193887,
  },
  {
    value: "BD",
    label: "Bangladesh",
    lng: 90.356331,
    lat: 23.684994,
  },
  {
    value: "BE",
    label: "Belgium",
    lng: 4.469936,
    lat: 50.503887,
  },
  {
    value: "BF",
    label: "Burkina Faso",
    lng: -1.561593,
    lat: 12.238333,
  },
  {
    value: "BG",
    label: "Bulgaria",
    lng: 25.48583,
    lat: 42.733883,
  },
  {
    value: "BH",
    label: "Bahrain",
    lng: 50.637772,
    lat: 25.930414,
  },
  {
    value: "BI",
    label: "Burundi",
    lng: 29.918886,
    lat: -3.373056,
  },
  {
    value: "BJ",
    label: "Benin",
    lng: 2.315834,
    lat: 9.30769,
  },
  {
    value: "BM",
    label: "Bermuda",
    lng: -64.75737,
    lat: 32.321384,
  },
  {
    value: "BN",
    label: "Brunei",
    lng: 114.727669,
    lat: 4.535277,
  },
  {
    value: "BO",
    label: "Bolivia",
    lng: -63.588653,
    lat: -16.290154,
  },
  {
    value: "BR",
    label: "Brazil",
    lng: -51.92528,
    lat: -14.235004,
  },
  {
    value: "BS",
    label: "Bahamas",
    lng: -77.39628,
    lat: 25.03428,
  },
  {
    value: "BT",
    label: "Bhutan",
    lng: 90.433601,
    lat: 27.514162,
  },
  {
    value: "BV",
    label: "Bouvet Island",
    lng: 3.413194,
    lat: -54.423199,
  },
  {
    value: "BW",
    label: "Botswana",
    lng: 24.684866,
    lat: -22.328474,
  },
  {
    value: "BY",
    label: "Belarus",
    lng: 27.953389,
    lat: 53.709807,
  },
  {
    value: "BZ",
    label: "Belize",
    lng: -88.49765,
    lat: 17.189877,
  },
  {
    value: "CA",
    label: "Canada",
    lng: -106.346771,
    lat: 56.130366,
  },
  {
    value: "CC",
    label: "Cocos [Keeling] Islands",
    lng: 96.870956,
    lat: -12.164165,
  },
  {
    value: "CD",
    label: "Congo [DRC]",
    lng: 21.758664,
    lat: -4.038333,
  },
  {
    value: "CF",
    label: "Central African Republic",
    lng: 20.939444,
    lat: 6.611111,
  },
  {
    value: "CG",
    label: "Congo [Republic]",
    lng: 15.827659,
    lat: -0.228021,
  },
  {
    value: "CH",
    label: "Switzerland",
    lng: 8.227512,
    lat: 46.818188,
  },
  {
    value: "CI",
    label: "Côte d'Ivoire",
    lng: -5.54708,
    lat: 7.539989,
  },
  {
    value: "CK",
    label: "Cook Islands",
    lng: -159.777671,
    lat: -21.236736,
  },
  {
    value: "CL",
    label: "Chile",
    lng: -71.542969,
    lat: -35.675147,
  },
  {
    value: "CM",
    label: "Cameroon",
    lng: 12.354722,
    lat: 7.369722,
  },
  {
    value: "CN",
    label: "China",
    lng: 104.195397,
    lat: 35.86166,
  },
  {
    value: "CO",
    label: "Colombia",
    lng: -74.297333,
    lat: 4.570868,
  },
  {
    value: "CR",
    label: "Costa Rica",
    lng: -83.753428,
    lat: 9.748917,
  },
  {
    value: "CU",
    label: "Cuba",
    lng: -77.781167,
    lat: 21.521757,
  },
  {
    value: "CV",
    label: "Cape Verde",
    lng: -24.013197,
    lat: 16.002082,
  },
  {
    value: "CX",
    label: "Christmas Island",
    lng: 105.690449,
    lat: -10.447525,
  },
  {
    value: "CY",
    label: "Cyprus",
    lng: 33.429859,
    lat: 35.126413,
  },
  {
    value: "CZ",
    label: "Czech Republic",
    lng: 15.472962,
    lat: 49.817492,
  },
  {
    value: "DE",
    label: "Germany",
    lng: 10.451526,
    lat: 51.165691,
  },
  {
    value: "DJ",
    label: "Djibouti",
    lng: 42.590275,
    lat: 11.825138,
  },
  {
    value: "DK",
    label: "Denmark",
    lng: 9.501785,
    lat: 56.26392,
  },
  {
    value: "DM",
    label: "Dominica",
    lng: -61.370976,
    lat: 15.414999,
  },
  {
    value: "DO",
    label: "Dominican Republic",
    lng: -70.162651,
    lat: 18.735693,
  },
  {
    value: "DZ",
    label: "Algeria",
    lng: 1.659626,
    lat: 28.033886,
  },
  {
    value: "EC",
    label: "Ecuador",
    lng: -78.183406,
    lat: -1.831239,
  },
  {
    value: "EE",
    label: "Estonia",
    lng: 25.013607,
    lat: 58.595272,
  },
  {
    value: "EG",
    label: "Egypt",
    lng: 30.802498,
    lat: 26.820553,
  },
  {
    value: "EH",
    label: "Western Sahara",
    lng: -12.885834,
    lat: 24.215527,
  },
  {
    value: "ER",
    label: "Eritrea",
    lng: 39.782334,
    lat: 15.179384,
  },
  {
    value: "ES",
    label: "Spain",
    lng: -3.74922,
    lat: 40.463667,
  },
  {
    value: "ET",
    label: "Ethiopia",
    lng: 40.489673,
    lat: 9.145,
  },
  {
    value: "FI",
    label: "Finland",
    lng: 25.748151,
    lat: 61.92411,
  },
  {
    value: "FJ",
    label: "Fiji",
    lng: 179.414413,
    lat: -16.578193,
  },
  {
    value: "FK",
    label: "Falkland Islands [Islas Malvinas]",
    lng: -59.523613,
    lat: -51.796253,
  },
  {
    value: "FM",
    label: "Micronesia",
    lng: 150.550812,
    lat: 7.425554,
  },
  {
    value: "FO",
    label: "Faroe Islands",
    lng: -6.911806,
    lat: 61.892635,
  },
  {
    value: "FR",
    label: "France",
    lng: 2.213749,
    lat: 46.227638,
  },
  {
    value: "GA",
    label: "Gabon",
    lng: 11.609444,
    lat: -0.803689,
  },
  {
    value: "GB",
    label: "United Kingdom",
    lng: -3.435973,
    lat: 55.378051,
  },
  {
    value: "GD",
    label: "Grenada",
    lng: -61.604171,
    lat: 12.262776,
  },
  {
    value: "GE",
    label: "Georgia",
    lng: 43.356892,
    lat: 42.315407,
  },
  {
    value: "GF",
    label: "French Guiana",
    lng: -53.125782,
    lat: 3.933889,
  },
  {
    value: "GG",
    label: "Guernsey",
    lng: -2.585278,
    lat: 49.465691,
  },
  {
    value: "GH",
    label: "Ghana",
    lng: -1.023194,
    lat: 7.946527,
  },
  {
    value: "GI",
    label: "Gibraltar",
    lng: -5.345374,
    lat: 36.137741,
  },
  {
    value: "GL",
    label: "Greenland",
    lng: -42.604303,
    lat: 71.706936,
  },
  {
    value: "GM",
    label: "Gambia",
    lng: -15.310139,
    lat: 13.443182,
  },
  {
    value: "GN",
    label: "Guinea",
    lng: -9.696645,
    lat: 9.945587,
  },
  {
    value: "GP",
    label: "Guadeloupe",
    lng: -62.067641,
    lat: 16.995971,
  },
  {
    value: "GQ",
    label: "Equatorial Guinea",
    lng: 10.267895,
    lat: 1.650801,
  },
  {
    value: "GR",
    label: "Greece",
    lng: 21.824312,
    lat: 39.074208,
  },
  {
    value: "GS",
    label: "South Georgia and the South Sandwich Islands",
    lng: -36.587909,
    lat: -54.429579,
  },
  {
    value: "GT",
    label: "Guatemala",
    lng: -90.230759,
    lat: 15.783471,
  },
  {
    value: "GU",
    label: "Guam",
    lng: 144.793731,
    lat: 13.444304,
  },
  {
    value: "GW",
    label: "Guinea-Bissau",
    lng: -15.180413,
    lat: 11.803749,
  },
  {
    value: "GY",
    label: "Guyana",
    lng: -58.93018,
    lat: 4.860416,
  },
  {
    value: "GZ",
    label: "Gaza Strip",
    lng: 34.308825,
    lat: 31.354676,
  },
  {
    value: "HK",
    label: "Hong Kong",
    lng: 114.109497,
    lat: 22.396428,
  },
  {
    value: "HM",
    label: "Heard Island and McDonald Islands",
    lng: 73.504158,
    lat: -53.08181,
  },
  {
    value: "HN",
    label: "Honduras",
    lng: -86.241905,
    lat: 15.199999,
  },
  {
    value: "HR",
    label: "Croatia",
    lng: 15.2,
    lat: 45.1,
  },
  {
    value: "HT",
    label: "Haiti",
    lng: -72.285215,
    lat: 18.971187,
  },
  {
    value: "HU",
    label: "Hungary",
    lng: 19.503304,
    lat: 47.162494,
  },
  {
    value: "ID",
    label: "Indonesia",
    lng: 113.921327,
    lat: -0.789275,
  },
  {
    value: "IE",
    label: "Ireland",
    lng: -8.24389,
    lat: 53.41291,
  },
  {
    value: "IL",
    label: "Israel",
    lng: 34.851612,
    lat: 31.046051,
  },
  {
    value: "IM",
    label: "Isle of Man",
    lng: -4.548056,
    lat: 54.236107,
  },
  {
    value: "IN",
    label: "India",
    lng: 78.96288,
    lat: 20.593684,
  },
  {
    value: "IO",
    label: "British Indian Ocean Territory",
    lng: 71.876519,
    lat: -6.343194,
  },
  {
    value: "IQ",
    label: "Iraq",
    lng: 43.679291,
    lat: 33.223191,
  },
  {
    value: "IR",
    label: "Iran",
    lng: 53.688046,
    lat: 32.427908,
  },
  {
    value: "IS",
    label: "Iceland",
    lng: -19.020835,
    lat: 64.963051,
  },
  {
    value: "IT",
    label: "Italy",
    lng: 12.56738,
    lat: 41.87194,
  },
  {
    value: "JE",
    label: "Jersey",
    lng: -2.13125,
    lat: 49.214439,
  },
  {
    value: "JM",
    label: "Jamaica",
    lng: -77.297508,
    lat: 18.109581,
  },
  {
    value: "JO",
    label: "Jordan",
    lng: 36.238414,
    lat: 30.585164,
  },
  {
    value: "JP",
    label: "Japan",
    lng: 138.252924,
    lat: 36.204824,
  },
  {
    value: "KE",
    label: "Kenya",
    lng: 37.906193,
    lat: -0.023559,
  },
  {
    value: "KG",
    label: "Kyrgyzstan",
    lng: 74.766098,
    lat: 41.20438,
  },
  {
    value: "KH",
    label: "Cambodia",
    lng: 104.990963,
    lat: 12.565679,
  },
  {
    value: "KI",
    label: "Kiribati",
    lng: -168.734039,
    lat: -3.370417,
  },
  {
    value: "KM",
    label: "Comoros",
    lng: 43.872219,
    lat: -11.875001,
  },
  {
    value: "KN",
    label: "Saint Kitts and Nevis",
    lng: -62.782998,
    lat: 17.357822,
  },
  {
    value: "KP",
    label: "North Korea",
    lng: 127.510093,
    lat: 40.339852,
  },
  {
    value: "KR",
    label: "South Korea",
    lng: 127.766922,
    lat: 35.907757,
  },
  {
    value: "KW",
    label: "Kuwait",
    lng: 47.481766,
    lat: 29.31166,
  },
  {
    value: "KY",
    label: "Cayman Islands",
    lng: -80.566956,
    lat: 19.513469,
  },
  {
    value: "KZ",
    label: "Kazakhstan",
    lng: 66.923684,
    lat: 48.019573,
  },
  {
    value: "LA",
    label: "Laos",
    lng: 102.495496,
    lat: 19.85627,
  },
  {
    value: "LB",
    label: "Lebanon",
    lng: 35.862285,
    lat: 33.854721,
  },
  {
    value: "LC",
    label: "Saint Lucia",
    lng: -60.978893,
    lat: 13.909444,
  },
  {
    value: "LI",
    label: "Liechtenstein",
    lng: 9.555373,
    lat: 47.166,
  },
  {
    value: "LK",
    label: "Sri Lanka",
    lng: 80.771797,
    lat: 7.873054,
  },
  {
    value: "LR",
    label: "Liberia",
    lng: -9.429499,
    lat: 6.428055,
  },
  {
    value: "LS",
    label: "Lesotho",
    lng: 28.233608,
    lat: -29.609988,
  },
  {
    value: "LT",
    label: "Lithuania",
    lng: 23.881275,
    lat: 55.169438,
  },
  {
    value: "LU",
    label: "Luxembourg",
    lng: 6.129583,
    lat: 49.815273,
  },
  {
    value: "LV",
    label: "Latvia",
    lng: 24.603189,
    lat: 56.879635,
  },
  {
    value: "LY",
    label: "Libya",
    lng: 17.228331,
    lat: 26.3351,
  },
  {
    value: "MA",
    label: "Morocco",
    lng: -7.09262,
    lat: 31.791702,
  },
  {
    value: "MC",
    label: "Monaco",
    lng: 7.412841,
    lat: 43.750298,
  },
  {
    value: "MD",
    label: "Moldova",
    lng: 28.369885,
    lat: 47.411631,
  },
  {
    value: "ME",
    label: "Montenegro",
    lng: 19.37439,
    lat: 42.708678,
  },
  {
    value: "MG",
    label: "Madagascar",
    lng: 46.869107,
    lat: -18.766947,
  },
  {
    value: "MH",
    label: "Marshall Islands",
    lng: 171.184478,
    lat: 7.131474,
  },
  {
    value: "MK",
    label: "Macedonia [FYROM]",
    lng: 21.745275,
    lat: 41.608635,
  },
  {
    value: "ML",
    label: "Mali",
    lng: -3.996166,
    lat: 17.570692,
  },
  {
    value: "MM",
    label: "Myanmar [Burma]",
    lng: 95.956223,
    lat: 21.913965,
  },
  {
    value: "MN",
    label: "Mongolia",
    lng: 103.846656,
    lat: 46.862496,
  },
  {
    value: "MO",
    label: "Macau",
    lng: 113.543873,
    lat: 22.198745,
  },
  {
    value: "MP",
    label: "Northern Mariana Islands",
    lng: 145.38469,
    lat: 17.33083,
  },
  {
    value: "MQ",
    label: "Martinique",
    lng: -61.024174,
    lat: 14.641528,
  },
  {
    value: "MR",
    label: "Mauritania",
    lng: -10.940835,
    lat: 21.00789,
  },
  {
    value: "MS",
    label: "Montserrat",
    lng: -62.187366,
    lat: 16.742498,
  },
  {
    value: "MT",
    label: "Malta",
    lng: 14.375416,
    lat: 35.937496,
  },
  {
    value: "MU",
    label: "Mauritius",
    lng: 57.552152,
    lat: -20.348404,
  },
  {
    value: "MV",
    label: "Maldives",
    lng: 73.22068,
    lat: 3.202778,
  },
  {
    value: "MW",
    label: "Malawi",
    lng: 34.301525,
    lat: -13.254308,
  },
  {
    value: "MX",
    label: "Mexico",
    lng: -102.552784,
    lat: 23.634501,
  },
  {
    value: "MY",
    label: "Malaysia",
    lng: 101.975766,
    lat: 4.210484,
  },
  {
    value: "MZ",
    label: "Mozambique",
    lng: 35.529562,
    lat: -18.665695,
  },
  {
    value: "NA",
    label: "Namibia",
    lng: 18.49041,
    lat: -22.95764,
  },
  {
    value: "NC",
    label: "New Caledonia",
    lng: 165.618042,
    lat: -20.904305,
  },
  {
    value: "NE",
    label: "Niger",
    lng: 8.081666,
    lat: 17.607789,
  },
  {
    value: "NF",
    label: "Norfolk Island",
    lng: 167.954712,
    lat: -29.040835,
  },
  {
    value: "NG",
    label: "Nigeria",
    lng: 8.675277,
    lat: 9.081999,
  },
  {
    value: "NI",
    label: "Nicaragua",
    lng: -85.207229,
    lat: 12.865416,
  },
  {
    value: "NL",
    label: "Netherlands",
    lng: 5.291266,
    lat: 52.132633,
  },
  {
    value: "NO",
    label: "Norway",
    lng: 8.468946,
    lat: 60.472024,
  },
  {
    value: "NP",
    label: "Nepal",
    lng: 84.124008,
    lat: 28.394857,
  },
  {
    value: "NR",
    label: "Nauru",
    lng: 166.931503,
    lat: -0.522778,
  },
  {
    value: "NU",
    label: "Niue",
    lng: -169.867233,
    lat: -19.054445,
  },
  {
    value: "NZ",
    label: "New Zealand",
    lng: 174.885971,
    lat: -40.900557,
  },
  {
    value: "OM",
    label: "Oman",
    lng: 55.923255,
    lat: 21.512583,
  },
  {
    value: "PA",
    label: "Panama",
    lng: -80.782127,
    lat: 8.537981,
  },
  {
    value: "PE",
    label: "Peru",
    lng: -75.015152,
    lat: -9.189967,
  },
  {
    value: "PF",
    label: "French Polynesia",
    lng: -149.406843,
    lat: -17.679742,
  },
  {
    value: "PG",
    label: "Papua New Guinea",
    lng: 143.95555,
    lat: -6.314993,
  },
  {
    value: "PH",
    label: "Philippines",
    lng: 121.0698107,
    lat: 14.5925666, // lng: 121.774017, lat: 12.879721,
  },
  {
    value: "PK",
    label: "Pakistan",
    lng: 69.345116,
    lat: 30.375321,
  },
  {
    value: "PL",
    label: "Poland",
    lng: 19.145136,
    lat: 51.919438,
  },
  {
    value: "PM",
    label: "Saint Pierre and Miquelon",
    lng: -56.27111,
    lat: 46.941936,
  },
  {
    value: "PN",
    label: "Pitcairn Islands",
    lng: -127.439308,
    lat: -24.703615,
  },
  {
    value: "PR",
    label: "Puerto Rico",
    lng: -66.590149,
    lat: 18.220833,
  },
  {
    value: "PS",
    label: "Palestinian Territories",
    lng: 35.233154,
    lat: 31.952162,
  },
  {
    value: "PT",
    label: "Portugal",
    lng: -8.224454,
    lat: 39.399872,
  },
  {
    value: "PW",
    label: "Palau",
    lng: 134.58252,
    lat: 7.51498,
  },
  {
    value: "PY",
    label: "Paraguay",
    lng: -58.443832,
    lat: -23.442503,
  },
  {
    value: "QA",
    label: "Qatar",
    lng: 51.183884,
    lat: 25.354826,
  },
  {
    value: "RE",
    label: "Réunion",
    lng: 55.536384,
    lat: -21.115141,
  },
  {
    value: "RO",
    label: "Romania",
    lng: 24.96676,
    lat: 45.943161,
  },
  {
    value: "RS",
    label: "Serbia",
    lng: 21.005859,
    lat: 44.016521,
  },
  {
    value: "RU",
    label: "Russia",
    lng: 105.318756,
    lat: 61.52401,
  },
  {
    value: "RW",
    label: "Rwanda",
    lng: 29.873888,
    lat: -1.940278,
  },
  {
    value: "SA",
    label: "Saudi Arabia",
    lng: 45.079162,
    lat: 23.885942,
  },
  {
    value: "SB",
    label: "Solomon Islands",
    lng: 160.156194,
    lat: -9.64571,
  },
  {
    value: "SC",
    label: "Seychelles",
    lng: 55.491977,
    lat: -4.679574,
  },
  {
    value: "SD",
    label: "Sudan",
    lng: 30.217636,
    lat: 12.862807,
  },
  {
    value: "SE",
    label: "Sweden",
    lng: 18.643501,
    lat: 60.128161,
  },
  {
    value: "SG",
    label: "Singapore",
    lng: 103.819836,
    lat: 1.352083,
  },
  {
    value: "SH",
    label: "Saint Helena",
    lng: -10.030696,
    lat: -24.143474,
  },
  {
    value: "SI",
    label: "Slovenia",
    lng: 14.995463,
    lat: 46.151241,
  },
  {
    value: "SJ",
    label: "Svalbard and Jan Mayen",
    lng: 23.670272,
    lat: 77.553604,
  },
  {
    value: "SK",
    label: "Slovakia",
    lng: 19.699024,
    lat: 48.669026,
  },
  {
    value: "SL",
    label: "Sierra Leone",
    lng: -11.779889,
    lat: 8.460555,
  },
  {
    value: "SM",
    label: "San Marino",
    lng: 12.457777,
    lat: 43.94236,
  },
  {
    value: "SN",
    label: "Senegal",
    lng: -14.452362,
    lat: 14.497401,
  },
  {
    value: "SO",
    label: "Somalia",
    lng: 46.199616,
    lat: 5.152149,
  },
  {
    value: "SR",
    label: "Suriname",
    lng: -56.027783,
    lat: 3.919305,
  },
  {
    value: "ST",
    label: "São Tomé and Príncipe",
    lng: 6.613081,
    lat: 0.18636,
  },
  {
    value: "SV",
    label: "El Salvador",
    lng: -88.89653,
    lat: 13.794185,
  },
  {
    value: "SY",
    label: "Syria",
    lng: 38.996815,
    lat: 34.802075,
  },
  {
    value: "SZ",
    label: "Swaziland",
    lng: 31.465866,
    lat: -26.522503,
  },
  {
    value: "TC",
    label: "Turks and Caicos Islands",
    lng: -71.797928,
    lat: 21.694025,
  },
  {
    value: "TD",
    label: "Chad",
    lng: 18.732207,
    lat: 15.454166,
  },
  {
    value: "TF",
    label: "French Southern Territories",
    lng: 69.348557,
    lat: -49.280366,
  },
  {
    value: "TG",
    label: "Togo",
    lng: 0.824782,
    lat: 8.619543,
  },
  {
    value: "TH",
    label: "Thailand",
    lng: 100.992541,
    lat: 15.870032,
  },
  {
    value: "TJ",
    label: "Tajikistan",
    lng: 71.276093,
    lat: 38.861034,
  },
  {
    value: "TK",
    label: "Tokelau",
    lng: -171.855881,
    lat: -8.967363,
  },
  {
    value: "TL",
    label: "Timor-Leste",
    lng: 125.727539,
    lat: -8.874217,
  },
  {
    value: "TM",
    label: "Turkmenistan",
    lng: 59.556278,
    lat: 38.969719,
  },
  {
    value: "TN",
    label: "Tunisia",
    lng: 9.537499,
    lat: 33.886917,
  },
  {
    value: "TO",
    label: "Tonga",
    lng: -175.198242,
    lat: -21.178986,
  },
  {
    value: "TR",
    label: "Turkey",
    lng: 35.243322,
    lat: 38.963745,
  },
  {
    value: "TT",
    label: "Trinidad and Tobago",
    lng: -61.222503,
    lat: 10.691803,
  },
  {
    value: "TV",
    label: "Tuvalu",
    lng: 177.64933,
    lat: -7.109535,
  },
  {
    value: "TW",
    label: "Taiwan",
    lng: 120.960515,
    lat: 23.69781,
  },
  {
    value: "TZ",
    label: "Tanzania",
    lng: 34.888822,
    lat: -6.369028,
  },
  {
    value: "UA",
    label: "Ukraine",
    lng: 31.16558,
    lat: 48.379433,
  },
  {
    value: "UG",
    label: "Uganda",
    lng: 32.290275,
    lat: 1.373333,
  },
  {
    value: "US",
    label: "United States",
    lng: -95.712891,
    lat: 37.09024,
  },
  {
    value: "UY",
    label: "Uruguay",
    lng: -55.765835,
    lat: -32.522779,
  },
  {
    value: "UZ",
    label: "Uzbekistan",
    lng: 64.585262,
    lat: 41.377491,
  },
  {
    value: "VA",
    label: "Vatican City",
    lng: 12.453389,
    lat: 41.902916,
  },
  {
    value: "VC",
    label: "Saint Vincent and the Grenadines",
    lng: -61.287228,
    lat: 12.984305,
  },
  {
    value: "VE",
    label: "Venezuela",
    lng: -66.58973,
    lat: 6.42375,
  },
  {
    value: "VG",
    label: "British Virgin Islands",
    lng: -64.639968,
    lat: 18.420695,
  },
  {
    value: "VI",
    label: "U.S. Virgin Islands",
    lng: -64.896335,
    lat: 18.335765,
  },
  {
    value: "VN",
    label: "Vietnam",
    lng: 108.277199,
    lat: 14.058324,
  },
  {
    value: "VU",
    label: "Vanuatu",
    lng: 166.959158,
    lat: -15.376706,
  },
  {
    value: "WF",
    label: "Wallis and Futuna",
    lng: -177.156097,
    lat: -13.768752,
  },
  {
    value: "WS",
    label: "Samoa",
    lng: -172.104629,
    lat: -13.759029,
  },
  {
    value: "XK",
    label: "Kosovo",
    lng: 20.902977,
    lat: 42.602636,
  },
  {
    value: "YE",
    label: "Yemen",
    lng: 48.516388,
    lat: 15.552727,
  },
  {
    value: "YT",
    label: "Mayotte",
    lng: 45.166244,
    lat: -12.8275,
  },
  {
    value: "ZA",
    label: "South Africa",
    lng: 22.937506,
    lat: -30.559482,
  },
  {
    value: "ZM",
    label: "Zambia",
    lng: 27.849332,
    lat: -13.133897,
  },
  {
    value: "ZW",
    label: "Zimbabwe",
    lng: 29.154857,
    lat: -19.015438,
  },
];
