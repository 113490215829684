/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

function FormInput({
  name,
  onChange,
  label,
  value,
  isReadOnly,
  hasTooltip,
  tooltipMsg,
  ...rest
}) {
  const handleChange = ({ target }) => {
    onChange((prev) => ({ ...prev, [name]: target.value }));
  };
  return (
    <>
      {label && (
        <small>
          {label}
          {rest.required && <span className="text-danger ml-1">*</span>}
          {hasTooltip && (
            <span
              className="scanner__scan-icon-btn ml-1 font-weight-bold"
              style={{ position: "relative", top: 0, right: 0 }}
            >
              (?)
              <div className="scanner__scan-icon-tooltip">{tooltipMsg}</div>
            </span>
          )}
        </small>
      )}
      {isReadOnly ? (
        <p className="form-control-plaintext" title={get(rest, "title") || ""}>
          {value || "-"}
        </p>
      ) : (
        <input
          className="form-control"
          onChange={handleChange}
          value={value || ""}
          {...rest}
        />
      )}
    </>
  );
}

FormInput.propTypes = {
  isReadOnly: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
};

FormInput.defaultProps = {
  isReadOnly: false,
  label: "",
  hasTooltip: false,
  tooltipMsg: "",
};

export default FormInput;
