import React from "react";
import UsersContainer from "./UsersContainer";

const PRIVATE_FILTER = {
  category: "PRIVATE",
};

function AclAdminContainer() {
  return <UsersContainer key="private" type="admin" filter={PRIVATE_FILTER} />;
}

export default AclAdminContainer;
