/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";

function DateRangePicker({ onChange, valueFrom, valueTo, dropdownStyle }) {
  const [selectMode, setSelectMode] = useState("start");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const toggler = (e) => {
    try {
      const elem = document.getElementById("date-range-picker");
      if (
        !elem.contains(e.target) &&
        (_.get(e, "target.className") || "").indexOf("react-datepicker__") < 0
      ) {
        setToggleDropdown(false);
      }
    } catch (err) {
      // do nothing
    }
  };
  const handleToggleDropdown = () => {
    if (toggleDropdown) return;
    setToggleDropdown(true);
    setSelectMode("start");
    setStartDate(null);
    setEndDate(null);
    window.addEventListener("click", toggler);
  };
  React.useEffect(
    () => () => {
      try {
        window.removeEventListener("click", toggler, false);
      } catch (err) {
        console.log(err); //eslint-disable-line
      }
    },
    []
  );

  const handleChangeStartDate = (newStartDate) => {
    setStartDate(newStartDate);
    setEndDate(newStartDate);
    setSelectMode("end");
  };
  const handleChangeEndDate = (newEndDate) => {
    setEndDate(newEndDate);
    setSelectMode("start");
    setToggleDropdown(false);
    onChange({
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(newEndDate).format("YYYY-MM-DD"),
    });
  };
  const handleConfirm = () => {
    setSelectMode("start");
    setToggleDropdown(false);
    onChange({
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
    });
  };
  const handleClear = () => {
    setSelectMode("start");
    setStartDate(null);
    setEndDate(null);
    setToggleDropdown(false);
    onChange({
      from: "",
      to: "",
    });
  };
  React.useEffect(() => {
    try {
      if (!toggleDropdown) {
        window.removeEventListener("click", toggler, true);
      }
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  }, [toggleDropdown]);
  React.useEffect(() => {
    if (_.isEmpty(valueFrom) || _.isEmpty(valueTo)) return;
    setStartDate(new Date(valueFrom));
    setEndDate(new Date(valueTo));
  }, [valueFrom, valueTo]);

  const momentStart = moment(startDate);
  const momentEnd = moment(endDate);
  return (
    <div id="date-range-picker" className="d-flex">
      <div
        className="d-flex flex-column border-bottom border-top px-2 text-center"
        style={{ minWidth: 90 }}
      >
        {toggleDropdown ? (
          <small>Please</small>
        ) : (
          <small>
            {momentStart.isValid() ? momentStart.format("MM/DD/YYYY") : "All"}
          </small>
        )}
        {toggleDropdown ? (
          <small>Select</small>
        ) : (
          <small>
            {momentEnd.isValid() ? momentEnd.format("MM/DD/YYYY") : "Dates"}
          </small>
        )}
      </div>
      <button
        className="btn btn-outline-primary dropdown-toggle"
        type="button"
        onClick={handleToggleDropdown}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />
      <div
        className={`dropdown-menu dropdown-menu-right shadow animated--grow-in${
          toggleDropdown ? " show" : ""
        }`}
        style={dropdownStyle}
      >
        {selectMode === "start" && (
          <>
            <div className="text-center">
              Please select <b style={{ color: '#f00' }}>start date</b>
            </div>
            <DatePicker
              selected={startDate}
              onChange={handleChangeStartDate}
              showYearDropdown
              showMonthDropdown
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              inline
            />
            <div className="px-1">
              <button
                className="btn btn-outline-primary btn-sm btn-block"
                type="button"
                onClick={handleClear}
              >
                Clear Range
              </button>
            </div>
          </>
        )}
        {selectMode === "end" && (
          <>
            <div className="text-center">
              Please select <b style={{ color: '#f00' }}>end date</b>
            </div>
            <DatePicker
              selected={endDate}
              onChange={handleChangeEndDate}
              showYearDropdown
              showMonthDropdown
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              inline
            />
            <div className="px-1">
              <button
                className="btn btn-outline-primary btn-sm btn-block"
                type="button"
                onClick={handleConfirm}
              >
                Confirm {moment(startDate).format("MM/DD/YYYY")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

DateRangePicker.propTypes = {
  onChange: PropTypes.instanceOf(Object).isRequired,
  valueFrom: PropTypes.string.isRequired,
  valueTo: PropTypes.string.isRequired,
  dropdownStyle: PropTypes.instanceOf(Object),
};

DateRangePicker.defaultProps = {
  dropdownStyle: {},
};

export default DateRangePicker;
