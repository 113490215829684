import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import GenericTable from "modules/common/components/GenericTable";
import GenericDetail from "modules/common/components/GenericDetail";
import { useListTraceExportItems } from '../hooks';

function TraceExportItems({ id, print }) {
  const [isLoading, list] = useListTraceExportItems(id);
  return (
    <div className="px-3 pb-3">
      <div>Traced Contacts ({list.length})</div>
      {list.length < 1 ? (
        <div className="text-center">
          {isLoading ? 'Loading...' : 'There are no items.'}
        </div>
      ) : list.map((row) => (
        <TracedContactItem key={row?.id} data={row} print={print} />
      ))}
    </div>
  )
}

TraceExportItems.defaultProps = {
  print: false,
};

TraceExportItems.propTypes = {
  id: PropTypes.string.isRequired,
  print: PropTypes.bool
}

const MAP_CASE_STATUS = {
  '0001': <span className="badge text-white" style={{ background: 'orange' }}>Probable</span>,
  '0002': <span className="badge text-white" style={{ background: 'red' }}>Suspect</span>,
  '0003': <span className="badge text-white" style={{ background: 'violet' }}>Confirmed Positive</span>,
  '0004': <span className="badge text-white" style={{ background: 'green' }}>Cleared</span>,
  '0005': <span className="badge" style={{ background: 'white' }}>Initial</span>,
  '0006': <span className="badge text-white" style={{ background: 'yellow' }}>Traveler PCR</span>,
  default: <span className="badge" style={{ background: '' }}>n/a</span>,
};

function TracedContactItem({ data , print }) {
  const history = useHistory();
  const [show, setShow] = React.useState(false);
  const handleToggle = (e) => {
    e.preventDefault();
    setShow(!show);
  }
  const handleView = () => {
    history.push(`/cases/list/${data?.case_id}`);
  };
  return (
    <div className="border mb-1">
      <div className="d-flex justify-content-between p-1">
        <div className="font-weight-bold">Details</div>
        <div>{MAP_CASE_STATUS[data?.case_status_code] || MAP_CASE_STATUS.default}</div>
      </div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <GenericDetail
            labelWidth="140px"
            format={[
              { label: 'Case Number', key: 'case_number' },
              { label: 'Registrant Number', key: 'registrant_number' },
            ]}
            data={data}
          />
        </div>
        <div className="flex-grow-1">
          <GenericDetail
            labelWidth="140px"
            format={[
              { label: 'Name', key: 'name' },
              { label: 'Contact Number', key: 'mobile_number' }
            ]}
            data={data}
          />
        </div>
        {!print ? (
          <div className="px-3 d-print-none">
            <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleView}>View Case</button>
          </div>
        ) : null}
      </div>
      {(show || print) ? (
        <div>
          <table className="table table-sm table-striped table-fixed-header mb-0">
            <thead>
              <tr>
                <th>
                  <small>Date/Time</small>
                </th>
                <th>
                  <small>Location</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {(data?.items || []).map((row) => (
                <tr key={row.id}>
                  <th>
                    <small>{row.date_time}</small>
                  </th>
                  <th>
                    <small>{row?.location_qr_label}</small>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <GenericTable format={[
            { label: 'Date/Time', key: 'date_time' },
            { label: 'Location', key: 'location_qr_label' },
          ]} data={data?.items || []} /> */}
        </div>
      ) : null}
      {!print ? (
        <div className="text-center">
          <small><a href="/" onClick={handleToggle}>{!show ? 'Show all encounters' : 'Hide all encounters'} ({(data?.items || []).length})</a></small>
        </div>
      ) : null}
    </div>
  );
}

TracedContactItem.defaultProps = {
  print: false,
};

TracedContactItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  print: PropTypes.bool
}

export default TraceExportItems
