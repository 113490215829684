import React from "react";
import PropTypes from "prop-types";
import FormInput from "modules/common/form/FormInput";
import FormSelect from "modules/common/form/FormSelect";
import { useApiLoading } from "react-reqq";
import _ from "lodash";
import * as actions from "./actions";
import * as c from "./constants";

function DatasetModal({ code, data, onClose }) {
  const isNew = !data?.id;
  const isLoading = useApiLoading(
    `${c.DATASET}_${code}`,
    isNew ? "post" : "put"
  );
  const [form, setForm] = React.useState({ code, ...data });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNew) {
      actions.addDataset(code, form, onClose);
      return;
    }
    actions.updateDataset(code, _.get(data, "id"), form, onClose);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col-8 form-group">
            <FormInput
              name="title"
              label="Title"
              onChange={setForm}
              value={form.title}
              required
            />
          </div>
          <div className="col-4 form-group">
            <FormSelect
              name="status"
              label="Status"
              onChange={setForm}
              value={form.status}
              options={c.DATASET_STATUS_OPTIONS}
              required
              defaultOption={false}
            />
          </div>
          <div className="col-9 form-group">
            <FormInput
              name="description"
              label="Description"
              onChange={setForm}
              value={form.description}
              required
            />
          </div>
          <div className="col-3 form-group">
            <FormInput
              name="sort_priority"
              label="Sort Priority"
              onChange={setForm}
              value={form?.sort_priority}
              hasTooltip
              tooltipMsg="A numerical value where larger numbers signify greater importance or urgency."
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary mr-1"
          type="submit"
          disabled={isLoading}
        >
          Save
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </form>
  );
}

DatasetModal.propTypes = {
  code: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
};

DatasetModal.defaultProps = {
  data: c.INIT_STATE,
};

export default DatasetModal;
