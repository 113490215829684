import { isEmpty, omit, omitBy } from "lodash";
import { useEffect } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq";
import * as actions from './actions';
import * as c from './constants';

export const useGenerateTraceExport = () => {
  const isLoading = useApiLoading(c.GENERATE_TRACE_EXPORT, 'post');
  return [isLoading, actions.generateTraceExport];
};

export const useListTraceExport = (filter) => {
  const isLoading = useApiLoading(c.LIST_TRACE_EXPORT, 'get');
  const data = useApiGet(c.LIST_TRACE_EXPORT, []);
  const pager = useApiGet(c.PAGER_TRACE_EXPORT, {});
  useEffect(() => {
    const formatFilter = omitBy({
      ...omit(filter, ['case']),
      case_id: filter?.case?.value,
    }, (x) => isEmpty(`${x ?? ''}`))
    actions.listTraceExport(formatFilter)
    return () => {
      req.set(c.LIST_TRACE_EXPORT, []);
      req.set(c.PAGER_TRACE_EXPORT, {});
    }
  }, [filter]);
  return [isLoading, data, pager];
};

export const useSelectTraceExport = (id) => {
  const isLoading = useApiLoading(c.SELECT_TRACE_EXPORT, 'get');
  const data = useApiGet(c.SELECT_TRACE_EXPORT, {});
  useEffect(() => {
    if (!id) return () => {};
    actions.selectTraceExport(id);
    return () => {
      req.set(c.SELECT_TRACE_EXPORT, {});
    }
  }, [id]);
  return [isLoading, data];
}

export const useListTraceExportItems = (id) => {
  const isLoading = useApiLoading(c.LIST_TRACE_EXPORT_ITEMS, 'get');
  const data = useApiGet(c.LIST_TRACE_EXPORT_ITEMS, []);
  useEffect(() => {
    actions.listTraceExportItems(id);
    return () => {
      req.set(c.LIST_TRACE_EXPORT_ITEMS, []);
    }
  }, [id]);
  return [isLoading, data];
}

export const useConsolidatedTraceContact = (id) => {
  const isLoading = useApiLoading(c.TRACE_EXPORT_GRAPH, 'get');
  const graph = useApiGet(c.TRACE_EXPORT_GRAPH, {
    nodes: [],
    edges: [],
  });
  useEffect(() => {
    actions.graphTraceExport(id);
    return () => {
      req.set(c.TRACE_EXPORT_GRAPH, {
        nodes: [],
        edges: [],
      })
    }
  }, [id]);
  return [isLoading, graph];
}
