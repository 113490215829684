/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

function FormSelect({
  name,
  onChange,
  label,
  value,
  options,
  defaultOption,
  isLoading,
  isReadOnly,
  icon, // eslint-disable-line
  ...rest
}) {
  const handleChange = ({ target }) => {
    onChange((prev) => ({ ...prev, [name]: target.value }));
  };
  return (
    <>
      {label && (
        <small>
          {label}
          {rest.required && <span className="text-danger ml-1">*</span>}
        </small>
      )}
      {isReadOnly ? (
        <p className="form-control-plaintext">
          {(options.find((item) => `${item.value}` === `${value}`) || {})
            .label || "-"}
        </p>
      ) : (
        <select
          className="form-control"
          onChange={handleChange}
          // disabled={isLoading}
          value={value || ""}
          {...rest}
        >
          {defaultOption && (
            <option value="">
              {isLoading
                ? "Loading..."
                : rest.placeholder || ` - Select ${label} - `}
            </option>
          )}
          {options.map((x) => (
            <option key={x.value} value={x.value} disabled={x.disabled}>
              {x.label}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  defaultOption: PropTypes.bool,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

FormSelect.defaultProps = {
  isReadOnly: false,
  isLoading: false,
  defaultOption: true,
  label: "",
};

export default FormSelect;
