import React from "react";
import PropTypes from "prop-types";
import FormInput from "modules/common/form/FormInput";
import FormSelect from "modules/common/form/FormSelect";

const INIT_VALUE = {
  input_type: "",
  input_mask: "",
  input_pattern: "",
  input_pattern_label: "",
};

export const QuestionInputTypeLabel = ({ value }) => {
  if (value?.input_type === "pattern") {
    return <span>(Pattern - {value?.input_pattern})</span>;
  }
  if (value?.input_type === "mask") {
    return <span>(Mask - {value?.input_mask})</span>;
  }
  if (value?.input_type === "number") {
    return <span>(Number Only)</span>;
  }
  return <span>(Text)</span>;
};

QuestionInputTypeLabel.propTypes = {
  value: PropTypes.instanceOf(Object).isRequired,
};

const QuestionInputType = ({ onChange, value }) => {
  const [form, setForm] = React.useState(value || INIT_VALUE);
  React.useEffect(() => {
    onChange((state) => ({
      ...state,
      meta: {
        ...(state?.meta || {}),
        ...form,
      },
    }));
  }, [form, onChange]);
  return (
    <>
      <div className="col">
        <FormSelect
          name="input_type"
          onChange={setForm}
          value={form.input_type || ""}
          placeholder="Text"
          options={[
            { label: "Number", value: "number" },
            { label: "Mask", value: "mask" },
            { label: "Regex Pattern", value: "pattern" },
          ]}
        />
      </div>
      {form.input_type === "mask" && (
        <div className="col">
          <FormInput
            name="input_mask"
            onChange={setForm}
            placeholder="9=[0-9] a=[A-Z,a-z] *=[A-Z,a-z,0-9]"
            value={form.input_mask || ""}
          />
        </div>
      )}
      {form.input_type === "pattern" && (
        <>
          <div className="col">
            <FormInput
              name="input_pattern"
              onChange={setForm}
              placeholder="Regex pattern"
              value={form.input_pattern || ""}
            />
          </div>
          <div className="col-12 my-2">
            <FormInput
              name="input_pattern_label"
              onChange={setForm}
              placeholder="Validation message"
              value={form.input_pattern_label || ""}
            />
          </div>
        </>
      )}
    </>
  );
};

QuestionInputType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export default React.memo(QuestionInputType);
