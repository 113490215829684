import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";

import TripsContainer from "./TripsContainer";
import TripPage from "./TripPage";

function TripRoutes() {
  const match = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Trips - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <Switch>
        <Route exact path={match.url} component={TripsContainer} />
        <Route path={`${match.url}/:id`} component={TripPage} />
      </Switch>
    </>
  );
}

export default TripRoutes;
