import React from "react";
import { motion } from "framer-motion";

import { containerVariants } from "./Installation";

import "../../styles/main.scss";

export default () => (
  <motion.div
    className="landing__how-faq-content-container"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    transition={{ duration: 1 }}
  >
    <div className="landing__how-faq-content-column-container">
      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          How can I make my account an establishment?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          Go to www.safetravelsmarianas.com and click on the establishment
          register link. Refer to the simple steps provided on the
          www.staysafemarianas.com establishment page.
        </p>
      </div>
    </div>

    <div className="landing__how-faq-content-column-container">
      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          How to activate my establishment account?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          Safe Travels Marianas will send an activation link to your email
          address provided upon registration. Click on the activation link to
          activate your account. If you are having trouble with activation,
          please contact info@safetravelsmarianas.com.
        </p>
      </div>
    </div>
  </motion.div>
);
