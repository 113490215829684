import React from "react";
import dayjs from "dayjs";
import { get } from "lodash";
import logo_horizontal from "assets/img/logo_horizontal.svg";
import bg_certificate from "assets/img/bg-certificate.png";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";

function VaccinationCertificateModal({ profile, medicalCertificateList }) {
  const medicals = medicalCertificateList.slice(-2);

  const latestCert = medicals.reduce(
    (a, b) => (new Date(a?.updated_at) > new Date(b.updated_at) ? a : b),
    {}
  );

  const dateIssued = dayjs(get(latestCert, "updated_at")).format(
    "MMM DD, YYYY"
  );

  const documentIssuance = dayjs(
    get(latestCert, "document_issuance_date")
  ).format("MMM DD, YYYY");

  return (
    <div
      className="container p-3 text-center bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${bg_certificate})` }}
    >
      <img src={logo_horizontal} alt="logo" className="mb-1" />
      <p
        className="font-weight-bold text-uppercase mt-5 mb-0"
        style={{ fontSize: 18 }}
      >
        Digital Health Certificate
      </p>
      <p className="font-weight mt-2" style={{ fontSize: 14 }}>
        {latestCert?.establishment}
      </p>
      <p
        className="font-weight text-uppercase mt-4 mb-0"
        style={{ fontSize: 18 }}
      >
        THIS IS TO CERTIFY THAT
      </p>
      <p className="font-weight-bold mt-2 mb-0" style={{ fontSize: 14 }}>
        {profile?.full_name}
      </p>
      <div className="text-center" style={{ fontSize: 14 }}>
        <p style={{ fontSize: 14 }}>
          {profile?.age} years old, {profile?.gender} was administered by{" "}
          <b>{latestCert?.establishment}</b>
          &nbsp;and undergone <b>Covid-19 Vaccination - {latestCert?.name}</b>
          &nbsp;on <b>{documentIssuance}</b>
        </p>
      </div>
      <div className="text-center mt-3 mb-5">Issued this: {dateIssued}</div>
      <div className="text-center">
        <QRCode value={documentIssuance || ""} size={116} />
      </div>
      <div
        className="font-weight-bold text-uppercase mb-3"
        style={{ fontSize: 15 }}
      >
        <div>{latestCert?.document_number}</div>
      </div>
    </div>
  );
}

VaccinationCertificateModal.defaultProps = {
  profile: {},
  medicalCertificateList: [],
};

VaccinationCertificateModal.propTypes = {
  profile: PropTypes.instanceOf(Object),
  medicalCertificateList: PropTypes.instanceOf(Array),
};

export default VaccinationCertificateModal;
