/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { showModal } from "./AlertModal";
import Lightbox from "./Lightbox";

function Img({ src, defaultSrc, lightBox, ...rest }) {
  const [url, setUrl] = React.useState(src || defaultSrc);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const handleLoad = () => {
    setImageLoaded(true);
  };
  const handleError = () => {
    setUrl(defaultSrc);
  };
  const handleOpenLightbox = () => {
    if (src === defaultSrc || !lightBox || !imageLoaded) return;
    showModal({
      title: false,
      className: "lightbox-wrapper",
      content: (onClose) => <Lightbox src={src} onClose={onClose} />,
    });
  };
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) handleOpenLightbox();
  };
  React.useEffect(() => {
    setImageLoaded(false);
    setUrl(src || defaultSrc);
  }, [src]);
  return (
    <img
      alt="thumb"
      onLoad={handleLoad}
      onError={handleError}
      onClick={handleOpenLightbox}
      onKeyUp={handleKeyUp}
      role="presentation"
      style={{ cursor: lightBox ? "pointer" : "initial" }}
      {...rest}
      src={url}
    />
  );
}

Img.propTypes = {
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  lightBox: PropTypes.bool,
};

Img.defaultProps = {
  src: "",
  defaultSrc: "https://via.placeholder.com/128?text=IMG",
  lightBox: false,
};

export default Img;
