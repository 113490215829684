import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApiGet, useApiLoading } from 'react-reqq';
import GenericTable from "modules/common/components/GenericTable";
import * as c from '../constants';

const DEFAULT_VALUE = { table: [] };

function DashboardTable({ id, label, action: actionOverride }) {
  const isLoading = useApiLoading(`${c.DASHBOARD_TABLE}/${id}`, 'get');
  const { table } = useApiGet(`${c.DASHBOARD_TABLE}/${id}`, DEFAULT_VALUE);
  const tableFormat = React.useMemo(() => {
    const columns = [
      { key: "label", label: "Label" },
      { key: "count", label: "Count", className: "text-right" },
    ];
    return columns;
  }, []);
  useEffect(() => {
    const action = actionOverride[id];
    if (action) action();
  }, [id, actionOverride]);
  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="p-3 border-b d-flex align-items-center justify-content-between">
        <div>
          <span className="font-weight-bold">{label}</span>
        </div>
      </div>
      <GenericTable
        isLoading={isLoading}
        format={tableFormat}
        data={table}
      />
    </div>
  )
}

DashboardTable.defaultProps = {
  action: {},
};

DashboardTable.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.instanceOf(Object),
}

export default DashboardTable