import React, { useEffect } from "react";
import * as actions from "../actions";

function Logout() {
  useEffect(() => {
    actions.logout();
  }, []);
  return <div className="text-center">Logging out...</div>;
}

export default Logout;
