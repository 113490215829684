import React from "react";
import PropTypes from "prop-types";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { store } from "index";
// import _ from "lodash";

const uniqueID = "my-print-marker";

export const PrintMarker = () => <div id={uniqueID} />;

const PrintWrapper = ({ content, onClose }) => {
  React.useEffect(() => {
    const prevClassName = document.body.className;
    document.body.className = "print";
    // if (true) return;
    setTimeout(() => {
      window.print();
      document.body.className = prevClassName;
      onClose();
    }, 1000);
  }, []);
  return <div className="print-content">{content()}</div>;
};

PrintWrapper.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const printElement = ({ content }) => {
  const rootElement = document.getElementById(uniqueID);
  const close = () => {
    render(<PrintMarker />, rootElement);
  };
  if (!rootElement) {
    alert("Alert Marker not found!"); // eslint-disable-line
    return;
  }
  render(
    <Provider store={store}>
      <PrintWrapper content={content} onClose={close} />
    </Provider>,
    rootElement
  );
};
