import React from "react";
// import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import ChangePasswordModal from "modules/auth/modals/ChangePasswordModal";
import { showModal } from "modules/common/components/AlertModal";
import _ from "lodash";
import { useApiGet } from "react-reqq";
import MfaQrModal from "../modals/MfaQrModal";
import DisableMfaModal from "../modals/DisableMfaModal";
import * as c from "../constants";

function SecuritySettings() {
  const location = useLocation();
  const { profile } = useApiGet(c.AUTHENTICATION, {});
  const isMfaEnabled = `${_.get(profile, "is_mfa_enabled")}` === "1";
  const handleOpenChangePasswordModal = () => {
    showModal({
      title: "Change Password",
      content: (onClose) => <ChangePasswordModal onClose={onClose} />,
    });
  };
  const handleEnableMFA = () => {
    showModal({
      title: false,
      content: (onClose) => <MfaQrModal onClose={onClose} />,
    });
  };
  const handleDisableMFA = () => {
    showModal({
      title: "Disable MFA",
      content: (onClose) => <DisableMfaModal onClose={onClose} />,
    });
  };
  React.useEffect(() => {
    if (_.get(location, "state.mfaModal")) handleEnableMFA();
  }, []);
  return (
    <div>
      <div className="text-center">
        <label className="text-gray-600">Security Settings</label>
      </div>
      <table className="table table-sm">
        <tbody>
          <tr>
            <td>Password</td>
            <td className="text-right">
              <button
                className="btn btn-sm btn-outline-secondary"
                type="button"
                onClick={handleOpenChangePasswordModal}
              >
                <i className="fas fa-key fa-sm fa-fw mr-2" /> Change
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <span className="mr-2">Multifactor Authentication (MFA):</span>
              {isMfaEnabled ? (
                <span className="badge badge-success">SECURED</span>
              ) : (
                <span className="badge badge-danger">NOT SECURED</span>
              )}
            </td>
            <td className="text-right">
              {isMfaEnabled ? (
                <button
                  className="btn btn-sm btn-outline-secondary"
                  type="button"
                  onClick={handleDisableMFA}
                >
                  <i className="fa fa-unlock fa-sm fa-fw mr-1" /> Disable
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-outline-secondary"
                  type="button"
                  onClick={handleEnableMFA}
                >
                  <i className="fa fa-lock fa-sm fa-fw mr-1" /> Enable
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

SecuritySettings.propTypes = {};

export default SecuritySettings;
