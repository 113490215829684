import { req } from "react-reqq";
import { ToastSuccess } from "modules/common/components/toast";
import { each } from "lodash";
// import { listCaseForm } from 'modules/case/actions';
// import { CASE_FORM_FILTER } from 'modules/case/constants';
import {
  payloadAssessment,
  payloadAssessmentQuestion,
  transformAssessment,
  transformAssessmentQuestion,
  transformForm,
} from "./transformers";
import * as c from "./constants";

const CASE_FORM_FILTER = "LEGACY/case_filter"; // LEGACY
const listCaseForm = () => {}; // LEGACY

export const listAssessment = (params, onSuccess) => {
  req.get({
    key: c.ASSESSMENT_LIST,
    url: "/api/v1/mng/assessments",
    params,
    transform: (res) => {
      req.set(c.ASSESSMENT_PAGER, res?.meta?.pagination || {});
      req.set(c.ASSESSMENT_FILTER, params);
      return (res.data || []).map((item) =>
        transformAssessment(item, res.included || [])
      );
    },
    onSuccess: (res) => {
      if (typeof onSuccess === "function")
        onSuccess(
          (res?.response?.data || []).map((item) =>
            transformAssessment(item, res.included || [])
          )
        );
    },
  });
};

export const selectAssessment = (id) => {
  req.get({
    key: c.ASSESSMENT_ITEM,
    url: `/api/v1/mng/assessments/${id}`,
    transform: (res) => transformAssessment(res.data || {}, res.included || []),
  });
};

export const addAssessment = (payload, onSuccess) => {
  req.post({
    key: c.ASSESSMENT_ITEM,
    url: "/api/v1/mng/assessments",
    payload: payloadAssessment(payload),
    onSuccess: (res, state) => {
      listAssessment(state[c.ASSESSMENT_FILTER]);
      ToastSuccess("Added!");
      if (onSuccess) onSuccess(res?.response?.data?.attributes?.uuid);
    },
  });
};

export const updateAssessment = (id, payload, onSuccess) => {
  req.put({
    key: c.ASSESSMENT_ITEM,
    url: `/api/v1/mng/assessments/${id}`,
    payload: payloadAssessment(payload),
    onSuccess: () => {
      ToastSuccess("Updated!");
      selectAssessment(id);
      if (onSuccess) onSuccess();
    },
  });
};

export const deleteAssessment = (id, onSuccess) => {
  req.remove({
    key: c.ASSESSMENT_ITEM,
    url: `/api/v1/mng/assessments/${id}`,
    onSuccess: (res, state) => {
      listAssessment(state[c.ASSESSMENT_FILTER]);
      req.set(c.ASSESSMENT_ITEM, null);
      ToastSuccess("Deleted!");
      if (onSuccess) onSuccess(res);
    },
  });
};

export const duplicateAssessment = (uuid, payload, onSuccess) => {
  req.post({
    key: c.ASSESSMENT_DUPLICATE,
    url: `/api/v1/mng/assessments/${uuid}/duplicate`,
    payload,
    onSuccess: (res, state) => {
      listAssessment(state[c.ASSESSMENT_FILTER]);
      ToastSuccess("You have successfully duplicated a new assessment");
      if (onSuccess) onSuccess(res?.response?.data?.attributes?.uuid);
    },
  });
};

// =====================
// QUESTIONS ===========
// =====================

export const listAssessmentQuestion = (
  assessment_id,
  params,
  onSuccess,
  onError
) => {
  req.get({
    key: c.ASSESSMENT_QUESTION_LIST,
    url: `/api/v1/mng/assessments/${assessment_id}/questions`,
    params,
    transform: (res) => {
      req.set(c.ASSESSMENT_QUESTION_PAGER, res?.meta?.pagination || {});
      req.set(c.ASSESSMENT_QUESTION_FILTER, params);
      return (res.data || []).map((r, sequence) =>
        transformAssessmentQuestion(r, res.included || [], sequence + 1)
      );
    },
    onSuccess,
    onError,
  });
};

export const selectAssessmentQuestion = (assessment_id, id) => {
  req.get({
    key: c.ASSESSMENT_QUESTION_ITEM,
    url: `/api/v1/mng/assessments/${assessment_id}/questions/${id}`,
    transform: (res) =>
      transformAssessmentQuestion(res.data || {}, res.included || []),
  });
};

export const addAssessmentQuestion = (assessment_id, payload, onSuccess) => {
  req.post({
    key: c.ASSESSMENT_QUESTION_ITEM,
    url: `/api/v1/mng/assessments/${assessment_id}/questions`,
    payload: payloadAssessmentQuestion(payload),
    onSuccess: (res, state) => {
      listAssessmentQuestion(
        assessment_id,
        state[c.ASSESSMENT_QUESTION_FILTER]
      );
      ToastSuccess("Added!");
      if (onSuccess) onSuccess(res?.response?.data?.attributes?.uuid);
    },
  });
};

export const duplicateAssessmentQuestion = (
  assessment_id,
  question_uuid,
  payload,
  onSuccess
) => {
  req.post({
    key: c.ASSESSMENT_QUESTION_ITEM,
    url: `/api/v1/mng/assessments/${assessment_id}/questions/${question_uuid}/duplicate`,
    payload,
    onSuccess: (res, state) => {
      listAssessmentQuestion(
        assessment_id,
        state[c.ASSESSMENT_QUESTION_FILTER]
      );
      ToastSuccess("Duplicated!");
      if (onSuccess) onSuccess(res?.response?.data?.attributes?.uuid);
    },
  });
};

export const appendAssessmentQuestion = (assessment_id, payload, onSuccess) => {
  req.post({
    key: c.ASSESSMENT_QUESTION_ITEM,
    url: `/api/v1/mng/assessments/${assessment_id}/append-questions`,
    payload: payloadAssessmentQuestion(payload),
    onSuccess: (res, state) => {
      listAssessmentQuestion(
        assessment_id,
        state[c.ASSESSMENT_QUESTION_FILTER]
      );
      ToastSuccess("Added!");
      if (onSuccess) onSuccess(res?.response?.data?.attributes?.uuid);
    },
  });
};

export const updateAssessmentQuestion = (
  assessment_id,
  id,
  payload,
  onSuccess
) => {
  req.put({
    key: `${c.ASSESSMENT_QUESTION_ITEM}/${id}`,
    url: `/api/v1/mng/assessments/${assessment_id}/questions/${id}`,
    payload: payloadAssessmentQuestion(payload),
    onSuccess: (res, state) => {
      // ToastSuccess('Saved!');
      // selectAssessmentQuestion(assessment_id, id);
      listAssessmentQuestion(
        assessment_id,
        state[c.ASSESSMENT_QUESTION_FILTER]
      );
      if (onSuccess) onSuccess();
    },
  });
};

export const updateAssessmentQuestionSequence = (
  assessment_id,
  id,
  payload,
  onSuccess
) => {
  req.put({
    key: `${c.ASSESSMENT_QUESTION_ITEM}/${id}`,
    url: `/api/v1/mng/assessments/${assessment_id}/questions/${id}`,
    payload: payloadAssessmentQuestion(payload),
    onSuccess: () => {
      // ToastSuccess('Saved!');
      if (onSuccess) onSuccess();
    },
  });
};

export const updateQuestionUp = (assessment_id, row) => {
  req.set(c.ASSESSMENT_QUESTION_LIST, (state) => {
    const newList = [...(state[c.ASSESSMENT_QUESTION_LIST] || [])];
    const index = newList.map((q) => q.id).indexOf(row?.id);
    const newIndex = index - 1;
    if (!newList[newIndex]) return state[c.ASSESSMENT_QUESTION_LIST];

    [newList[index].sequence, newList[newIndex].sequence] = [
      newList[newIndex].sequence,
      newList[index].sequence,
    ];

    [newList[index], newList[newIndex]] = [newList[newIndex], newList[index]];

    setTimeout(() => {
      updateAssessmentQuestionSequence(
        assessment_id,
        newList[index].id,
        newList[index]
      );
      updateAssessmentQuestionSequence(
        assessment_id,
        newList[newIndex].id,
        newList[newIndex]
      );
    }, 250);
    return newList;
  });
};

export const updateQuestionDown = (assessment_id, row) => {
  req.set(c.ASSESSMENT_QUESTION_LIST, (state) => {
    const newList = [...(state[c.ASSESSMENT_QUESTION_LIST] || [])];
    const index = newList.map((q) => q.id).indexOf(row?.id);
    const newIndex = index + 1;
    if (!newList[newIndex]) return state[c.ASSESSMENT_QUESTION_LIST];

    [newList[index].sequence, newList[newIndex].sequence] = [
      newList[newIndex].sequence,
      newList[index].sequence,
    ];

    [newList[index], newList[newIndex]] = [newList[newIndex], newList[index]];

    setTimeout(() => {
      updateAssessmentQuestionSequence(
        assessment_id,
        newList[index].id,
        newList[index]
      );
      updateAssessmentQuestionSequence(
        assessment_id,
        newList[newIndex].id,
        newList[newIndex]
      );
    }, 250);
    return newList;
  });
};

export const deleteAssessmentQuestion = (assessment_id, id, onSuccess) => {
  req.remove({
    key: c.ASSESSMENT_QUESTION_ITEM,
    url: `/api/v1/mng/assessments/${assessment_id}/questions/${id}`,
    onSuccess: (res, state) => {
      listAssessmentQuestion(
        assessment_id,
        state[c.ASSESSMENT_QUESTION_FILTER]
      );
      req.set(c.ASSESSMENT_QUESTION_ITEM, null);
      ToastSuccess("Deleted!");
      if (onSuccess) onSuccess(res);
    },
  });
};

// =====================
// FORM ================
// =====================

export const selectForm = (id, onSuccess, onError) => {
  req.get({
    key: c.FORM_ITEM,
    url: `/api/v1/mng/forms/${id}`,
    transform: (res) => transformForm(res.data || {}, res.included || []),
    onSuccess,
    onError,
  });
};

export const countryDataset = (dataset, onSuccess, onError) => {
  req.get({
    key: `${dataset}_DATASET`,
    url: `/api/v1/datasets/${dataset}`,
    transform: (res) => {
      const newOptions = [];
      each(res, (v) => {
        newOptions.push({
          label: v,
          value: v,
        });
      });
      return newOptions;
    },
    onSuccess,
    onError,
  });
};

export const selectFormAnswer = (id, onSuccess, onError) => {
  req.get({
    key: c.FORM_ANSWERS,
    url: `/api/v1/mng/forms/${id}/answers`,
    transform: (res) => {
      const ans = {};
      each(res.data || [], (row) => {
        ans[row?.attributes?.assessment_question_uuid] =
          row?.attributes?.answer || {};
      });
      return ans;
    },
    onSuccess,
    onError,
  });
};

export const selectFormSubmit = (form_id, onSuccess) => {
  req.put({
    key: c.FORM_SUBMIT,
    url: `/api/v1/mng/forms/${form_id}/submit`,
    onSuccess: (res, state) => {
      ToastSuccess("Submitted!");
      selectForm(form_id);
      listCaseForm(state[CASE_FORM_FILTER]);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const selectFormResubmit = (form_id, onSuccess) => {
  req.put({
    key: c.FORM_RESUBMIT,
    url: `/api/v1/mng/forms/${form_id}/resubmit`,
    onSuccess: (res, state) => {
      ToastSuccess("Resubmit!");
      selectForm(form_id);
      listCaseForm(state[CASE_FORM_FILTER]);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const detatchForm = (form_id, onSuccess) => {
  req.remove({
    key: c.FORM_RESUBMIT,
    url: `/api/v1/mng/forms/${form_id}`,
    onSuccess: (res, state) => {
      ToastSuccess("Deleted!");
      req.set(c.FORM_ITEM, null);
      listCaseForm(state[CASE_FORM_FILTER]);
      if (onSuccess) onSuccess(res);
    },
  });
};
