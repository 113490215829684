import React from "react";
import PropTypes from "prop-types";
import { each } from "lodash";
import FormSurveyOptions from "../components/FormSurveyOptions";
import FormSurveyFieldOptions from "../components/FormSurveyFieldOptions";
import { assessmentGraph } from "../actions";

function CreateChartReport({ setField, setChart, defaultForm, onClose }) {
  const [form, setForm] = React.useState({
    report_type: "",
    specified_columns: "",
    ...defaultForm,
  });
  const handleExport = () => {
    assessmentGraph(form?.specified_columns?.value, (data) => {
      const chart = [];
      each(data, (v, k) => {
        chart.push({
          id: k,
          label: k,
          value: v,
        });
      });
      const keys = ["count"];
      setField(form?.specified_columns);
      setChart({ chart, keys });
      onClose();
    });
  };

  return (
    <div className="modal-body">
      <div className="w-full">
        <div className="w-full py-2">
          <span>Select Special Survey *</span>
          <FormSurveyOptions
            name="report_type"
            onChange={setForm}
            value={form?.report_type}
          />
        </div>
        <div className="w-full py-2">
          <span>Fields *</span>
          <FormSurveyFieldOptions
            name="specified_columns"
            onChange={setForm}
            value={form?.specified_columns}
            report_type={form?.report_type}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary mr-1"
          type="button"
          onClick={handleExport}
        >
          Add
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

CreateChartReport.defaultProps = {
  defaultForm: {},
};

CreateChartReport.propTypes = {
  defaultForm: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  setChart: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
};

export default CreateChartReport;
