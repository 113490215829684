import React from "react";
import _ from "lodash";
import { Route, Switch } from "react-router-dom";
import { useAuth } from "modules/auth/hooks";
import BrowserInfo from "modules/common/components/BrowserInfo";
import ConnectionInfo from "modules/common/components/ConnectionInfo";
import { AlertMarker } from "modules/common/components/Alert";
import { ModalMarker } from "modules/common/components/AlertModal";
import { PrintMarker } from "modules/common/components/Print";
import ShareRedirect from "modules/common/components/ShareRedirect";
import { AddressMapMarker } from "modules/common/form/FormAddressMap";
import Toast from "modules/common/components/toast";
import TraceExportForm from "./modules/trace-export/form/TraceExportForm";
import Private from "./Private";
import Public from "./Public";

function App() {
  const auth = useAuth();
  if (_.isEmpty(auth)) return null;
  return (
    <>
      <PrintMarker />
      <BrowserInfo />
      <ConnectionInfo />
      <Toast />
      <ModalMarker />
      <AlertMarker />
      <AddressMapMarker />
      <input id="clipboard-input" />
      <Switch>
        <Route
          exact
          path="/trace-export-print/:id"
          component={TraceExportForm}
        />
        <Route exact path="/report-print/:id" component={TraceExportForm} />
        <Route exact path="/share" component={ShareRedirect} />
        <Route>{auth.isAuthenticated ? <Private /> : <Public />}</Route>
      </Switch>
    </>
  );
}

export default React.memo(App);
