import icon_marker_black from "assets/img/markers/black-marker.png";
import icon_marker_blue from "assets/img/markers/blue-marker.png";
import icon_marker_green from "assets/img/markers/green-marker.png";
import icon_marker_orange from "assets/img/markers/orange-marker.png";
import icon_marker_red from "assets/img/markers/red-marker.png";
import icon_marker_violet from "assets/img/markers/violet-marker.png";
import icon_marker_white from "assets/img/markers/white-marker.png";
import icon_marker_yellow from "assets/img/markers/yellow-marker.png";

import icon_circle_black from "assets/img/markers/black-circle.png";
import icon_circle_blue from "assets/img/markers/blue-circle.png";
import icon_circle_green from "assets/img/markers/green-circle.png";
import icon_circle_orange from "assets/img/markers/orange-circle.png";
import icon_circle_red from "assets/img/markers/red-circle.png";
import icon_circle_violet from "assets/img/markers/violet-circle.png";
import icon_circle_white from "assets/img/markers/white-circle.png";
import icon_circle_yellow from "assets/img/markers/yellow-circle.png";

import icon_marker_xs_black from "assets/img/markers/black-marker-xs.png";
import icon_marker_xs_blue from "assets/img/markers/blue-marker-xs.png";
import icon_marker_xs_green from "assets/img/markers/green-marker-xs.png";
import icon_marker_xs_orange from "assets/img/markers/orange-marker-xs.png";
import icon_marker_xs_red from "assets/img/markers/red-marker-xs.png";
import icon_marker_xs_violet from "assets/img/markers/violet-marker-xs.png";
import icon_marker_xs_white from "assets/img/markers/white-marker-xs.png";
import icon_marker_xs_yellow from "assets/img/markers/yellow-marker-xs.png";

import icon_case_status_black from "assets/img/status/graphics-status-black.svg";
import icon_case_status_blue from "assets/img/status/graphics-status-blue.svg";
import icon_case_status_green from "assets/img/status/graphics-status-green.svg";
// import icon_case_status_orange from "assets/img/status/graphics-status-orange.svg";
import icon_case_status_red from "assets/img/status/graphics-status-red.svg";
import icon_case_status_violet from "assets/img/status/graphics-status-violet.svg";
import icon_case_status_white from "assets/img/status/graphics-status-white.svg";
import icon_case_status_yellow from "assets/img/status/graphics-status-yellow.svg";

import icon_location_default from "assets/img/markers/pin-places-others.svg";

export const MARKER_TYPES = [
  "black",
  "blue",
  "green",
  "orange",
  "red",
  "violet",
  "white",
  "yellow",
];

export const MAP_CASE_ICON = {
  'TRAVELER PCR': icon_case_status_yellow,
  'CONFIRMED POSITIVE': icon_case_status_violet,
  CONFIRMED: icon_case_status_violet,
  CLEARED: icon_case_status_green,
  SUSPECT: icon_case_status_red,
  PROBABLE: icon_case_status_yellow,
  DEFAULT: icon_case_status_white,
};

export const CASE_ICON = {
  black: icon_case_status_black,
  blue: icon_case_status_blue,
  green: icon_case_status_green,
  orange: icon_case_status_yellow, // icon_case_status_orange,
  red: icon_case_status_red,
  violet: icon_case_status_violet,
  white: icon_case_status_white,
  yellow: icon_case_status_yellow,
};

export const MARKER_ICON = {
  black: icon_marker_black,
  blue: icon_marker_blue,
  green: icon_marker_green,
  orange: icon_marker_orange,
  red: icon_marker_red,
  violet: icon_marker_violet,
  white: icon_marker_white,
  yellow: icon_marker_yellow,
};

export const MARKERS_XS_ICON = {
  black: icon_marker_xs_black,
  blue: icon_marker_xs_blue,
  green: icon_marker_xs_green,
  orange: icon_marker_xs_orange,
  red: icon_marker_xs_red,
  violet: icon_marker_xs_violet,
  white: icon_marker_xs_white,
  yellow: icon_marker_xs_yellow,
};

export const CIRCLE_XS_ICON = {
  black: icon_circle_black,
  blue: icon_circle_blue,
  green: icon_circle_green,
  orange: icon_circle_orange,
  red: icon_circle_red,
  violet: icon_circle_violet,
  white: icon_circle_white,
  yellow: icon_circle_yellow,
};

export const LOCATION = {
  default: icon_location_default,
};
