import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import logo_h from "assets/img/logo_horizontal.svg";
import banner from "assets/img/graphic-company.svg";
// import Register from './Register';

import Register from "./Register";

function RegisterContainer() {
  return (
    <>
      <Helmet>
        <title>Request Access - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="login-account">
        <div className="container-fluid h-100 row">
          <div className="col-6 d-flex flex-column justify-content-center align-items-center">
            <div className="w-100">
              <div className="p-5">
                <img className="w-100 img-fluid" src={banner} alt="Banner" />
              </div>
              <div className="login-form mx-auto">
                <div className="mb-2">
                  <Link
                    className="btn btn-outline-primary btn-block"
                    to="/signin"
                  >
                    Go to Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 d-flex flex-column justify-content-center align-items-center">
            <div className="mb-4">
              <img
                className="mx-auto"
                src={logo_h}
                alt="StaySafe"
                style={{ width: 250 }}
              />
            </div>
            <div className="card">
              <div className="card-body">
                <h4>Request Access - For Companies and Organizations</h4>
                <Register />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

RegisterContainer.propTypes = {};

export default RegisterContainer;
