import React from "react";
import { req } from "react-reqq";
import PropTypes from "prop-types";
import { debounce, get } from "lodash";
import AsyncSelect from "react-select/async";
import { flightTypeList } from "../actions";

const transformOptions = (raw) => {
  return {
    label: raw?.attributes?.title,
    value: raw?.attributes?.title,
    data: raw,
  };
};

function FormFlightOptions({ name, onChange, value }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleChange = (e) => {
    onChange((state) => ({
      ...state,
      [name]: e,
    }));
  };

  const loadTripOptions = React.useCallback(
    debounce((search, callback) => {
      setIsLoading(true);
      req.get({
        key: "SEARCH_FLIGHT_TYPE",
        url: "/api/v1/mng/datasets",
        params: { keyword: search, per_page: 15, code: "AIRLINE_VESSEL_TYPE" },
        onSuccess: (res) => {
          setIsLoading(false);
          const newOptions = (get(res, "response.data") || []).map(
            transformOptions
          );
          callback(newOptions);
        },
      });
    }, 500),
    []
  );

  React.useEffect(() => {
    flightTypeList();
  }, []);

  return (
    <AsyncSelect
      placeholder="Search flight type..."
      isLoading={isLoading}
      cacheOptions
      onChange={handleChange}
      noOptionsMessage={() => "Type(Name) to search..."}
      defaultOptions
      // defaultOptions={transformOptions(list)}
      loadOptions={loadTripOptions}
      value={value}
      isClearable
    />
  );
}

FormFlightOptions.defaultProps = {
  value: null,
};

FormFlightOptions.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default FormFlightOptions;
