import { createContext } from "react";

export const QuestionsContext = createContext();

export const ASSESSMENT_LIST = "ASSESSMENT/list";
export const ASSESSMENT_PAGER = "ASSESSMENT/pager";
export const ASSESSMENT_FILTER = "ASSESSMENT/filter";
export const ASSESSMENT_ITEM = "ASSESSMENT/item";
export const ASSESSMENT_DUPLICATE = "ASSESSMENT/duplicate";

export const ASSESSMENT_QUESTION_LIST = "ASSESSMENT_QUESTION/list";
export const ASSESSMENT_QUESTION_PAGER = "ASSESSMENT_QUESTION/pager";
export const ASSESSMENT_QUESTION_FILTER = "ASSESSMENT_QUESTION/filter";
export const ASSESSMENT_QUESTION_ITEM = "ASSESSMENT_QUESTION/item";

export const FORM_ITEM = "FORM/item";
export const FORM_ANSWERS = "FORM/answers";
export const FORM_SUBMIT = "FORM/submit";
export const FORM_RESUBMIT = "FORM/form_resubmit";

export const STATUS_OPTIONS = [
  { label: "DRAFT", value: "DRAFT" },
  { label: "PUBLISHED", value: "PUBLISHED" },
];
export const DATASET_OPTIONS = [
  { label: "COUNTRY", value: "COUNTRY" },
  { label: "ETHNICITY", value: "ETHNICITY" },
  { label: "OCCUPATION", value: "OCCUPATION" },
  { label: "ISLAND", value: "ISLAND" },
  { label: "AIRLINES", value: "AIRLINES" },
  { label: "VESSEL", value: "VESSEL" },
];

export const NONE_LIST = ["none of the above", "none"];
