import React from "react";
// import PropTypes from 'prop-types';
import { useLocation, useHistory, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { useApiLoading } from "react-reqq";
import FormInput from "modules/common/form/FormInput";
import { ToastError } from "modules/common/components/toast";
import qs from "query-string";
import * as actions from "../actions";
import * as c from "../constants";

// const wait = (t) => new Promise((r) => setTimeout(r, t));

function ResetPassword() {
  const location = useLocation();
  const history = useHistory();
  const isLoading = useApiLoading(c.RESET_PASSWORD, "post");
  const [form, setForm] = React.useState({
    password: "",
    password_confirmation: "",
  });
  React.useEffect(() => {
    const init = async () => {
      const { token, email } = qs.parse(location.search);
      if (isEmpty(token || "") || isEmpty(token || "")) {
        ToastError("Reset Password link is invalid or expired!");
        history.push("/");
        return;
      }
      setForm((state) => ({
        ...state,
        token,
        email,
      }));
    };
    init();
  }, [location.search]);
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.resetPassword(form, () => {
      history.push("/");
    });
  };
  return (
    <div className="row">
      <div className="col-md-4 offset-md-4 col-12">
        <form
          className="mt-5 rounded border shadow p-3"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <FormInput
              name="password"
              label="Password"
              onChange={setForm}
              value={form.password}
              type="password"
            />
          </div>
          <div className="form-group">
            <FormInput
              name="password_confirmation"
              label="Password Confirmation"
              onChange={setForm}
              value={form.password_confirmation}
              type="password"
            />
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              Reset Password
            </button>
          </div>
          <div className="text-center mt-3">
            <Link className="btn btn-link" to="/">
              Cancel and go back to home.
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

ResetPassword.propTypes = {};

export default ResetPassword;
