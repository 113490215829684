export const INIT_STATE = {
  title: "",
  description: "",
  sort_priority: "1",
  status: "PUBLISHED",
};

export const DATASET = "DATASET";

export const DATASET_STATUS = {
  PUBLISHED: { label: "Published", value: "PUBLISHED" },
  DRAFTED: { label: "Draft", value: "DRAFTED" },
};

export const DATASET_STATUS_OPTIONS = Object.values(DATASET_STATUS);
