import React from "react";
import PropTypes from "prop-types";
import GoogleMaps from "modules/dashboard/maps/GoogleMaps";
// import { req } from "react-reqq";
import _ from "lodash";

function useOnClickOutside(ref, onClick) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      const other =
        document.getElementsByClassName("pac-container pac-logo") || [];
      if (ref.current && !ref.current.contains(event.target)) {
        if (other[0] && other[0]?.contains(event.target)) return;
        onClick(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);
}

const DEFAULT_VIEW = {
  zoom: +10,
  center: {
    lat: 15.202316,
    lng: 145.7089154,
  },
};

const MAP_OPTIONS = {
  scaleControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};

function FormAddressMap2({ onChange, value }) {
  const wrapperRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  useOnClickOutside(wrapperRef, setShow);
  const [form, setForm] = React.useState({
    address: value.address || "",
    geoloc: value.geoloc || "",
    region: value.region || "",
    province: value.province || "",
    municipality: value.municipality || "",
    barangay: value.barangay || "",
  });
  const change = React.useCallback(
    _.debounce((newForm) => {
      onChange(_.omit(newForm, ['address']));
    }, 200),
    [onChange]
  );
  React.useEffect(() => {
    change(form);
  }, [form]);
  // const handleChange = ({ target }) => {
  //   setForm((state) => ({
  //     ...state,
  //     address: target.value,
  //   }));
  // };
  const [isLoading, setIsLoading] = React.useState(false);
  const getCodes = React.useCallback(
    _.debounce((lat, lng) => {
      // eslint-disable-next-line
      console.log('LOCATION', lat, lng);
      setIsLoading(false);
      // req.get({
      //   key: "GET_ADDRESS_CODES",
      //   url: () => "https://geojson.multidemos.com/get-address-codes",
      //   params: {
      //     geoloc: `${lat},${lng}`,
      //   },
      //   onSuccess: (res) => {
      //     const newCodes = _.get(res, "response.codes");
      //     setForm((state) => ({
      //       ...state,
      //       region: (newCodes?.region?.ADM1_PCODE || "").replace("PH", ""),
      //       province: (newCodes?.province?.ADM2_PCODE || "").replace("PH", ""),
      //       municipality: (newCodes?.municipality?.ADM3_PCODE || "").replace(
      //         "PH",
      //         ""
      //       ),
      //       barangay: (newCodes?.barangay?.ADM4_PCODE || "").replace("PH", ""),
      //     }));
      //     setIsLoading(false);
      //   },
      //   onError: () => {
      //     setIsLoading(true);
      //   },
      // });
    }, 2000),
    [setIsLoading]
  );
  const handleMapChange = (e) => {
    const { lat, lng } = e.center.toJSON();
    setIsLoading(true);
    getCodes(lat, lng);
    setForm((state) => ({
      ...state,
      geoloc: `${lat},${lng}`,
    }));
  };
  const handleSearch = (e) => {
    const elem = document.getElementById("map-searchbox");
    setForm((state) => ({
      ...state,
      address: elem?.value || e.formatted_address || state.address || "",
    }));
  };
  const defaultView = React.useMemo(
    () => ({
      zoom: !_.isEmpty(value.geoloc) ? 15 : DEFAULT_VIEW.zoom,
      center: {
        lat: +(value.geoloc || "").split(",")[0] || DEFAULT_VIEW.center.lat,
        lng: +(value.geoloc || "").split(",")[1] || DEFAULT_VIEW.center.lng,
      },
    }),
    [value]
  );
  return (
    <div ref={wrapperRef} className="position-relative">
      <div>
        {isLoading && (
          <div
            className="position-absolute"
            style={{ zIndex: 1, right: 10, top: 8 }}
          >
            <i className="fa fa-spin fa-spinner" />
          </div>
        )}
        <input
          className="form-control"
          onChange={() => {}}
          placeholder="Click to open address picker"
          // onChange={handleChange}
          onClick={() => {
            setShow(true);
            setTimeout(() => {
              const elem = document.getElementById("map-searchbox");
              elem.focus();
            }, 200);
          }}
          value={form.geoloc}
        />
      </div>
      <div
        className={`dropdown-menu shadow animated--grow-in${
          show ? " show" : ""
        }`}
        style={{ top: 0 }}
      >
        <div className="position-relative" style={{ width: 480, height: 320 }}>
          <div className="map-center-pin" />
          <GoogleMaps
            view={defaultView}
            withSearch
            searchBoxProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              },
            }}
            onSearch={handleSearch}
            onDragEnd={handleMapChange}
            options={MAP_OPTIONS}
          />
        </div>
      </div>
    </div>
  );
}

FormAddressMap2.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export default FormAddressMap2;
