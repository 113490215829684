import { useEffect } from "react";
import { req, useApiGet, useApiLoading } from "react-reqq";
import {
  listAssessment,
  selectAssessment,
  listAssessmentQuestion,
  selectForm,
  selectFormAnswer,
} from "./actions";
import * as c from "./constants";

export const useAssessmentList = (params, onSuccess) => {
  const isLoading = useApiLoading(c.ASSESSMENT_LIST, "get");
  const list = useApiGet(c.ASSESSMENT_LIST, []);
  const pager = useApiGet(c.ASSESSMENT_PAGER, {});
  useEffect(() => {
    listAssessment(params, onSuccess);
  }, []);
  return [isLoading, list, pager];
};

export const useAssessmentById = (id) => {
  const isLoading = useApiLoading(c.ASSESSMENT_ITEM, "get");
  const data = useApiGet(c.ASSESSMENT_ITEM, {});
  useEffect(() => {
    if (!id) return;
    selectAssessment(id);
  }, [id]);
  return [isLoading, data];
};

export const useAssessmentQuestionList = (id, params) => {
  const isLoading = useApiLoading(c.ASSESSMENT_QUESTION_LIST, "get");
  const list = useApiGet(c.ASSESSMENT_QUESTION_LIST, []);
  const pager = useApiGet(c.ASSESSMENT_QUESTION_PAGER, {});
  useEffect(() => {
    listAssessmentQuestion(id, params);
    return () => {
      req.set(c.ASSESSMENT_QUESTION_LIST, []);
      req.set(c.ASSESSMENT_QUESTION_PAGER, {});
    };
  }, [id, params]);
  return [isLoading, list, pager];
};

export const useAssessmentFormById = (id) => {
  const isLoading = useApiLoading(c.FORM_ITEM, "get");
  const data = useApiGet(c.FORM_ITEM, {});
  useEffect(() => {
    if (!id) return;
    selectForm(id);
  }, [id]);
  return [isLoading, data];
};

export const useAssessmentFormAnswers = (id) => {
  const isLoading = useApiLoading(c.FORM_ANSWERS, "get");
  const data = useApiGet(c.FORM_ANSWERS, {});
  useEffect(() => {
    if (!id) return;
    selectFormAnswer(id);
  }, [id]);
  return [isLoading, data];
};
