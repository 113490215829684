import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import FormDatePicker from "modules//common/form/FormDatePicker";
import FormInput from "modules/common/form/FormInput";
import FormFieldOptions from "../components/FormFieldOptions";
import FormFlightOptions from "../components/FormFlightOptions";
import FormOriginOptions from "../components/FormOriginOptions";
import { useGenerateReports } from "../hooks";
import { listReports } from "../actions";

function GenerateReports({ defaultForm, onClose }) {
  const [isLoading, generate] = useGenerateReports();
  const [form, setForm] = React.useState({
    file_name: "",
    date_of_arrival_from: "",
    date_of_arrival_to: "",
    origin_country: "",
    flight_type: "",
    specified_columns: [],
    ...defaultForm,
  });
  const handleExport = () => {
    const payload = {
      file_name: form?.file_name || "",
      date_of_arrival_from: form?.date_of_arrival_from || "",
      date_of_arrival_to: form?.date_of_arrival_to || "",
      specified_columns: form?.specified_columns || [],
      origin_country: form?.origin_country?.value || "",
      flight_type: form?.flight_type?.value || "",
    };

    generate(_.omitBy(payload, _.isEmpty), onClose);
  };
  const handleFilter = () => {
    const payload = {
      file_name: form?.file_name || "",
      date_of_arrival_from: form?.date_of_arrival_from || "",
      date_of_arrival_to: form?.date_of_arrival_to || "",
      specified_columns: form?.specified_columns || [],
      origin_country: form?.origin_country?.value || "",
      flight_type: form?.flight_type?.value || "",
    };
    listReports({ ..._.omitBy(payload, _.isEmpty), page: 1 });
  };

  const minValue = useMemo(() => {
    if (!form?.date_of_arrival_from) return null;
    return moment(form?.date_of_arrival_from).format("MM-DD-YYYY");
  }, [form?.date_of_arrival_from]);

  return (
    <div className="modal-body">
      <div className="w-full">
        <div className="w-full py-2">
          <FormFlightOptions
            name="flight_type"
            onChange={setForm}
            value={form?.flight_type}
          />
        </div>
        <div className="w-full py-2">
          <FormOriginOptions
            name="origin_country"
            onChange={setForm}
            value={form?.origin_country}
          />
        </div>
        <div className="w-full py-2">
          <FormInput
            name="file_name"
            className="form-control"
            onChange={setForm}
            value={form?.file_name}
            placeholder="Enter file name"
          />
        </div>
        <div className="w-full py-2">
          <div className="input-group mb-1">
            <div className="mr-3 input-group my-1">
              <div className="input-group-prepend">
                <div className="input-group-text" style={{ width: 110 }}>
                  <small>Arrival Date From</small>
                </div>
              </div>
              <FormDatePicker
                name="date_of_arrival_from"
                onChange={setForm}
                value={form?.date_of_arrival_from}
              />
            </div>
            <div className="mr-3 input-group my-1">
              <div className="input-group-prepend">
                <div className="input-group-text" style={{ width: 110 }}>
                  <small>Arrival Date To</small>
                </div>
              </div>
              <FormDatePicker
                name="date_of_arrival_to"
                onChange={setForm}
                value={form?.date_of_arrival_to}
                minDate={new Date(minValue)}
              />
            </div>
          </div>
        </div>
        <div className="w-full py-2">
          <FormFieldOptions name="specified_columns" onChange={setForm} />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary mr-1"
          type="button"
          disabled={isLoading}
          onClick={handleFilter}
        >
          {isLoading ? "Please wait..." : "Filter"}
        </button>
        <button
          className="btn btn-primary mr-1"
          type="button"
          disabled={isLoading}
          onClick={handleExport}
        >
          {isLoading ? "Please wait..." : "Generate"}
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

GenerateReports.defaultProps = {
  defaultForm: {},
};

GenerateReports.propTypes = {
  defaultForm: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
};

export default GenerateReports;
