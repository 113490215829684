import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { configureApi } from "react-reqq";
import _ from "lodash";
import Cookie from "js-cookie";
import App from "App";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-virtualized/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "assets/sb-admin-2/scss/sb-admin-2.scss";
// import 'assets/bootstrap/scss/bootstrap.scss';
import "assets/fontawesome/css/all.min.css";
import "assets/user-style.scss";

import { ToastError } from "modules/common/components/toast";

export const history = createBrowserHistory();

const getErrorMessage = (err) => {
  try {
    const errKeys = Object.keys(_.get(err, "response.errors"));
    return (_.get(err, `response.errors` || {})[errKeys[0]] || [])[0];
  } catch (error) {
    return null;
  }
};

export const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = Cookie.get("token");
    if (!token) return {};
    return {
      Authorization: `Bearer ${token}`,
    };
  },
  timeout: 180000,
  cacheStorage: "LOCALSTORAGE",
  onError: (err) => {
    const err_message = _.get(err, "response.message");
    if (err.status === 422) {
      const message = getErrorMessage(err);
      ToastError(message || "Unprocessable Entity!");
      return;
    }
    if (err.status === 400) {
      ToastError(err_message || "Bad Request!");
      return;
    }
    if (err.status === 401) {
      ToastError(err_message || "Unauthorized!");
      if (_.get(err, "response.error") === "auth_token_expired") {
        history.push("/logout");
      }
      return;
    }
    if (err.status === 500) {
      ToastError(err_message || "Internal Server Error!");
      return;
    }
    if (err.status === 403) {
      ToastError(err_message || "Forbidden!");
      if (
        _.get(err, "response.error") === "token_expired" ||
        _.get(err, "response.error") === "token_invalid"
      )
        history.push("/logout");
      return;
    }
    if (err.status === 0) {
      ToastError("Unable to connect to web service");
      return;
    }
    console.warn("unhandled error", err); // eslint-disable-line
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
