/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import { formatDate } from "../helper";

function FormDatePicker({ name, onChange, label, value, isReadOnly, ...rest }) {
  const handleSetDate = (date) => {
    onChange((state) => ({
      ...state,
      [name]: moment(date).format("YYYY-MM-DD"),
    }));
  };
  return (
    <>
      {label && (
        <small>
          <span>
            <span className="text-nowrap">
              {label}
              {rest.required && <span className="text-danger ml-1">*</span>}
            </span>
            <small className="text-muted ml-2">(MM/DD/YYYY)</small>
          </span>
        </small>
      )}
      {isReadOnly ? (
        <p className="form-control-plaintext">{formatDate(value) || "-"}</p>
      ) : (
        <DatePicker
          className="form-control"
          onChange={handleSetDate}
          value={value}
          {...rest}
        />
      )}
    </>
  );
}

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

FormDatePicker.defaultProps = {
  label: "",
  isReadOnly: false,
};

export default FormDatePicker;
