import React from "react";
// import PropTypes from 'prop-types';
import { useApiGet } from "react-reqq";
// import { isEmpty } from "lodash";
import Img from "modules/common/components/Img";
import GenericDetail from "modules/common/components/GenericDetail";
import { showModal } from "modules/common/components/AlertModal";
// import QRCode from "qrcode.react";
// import { printElement } from "modules/common/components/Print";
import ProfileModal from "../modals/ProfileModal";
import { getProfile } from "../actions";
import { AUTHENTICATION, ADMIN_ACCESS_KEY } from "../constants";

const thumbs = "https://via.placeholder.com/128?text=LOGO";

function Profile() {
  const { profile } = useApiGet(AUTHENTICATION, {});
  const logo = profile.logo || thumbs;
  const isAdmin = profile.access_control === ADMIN_ACCESS_KEY;
  const handleOpenEdit = () => {
    showModal({
      title: "Edit Profile",
      content: (onClose) => <ProfileModal data={profile} onClose={onClose} />,
    });
  };
  // const handlePrintQr = (e) => {
  //   e.preventDefault();
  //   printElement({
  //     content: () => (
  //       <div style={{ width: 192 }}>
  //         <QRCode value={profile.uuid} size={192} />
  //         <div className="text-center font-weight-bold">
  //           <div>{profile.full_name}</div>
  //           <div>{profile.branch || "Branch"}</div>
  //         </div>
  //       </div>
  //     ),
  //   });
  // };
  React.useEffect(() => {
    getProfile();
  }, []);
  return (
    <div>
      <div className="text-center">
        <label className="text-gray-600">Profile</label>
      </div>
      <div className="mb-2 d-flex justify-content-center">
        <div className="mr-1" style={{ width: 128 }}>
          <Img
            className="img w-100 img-fluid border p-1 rounded shadow-sm"
            src={logo}
            defaultSrc={thumbs}
          />
        </div>
        {/* profile.uuid ? (
          <div className="text-center" style={{ width: 128 }}>
            <QRCode value={profile.uuid} size={128} />
            <a href="/" onClick={handlePrintQr}>
              <i className="fa fa-print mr-1" />
              Print QR
            </a>
          </div>
        ) : (
          <span />
        ) */}
      </div>
      <div className="mb-2">
        <GenericDetail
          labelWidth="120px"
          format={[
            {
              key: "full_name",
              label: "Access Name",
              className: "text-uppercase",
            },
            // {
            //   key: "branch",
            //   label: "Branch",
            //   className: "text-uppercase",
            // },
            // {
            //   key: "address",
            //   label: "Address",
            //   className: "text-uppercase",
            // },
            // {
            //   key: "representative_name",
            //   label: "Representative's Name",
            //   className: "text-uppercase",
            // },
            // {
            //   // eslint-disable-next-line
            //   key: ({ coordinates_geoloc }) => (
            //     <div>
            //       {!isEmpty(coordinates_geoloc) ? (
            //         <span className="badge badge-success">Yes</span>
            //       ) : (
            //         <span className="badge badge-danger">No</span>
            //       )}
            //     </div>
            //   ),
            //   label: "Is Location Pinned",
            //   className: "text-uppercase",
            // },
          ]}
          data={profile || {}}
        />
      </div>
      {isAdmin && (
        <div className="text-center">
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={handleOpenEdit}
          >
            <i className="fa fa-edit mr-1" />
            Edit
          </button>
        </div>
      )}
    </div>
  );
}

Profile.propTypes = {};

export default Profile;
