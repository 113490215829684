import React from "react";
import PropTypes from "prop-types";
import { isEmpty, replace, upperCase } from "lodash";
import FormInput from "modules/common/form/FormInput";
import FormSelect from "modules/common/form/FormSelect";
import FormMultipleSelect from "modules/common/form/FormMultipleSelect";
import DayPicker from "modules/common/components/DayPicker";
import { addAssessment, updateAssessment } from "../actions";
import { STATUS_OPTIONS } from "../constants";
import { transformAssessment } from "../transformers";

function SpecialSurveyModal({ countries, isLoading, data, onClose }) {
  const isNew = isEmpty(data?.id);
  const [form, setForm] = React.useState(data);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNew) {
      addAssessment(
        {
          ...form,
          meta: { ...form.meta, origin: form.origin },
          is_system_defined: 0,
          code: replace(upperCase(form.title), " ", "_"),
        },
        onClose
      );
      return;
    }
    updateAssessment(data.id, form, onClose);
  };

  React.useEffect(() => {
    if (!data) return;
    setForm((state) => ({
      ...state,
      origin: state?.meta?.origin,
    }));
  }, []);

  return (
    <form className="p-4" onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-2">
        <div className="mb-2">
          <FormInput
            name="title"
            onChange={setForm}
            value={form.title}
            label="Title"
          />
        </div>
        <div className="mb-2">
          <FormSelect
            name="status"
            onChange={setForm}
            value={form.status}
            label="Status"
            options={STATUS_OPTIONS}
          />
        </div>
        <div className="mb-2">
          <FormInput
            name="description"
            onChange={setForm}
            value={form.description}
            label="Description"
          />
        </div>
        <div className="mb-2">
          <small>Date Validity *</small>
          <div className="d-flex justify-between content-center items-center">
            <DayPicker
              name="starts_at"
              onChange={setForm}
              value={form.starts_at}
              minDate={new Date()}
            />
            <small className="py-2 pr-2"> to </small>
            <DayPicker
              name="ends_at"
              onChange={setForm}
              value={form.ends_at}
              minDate={new Date()}
            />
          </div>
        </div>
        <div className="mb-2">
          <FormMultipleSelect
            name="origin"
            label="Target Country"
            onChange={setForm}
            value={form?.origin}
            options={countries}
            isLoading={isLoading}
          />
        </div>

        <div className="flex justify-end gap-x-2 text-right">
          <button className="btn btn-primary" type="submit">
            {isNew ? "Add" : "Save Changes"}
          </button>
          <button
            className="btn btn-outline-primary ml-1"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}

SpecialSurveyModal.defaultProps = {
  data: transformAssessment(),
};

SpecialSurveyModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  countries: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SpecialSurveyModal;
