import React, { useMemo } from "react";
import { omit } from "lodash";
import PropTypes from "prop-types";
import {
  EmploymentInformation,
  FlightVesselInformation,
  IslandDestination,
  OtherForms,
  TravelInformation,
} from "../trips/TripInfo";
import { useTripById } from "../trips/hooks";

function RegistrantCurrentTrip({ tripId, registrant }) {
  const [, trip] = useTripById(tripId);
  const combineTripInfo = useMemo(
    () => ({ ...omit(registrant, ["id"]), ...trip }),
    [registrant, trip]
  );

  return (
    <div className="p-3">
      <div className="row">
        <div className="col-4 mb-3">
          <TravelInformation data={combineTripInfo} />
        </div>
        <div className="col-4 mb-3">
          <div className="mb-4">
            <FlightVesselInformation data={combineTripInfo} />
          </div>
          <div>
            <OtherForms data={combineTripInfo} />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="mb-4">
            <IslandDestination data={combineTripInfo} />
          </div>
          <div>
            <EmploymentInformation data={combineTripInfo} />
          </div>
        </div>
      </div>
    </div>
  );
}

RegistrantCurrentTrip.defaultProps = {
  registrant: {},
};

RegistrantCurrentTrip.propTypes = {
  tripId: PropTypes.string.isRequired,
  registrant: PropTypes.instanceOf(Object),
};

export default RegistrantCurrentTrip;
