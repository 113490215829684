export const SYMPTOMS = "CONTENT_MANAGEMENT/symptoms";
export const STATUS = "CONTENT_MANAGEMENT/status";
export const COMPANY = "CONTENT_MANAGEMENT/company";
export const CASE_STATUS = "CONTENT_MANAGEMENT/case_status";
export const MESSENGER_BOT_RESPONSE =
  "CONTENT_MANAGEMENT/messenger_bot_response";
export const LOCATION_TYPE = "CONTENT_MANAGEMENT/location_type";
export const HEALTH_CHECKLIST = "CONTENT_MANAGEMENT/health_checklist";
export const FACILITY_MANAGEMENT = "CONTENT_MANAGEMENT/facility_management";
export const PURPOSE_MANAGEMENT = "CONTENT_MANAGEMENT/purpose_management";
export const VACCINE_MANAGEMENT = "CONTENT_MANAGEMENT/vaccine_management";

export const CATEGORY_OPTIONS = [
  { value: "COMMON", label: "COMMON" },
  { value: "OTHER", label: "OTHER" },
];

export const SECTOR_OPTIONS = [
  {
    value: "PRIVATE",
    label: "PRIVATE",
  },
  {
    value: "GOVERNMENT",
    label: "GOVERNMENT",
  },
];

export const PURPOSE_CATEGORY_OPTIONS = [
  {
    value: "GOVERNMENT",
    label: "GOVERNMENT",
  },
  {
    value: "BUSINESS",
    label: "BUSINESS",
  },
  {
    value: "OTHER",
    label: "OTHER",
  },
];

export const STATUS_OPTIONS = [
  {
    value: "ACTIVE",
    label: "ACTIVE",
  },
  {
    value: "INACTIVE",
    label: "INACTIVE",
  },
];

export const VACCINE_STATUS_OPTIONS = [
  {
    value: "PUBLISHED",
    label: "PUBLISHED",
  },
  {
    value: "DRAFTED",
    label: "DRAFTED",
  },
];

export const META_TYPE_OPTIONS = [
  {
    value: "CHECKBOX",
    label: "CHECKBOX",
  },
  {
    value: "CHECKBOX_INPUT",
    label: "CHECKBOX INPUT",
  },
];
