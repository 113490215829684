import React from "react";
import { req, useApiGet } from "react-reqq";
import PropTypes from "prop-types";
import { debounce, get } from "lodash";
import AsyncSelect from "react-select/async";
import { fieldsList } from "../actions";

const transformOptions = (raw) => {
  return {
    label: raw?.attributes?.label,
    value: raw?.attributes?.value,
    data: raw,
  };
};

function FormSurveyFieldOptions({ name, onChange, value, report_type }) {
  const fields = useApiGet("SELECT_SURVEY_FORM_FIELDS.data", []);
  const mappedOptions = fields.map(transformOptions);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleChange = (e) => {
    onChange((state) => ({
      ...state,
      [name]: e,
    }));
  };
  const loadTripOptions = React.useCallback(
    debounce((search, callback) => {
      setIsLoading(true);
      req.get({
        key: "SELECT_SURVEY_FORM_FIELDS",
        url: "/api/v2/mng/dropdown/fields",
        params: {
          keyword: search,
          per_page: 15,
          report_type: report_type?.value,
        },
        onSuccess: (res) => {
          setIsLoading(false);
          const newOptions = (get(res, "response.data") || []).map(
            transformOptions
          );
          callback(newOptions);
        },
      });
    }, 500),
    [report_type]
  );

  React.useEffect(() => {
    if (!report_type?.value) return;
    fieldsList({ report_type: report_type?.value });
  }, [report_type]);
  return (
    <AsyncSelect
      placeholder="Select Special Survey Field..."
      isLoading={isLoading}
      cacheOptions
      onChange={handleChange}
      noOptionsMessage={() => "Type(Name) to search..."}
      defaultOptions={mappedOptions}
      // defaultOptions={transformOptions(list)}
      loadOptions={loadTripOptions}
      value={value}
      isClearable
    />
  );
}

FormSurveyFieldOptions.defaultProps = {
  value: null,
};

FormSurveyFieldOptions.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  report_type: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]).isRequired,
};

export default FormSurveyFieldOptions;
