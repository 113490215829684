import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { formatNumber, parseNumber } from "../helper";
import { ToastWarn } from "./toast";

function Pagination({ data, isLoading, onChange }) {
  const handleChange = (newPage) => () => {
    onChange(newPage);
  };
  const handleGoToPage = () => {
    const newPage = parseNumber(
      prompt("Please enter page", ""), // eslint-disable-line
      false
    );
    if (!newPage) return;
    if (newPage < 1 || newPage > data.last_page) {
      ToastWarn(`Please enter number between 1 to ${data.last_page}`);
      return;
    }
    onChange(newPage);
  };
  return !_.isEmpty(data) ? (
    <div className="d-flex justify-content-between align-items-center p-2">
      <small className="text-muted">
        {formatNumber(data.total || "0", 0)} item(s)
      </small>
      {data.total_pages > 1 && (
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-sm btn-outline-primary"
            type="button"
            disabled={isLoading}
            onClick={handleChange(data.current_page - 1)}
          >
            Prev
          </button>
          <button
            className="btn btn-sm btn-outline-primary mx-1"
            type="button"
            disabled={isLoading}
            onClick={handleGoToPage}
          >
            {data.current_page} of {data.total_pages || data.last_page}
          </button>
          <button
            className="btn btn-sm btn-outline-primary"
            type="button"
            disabled={isLoading}
            onClick={handleChange(data.current_page + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  ) : null;
}

Pagination.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.memo(Pagination);
