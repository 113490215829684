/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import GenericTable from "modules/common/components/GenericTable";
import { HeaderTitle } from "modules/common/components/Header";
import { showModal } from "modules/common/components/AlertModal";
import { useApiLoading, useApiGet } from "react-reqq";
import _ from "lodash";
import DOMPurify from "dompurify";
import StatusManagementModal from "../modals/StatusManagementModal";
import * as actions from "../actions";
import * as c from "../constants";

function StatusManagement() {
  const isLoading = useApiLoading(c.STATUS, "get");
  const data = useApiGet(c.STATUS, []);

  const handleOpenEdit = (row) => () => {
    showModal({
      title: `Edit ${_.get(row, "attributes.name")}`,
      className: "modal-lg",
      content: (onClose) => (
        <StatusManagementModal data={row} onClose={onClose} />
      ),
    });
  };

  const renderAction = React.useCallback(
    (row) => (
      <div>
        <button
          className="btn btn-outline-primary btn-sm btn-block"
          type="button"
          onClick={handleOpenEdit(row)}
        >
          <i className="fa fa-edit" />
        </button>
      </div>
    ),
    []
  );

  const handleOpenPreview = (row) => () => {
    const desc = _.get(row, "attributes.description");
    showModal({
      title: "Preview",
      content: (onClose) => (
        <>
          <div className="modal-body">
            {_.isEmpty(desc) ? (
              <div className="text-center text-muted">No data.</div>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc) }}
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-outline-primary"
              onClick={onClose}
              type="button"
            >
              Close
            </button>
          </div>
        </>
      ),
    });
  };
  const renderPreview = React.useCallback(
    (row) => (
      <div>
        <button
          className="btn btn-outline-primary btn-sm btn-block"
          type="button"
          onClick={handleOpenPreview(row)}
        >
          <i className="fa fa-eye" /> Preview
        </button>
      </div>
    ),
    []
  );

  const getTableFormat = React.useCallback(
    () => [
      { key: renderAction, label: "Action", width: 50 },
      { key: "attributes.code", label: "Code", width: 100 },
      { key: "attributes.points", label: "Points", width: 100 },
      { key: "attributes.name", label: "Status" },
      { key: renderPreview, label: "Description", width: 100 },
    ],
    []
  );

  React.useEffect(() => {
    actions.listStatus({ paginate: 0 });
  }, []);
  return (
    <>
      <Helmet>
        <title>Status Management - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Status Management</div>
      </HeaderTitle>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column">
        <div className="box-fit-wrapper w-100 flex-grow-1">
          <div className="box-fit-overflow">
            <GenericTable
              isLoading={isLoading}
              format={getTableFormat()}
              data={data}
            />
          </div>
        </div>
      </div>
    </>
  );
}

StatusManagement.propTypes = {};

export default StatusManagement;
