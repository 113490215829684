import React from "react";
// import PropTypes from 'prop-types';
import { useLocation, useHistory } from "react-router-dom";
import { req } from "react-reqq";
import qs from "query-string";
import Cookie from "js-cookie";

const wait = (t) => new Promise((r) => setTimeout(r, t));

function SiteLogin() {
  const location = useLocation();
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);
  const [authMessage, setAuthMessage] = React.useState("Authenticating...");
  React.useEffect(() => {
    const init = async () => {
      try {
        const { key, success } = qs.parse(location.search);

        if (key) {
          sessionStorage.removeItem("profile");
          localStorage.clear();
          await req.clearCache();

          await wait(1500);

          Cookie.set("token", key);

          window.location.href = "/authenticate?success=1";
          return;
        }
        if (+success === 1) {
          setAuthMessage("Please wait...");
          history.push("/");
          return;
        }
        setIsAuthenticating(false);
        setAuthMessage("Authentication failed, you may now close this window");
      } catch (err) {
        setIsAuthenticating(false);
        setAuthMessage("Authentication failed, you may now close this window");
        history.push("/authenticate?success=0");
      }
    };
    init();
  }, [location.search]);
  return (
    <div className="pt-5">
      <div className="text-center">
        <div>
          {isAuthenticating ? (
            <i className="fa fa-spin fa-circle-notch fa-2x" />
          ) : (
            <i className="fa fa-user fa-2x" />
          )}
        </div>
        <div>{authMessage}</div>
      </div>
    </div>
  );
}

SiteLogin.propTypes = {};

export default SiteLogin;
