import React from "react";
import PropTypes from "prop-types";
import { useApiGet, useApiLoading } from "react-reqq";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import GenericDetail from "modules/common/components/GenericDetail";
import { showModal } from "modules/common/components/AlertModal";
import { confirmAlert } from "modules/common/components/Alert";
import {
  removeNull,
  // toLngLat,
  // isValidLatLng,
  // toBorder,
} from "modules/common/helper";
// import GoogleMaps from "modules/dashboard/maps/GoogleMaps";
import IntegrationManagement from "modules/integration/containers/IntegrationManagement";
// import PageNotFound from 'modules/common/containers/PageNotFound';
// import RoleAssignment from "../components/RoleAssignment";
import UserModal from "../modals/UserModal";
import ResetUserPwModal from "../modals/ResetUserPwModal";
import UserAccessControl from "./UserAccessControl";

import * as actions from "../actions";
import * as c from "../constants";

const CONST_KEY = {
  admin: c.SELECT_ADMIN,
  subuser: c.SELECT_SUB_USER,
};

const ACTION_SELECT = {
  admin: actions.selectAdmin,
  subuser: actions.selectSubUser,
};

const ACTION_REMOVE = {
  admin: actions.removeAdmin,
  subuser: actions.removeSubUser,
};

const DETAIL_FORMAT = {
  admin: {
    1: [
      {
        key: "attributes.name",
        label: "Access Name",
        className: "text-uppercase",
      },
      {
        key: "attributes.representative_name",
        label: "Representative's Name",
        className: "text-uppercase",
      },
      {
        key: "attributes.representative_position",
        label: "Representative's Position",
        className: "text-uppercase",
      },
    ],
    2: [
      { key: "attributes.mobile_number", label: "Mobile Number" },
      { key: "attributes.email", label: "Email" },
      {
        key: "id",
        label: "ID",
        className: "text-uppercase",
      },
    ],
  },
  subuser: {
    1: [
      {
        key: "attributes.name",
        label: "Access Name",
        className: "text-uppercase",
      },
      { key: "attributes.email", label: "Email" },
    ],
    2: [
      { key: "attributes.mobile_number", label: "Mobile Number" },
      { key: "attributes.sub_category", label: "Category" },
    ],
  },
};

function UserSelected({ type, filter, list }) {
  // const [resetMap, setResetMap] = React.useState(new Date().getTime());
  const history = useHistory();
  const { user_id } = useParams();
  const data = useApiGet(CONST_KEY[type], {});
  const isLoading = useApiLoading(CONST_KEY[type], "get");
  const handleOpenResetPW = React.useCallback(() => {
    showModal({
      title: "Reset Password",
      className: "modal-sm",
      content: (onClose) => (
        <ResetUserPwModal data={data} type={type} onClose={onClose} />
      ),
    });
  }, [data, type]);
  const handleOpenEdit = React.useCallback(() => {
    showModal({
      title: "Update User",
      className: "modal-lg",
      content: (onClose) => (
        <UserModal
          type={type}
          filter={removeNull(filter)}
          value={data}
          onClose={onClose}
        />
      ),
    });
  }, [data]);

  const handleRemove = React.useCallback(() => {
    confirmAlert({
      content: "Are you sure you want to remove?",
      onYes: (onClose) => {
        if (ACTION_REMOVE[type]) {
          const selecteUserdId = _.get(data, "attributes.uuid");
          ACTION_REMOVE[type](selecteUserdId, () => {
            const filteredList = list?.filter(
              (item) => item?.attributes?.uuid !== selecteUserdId
            );
            history.push(`${filteredList[0]?.attributes?.uuid || ""}`);
            onClose();
          });
        }
      },
    });
  }, [data]);

  // const handleRoleSaved = React.useCallback(() => {
  //   if (ACTION_SELECT[type]) {
  //     ACTION_SELECT[type](user_id);
  //   }
  // }, [user_id]);

  // const renderRoles = React.useCallback(
  //   (row) => (
  //     <RoleAssignment
  //       key={user_id}
  //       userId={user_id}
  //       value={row.roles || []}
  //       disabled={isLoading}
  //       onSuccess={handleRoleSaved}
  //     />
  //   ),
  //   [user_id, isLoading]
  // );

  const getDetailFormat = React.useCallback(
    (index) => _.get(DETAIL_FORMAT, `${type}.${index}`) || [],
    [type, user_id, isLoading]
  );

  // React.useEffect(() => {
  //   setResetMap(new Date().getTime());
  // }, [_.get(data, "attributes.map_bounds")]);

  React.useEffect(() => {
    if (ACTION_SELECT[type]) {
      ACTION_SELECT[type](user_id);
    }
  }, [user_id]);

  // console.log(data);
  // const mapBounds = c.parseMapBounds(_.get(data, "attributes.map_bounds"));
  // const { lng, lat } = toLngLat(_.get(data, "attributes.geoloc"));
  // const shouldRenderMap = isValidLatLng(_.get(data, "attributes.geoloc"));

  return (
    <>
      <div
        className="border rounded bg-white mx-2 ml-0 flex-grow-1 d-flex flex-column"
        style={{
          opacity: isLoading ? 0.5 : 1,
          pointerEvents: isLoading ? "none" : "auto",
        }}
      >
        <div className="d-flex justify-content-between p-1">
          <div>
            {isLoading && (
              <span className="spinner-grow spinner-grow-sm text-primary mr-1" />
            )}
          </div>
          <div>
            <button
              className="btn btn-outline-warning btn-sm mr-3"
              type="button"
              onClick={handleOpenResetPW}
            >
              <i className="fa fa-key mr-1" />
              Reset PW
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-1"
              type="button"
              onClick={handleOpenEdit}
              disabled={_.isEmpty(data)}
            >
              <i className="fa fa-edit mr-1" />
              Edit
            </button>
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={handleRemove}
              disabled={_.isEmpty(data)}
            >
              <i className="fa fa-trash mr-1" />
              Remove
            </button>
          </div>
        </div>
        <div className="row mx-0">
          <div className="col p-0 form-group">
            <GenericDetail
              labelWidth="150px"
              format={getDetailFormat(1)}
              data={data}
            />
          </div>
          <div className="col p-0">
            <GenericDetail
              labelWidth="150px"
              format={getDetailFormat(2)}
              data={data}
            />
          </div>
        </div>
        <div className="row mx-0">
          <div className="col px-0">
            {["admin", "subuser"].indexOf(type) > -1 && (
              <UserAccessControl data={data} type={type} />
            )}
          </div>
          <div className="col-7 px-0">
            {/* ["admin"].indexOf(type) > -1 && (
              <div className="embed-responsive embed-responsive-16by9">
                <div className="embed-responsive-item">
                  {shouldRenderMap ? (
                    <GoogleMaps
                      key={resetMap}
                      // bounds={mapBounds.bounds}
                      // poly={toBorder(
                      //   _.get(data, "attributes.municipality.border")
                      // )}
                      view={{
                        // zoom: +(mapBounds.zoom || "5"),
                        zoom: 10,
                        center: {
                          lat: 15.202316, // lat ||
                          lng: 145.7089154, // lng ||
                        },
                      }}
                    />
                  ) : (
                    <small className="h-100 w-100 d-flex justify-content-center align-items-center text-gray-600">
                      No data
                    </small>
                  )}
                </div>
              </div>
            )}
            <hr /> */}
            {data?.attributes?.uuid && (
              <div>
                <div className="px-2">
                  <h4>API Integration</h4>
                </div>
                <IntegrationManagement userId={data?.attributes?.uuid} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

UserSelected.propTypes = {
  type: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object),
  list: PropTypes.instanceOf(Array),
};

UserSelected.defaultProps = {
  filter: {},
  list: [],
};

export default UserSelected;
