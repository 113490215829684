import React from "react";
import PropTypes from "prop-types";
import FormInput from "modules/common/form/FormInput";
import { useApiGet, req, useApiLoading } from "react-reqq";
import * as actions from "../actions";
import * as c from "../constants";

// const placeholder =
//   "https://chart.googleapis.com/chart?chs=128x128&cht=qr&chl=MFACODE&chld=L|1&choe=UTF-8";

function MfaQrModal({ onClose }) {
  const isLoading = useApiLoading(c.MFA, "post");
  const [form, setForm] = React.useState({ code: "" });
  const MFA_QR = useApiGet(c.MFA_QR, null);
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.enableMfa(form, onClose);
  };
  React.useEffect(() => {
    actions.getMfaQr();
    return () => {
      req.set(c.MFA_QR, null);
    };
  }, []);
  return (
    <form className="modal-body" onSubmit={handleSubmit}>
      <ol>
        <li>
          <p className="mb-0">
            Install a compatible app on your mobile device or computer.
          </p>
          <ul className="mb-2">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              >
                Google Authenticator(Android)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              >
                Google Authenticator(iOS)
              </a>
            </li>
          </ul>
        </li>
        <li>
          <p>Scan this QR code using your MFA app.</p>
          {MFA_QR ? (
            <div className="text-center mb-2">
              <img
                className="img img-fluid border rounded shadow-sm w-100"
                src={MFA_QR}
                alt="mfa qr"
                style={{ maxWidth: 250 }}
              />
            </div>
          ) : (
            <div className="text-center mb-2">
              <div style={{ height: 250, width: 250 }}>Loading...</div>
            </div>
          )}
        </li>
        <li>
          <p>Please enter MFA PIN below</p>
          <div className="form-group">
            <FormInput
              name="code"
              label="MFA Code"
              onChange={setForm}
              value={form.code}
              required
            />
          </div>
          {/* <div className="form-group">
            <FormInput
              name="mfa_code2"
              label="MFA Code 2"
              onChange={setForm}
              value={form.mfa_code2}
            />
          </div> */}
        </li>
      </ol>
      <div className="alert alert-danger">
        Imporant: Make sure NOT to <b>uninstall</b> your authenticator app or{" "}
        <b>remove</b> the staysafe account in your authenticator app. You will
        NOT be able to login without the PIN while MFA is enabled.
      </div>
      <div>
        <button
          className="btn btn-primary btn-block mb-1"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Please wait..." : "Submit"}
        </button>
        <button
          className="btn btn-outline-primary btn-block"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

MfaQrModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MfaQrModal;
