import React from "react";
import PropTypes from "prop-types";
import GenericView, { ViewItem } from "modules/common/components/GenericView";
import { showDynamicQuestionsModal } from "./DynamicQuestionModal";

const Label = ({ children }) => (
  <label className="font-weight-bold mb-2">{children}</label>
);
Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]).isRequired,
};

const DynamicFormButton = ({ formId, label, data, name }) => {
  const handleView = () => {
    showDynamicQuestionsModal(formId, label, data, name);
  };
  return (
    <button
      className="btn btn-outline-primary btn-sm"
      type="button"
      onClick={handleView}
      disabled={!formId}
    >
      View Details
    </button>
  );
};

DynamicFormButton.propTypes = {
  formId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
};

export const TravelInformation = ({ data }) => {
  return (
    <div>
      <Label>Travel Info</Label>
      <GenericView data={data} labelWidth="140px">
        <ViewItem name="first_name" label="First Name" />
        <ViewItem name="middle_name" label="Middle Name" />
        <ViewItem name="last_name" label="Last Name" />
        <ViewItem name="birthdate" label="Date of Birth" />
        <ViewItem name="age_range" label="Age" />
        <ViewItem name="gender" label="Gender" />
        <ViewItem name="ethnicity" label="Ethnicity" />
        <ViewItem name="passport_country" label="Passport Country" />
        <ViewItem name="passport_number" label="Passport Number" />
        <ViewItem name="residency_status" label="CNMI Residency Status" />
        <ViewItem
          name="parent_full_name"
          label="Parent/Legal Guardian's Name"
        />
        <ViewItem
          name="parent_birthdate"
          label="Parent/Legal Guardian's Date of Birth"
        />
        <ViewItem name="parent_gender" label="Parent/Legal Guardian's Gender" />
      </GenericView>
    </div>
  );
};

TravelInformation.propTypes = { data: PropTypes.instanceOf(Object).isRequired };

export const FlightVesselInformation = ({ data }) => {
  return (
    <div>
      <Label>Flight/Vessel Info</Label>
      <GenericView data={data} labelWidth="140px">
        <ViewItem
          name="type_of_traveler"
          label="Visit As"
          title="Are you one of the following?"
        />
        <ViewItem
          name="type_of_transportation"
          label="Arriving Via (Air or Sea)"
        />
        {data?.type_of_transportation === "AIR" && (
          <>
            <ViewItem name="airline_flight_no" label="Flight/Vessel No." />
            <ViewItem name="airline_seat_no" label="Seat No." />
            <ViewItem name="airline_seat_letter" label="Seat Letter" />
          </>
        )}
        {data?.type_of_transportation === "SEA" && (
          <ViewItem name="vessel_info" label="Vessel Info." />
        )}
        <ViewItem name="date_of_arrival" label="Date of Arrival" />
        <ViewItem name="origin_country" label="Point of Origin (Country)" />
        <ViewItem name="origin_state" label="Point of Origin (State)" />
        <ViewItem name="origin_city" label="Point of Origin (City)" />
      </GenericView>
    </div>
  );
};

FlightVesselInformation.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export const IslandDestination = ({ data }) => {
  return (
    <div>
      <Label>Island Destination</Label>
      <GenericView data={data} labelWidth="140px">
        <ViewItem
          name="is_exiting_within_5_days"
          label="Exiting CNMI within 5 days of arrival"
        />
        <ViewItem
          name="date_of_departure"
          label="Estimated/Date of Departure"
        />
        <ViewItem name="destination" label="Final Island Destination" />
        <ViewItem name="island_village" label="Village" />
        <ViewItem name="staying_location" label="Stay Type" />
        <ViewItem name="stay_at_name" label="Stay At Name" />
        <ViewItem name="stay_at_address" label="Stay At Address" />
        <ViewItem name="stay_at_number" label="Stay At Phone No." />
        <ViewItem name="airbnb_name" label="Bed and Breakfast" />
        <ViewItem name="airbnb_address" label="Bed and Breakfast Address" />
        <ViewItem name="airbnb_number" label="Bed and Breakfast Phone No" />
        <ViewItem name="other_staying_location" label="Please Specify:" />
      </GenericView>
    </div>
  );
};

IslandDestination.propTypes = { data: PropTypes.instanceOf(Object).isRequired };

export const EmploymentInformation = ({ data }) => {
  return (
    <div>
      <Label>Employment Info</Label>
      <GenericView data={data} labelWidth="140px">
        <ViewItem name="employment_status" label="Is Employed" />
        <ViewItem name="employer_name" label="Employer Name" />
        <ViewItem name="occupation" label="Occupation / Job Title" />
      </GenericView>
    </div>
  );
};

EmploymentInformation.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export const OtherForms = ({ data }) => {
  return (
    <div>
      <div className="mb-4">
        <Label>Contact Information / Sara Alert Registration</Label>
        <div>
          <DynamicFormButton
            formId={data?.form_contact_info_id || ""}
            label="Contact Info / Sara Alert Registration"
            data={data}
            name="contact"
          />
        </div>
      </div>

      <div className="mb-4">
        <Label>Medical Questionnaire</Label>
        <div>
          <DynamicFormButton
            formId={data?.form_medical_questionaire_id || ""}
            label="Medical Questionnaire"
            data={data}
            name="medical"
          />
        </div>
      </div>
    </div>
  );
};

OtherForms.propTypes = { data: PropTypes.instanceOf(Object).isRequired };
