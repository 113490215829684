import { HeaderTitle } from "modules/common/components/Header";
import React from "react";
import { Helmet } from "react-helmet";
import FormQuestionnaires from "../components/FormQuestionnaires";

function UserTripContainer() {
  return (
    <>
      <Helmet>
        <title>Trips - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Trips</div>
      </HeaderTitle>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column">
        <div className="p-3">Health Declaration</div>
        <FormQuestionnaires />
      </div>
    </>
  );
}

export default UserTripContainer;
