import React from "react";
import { NavLink } from "react-router-dom";

import Mobile from "assets/img/landing/home/learn/mobile-hand.webp";

import "../styles/main.scss";

export default () => (
  <section className="landing__learn-container">
    <figure className="landing__learn-img-container landing-container">
      <img src={Mobile} alt="learn" className="landing__learn-img" />
      <figcaption className="landing__learn-content-container landing-text-center">
        <h1 className="landing__learn-content-title">
          Learn <span>How To</span> Download App with your browser
        </h1>
        <NavLink to="how-to-register" className="landing-btn">
          Learn How
        </NavLink>
      </figcaption>
    </figure>
  </section>
);
