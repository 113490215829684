import React from "react";

export function CurveBackground() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 375 118.771"
      className="scanner__header-logo"
    >
      <defs>
        <linearGradient
          id="a"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#000910" />
          <stop offset="1" stopColor="#71a3cb" />
        </linearGradient>
      </defs>
      <g transform="translate(0 -80)">
        <path
          d="M0,0H375V84S280.883,64,187.133,64,0,88.771,0,88.771Z"
          transform="translate(0 80)"
          fill="url(#a)"
        />
        <path
          d="M0,0H375V24S280.883,64,187.133,64,0,24,0,24Z"
          transform="translate(0 80)"
          fill="#012c51"
        />
        <path
          d="M0,0H375V114S280.883,64,187.133,64,0,118.771,0,118.771Z"
          transform="translate(0 80)"
          fill="#012c51"
          opacity="0.8"
        />
      </g>
    </svg>
  );
}

export function ExclamationCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 16 16"
      className="scanner__card-warning-icon"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>
  );
}

export function LeftArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      aria-hidden="true"
      className="scanner__back-icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </svg>
  );
}

export function ScanIcon() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      className="scanner__scan-icon"
    >
      <path d="M4 4h4.01V2H2v6h2V4zm0 12H2v6h6.01v-2H4v-4zm16 4h-4v2h6v-6h-2v4zM16 4h4v4h2V2h-6v2z" />
      <path d="M5 11h6V5H5zm2-4h2v2H7zM5 19h6v-6H5zm2-4h2v2H7zM19 5h-6v6h6zm-2 4h-2V7h2zm-3.99 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z" />
    </svg>
  );
}

export default {};
