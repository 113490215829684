export const MODULE_GROUP = "ACL/module_group";
export const ROLE = "ACL/role";
export const ROLE_KEYS = "ACL/role_keys";

export const LIST_ADMIN = "ACL/list_admin";
export const SELECT_ADMIN = "ACL/select_admin";
export const RESET_PW_ADMIN = "ACL/reset_pw_admin";
export const LIST_SUB_USER = "ACL/list_sub_user";
export const SELECT_SUB_USER = "ACL/select_sub_user";
export const RESET_PW_SUB_USER = "ACL/reset_pw_sub_user";

export const UPDATE_USERS_ACL = "ACL/update_users_acl";

export const CATEGORY_OPTIONS = [
  { value: "GOVERNMENT", label: "GOVERNMENT" },
  { value: "PRIVATE", label: "PRIVATE" },
];

export const parseMapBounds = (map_bounds) => {
  try {
    return JSON.parse(map_bounds);
  } catch (err) {
    return { zoom: 5, bounds: null, poly: [] };
  }
};

export const ACL_OPTIONS = [
  // {
  //   label: "Private",
  //   value: "private",
  //   acl: [
  //     "dashboard",
  //     "contact-tracing",
  //     "reports",
  //     "registrants",
  //     "trips",
  //     "content-management",
  //   ],
  // },
  {
    label: "Establishment",
    value: "establishment",
    acl: [
      "logbook",
      "lb-digital",
      "lb-entry-points",
      "lb-export",
      "lb-list",
      "scanner",
      "acl-sub-accounts",
    ],
  },
  {
    label: "Select All",
    value: "all",
    acl: [],
  },
  {
    label: "Clear All",
    value: "clear",
    acl: [],
  },
];
