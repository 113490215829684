/* global google */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import GoogleMap from "modules/common/components/GoogleMap";
import {
  StandaloneSearchBox,
  Marker,
  Polygon,
  HeatmapLayer,
} from "@react-google-maps/api";
import { useApiGet } from "react-reqq";
import { AUTHENTICATION, SUPER_ADMIN_ACCESS_KEY } from "modules/auth/constants";
import { MAP_STYLE } from "../constants";

const HEATMAP_OPTIONS = {
  // dissipating: true,
  // radius: 30,
  dissipating: false,
  radius: 0.002,
  opacity: 0.6,
  maxIntensity: 100,
  // gradient: ['rgba(0, 255, 255, 0)','#17b978','#ffbc24','#fa214a']
};

const HEATMAP_WEIGHT_BY_TYPE = {
  SEVERE: 100,
  MILD: 20,
  GOOD: 0,
};

const Z_INDEX_BY_TYPE = {
  SEVERE: 3,
  MILD: 2,
  GOOD: 1,
};

function GoogleMaps({
  bounds,
  view,
  layers,
  markers,
  poly,
  isAnimated,
  onMarkerClick,
  iconKey,
  withSearch,
  onSearch,
  onDragEnd,
  options,
  onLoad,
  searchBoxClassName,
  searchBoxProps,
}) {
  const getOptions = React.useCallback(() => {
    const opt = {
      disableDefaultUI: true,
      scaleControl: true,
      zoomControl: true,
      minZoom: !_.isEmpty(bounds) ? view.zoom || 2 : 2,
      styles: MAP_STYLE,
      mapTypeControl: true,
      fullscreenControl: true,
      ...options,
    };
    if (!_.isEmpty(bounds)) {
      opt.restriction = {
        latLngBounds: bounds,
        strictBounds: false,
      };
    }
    return opt;
  }, [options, bounds, view.zoom]);

  const [isMapLoaded, setIsMapLoaded] = React.useState(false);

  const mapsRef = React.useRef();
  const handleMapLoaded = React.useCallback((map) => {
    mapsRef.current = map;
    setIsMapLoaded(true);
    onLoad(map);
  }, []);

  const handleDragEnd = React.useCallback(() => {
    if (!isMapLoaded) return;
    if (typeof onDragEnd === "function") onDragEnd(mapsRef.current);
  }, [isMapLoaded]);

  const searchBoxRef = React.useRef();
  const handleSearchLoaded = React.useCallback((search) => {
    searchBoxRef.current = search;
  });
  const handleSearch = React.useCallback(() => {
    if (!isMapLoaded) return;
    const place = searchBoxRef.current.getPlaces();
    mapsRef.current.fitBounds(_.get(place, "0.geometry.viewport"));
    onSearch((place || [])[0]);
    handleDragEnd();
  }, [isMapLoaded]);

  const [heatmapData, setHeatmapData] = React.useState([]);

  React.useEffect(() => {
    if (_.isEmpty(markers) || !isMapLoaded) return;
    // console.time("process heatmap");
    const newHeatmapData = [];
    _.forOwn(markers, (item) => {
      newHeatmapData.push({
        location: new google.maps.LatLng(item.lat, item.lng),
        weight: HEATMAP_WEIGHT_BY_TYPE[item.type] || 1,
      });
    });
    // console.timeEnd("process heatmap");
    setHeatmapData(newHeatmapData);
  }, [isMapLoaded, markers]);
  const { profile } = useApiGet(AUTHENTICATION, {});
  const isSuperAdmin = profile.access_control === SUPER_ADMIN_ACCESS_KEY;
  const handleClickMarker = React.useCallback(
    (row) => () => {
      if (typeof onMarkerClick === "function") onMarkerClick(row);
    },
    []
  );
  const renderMarkers = React.useCallback(
    (list) =>
      list.map((item) => (
        <Marker
          key={`${item.id}`}
          position={{ lat: item.lat, lng: item.lng }}
          animation={
            ["SEVERE"].indexOf(item.type) > -1 && isAnimated
              ? google.maps.Animation.BOUNCE
              : null
          }
          clickable={isSuperAdmin || ["SEVERE", "MILD"].indexOf(item.type) > -1}
          onClick={handleClickMarker(item)}
          options={{
            icon: item[iconKey],
            zIndex: Z_INDEX_BY_TYPE[item.type] || 1,
          }}
          tracksViewChanges={false}
          // clusterer={clusterer}
        />
      )),
    [isAnimated, markers, iconKey]
  );
  const renderHeatmap = React.useCallback(
    () => (
      <>
        {layers.indexOf("heatmap") > -1 && (
          <HeatmapLayer data={heatmapData} options={HEATMAP_OPTIONS} />
        )}
      </>
    ),
    [layers, heatmapData]
  );
  const renderPoly = React.useCallback(() => {
    if (!Array.isArray(poly)) return null;
    return (
      <>
        {Array.isArray(poly[0]) ? (
          poly.map((sub, i) => (
            <Polygon
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              options={{
                fillOpacity: 0,
                strokeColor: "#f00",
              }}
              paths={sub}
            />
          ))
        ) : (
          <Polygon
            options={{
              fillOpacity: 0,
              strokeColor: "#f00",
            }}
            paths={poly}
          />
        )}
      </>
    );
  }, [poly]);

  return (
    <>
      <GoogleMap
        onLoad={handleMapLoaded}
        options={getOptions()}
        zoom={view.zoom}
        center={view.center}
        onDragEnd={handleDragEnd}
      >
        <>
          {isMapLoaded && withSearch && (
            <StandaloneSearchBox
              onLoad={handleSearchLoaded}
              onPlacesChanged={handleSearch}
            >
              <input
                id="map-searchbox"
                type="text"
                placeholder="Search Location..."
                className={`maps-searchbox ${searchBoxClassName}`}
                // eslint-disable-next-line
                {...searchBoxProps}
              />
            </StandaloneSearchBox>
          )}
          {layers.indexOf("marker") > -1 && renderMarkers(markers)}
          {renderHeatmap()}
          {renderPoly()}
        </>
      </GoogleMap>
    </>
  );
}

GoogleMaps.propTypes = {
  view: PropTypes.instanceOf(Object),
  bounds: PropTypes.instanceOf(Object),
  poly: PropTypes.instanceOf(Array),
  markers: PropTypes.instanceOf(Array),
  layers: PropTypes.instanceOf(Array),
  isAnimated: PropTypes.bool,
  withSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  onMarkerClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  iconKey: PropTypes.string,
  onDragEnd: PropTypes.func,
  searchBoxClassName: PropTypes.string,
  searchBoxProps: PropTypes.instanceOf(Object),
  onLoad: PropTypes.func,
  options: PropTypes.instanceOf(Object),
};

GoogleMaps.defaultProps = {
  view: { zoom: 2, center: { lat: 40, lng: 0 } },
  bounds: {},
  poly: [],
  markers: [],
  isAnimated: true,
  onMarkerClick: false,
  layers: ["marker"],
  iconKey: "icon",
  withSearch: false,
  onLoad: () => {},
  onDragEnd: () => {},
  options: {},
  searchBoxClassName: "",
  searchBoxProps: {},
  onSearch: () => {},
};

export default React.memo(GoogleMaps);
