import React from "react";
import { Helmet } from "react-helmet";

// import GenericTable from 'modules/common/components/GenericTable';
import BarChart from "../../modules/dashboard-v2/components/BarChart";
import CountCard from "../../modules/dashboard-v2/components/CountCard";
import DashboardTable from "../../modules/dashboard-v2/components/DashboardTable";
import * as actions from "./actions";

const CountCards = () => (
  <div className="row">
    <div className="col-3">
      <CountCard
        id="travelers_total"
        label="Total Trips"
        color="success"
        icon="users"
        action={actions.count}
      />
    </div>
    <div className="col-3">
      <CountCard
        id="travelers_weekly"
        label="Trips (Last Week)"
        color="warning"
        icon="calendar-week"
        action={actions.count}
      />
    </div>
    <div className="col-3">
      <CountCard
        id="travelers_monthly"
        label="Trips (Last Month)"
        color="info"
        icon="calendar-alt"
        action={actions.count}
      />
    </div>
    <div className="col-3">
      <CountCard
        id="travelers_incoming"
        label="Upcoming Trips"
        color="danger"
        icon="plane-arrival"
        action={actions.count}
      />
    </div>
  </div>
);

const PerDayChart = () => {
  return (
    <div className="card p-2" style={{ height: 300 }}>
      <BarChart
        id="histogram_travelers"
        label="Per Day Trips"
        action={actions.histogram}
      />
    </div>
  );
};

const AgeGroupChart = () => {
  return (
    <div className="card p-2" style={{ height: 300 }}>
      <BarChart
        id="age_group"
        label="Age Group"
        action={actions.histogram}
        layout="horizontal"
        // marginLeft={20}
      />
    </div>
  );
};

const GenderChart = () => {
  return (
    <div className="card p-2" style={{ height: 300 }}>
      <BarChart
        id="gender"
        label="Gender"
        action={actions.histogram}
        layout="horizontal"
      />
    </div>
  );
};

const ByOriginTable = () => {
  return (
    <div className="card h-100">
      {/* <GenericTable data={[]} format={[]} /> */}
      <DashboardTable
        id="top_by_origin"
        label="Top By Country of Origin"
        action={actions.table}
      />
    </div>
  );
};

function DashboardContainer() {
  return (
    <>
      <Helmet>
        <title>Dashboard - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div>
        <div className="mb-3">
          <CountCards />
        </div>
        <div className="row">
          <div className="col-8">
            <div className="mb-3">
              <PerDayChart />
            </div>
            <div className="row">
              <div className="col-6">
                <AgeGroupChart />
              </div>
              <div className="col-6">
                <GenderChart />
              </div>
            </div>
          </div>
          <div className="col-4">
            <ByOriginTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardContainer;
