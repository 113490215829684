import React from "react";
// import PropTypes from 'prop-types';
import { isChrome } from "../helper";

const STYLE = {
  position: "fixed",
  height: "100%",
  width: "100%",
  zIndex: 2000,
};

function BrowserInfo() {
  const [warn, setWarn] = React.useState(
    // sessionStorage.getItem("block_browser") !== "0"
    false
  );
  const is_chrome = isChrome();
  const proceedAnyways = () => {
    sessionStorage.setItem("block_browser", "0");
    setWarn(false);
  };
  if (is_chrome) return null;
  return (
    <>
      <div className="text-center">
        <small>
          This site works best on{" "}
          <a
            href="https://www.google.com/chrome"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              setWarn(true);
            }}
          >
            Google Chrome
          </a>
        </small>
      </div>
      {warn && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={STYLE}
        >
          <div
            className="bg-white border rounded p-3 text-center"
            style={{ position: "relative", zIndex: 1041 }}
          >
            <div>
              This website is tested on
              <br />
              <b>
                <img
                  className="mr-2"
                  src="https://www.google.com/chrome/static/images/chrome-logo.svg"
                  alt="chrome"
                />
                Google Chrome
              </b>
              <br />
              Some elements might not work as intended.
              <br />
              <small>
                We are currently working on supporting other browsers
                <br />
                Sorry for the inconvenience
              </small>
            </div>
            <div className="d-flex justify-content-between mt-1">
              <a
                className="btn btn-primary"
                href="https://www.google.com/chrome"
                target="_blank"
                rel="noopener noreferrer"
              >
                Install Chrome
              </a>
              <button
                className="btn btn-link"
                type="button"
                onClick={proceedAnyways}
              >
                Proceed Anyways
              </button>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
    </>
  );
}

BrowserInfo.propTypes = {};

export default BrowserInfo;
