import React from "react";
import PropTypes from "prop-types";
import { useApiGet, useApiLoading } from "react-reqq";
import { debounce, each, isEqual, omit } from "lodash";
import { showModal } from "modules/common/components/AlertModal";
import FormInput from "modules/common/form/FormInput";
import FormSelect from "modules/common/form/FormSelect";
import { DATASET } from "travel-marianas-modules/dataset/constants";
import { listDataset } from "travel-marianas-modules/dataset/actions";
import AddPanel from "./AddPanel";
import QuestionAdvanceOptions, {
  QuestionAdvanceLabel,
} from "./QuestionAdvanceOptions";
import { DATASET_OPTIONS } from "../constants";
import * as actions from "../actions";
import * as c from "../constants";

const OptionItem = ({ data, onChange, onDelete }) => {
  const [form, setForm] = React.useState(data);
  // eslint-disable-next-line
  const save = React.useCallback(
    debounce((newValue) => {
      if (isEqual(data, newValue)) {
        // console.log('skip cuz same!');
        return;
      }
      onChange(newValue);
    }, 500),
    []
  );
  React.useEffect(() => {
    save(form);
  }, [form, save]);
  return (
    <div className="d-flex align-items-center flex-row mb-1">
      <div className="mr-1">
        <i className="fa fa-caret-down" />
      </div>
      <div className="flex-grow-1">
        <FormInput
          name="label"
          onChange={setForm}
          placeholder="Enter Option..."
          value={form.label}
        />
      </div>
      <div>
        <button
          className="btn xs text-red-500"
          type="button"
          onClick={onDelete}
        >
          <i className="fa fa-times" />
        </button>
      </div>
    </div>
  );
};

OptionItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const buildData = ({ id, ...rest }) => {
  return rest;
};

function QuestionDropdownDataset({
  data,
  index,
  editMode,
  answer,
  assessmentId,
}) {
  const addQuestionModal = () => {
    showModal({
      title: false,
      content: (onClose) => (
        <AddPanel index={index} assessmentId={assessmentId} onClose={onClose} />
      ),
      closeToggle: true,
    });
  };
  const duplicateQuestion = () => {
    actions.duplicateAssessmentQuestion(assessmentId, data?.id);
  };
  const isApiSaving = useApiLoading(
    `${c.ASSESSMENT_QUESTION_ITEM}/${data.id}`,
    "put"
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const [form, setForm] = React.useState(buildData(data));
  const canSave = React.useMemo(() => {
    const oldData = buildData(data);
    return !isEqual(omit(oldData, ["sequence"]), omit(form, ["sequence"]));
  }, [form, data]);
  // eslint-disable-next-line
  const save = React.useCallback(
    debounce((newValue) => {
      const oldData = buildData(data);
      if (isEqual(omit(oldData, ["sequence"]), omit(newValue, ["sequence"]))) {
        // console.log('skip cuz same!');
        return;
      }

      setIsSaving(true);
      actions.updateAssessmentQuestion(assessmentId, data.id, newValue, () =>
        setIsSaving(false)
      );
    }, 1), // }, 1500),
    [assessmentId]
  );

  const handleOptionChange = (e) => {
    setForm((state) => ({
      ...state,
      code: e({})?.code,
      meta: {
        ...state.meta,
        code: e({})?.code,
      },
    }));
  };
  const handleSave = () => {
    save(form);
  };

  const dataset = useApiGet(`${DATASET}_${form?.meta?.code}`, []);
  const transform = (list) => {
    const newOptions = [];
    each(list, (v) => {
      newOptions.push({
        label: v?.title,
        value: v?.title,
      });
    });
    return newOptions;
  };
  React.useEffect(() => {
    if (!form || !editMode) return;
    listDataset(form?.meta?.code, {});
  }, [form, editMode]);
  const optList = transform(dataset);

  return (
    <div
      className="card card-body mb-3"
      style={canSave ? { border: "3px solid #133963" } : {}}
    >
      <div>
        {!editMode && (
          <div className="inline-block float-right space-x-1 mt-2">
            <button
              className="btn outline-primary xs bg-light mr-1"
              type="button"
              disabled={isSaving || isApiSaving}
              onClick={() => actions.updateQuestionUp(assessmentId, data)}
            >
              <i className="fa fa-chevron-up" />
            </button>
            <button
              className="btn outline-primary xs bg-light"
              type="button"
              disabled={isSaving || isApiSaving}
              onClick={() => actions.updateQuestionDown(assessmentId, data)}
            >
              <i className="fa fa-chevron-down" />
            </button>
          </div>
        )}
        {editMode ? (
          <>
            <div>
              <p className="flex">
                <b>{form?.question}</b>{" "}
                {!form?.required && <span>(Optional)</span>}
              </p>
            </div>
            <div>
              <FormSelect
                name="temp"
                onChange={() => {}}
                value={answer?.value || ""}
                options={optList || []}
              />
            </div>
            <QuestionAdvanceLabel value={data.meta} />
          </>
        ) : (
          <>
            <div className="mb-2">
              <span className="mr-2 text-primary index-circle"># {index}</span>
            </div>
            <div className="row">
              <div className="col">
                <FormInput
                  name="question"
                  onChange={setForm}
                  placeholder="Enter Question..."
                  value={form.question}
                />
              </div>
              <div className="col">
                <FormSelect
                  name="code"
                  onChange={handleOptionChange}
                  value={form?.meta?.code || ""}
                  options={DATASET_OPTIONS}
                  placeholder=" - Select Source - "
                  // label="Source"
                />
              </div>
              <span className="d-flex align-items-center badge badge-light mr-2 text-primary">
                Dataset
              </span>
            </div>
          </>
        )}
        {!editMode && (
          <>
            <QuestionAdvanceOptions
              id={data.id}
              onChange={setForm}
              value={form.meta || {}}
            />
            <hr />
            <div className="d-flex justify-content-between">
              <div className="row">
                <div className="col d-flex align-items-center">
                  <label
                    className="d-inline-block mr-2 mb-0"
                    htmlFor={`toggle-${form.question}-${data.id}`}
                  >
                    Required
                  </label>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      onChange={({ target }) =>
                        setForm((state) => ({
                          ...state,
                          required: target.checked,
                        }))
                      }
                      readOnly={editMode}
                      checked={form.required}
                      id={`toggle-${form.question}-${data.id}`}
                      value={form.required}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`toggle-${form.question}-${data.id}`}
                    />
                  </div>
                  <button
                    className="btn outline-danger xs bg-light mx-1"
                    type="button"
                    style={{ color: "#133963" }}
                    onClick={addQuestionModal}
                    disabled={isSaving || isApiSaving}
                  >
                    <i className="fas fa-plus-circle" />
                  </button>
                  <button
                    className="btn outline-danger xs bg-light mx-1"
                    type="button"
                    style={{ color: "#133963" }}
                    onClick={duplicateQuestion}
                    disabled={isSaving || isApiSaving}
                  >
                    <i className="far fa-copy" />
                  </button>
                  <button
                    className="btn outline-danger xs bg-light mx-1"
                    type="button"
                    style={{ color: "#133963" }}
                    onClick={() =>
                      actions.deleteAssessmentQuestion(assessmentId, data.id)
                    }
                    disabled={isSaving || isApiSaving}
                  >
                    <i className="far fa-trash-alt" />
                  </button>
                </div>
              </div>
              <div className="row">
                {isSaving || isApiSaving ? (
                  <button className="btn success xs" type="button" disabled>
                    <i className="fa fa-spinner fa-spin" /> Save
                  </button>
                ) : (
                  <button
                    className="btn success xs btn-primary px-3"
                    type="button"
                    onClick={handleSave}
                    disabled={!canSave}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

QuestionDropdownDataset.defaultProps = {
  editMode: false,
  answer: null,
  assessmentId: "",
};

QuestionDropdownDataset.propTypes = {
  assessmentId: PropTypes.string,
  editMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  answer: PropTypes.instanceOf(Object),
};

export default QuestionDropdownDataset;
