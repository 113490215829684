import React from "react";
import _ from "lodash";
import { isValidLatLng } from "./helper";

export const VERSION_NUMBER = "v2.0.8";

export const GLOBAL = "COMMON/global";
export const SIDEBAR = "COMMON/sidebar";

export const HeaderContext = React.createContext();

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const renderLocation = (path) => (row) => {
  const latLng = _.get(row, path);
  const isValid = isValidLatLng(latLng);
  if (!isValid)
    return <div className="text-center text-muted">Location not defined</div>;
  return (
    <div className="embed-responsive embed-responsive-4by3 position-relative">
      <div className="embed-responsive-item">
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAP_KEY}&q=${latLng}&zoom=16&maptype=roadmap`}
          allowFullScreen
          title="Location"
        />
      </div>
    </div>
  );
};
