import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { usePersistState } from "modules/common/helper";
import { showModal } from "modules/common/components/AlertModal";
import { confirmAlert } from "modules/common/components/Alert";

import Pagination from "modules/common/components/Pagination";
import GenericTable from "modules/common/components/GenericTable";
import { HeaderTitle } from "modules/common/components/Header";
import GenerateReports from "../modals/GenerateReports";
import { useListReports } from "../hooks";

import * as actions from "../actions";

function ReportContainer() {
  const [filter, setFilter] = usePersistState(
    "reports-filter",
    { page: 1 },
    false
  );
  const [isLoading, data, pager] = useListReports(filter);

  const handleReload = () => {
    actions.listReports(filter);
  };

  const modalGenerateReports = () => {
    showModal({
      title: "Generate Reports",
      className: "modal-lg",
      content: (onClose) => (
        <GenerateReports defaultForm={filter} onClose={onClose} />
      ),
    });
  };

  const handleDelete = React.useCallback((uuid) => {
    confirmAlert({
      content: "Are you sure you want to delete?",
      onYes: (onClose) => actions.removeSelectedReport(uuid, onClose),
    });
  }, []);

  const getTableFormat = React.useMemo(() => {
    const handleDownload = (row) => () => {
      actions.downloadReport(row?.id, row?.file_name);
    };

    return [
      { key: "created_at", label: "Created At" },
      {
        key: (row) => (
          <div>
            <div>
              <small>{row.date_of_arrival_from} ~</small>
            </div>
            <div>
              <small>{row.date_of_arrival_to}</small>
            </div>
          </div>
        ),
        label: "Date From/To",
      },
      { key: "file_name", label: "File Name" },
      // { key: "traveller_type", label: "Travelers Type" },
      // { key: "report_type", label: "Forms Type" },
      // { key: "remarks", label: "Remarks" },
      { key: "status", label: "Status", width: 80 },
      { key: "download_count", label: "# Download", className: "text-center" },
      {
        label: "Action",
        key: (row) => {
          return (
            <div>
              <button
                className="btn btn-sm btn-outline-primary mx-1"
                type="button"
                disabled={row?.status !== "PROCESSED"}
                style={{
                  cursor:
                    row?.status !== "PROCESSED" ? "not-allowed" : "pointer",
                }}
                onClick={handleDownload(row)}
              >
                <i className="fa fa-download mr-1" />
                Export
              </button>
              <button
                className="btn btn-sm btn-outline-danger mx-1"
                type="button"
                onClick={() => handleDelete(row?.uuid)}
              >
                <i className="fa fa-trash mr-1" />
              </button>
            </div>
          );
        },
        width: 130,
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Module Reports- {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Reports</div>
      </HeaderTitle>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column">
        <div className="form-inline p-2 d-flex justify-content-end">
          <button
            className="btn btn-primary mr-2"
            type="button"
            onClick={handleReload}
            disabled={isLoading}
          >
            <i className="fa fa-sync mr-1" />
            Reload
          </button>
          <button
            className="btn btn-primary "
            type="button"
            onClick={modalGenerateReports}
            disabled={isLoading}
          >
            Generate Reports
          </button>
        </div>
        <div className="box-fit-wrapper w-100 flex-grow-1">
          <div className="box-fit-overflow">
            <GenericTable
              format={getTableFormat}
              data={data}
              isLoading={isLoading}
              rowHeight={42}
            />
          </div>
        </div>
        <Pagination
          data={pager}
          isLoading={isLoading}
          onChange={(newPage) => setFilter({ ...filter, page: newPage })}
        />
      </div>
    </>
  );
}

ReportContainer.propTypes = {};

export default ReportContainer;
