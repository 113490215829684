export const AUTHENTICATION = "AUTH/authentication";
export const LOGIN = "AUTH/login";
export const CHANGE_PASSWORD = "AUTH/change_password";
export const FORGOT_PASSWORD = "AUTH/forgot_password";
export const RESET_PASSWORD = "AUTH/reset_password";
export const MFA_QR = "AUTH/mfa_qr";
export const MFA = "AUTH/mfa";

export const SUPER_ADMIN_ACCESS_KEY = "0";
export const ADMIN_ACCESS_KEY = "1";
export const SUB_USER_ACCESS_KEY = "2";

export const USER_TYPE_BY_ACCESS_KEY = {
  [SUPER_ADMIN_ACCESS_KEY]: "SUPER ADMINISTRATOR",
  [ADMIN_ACCESS_KEY]: `${process.env.REACT_APP_TITLE} ADMINISTRATOR`,
  [SUB_USER_ACCESS_KEY]: `${process.env.REACT_APP_TITLE} USER`,
};

export const APPLY_FOR_ADMIN_ACCESS = "LANDING/apply_for_admin_access";

export const REQUEST_OPTIONS = [
  {
    value: "COMPANY",
    label: "As Company",
    name: "Name of Company",
    example: "",
  },
  {
    value: "ORGANIZATION",
    label: "As Organization",
    name: "Name of Organization",
    example: "",
  },
  // {
  //   value: "LGU",
  //   label: "As Local Government Unit (LGU)",
  //   name: "LGU Name",
  //   example: "EX. CITY OF MANILA",
  // },
  // {
  //   value: "GOVERNMENT_AGENCY",
  //   label: "As Government Agency",
  //   name: "Name of Agency",
  //   example: "EX. DILG",
  // },
];
