import React from "react";
import { motion } from "framer-motion";

import { useIntersectionObserver } from "modules/common/helper";

import Browser from "assets/img/landing/register/register/browser.svg";
import Android from "assets/img/landing/register/register/android.svg";
import Ios from "assets/img/landing/register/register/ios.svg";
import RegBrowser1 from "assets/img/landing/register/register/browser-step-1.svg";
import RegBrowser2 from "assets/img/landing/register/register/browser-step-2.svg";
import RegBrowser3 from "assets/img/landing/register/register/browser-step-3.svg";

import RegAndroid1 from "assets/img/landing/register/register/android-step-1@2x.png";
import RegAndroid2 from "assets/img/landing/register/register/android-step-2@2x.png";
import RegAndroid3 from "assets/img/landing/register/register/android-step-3@2x.png";

import RegIos1 from "assets/img/landing/register/register/ios-step-1@2x.png";
import RegIos2 from "assets/img/landing/register/register/ios-step-2@2x.png";
import RegIos3 from "assets/img/landing/register/register/ios-step-3@2x.png";

import { cardVariants } from "../home/Why";

import "../../styles/main.scss";

const regBrowserSteps = [
  {
    title: "Step 1",
    description: `Open Browser on your device and visit
  https://www.safetravelsmarianas.com`,
    img: RegBrowser1,
  },
  {
    title: "Step 2",
    description: `Click on 3 verticals dots beside the URL`,
    img: RegBrowser2,
  },
  {
    title: "Step 3",
    description: `Click On install the app or install icon it will save on your desktop screen or home screen of mobile`,
    img: RegBrowser3,
  },
];

const regAndroidSteps = [
  {
    title: "Step 1",
    description: `Visit https://www.safetravelsmarianas.com and click on share icon`,
    img: RegAndroid1,
  },
  {
    title: "Step 2",
    description: `Select the Install app option from the list`,
    img: RegAndroid2,
  },
  {
    title: "Step 3",
    description: `Select the Install option on the popup and it will get added on your mobile homescreen`,
    img: RegAndroid3,
  },
];

const regIosSteps = [
  {
    title: "Step 1",
    description: `Visit https://www.safetravelsmarianas.com and click on share icon`,
    img: RegIos1,
  },
  {
    title: "Step 2",
    description: `Click on Add to HomeScreen option`,
    img: RegIos2,
  },
  {
    title: "Step 3",
    description: `Click on the icon of safe travel marianas on homescreen`,
    img: RegIos3,
  },
];

export default () => (
  <section className="landing__how-register-container landing-container">
    <h1 className="landing__how-register-title landing-heading-1">
      How to download Safe Travels Marianas App
    </h1>
    <ForBrowser />
    <ForAndroid />
    <ForIos />
  </section>
);

function ForBrowser() {
  const [control, ref] = useIntersectionObserver("");
  return (
    <div ref={ref} className="landing__how-register-content-container">
      <figure className="landing__register-subtitle-container">
        <img
          className="landing__register-subtitle-logo"
          src={Browser}
          alt="Browser"
        />
        <h3 className="landing__register-subtitle">For Browsers</h3>
      </figure>

      <div className="landing__how-register-cards-container">
        {regBrowserSteps.map(({ title, description, img }) => (
          <figure
            key={title}
            className="landing__how-register-card-img-container"
          >
            <motion.img
              src={img}
              alt={title}
              className="landing__how-register-card-img"
              variants={cardVariants}
              initial="hidden"
              animate={control}
              transition={{ duration: 0.6 }}
            />
            <figcaption className="landing__how-register-card-caption-container landing-text-center">
              <h4 className="landing__how-register-card-caption-subtitle landing-heading-4">
                {title}
              </h4>
              <p className="landing-paragraph">{description}</p>
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
}

function ForAndroid() {
  const [control, ref] = useIntersectionObserver();
  return (
    <div ref={ref} className="landing__how-register-content-container">
      <figure className="landing__register-subtitle-container">
        <img
          className="landing__register-subtitle-logo"
          src={Android}
          alt="Browser"
        />
        <h3 className="landing__register-subtitle">For Android</h3>
      </figure>

      <div className="landing__how-register-cards-container">
        {regAndroidSteps.map(({ title, description, img }) => (
          <figure
            key={title}
            className="landing__how-register-card-img-container"
          >
            <motion.img
              src={img}
              alt={title}
              className="landing__how-register-card-img"
              variants={cardVariants}
              initial="hidden"
              animate={control}
              transition={{ duration: 0.6 }}
            />
            <figcaption className="landing__how-register-card-caption-container landing-text-center">
              <h4 className="landing__how-register-card-caption-subtitle landing-heading-4">
                {title}
              </h4>
              <p className="landing-paragraph">{description}</p>
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
}

function ForIos() {
  const [control, ref] = useIntersectionObserver();
  return (
    <div ref={ref} className="landing__how-register-content-container">
      <figure className="landing__register-subtitle-container">
        <img
          className="landing__register-subtitle-logo"
          src={Ios}
          alt="Browser"
        />
        <h3 className="landing__register-subtitle">For iOS</h3>
      </figure>

      <div className="landing__how-register-cards-container">
        {regIosSteps.map(({ title, description, img }) => (
          <figure
            key={title}
            className="landing__how-register-card-img-container"
          >
            <motion.img
              src={img}
              alt={title}
              className="landing__how-register-card-img"
              variants={cardVariants}
              initial="hidden"
              animate={control}
              transition={{ duration: 0.6 }}
            />
            <figcaption className="landing__how-register-card-caption-container landing-text-center">
              <h4 className="landing__how-register-card-caption-subtitle landing-heading-4">
                {title}
              </h4>
              <p className="landing-paragraph">{description}</p>
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
}
