import { each } from 'lodash';
import { req } from 'react-reqq';
import * as icn from "modules/common/icons";
import { formatDate, transformIncluded, formatNumber } from "modules/common/helper";
import { ToastSuccess } from "modules/common/components/toast";
import * as c from './constants';

const transformTraceExport = (raw, included) => {
  const data = transformIncluded(raw, included);
  return {
    id: data?.id,
    case_id: data?.attributes?.case_id,
    created_at_human: formatDate(data?.attributes?.created_at, 'MMM DD, YYYY hh:mmA'),
    case_number: data?.included?.case?.attributes?.case_number,
    registrant_number: data?.attributes?.registrant_id,
    mobile_number: data?.included?.case?.attributes?.mobile_number,
    name: `${data?.included?.case?.attributes?.first_name} ${data?.included?.case?.attributes?.last_name}`,
    export_range_human: `${formatDate(data?.attributes?.from, 'MMM DD, YYYY')} to ${formatDate(data?.attributes?.to, 'MMM DD, YYYY')}`,
    total_traced_unique: formatNumber(data?.attributes?.total_traced_unique_count, 0),
  };
}

const transformTraceExportItem = (data) => ({
  id: data?.id,
  case_id: data?.attributes?.case_id,
  case_status_code: data?.included?.case?.attributes?.case_status?.code,
  case_number: data?.included?.case?.attributes?.case_number,
  registrant_number: data?.attributes?.registrant_id,
  mobile_number: data?.included?.case?.attributes?.mobile_number,
  name: data?.included?.case
    ? `${data?.included?.case?.attributes?.first_name} ${data?.included?.case?.attributes?.last_name}`
    : ` - deleted - (ID: ${data?.attributes?.case_id})`,
});

export const listTraceExport = (params) => {
  req.get({
    key: c.LIST_TRACE_EXPORT,
    url: '/api/v1/mng/trace_export',
    params,
    transform: (res) => {
      const data = (res?.data || []).map((item) => transformTraceExport(item, res?.included || []));
      req.set(c.PAGER_TRACE_EXPORT, res?.meta?.pagination || {});
      req.set(c.FILTER_TRACE_EXPORT, params);
      return data;
    },
  });
};

export const generateTraceExport = (payload, onSuccess) => {
  req.post({
    key: c.GENERATE_TRACE_EXPORT,
    url: '/api/v1/mng/trace_export',
    payload,
    onSuccess: (res, state) => {
      ToastSuccess(res?.response?.message || 'Success!');
      if (onSuccess) onSuccess();
      listTraceExport(state[c.FILTER_TRACE_EXPORT])
    },
  });
};

export const selectTraceExport = (id, onSuccess) => {
  req.get({
    key: c.SELECT_TRACE_EXPORT,
    url: `/api/v1/mng/trace_export/${id}`,
    transform: (res) => transformTraceExport(res?.data, res?.included || []),
    onSuccess,
  });
};

export const listTraceExportItems = (id) => {
  req.get({
    key: c.LIST_TRACE_EXPORT_ITEMS,
    url: `/api/v1/mng/trace_export/${id}/traces`,
    params: { per_page: 10000 },
    transform: (res) => {
      const data = (res?.data || []).map((item) => transformIncluded(item, res?.included || []));
      const grouped = {};
      each(data, (v) => {
        const case_number = v?.included?.case?.attributes?.case_number;
        const items = grouped[case_number] || [];
        items.push(v);
        grouped[case_number] = items;
      });
      const formatted = [];
      each(grouped, (v) => {
        formatted.push({
          ...transformTraceExportItem(v[0] || {}),
          items: (v || []).map((item) => ({
            id: v.id,
            location_qr_label: `${item?.included?.locationQrCode?.attributes?.label} - ${item?.included?.locationQrCode?.attributes?.type}`,
            location_qr_uuid: item?.attributes?.location_qr_code_uuid,
            date_time: formatDate(item?.included?.scannedLogbook?.attributes?.created_at, 'MMM DD, YYYY hh:mmA'),
          })),
        });
      });
      return formatted;
    },
  });
};

export const graphTraceExport = (id) => {
  selectTraceExport(id, (res1) => {
    const self = transformIncluded(res1?.response?.data, res1?.response?.included || []);
    req.get({
      key: c.TRACE_EXPORT_GRAPH,
      url: `/api/v1/mng/trace_export/${id}/traces`,
      params: { per_page: 10000 },
      transform: (res) => {
        const data = (res?.data || []).map((item) => transformIncluded(item, res?.included || []));
        const grouped = {};
        each(data, (v) => {
          const case_number = v?.included?.case?.attributes?.case_number;
          const items = grouped[case_number] || [];
          items.push(v);
          grouped[case_number] = items;
        });
        // const nodes = [];
        const formattedSelf = {
          id: self?.included?.case?.id,
          label: `SELF (${self?.included?.case?.attributes?.case_number})`,
          image: icn.MAP_CASE_ICON[self?.included?.case?.attributes?.case_status?.name] || icn.MAP_CASE_ICON.DEFAULT,
          font: { color: "#555", size: 16, strokeWidth: 1 },
          case_id: self?.included?.case?.id,
        };
        const nodes = [formattedSelf];
        const edges = [];
        each(grouped, (v1, i) => {
          const v = v1[0];
          if (v?.included?.case?.id) {
            nodes.push({
              id: v?.included?.case?.id,
              label: `CONTACT (${v?.included?.case?.attributes?.case_number || 'n/a'})`,
              image: icn.MAP_CASE_ICON[v?.included?.case?.attributes?.case_status?.name] || icn.MAP_CASE_ICON.DEFAULT,
              font: { color: "#555", size: 16, strokeWidth: 1 },
              case_id: v?.included?.case?.id,
            });
            edges.push({ id: i, from: formattedSelf.id, to: v?.included?.case?.id })
          }
        });
        return {
          nodes,
          edges,
        };
      },
    });
  });
}