import { req } from "react-reqq";
import { join } from "lodash";

import { formatDate } from "modules/common/helper";

const transformTrip = (raw) => {
  const attributes = raw?.attributes;
  return {
    id: raw.id,
    ethnicity: attributes?.ethnicity,
    age_range: attributes?.age_range,
    residency_status: attributes?.residency_status,
    airline_flight_no: attributes?.airline_flight_no,
    origin_country: attributes?.origin_country,
    origin_city: attributes?.origin_city,
    origin_state: attributes?.origin_state,
    is_exiting_within_5_days: attributes?.is_exiting_within_5_days,
    destination: attributes?.destination || "--",
    employment_status: attributes?.employment_status,
    employer_name: attributes?.employer_name,
    occupation: attributes?.occupation,
    type_of_traveler: attributes?.type_of_traveler,
    // staying_location: attributes?.staying_location,
    staying_location: join(attributes?.staying_location, ", "),
    parent_full_name: attributes?.parent_full_name || "-",
    parent_birthdate: attributes?.parent_birthdate,
    parent_gender: attributes?.parent_gender,
    stay_at_name: attributes?.stay_at_name,
    stay_at_address: attributes?.stay_at_address,
    stay_at_number: attributes?.stay_at_number,
    airline_seat_no: attributes?.airline_seat_no,
    airline_seat_letter: attributes?.airline_seat_letter,
    airbnb_address: attributes?.airbnb_address,
    airbnb_name: attributes?.airbnb_name,
    airbnb_number: attributes?.airbnb_number,
    other_staying_location: attributes?.other_staying_location,
    type_of_transportation: attributes?.type_of_transportation,
    island_village: attributes?.island_village,
    vessel_info: attributes?.vessel_info,
    form_contact_info_id: attributes?.survey_form_uuid,
    form_medical_questionaire_id: attributes?.attestation_form_uuid,
    has_household_in_contact_within_14_days:
      attributes?.has_household_in_contact_within_14_days,
    has_household_sick_within_14_days:
      attributes?.has_household_sick_within_14_days,
    has_taken_fever_meds_within_14_days:
      attributes?.has_taken_fever_meds_within_14_days,
    has_tested_positive_for_covid: attributes?.has_tested_positive_for_covid,
    has_visited_clinics_within_14_days:
      attributes?.has_visited_clinics_within_14_days,
    has_visited_farms_within_14_days:
      attributes?.has_visited_farms_within_14_days,
    will_be_tested_for_covid: attributes?.will_be_tested_for_covid,
    mobile_phone: attributes?.mobile_phone,
    work_phone: attributes?.work_phone,
    home_phone: attributes?.home_phone,
    passport_country: attributes?.passport_country,
    passport_number: attributes?.passport_number,
    first_name: attributes?.first_name,
    middle_name: attributes?.middle_name,
    last_name: attributes?.last_name,
    email_address: attributes?.email_address,
    birthdate: formatDate(attributes?.birthdate),
    contact_number:
      attributes?.mobile_phone ||
      attributes?.work_phone ||
      attributes?.home_phone ||
      "--",
    registrant: {
      id: attributes?.registrant_uuid,
      full_name: attributes?.full_name || "--",
      mobile_number:
        attributes?.mobile_phone || attributes?.mobile_number || "--",
    },

    origin: attributes?.origin,
    is_domestic: +attributes?.is_domestic === 1,

    date_of_arrival: formatDate(attributes?.date_of_arrival, "YYYY-MM-DD"),
    date_of_departure: formatDate(attributes?.date_of_departure),
  };
};

export const listTrip = (code, params) => {
  req.get({
    key: `TRIP_LIST/${code}`,
    url: "/api/v1/mng/visit-histories",
    params,
    transform: (res) => {
      return (res.data || []).map(transformTrip);
    },
    onSuccess: (res) => {
      req.set(`TRIP_PAGER/${code}`, res?.response?.meta?.pagination);
    },
  });
};

export const showTripById = (id) => {
  req.get({
    key: "TRIP_BY_ID",
    url: `/api/v1/mng/visit-histories/${id}`,
    transform: (res) => {
      return transformTrip(res.data);
    },
  });
};
