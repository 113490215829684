export const COUNT_CARD = "DASHBOARD/count_card";
export const LINE_CHART = "DASHBOARD/line_chart";
export const BAR_CHART = "DASHBOARD/bar_chart";
export const PIE_CHART = "DASHBOARD/pie_chart";
export const DASHBOARD_TABLE = "DASHBOARD/table";

export const TRACE_ITEM_LIST = "TRACE/trace_item_list";
export const TRACE_ITEM_PAGER = "TRACE/trace_item_pager";

export const TRACE_QUICK_DASHBOARD = "TRACE/trace_quick_dashboard";

export const HUMAN_RATIO = "TRACE/human_ratio";

export const CACHE_ENABLED = true;

export const MAP_DATE_FORMAT_BY_TYPE_SHORT = {
  day: "MMM DD",
  month: "MMM YYYY",
};

export const MAP_DATE_FORMAT_BY_TYPE = {
  day: "MMM DD YYYY",
  month: "MMMM YYYY",
};
