import React from "react";
import { showModal } from "modules/common/components/AlertModal";
import GenericTable from "modules/common/components/GenericTable";
import { useApiGet, useApiLoading } from "react-reqq";
import SurveyKeywordFilter from "modules/special-survey/components/SurveyKeywordFilter";
import { INIT_FILTER } from "../components/AssessmentFilter";
import AssessmentSelected from "./AssessmentSelected";
import { useAssessmentList } from "../hooks";
import {
  countryDataset,
  deleteAssessment,
  duplicateAssessment,
  listAssessment,
} from "../actions";
import SpecialSurveyModal from "../modals/SpecialSurveyModal";

function SurveyAssessmentContainer() {
  const [filter, setFilter] = React.useState(INIT_FILTER);
  const countries = useApiGet(`COUNTRY_DATASET`, []);
  const isLoadingCountries = useApiLoading(`COUNTRY_DATASET`, "get");
  const [selected, setSelected] = React.useState(null);
  const selectFirst = React.useCallback(
    (x) => {
      setSelected(x[0]);
    },
    [setSelected]
  );

  const [isLoading, list] = useAssessmentList(filter, selectFirst);
  const surveyList = list?.filter((l) => !l.is_system_defined);

  React.useEffect(() => {
    countryDataset("COUNTRY");
  }, []);

  const handleAddSpecialSurvey = () => {
    showModal({
      title: "Add Survey Form",
      content: (onClose) => (
        <SpecialSurveyModal
          countries={countries}
          isLoading={isLoadingCountries}
          onClose={onClose}
        />
      ),
    });
  };

  const handleDuplicateAssessment = (id) => {
    duplicateAssessment(id);
  };

  const handleUpdateSpecialSurvey = (data) => {
    showModal({
      title: "Update Survey Form",
      content: (onClose) => (
        <SpecialSurveyModal
          data={data}
          countries={countries}
          isLoading={isLoadingCountries}
          onClose={onClose}
        />
      ),
    });
  };

  const handleDeleteAssessment = (id) => {
    deleteAssessment(id);
  };

  const getTableFormat = React.useCallback(
    () => [
      { key: "title", label: "NAME", width: 200 },
      {
        label: "Validity",
        key: (row) => {
          return (
            <div>
              <span
                className={`badge ${
                  row?.validity_status === "ACTIVE"
                    ? "badge-success"
                    : "badge-danger"
                } mr-2`}
              >
                {row?.validity_status}
              </span>
            </div>
          );
        },
        width: 80,
      },
      {
        label: "Status",
        key: (row) => {
          return (
            <div>
              <span
                className={`badge ${
                  row?.status === "PUBLISHED" ? "badge-success" : "badge-danger"
                } mr-2`}
              >
                {row?.status}
              </span>
            </div>
          );
        },
        width: 80,
      },
      {
        label: "Action",
        key: (row) => {
          return (
            <div>
              <button
                className="btn btn-sm bg-light mx-1"
                type="button"
                style={{ color: "#133963" }}
                onClick={() => handleDuplicateAssessment(row?.id)}
              >
                <i className="fa fa-copy mr-1" />
              </button>
              <button
                className="btn btn-sm bg-light mx-1"
                type="button"
                style={{ color: "#133963" }}
                onClick={() => handleUpdateSpecialSurvey(row)}
              >
                <i className="fa fa-pen mr-1" />
              </button>
              <button
                className="btn btn-sm bg-light mx-1"
                type="button"
                style={{ color: "#133963" }}
                onClick={() => handleDeleteAssessment(row?.id)}
              >
                <i className="fa fa-trash mr-1" />
              </button>
            </div>
          );
        },
        width: 130,
      },
    ],
    []
  );

  const fetchList = (newFilter) => {
    setFilter({ ...newFilter, page: 1 });
    listAssessment({ ...newFilter, page: 1 });
  };

  return (
    <div className="pt-2 bg-light row d-flex justify-content-between m-0 flex-grow-1">
      <div className="col-6 card card-body px-3" style={{ maxWidth: "49%" }}>
        <div className="form-inline p-2 d-flex justify-content-between">
          <SurveyKeywordFilter isLoading={isLoading} onSubmit={fetchList} />
          <div className="p-1">
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={handleAddSpecialSurvey}
            >
              Add Special Survey
            </button>
          </div>
        </div>
        <div className="box-fit-wrapper w-100 flex-grow-1">
          <div className="box-fit-overflow">
            <GenericTable
              isLoading={isLoading}
              format={getTableFormat()}
              data={surveyList}
              selected={selected}
              rowHeight={42}
              onRowClick={
                typeof setSelected === "function"
                  ? ({ rowData }) => setSelected(rowData)
                  : null
              }
            />
          </div>
        </div>
      </div>
      <div className="col-6 card card-body px-3" style={{ maxWidth: "49%" }}>
        {selected?.id ? (
          <div className="row mx-0">
            <div className="col">
              <AssessmentSelected
                id={selected?.id}
                countries={countries}
                isLoadingCountries={isLoadingCountries}
              />
            </div>
          </div>
        ) : (
          <div className="row mx-0">
            <div className="col">
              <div className="text-muted text-center">
                {isLoading ? "Loading..." : "No active form"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

SurveyAssessmentContainer.propTypes = {};

export default SurveyAssessmentContainer;
