import React from "react";
import PropTypes from "prop-types";
import FormSelect from "modules/common/form/FormSelect";
import FormMultipleSelect from "modules/common/form/FormMultipleSelect";
import FormInput from "modules/common/form/FormInput";
import { isEmpty } from "lodash";
import { QuestionsContext } from "../constants";

const INIT_VALUE = {
  parent_id: "",
  parent_answer: "",
  parent_multiple_answer: [],
  parent_visibility_id: "",
  parent_visibility_multiple_answer: [],
  readonly_id: "",
  readonly_multiple_answer: [],
  min_date: "",
  max_date: "",
  clear_child_answer: false,
};

const MAP_PARENT_ANSWER = {
  _ALL_BUT_NOT_NONE: "Atleast 1 checked",
};

export const QuestionAdvanceLabel = ({ value, isDataset }) => {
  const { questions } = React.useContext(QuestionsContext);
  const isAdvance =
    !isEmpty(value?.parent_id) &&
    (!isEmpty(value?.parent_answer) ||
      !isEmpty(value?.parent_multiple_answer) ||
      !isEmpty(value?.min_date) ||
      !isEmpty(value?.max_date) ||
      !isEmpty(value?.readonly_multiple_answer) ||
      value?.clear_child_answer);
  const isAdvanceDataset =
    !isEmpty(value?.parent_id) ||
    !isEmpty(value?.parent_answer) ||
    !isEmpty(value?.parent_multiple_answer) ||
    !isEmpty(value?.min_date) ||
    !isEmpty(value?.max_date) ||
    !isEmpty(value?.readonly_multiple_answer) ||
    value?.clear_child_answer;
  const questionRef = questions.find((x) => x.id === value?.parent_id) || {};
  const questionVisibilityRef =
    questions.find((x) => x.id === value?.parent_visibility_id) || {};
  if (isDataset ? !isAdvanceDataset : !isAdvance) return null;
  return (
    <div className="flex italic text-gray-800">
      <div className="mr-2">(Will Only Show if: </div>
      <div className="truncate font-bold" style={{ maxWidth: 120 }}>
        {questionRef.question || "n/a"} {value.max_date || value.min_date}
      </div>
      <div className="mx-2">&#61;</div>
      <div className="truncate font-bold" style={{ maxWidth: 120 }}>
        {MAP_PARENT_ANSWER[value?.parent_answer] ||
          value?.parent_answer ||
          value?.parent_multiple_answer ||
          "n/a"}
      </div>
      <div>)</div>
      <div className="mr-2">(Visibility Will Only Show if: </div>
      <div className="truncate font-bold" style={{ maxWidth: 120 }}>
        {questionVisibilityRef.question || "n/a"}
      </div>
      <div className="mx-2">&#61;</div>
      <div className="truncate font-bold" style={{ maxWidth: 120 }}>
        {MAP_PARENT_ANSWER[value?.parent_visibility_multiple_answer] ||
          value?.parent_visibility_multiple_answer ||
          "n/a"}
      </div>
      <div>)</div>
    </div>
  );
};

QuestionAdvanceLabel.defaultProps = {
  isDataset: false,
};

QuestionAdvanceLabel.propTypes = {
  value: PropTypes.instanceOf(Object).isRequired,
  isDataset: PropTypes.bool,
};

const QuestionAdvanceOptions = ({ id, onChange, value, isDataset }) => {
  const [form, setForm] = React.useState(value || INIT_VALUE);

  const datasetAdvanceValue =
    !isEmpty(value?.parent_id) ||
    !isEmpty(value?.parent_answer) ||
    !isEmpty(value?.parent_multiple_answer) ||
    !isEmpty(value?.min_date) ||
    !isEmpty(value?.max_date) ||
    !isEmpty(value?.readonly_multiple_answer) ||
    value?.clear_child_answer;
  const advanceValue =
    (!isEmpty(value?.parent_id) &&
      (!isEmpty(value?.parent_answer) ||
        !isEmpty(value?.parent_multiple_answer))) ||
    !isEmpty(value?.min_date) ||
    !isEmpty(value?.max_date) ||
    !isEmpty(value?.readonly_multiple_answer) ||
    value?.clear_child_answer;
  const [advance, setAdvance] = React.useState(
    isDataset ? datasetAdvanceValue : advanceValue
  );

  React.useEffect(() => {
    if (!advance) {
      onChange((state) => ({
        ...state,
        meta: {
          ...state.meta,
          ...INIT_VALUE,
        },
      }));
      setForm(INIT_VALUE);
    }
  }, [advance, onChange]);

  React.useEffect(() => {
    const datasetFormValue =
      form.parent_id ||
      form.parent_answer ||
      form.parent_multiple_answer ||
      form.parent_visibility_id ||
      form.parent_visibility_multiple_answer ||
      form.readonly_multiple_answer ||
      form.readonly_id ||
      form.clear_child_answer;
    const formValue =
      (form.parent_id && (form.parent_answer || form.parent_multiple_answer)) ||
      form.parent_visibility_id ||
      form.parent_visibility_multiple_answer ||
      form.readonly_multiple_answer ||
      form.readonly_id ||
      form.clear_child_answer;

    if (isDataset ? datasetFormValue : formValue) {
      onChange((state) => ({
        ...state,
        meta: {
          ...(state?.meta || {}),
          ...form,
        },
      }));
    }
  }, [form, onChange, isDataset]);

  const handleChangeGroup = ({ target }) => {
    onChange((state) => ({
      ...state,
      meta: {
        ...(state?.meta || {}),
        ...form,
        group: target?.value,
      },
    }));
    setForm({
      ...form,
      group: target?.value,
    });
  };
  const { questions } = React.useContext(QuestionsContext);

  const selectedQuestion = questions.find((x) => x.id === id);

  const questionOptions = React.useMemo(
    () =>
      questions
        .filter(
          (x) =>
            (x.id !== id && x.type === "MULTIPLE_CHOICE") ||
            (x.id !== id && x.type === "DROPDOWN") ||
            (x.id !== id && x.type === "CHECKBOX")
        )
        .map((x) => ({
          label: x.question,
          value: x.id,
        })),
    [id, questions]
  );
  const questionDatasetOptions = React.useMemo(
    () =>
      questions
        .filter(
          (x) =>
            (x.id !== id && x.type === "MULTIPLE_CHOICE") ||
            (x.id !== id && x.type === "DROPDOWN") ||
            (x.id !== id && x.type === "CHECKBOX") ||
            (x.id !== id && x.type === "DROPDOWN_DATASET") ||
            (x.id !== id && x.type === "DROPDOWN_DATASET_CHILD")
        )
        .map((x) => ({
          label: x.question,
          value: x.id,
        })),
    [id, questions]
  );
  const parentQuestion = React.useMemo(() => {
    return questions.find((x) => x.id === form.parent_id) || {};
  }, [questions, form.parent_id]);
  const parentOptions = React.useMemo(() => {
    return (questions.find((x) => x.id === form.parent_id)?.options || []).map(
      (x) => ({
        label: x.label,
        value: x.label,
      })
    );
  }, [questions, form.parent_id]);

  const parentVisibilityQuestion = React.useMemo(() => {
    return questions.find((x) => x.id === form.parent_visibility_id) || {};
  }, [questions, form.parent_visibility_id]);
  const parentVisibilityOptions = React.useMemo(() => {
    return (
      questions.find((x) => x.id === form.parent_visibility_id)?.options || []
    ).map((x) => ({
      label: x.label,
      value: x.label,
    }));
  }, [questions, form.parent_visibility_id]);

  const readOnlyQuestion = React.useMemo(() => {
    return questions.find((x) => x.id === form.readonly_id) || {};
  }, [questions, form.readonly_id]);
  const readOnlyOptions = React.useMemo(() => {
    return (
      questions.find((x) => x.id === form.readonly_id)?.options || []
    ).map((x) => ({
      label: x.label,
      value: x.label,
    }));
  }, [questions, form.readonly_id]);

  React.useEffect(() => {
    if (parentQuestion.type === "CHECKBOX") {
      setForm((state) => ({
        ...state,
        parent_multiple_answer: "_ALL_BUT_NOT_NONE",
        parent_visibility_multiple_answer: "_ALL_BUT_NOT_NONE",
      }));
    }
  }, [parentQuestion.type]);

  // React.useEffect(() => {
  //   if (selectedQuestion.type === "DATE") {
  //     setForm((state) => ({
  //       ...state,
  //       min_date: 0,
  //     }));
  //   }
  // }, [selectedQuestion.type]);
  // console.log(form.parent_answer)
  return (
    <>
      <hr />
      <div className="row">
        <div className="col d-flex align-items-center">
          <label className="block mb-0 d-flex align-items-center">
            <input
              type="checkbox"
              className="mr-2"
              onChange={({ target }) => {
                setAdvance(target.checked);
              }}
              checked={advance}
            />
            Advance Settings
          </label>
        </div>
        <div className="col-4">
          <input
            className="form-control"
            placeholder="Group code"
            onChange={handleChangeGroup}
            value={form?.group}
          />
        </div>
      </div>
      {advance && (
        <>
          <div className="grid grid-cols-2 gap-2">
            {selectedQuestion.type === "DATE" ? (
              <div className="mb-2">
                <div className="row">
                  <div className="col mb-2">
                    <FormInput
                      type="number"
                      name="min_date"
                      label="Min Date"
                      placeholder="Ex. 0"
                      onChange={setForm}
                      value={form.min_date || ""}
                    />
                  </div>
                  <div className="col mb-2">
                    <FormInput
                      type="number"
                      name="max_date"
                      label="Max Date"
                      placeholder="Ex. 0"
                      onChange={setForm}
                      value={form.max_date || ""}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mb-2">
              <FormSelect
                name="parent_id"
                onChange={setForm}
                value={form.parent_id}
                options={isDataset ? questionDatasetOptions : questionOptions}
                label="Parent Question"
              />
            </div>
            {parentQuestion.type === "MULTIPLE_CHOICE" ||
            parentQuestion.type === "DROPDOWN" ||
            parentQuestion.type === "DROPDOWN_DATASET" ||
            parentQuestion.type === "DROPDOWN_DATASET_CHILD" ||
            parentQuestion.type === "CHECKBOX" ? (
              <>
                <div className="mb-2">
                  <FormSelect
                    name="parent_answer"
                    onChange={setForm}
                    value={form.parent_answer}
                    options={parentOptions}
                    label="Parent Answer"
                  />
                </div>
                <div className="mb-2">
                  <FormMultipleSelect
                    name="parent_multiple_answer"
                    onChange={setForm}
                    value={form.parent_multiple_answer}
                    options={parentOptions}
                    label="Parent Multiple Answer"
                  />
                </div>
              </>
            ) : null}
            {parentQuestion.type === "CHECKBOX" && (
              <div className="mb-2 py-2 leading-none">
                Will ONLY show if atleast 1 item is selected
              </div>
            )}
          </div>
          <div className="text-xs text-gray-800 italic">
            Questions with parent question will ONLY appear if the selected
            parent answer is chosen
          </div>
          <div className="mb-2">
            <FormSelect
              name="parent_visibility_id"
              onChange={setForm}
              value={form.parent_visibility_id}
              options={questionOptions}
              label="Parent Visibility Question"
            />
          </div>
          {parentVisibilityQuestion.type === "MULTIPLE_CHOICE" ||
          parentVisibilityQuestion.type === "DROPDOWN" ||
          parentVisibilityQuestion.type === "DROPDOWN_DATASET" ||
          parentVisibilityQuestion.type === "DROPDOWN_DATASET_CHILD" ||
          parentVisibilityQuestion.type === "CHECKBOX" ? (
            <div className="mb-2">
              <FormMultipleSelect
                name="parent_visibility_multiple_answer"
                onChange={setForm}
                value={form.parent_visibility_multiple_answer}
                options={parentVisibilityOptions}
                label="Parent Visibility Multiple Answer"
              />
            </div>
          ) : null}
          <div className="mb-2">
            <FormSelect
              name="readonly_id"
              onChange={setForm}
              value={form.readonly_id}
              options={questionOptions}
              label="Parent Readonly Question"
            />
          </div>
          {readOnlyQuestion.type === "MULTIPLE_CHOICE" ||
          readOnlyQuestion.type === "DROPDOWN" ||
          readOnlyQuestion.type === "CHECKBOX" ? (
            <div className="mb-2">
              <FormMultipleSelect
                name="readonly_multiple_answer"
                onChange={setForm}
                value={form.readonly_multiple_answer}
                options={readOnlyOptions}
                label="Parent Readonly Multiple Answer"
              />
            </div>
          ) : null}
          {selectedQuestion.type === "MULTIPLE_CHOICE" ? (
            <div className="mb-2">
              <div className="col d-flex align-items-center items-center p-0">
                <label className="block mb-0 ">
                  <input
                    type="checkbox"
                    onChange={({ target }) => {
                      setForm((state) => ({
                        ...state,
                        clear_child_answer: target.checked,
                      }));
                    }}
                    checked={form.clear_child_answer}
                  />
                  <span className="mx-2">Clear Child Answer ?</span>
                </label>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

QuestionAdvanceOptions.defaultProps = {
  isDataset: false,
};

QuestionAdvanceOptions.propTypes = {
  id: PropTypes.string.isRequired,
  isDataset: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export default React.memo(QuestionAdvanceOptions);
