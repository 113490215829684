import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";

import RegistrantsContainer from "./RegistrantsContainer";
import RegistrantPage from "./RegistrantPage";

function RegistrantRoutes() {
  const match = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Travelers - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <Switch>
        <Route exact path={match.url} component={RegistrantsContainer} />
        <Route path={`${match.url}/:id`} component={RegistrantPage} />
      </Switch>
    </>
  );
}

export default RegistrantRoutes;
