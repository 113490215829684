import React from "react";
import PropTypes from "prop-types";
import { useApiLoading } from "react-reqq";
import FormInput from "modules/common/form/FormInput";
import * as actions from "../actions";
import * as c from "../constants";

function DisableMfaModal({ onClose }) {
  const isLoading = useApiLoading(c.MFA, "remove");
  const [form, setForm] = React.useState({
    password: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.disableMfa(form, onClose);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 form-group">
            <FormInput
              name="password"
              label="Please enter your password to disable MFA"
              onChange={setForm}
              value={form.password}
              type="password"
              autoFocus
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-danger" type="submit" disabled={isLoading}>
          Disable MFA
        </button>
        <button
          className="btn btn-outline-primary ml-1"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

DisableMfaModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DisableMfaModal;
