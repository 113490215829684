import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "modules/auth/containers/Login";
import RegisterContainer from "modules/auth/containers/RegisterContainer";
import SiteLogin from "modules/auth/containers/SiteLogin";
import ResetPassword from "modules/auth/containers/ResetPassword";
import RedirectPage from "modules/auth/containers/RedirectPage";
import PageNotFound from "modules/common/containers/PageNotFound";
import VerifyEmail from "./modules/auth/containers/VerifyEmail";
import HowToRegister from "./modules/landing/pages/register";
import FAQ from "./modules/landing/pages/faq";

function Public() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/how-to-register" component={HowToRegister} />
      <Route path="/faq" component={FAQ} />
      <Route path="/signin" component={Login} />
      <Route path="/signup" component={RegisterContainer} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/authenticate" component={SiteLogin} />
      <Route path="/redirect" component={RedirectPage} />
      <Route path="/verify-email" component={VerifyEmail} />
      <Route path="/logout">
        <Redirect to="/signin" />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Public;
