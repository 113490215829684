import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { usePersistState } from "modules/common/helper";
import { HeaderTitle } from "modules/common/components/Header";
import GenericTable from "modules/common/components/GenericTable";
import Pagination from "modules/common/components/Pagination";
import userThumb from "assets/img/thumb-user-admin.png";
import RegistrantFilter from "./RegistrantFilter";
import { useRegistrantList } from "./hooks";

function RegistrantsContainer() {
  const history = useHistory();
  const [filter, setFilter] = usePersistState("registrants-list", { page: 1 });
  const [isLoading, list, pager] = useRegistrantList(filter);
  const tableFormat = useMemo(
    () => [
      {
        key: (row) => (
          <img
            className="img img-fluid rounded"
            src={row.photo_url || userThumb}
            style={{ height: 28, width: 28 }}
            alt=""
          />
        ),
        label: " ",
        width: 42,
      },
      { key: "full_name", label: "Name" },
      {
        key: (row) => {
          const mobileNumber =
            row?.mobile_number === "-"
              ? row?.latest_trip_mobile_number
              : row?.mobile_number;

          return <span>{mobileNumber || "-"}</span>;
        },
        label: "Contact Number",
      },
      {
        key: (row) => (
          <div>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => history.push(`/registrants/${row.id}`)}
              type="button"
            >
              Details
            </button>
          </div>
        ),
        label: "",
        width: 60,
      },
    ],
    []
  );
  return (
    <>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Travelers</div>
      </HeaderTitle>
      <div className="card">
        <div className="p-3">
          <RegistrantFilter
            isLoading={isLoading}
            onSubmit={setFilter}
            value={filter}
          />
        </div>
        <div style={{ height: 610 }}>
          <GenericTable
            isLoading={isLoading}
            format={tableFormat}
            data={list}
            rowHeight={38}
          />
        </div>
        <Pagination
          data={pager}
          isLoading={isLoading}
          onChange={(page) => setFilter({ ...filter, page })}
        />
      </div>
    </>
  );
}

export default RegistrantsContainer;
