import React, { useEffect, useState } from "react";
import { useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import _ from "lodash";
import moment from "moment";
import DOMPurify from "dompurify";
import { req } from "react-reqq";

import { useProfile } from "modules/auth/hooks";
import sha256 from "crypto-js/sha256";
import { ToastError } from "./components/toast";
import * as icn from "./icons";

export const delay = (t = 1000) => new Promise((r) => setTimeout(r, t));

export const diffObject = (rObject, rBase) => {
  const changes = (object, base) =>
    _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = value; // eslint-disable-line
      }
    });
  return changes(rObject, rBase);
};

export const limitString = (str, max_length, ellipsis = "…") =>
  `${str}`.length > max_length
    ? `${`${str || ""}`.substring(0, max_length)}${ellipsis}`
    : str;

export const isNumber = (number) => {
  if (_.isEmpty(`${number}`)) return true;
  // const regexp = /^[0-9]+([,.][0-9]+)?$/g;
  const regexp = /^[0-9.]*$/;
  return regexp.test(number);
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatDate = (date, format = "MM/DD/YYYY", defaultValue = "-") => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return defaultValue;
  return moment(d).format(format);
};

export const formatDateTime = (
  date,
  format = "MM/DD/YYYY HH:mm:ss",
  defaultValue = "-"
) => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return defaultValue;
  return moment(d).format(format);
};

export const formatTime = (time, format = "HH:mm") => {
  if (!time) return "";
  const d = moment(time, ["hh:mm A"]);
  if (!d.isValid()) return "";
  return d.format(format);
};

export const parseTime = (time, format = "hh:mm A") => {
  if (!time) return "";
  const d = moment(time, ["HH:mm"]);
  if (!d.isValid()) return "";
  return d.format(format);
};

export const generate = (length = 10) => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const replaceParam = (match, params) => {
  try {
    const arr = match.path.split("/");
    const arr2 = window.location.pathname.split("/");
    let url = "";

    for (let i = 0; i < arr2.length; i += 1) {
      const x = arr[i] || arr2[i];
      if (x) {
        const key = x.replace(":", "").replace("?", "");
        const newValue = params[key];
        url = `${url}/${newValue || x}`;
      }
    }

    return url;
  } catch (err) {
    return "/";
  }
};

export const replaceUrl = (base, newUrl) => {
  const arr1 = base.split("/");
  const arr2 = newUrl.split("/");
  return arr1.map((x, i) => arr2[i] || x).join("/");
};

export const renderPercentage = (current, previous) => {
  if (
    current === "now" ||
    (typeof current !== "number" &&
      typeof previous !== "number" &&
      !current &&
      !previous)
  )
    return <span className="badge badge-info">NOW</span>;
  if (previous === 0) return null;
  const percentage = ((current - previous) / previous) * 100;
  const percentage_string = formatNumber(percentage);
  const p = percentage > 999 ? "+999%" : `${percentage_string}%`;
  if (percentage < 0)
    return (
      <span className="badge badge-danger" title={percentage_string}>
        <i className="fa fa-caret-down" />
        {p}
      </span>
    );
  if (percentage > 0)
    return (
      <span className="badge badge-success" title={percentage_string}>
        <i className="fa fa-caret-up" />
        {p}
      </span>
    );
  return null;
};

export const useACL = (permission) => {
  const auth = useProfile();
  const [hasAccess, setHasAccess] = React.useState(false);
  React.useEffect(() => {
    setHasAccess((auth.permissions || []).indexOf(permission) > -1);
  }, []);
  return hasAccess;
};

export const loadAPI = (url, onLoad, onReject) => {
  try {
    const tag = document.createElement("script");
    tag.type = "text/javascript";
    tag.async = false;

    const handleLoad = () => {
      onLoad();
    };
    const handleReject = () => {
      onReject();
    };

    tag.addEventListener("load", handleLoad);
    tag.addEventListener("error", handleReject);
    tag.src = url;
    document.getElementsByTagName("body")[0].appendChild(tag);
  } catch (err) {
    console.error(err); // eslint-disable-line
    onReject(err);
  }
};

export const renderDangerous = (content) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
);

export const renderColor = (color) => (
  <span
    className="label rounded"
    style={{ backgroundColor: color, width: 50, display: "block" }}
  >
    &nbsp;
  </span>
);

export const renderMarker = (value) => (
  <div className="text-center">
    <img src={icn.MARKER_ICON[value]} alt="marker" style={{ maxHeight: 21 }} />
  </div>
);

export const removeNull = (obj) =>
  _.omitBy(obj, (x) => typeof x === "undefined" || x === null);

export const removeFalsyValue = (arg) =>
  _.omitBy(arg, (v) => _.isUndefined(v) || _.isNull(v) || v === "");

export const removeEmpty = (obj) => _.omitBy(obj, (x) => _.isEmpty(`${x}`));

export const isValidLatLng = (geoloc) => {
  try {
    const arr = geoloc.split(",");
    if (_.isEmpty(arr[0] || "")) return false;
    return !(Number.isNaN(+arr[0]) || Number.isNaN(+arr[0]));
  } catch (err) {
    return false;
  }
};

export const toLngLat = (
  geoloc,
  default_value = {
    lng: 0,
    lat: 0,
  }
) => {
  try {
    const arr = geoloc.split(",");
    return {
      lng: +arr[1] || 0,
      lat: +arr[0] || 0,
    };
  } catch (err) {
    return default_value;
  }
};

export const hash = (value) => sha256(value).toString();

export const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return true;
  }
  if (
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  }
  return false;
};

export const scrollTo = (id) => {
  try {
    const elem = document.getElementById(id);
    if (id) elem.scrollIntoView({ behavior: "smooth" });
  } catch (err) {
    // do nothing...
  }
};

const padBounds = (bounds, padding = 0.2) => {
  const x1 = bounds[0];
  const y1 = bounds[1];
  const x2 = bounds[2];
  const y2 = bounds[3];

  // const x_center = (((x1 + x2) - x1) / 2) + x1;
  // const y_center = (((y1 + y2) - y1) / 2) + y1;
  // const w_padded = (x2 - x1) * padding;
  // const h_padded = (y2 - y1) * padding;
  // const size = w_padded > h_padded ? w_padded : h_padded;

  // const new_x1 = Math.floor(x_center - (size / 2));
  // const new_y1 = Math.floor(y_center - (size / 2));
  // const new_x2 = Math.floor(x_center + (size / 2)) - new_x1;
  // const new_y2 = Math.floor(y_center + (size / 2)) - new_y1;

  const new_x1 = Math.floor(x1 - x2 * padding);
  const new_y1 = Math.floor(y1 - y2 * padding);
  const new_x2 = Math.floor(x2 + x2 * padding * 2);
  const new_y2 = Math.floor(y2 + y2 * padding * 2);

  return {
    x: new_x1,
    y: new_y1,
    w: new_x2,
    h: new_y2,
  };
};

export const autoCropFace = (ucare_url) =>
  new Promise((resolve) => {
    req.get({
      key: "AUTO_CROP",
      url: () => `${ucare_url}detect_faces/`,
      onSuccess: (res) => {
        const face = _.get(res, "response.faces.0") || [];
        if (_.isEmpty(face)) {
          ToastError("Unable to crop automatically. No face detected.");
          resolve(ucare_url);
          return;
        }
        const { x, y, w, h } = padBounds(face);
        resolve(`${ucare_url}-/crop/${w}x${h}/${x},${y}/-/preview/`);
      },
      onError: () => {
        ToastError("Unable to crop automatically. Crop error!");
        resolve(ucare_url);
      },
    });
  });

export const focusElement = (id) => {
  try {
    const elem = document.getElementById(id);
    elem.focus();
  } catch (err) {
    // eslint-disable-line
  }
};

export const toBorder = (border) => {
  try {
    const borderArr = JSON.parse(border);
    let newBorder = [];
    if (typeof _.get(borderArr, "0.0.0") === "number") {
      newBorder = (borderArr[0] || []).map((v) => ({
        lng: v[0],
        lat: v[1],
      }));
    }
    if (typeof _.get(borderArr, "0.0.0.0") === "number") {
      newBorder = borderArr.map((item) =>
        (item[0] || []).map((v) => ({
          lng: v[0],
          lat: v[1],
        }))
      );
    }
    return newBorder;
  } catch (err) {
    return [];
  }
};

export const transformIncluded = (x, included) => {
  if (!included || _.isEmpty(included)) return x;
  const rowIncluded = {};
  _.forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === _.get(z, "type") && y.id === _.get(z, "id")
            ) || {}
        )
      : included.find(
          (y) =>
            y.type === _.get(v, "data.type") && y.id === _.get(v, "data.id")
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

const storage = {
  get: (key) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return false;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

const default_removeOnUnmount = true;
export const usePersistState = (
  key,
  state,
  removeOnUnmount = default_removeOnUnmount
) => {
  const [value, setValue] = React.useState(storage.get(key) || state);
  const updateState = React.useCallback(
    _.debounce((newState) => {
      storage.set(key, newState);
    }, 500),
    []
  );
  React.useEffect(() => {
    updateState(value);
    return () => {
      if (removeOnUnmount) storage.remove(key);
    };
  }, [value, removeOnUnmount]);
  return [value, setValue];
};

export const buildDateArray = (
  from,
  to,
  format = "YYYY-MM-DD",
  increment = "day"
) => {
  let xfrom = moment(from);
  const xto = moment(to);
  const arr = [];
  while (xfrom <= xto) {
    arr.push(xfrom.format(format));
    xfrom.add(1, increment);
    if (arr.length > 100) xfrom = xto;
  }
  return arr;
};

export const capByDate = (count, date, today = moment()) => {
  const refer = moment(new Date(date));
  if (refer > today) return null;
  return count;
};

export const objectToUpperCase = (params, ignore = []) => {
  const newObjt = {};
  _.each(Object.keys(params), (item) => {
    newObjt[item] =
      ignore.indexOf(item) < 0 &&
      params[item] &&
      typeof params[item] === "string"
        ? `${params[item].toUpperCase()}`
        : params[item];
  });
  return newObjt;
};

export const useWindowScrollPositions = () => {
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 });

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY });
    }

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: "",
    height: "",
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const el = ref?.current;
      if (!el || el.contains(event?.target || null)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const useIntersectionObserver = (
  margin = "-300px 0px",
  disable = false
) => {
  const control = useAnimation();

  const [ref, inView] = useInView({
    rootMargin: margin,
    triggerOnce: true,
  });
  useEffect(() => {
    if (disable) {
      control.start("visible");
      return;
    }
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return [control, ref];
};

export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function useDebounce(value, delayCount) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delayCount);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delayCount]);

  return debouncedValue;
}
