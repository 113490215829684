import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import moment from "moment";
import AddPanel from "../components/AddPanel";
import Questions from "../components/Questions";
import { useAssessmentQuestionList } from "../hooks";

const DEFAULT_FILTER = {
  per_page: 100,
};
function AssessmentQuestionsContainer({ id, data }) {
  const [filter, setFilter] = React.useState(DEFAULT_FILTER);
  const [isLoading, list] = useAssessmentQuestionList(id, filter);
  const [preview, setPreview] = React.useState(true);
  const handleTogglePreview = (e) => {
    e.preventDefault();
    setPreview(!preview);
    if (!preview) {
      setFilter((state) => ({ ...state, t: new Date().getTime() }));
    }
  };

  const panel = React.useMemo(() => {
    return <>{!preview && <AddPanel assessmentId={id} />}</>;
  }, [preview, id]);

  return (
    <div
      className="bg-gray-200 py-3 px-2"
      style={{ overflowY: "auto", minHeight: 600 }}
    >
      <div className="card card-body mb-2 d-flex flex-row">
        {!preview && (
          <div className="mr-auto">
            <a href="/" onClick={handleTogglePreview}>
              <i className="fa fa-arrow-left" />
            </a>
          </div>
        )}
        <div className="mr-auto">
          <span className="d-block font-weight-bold text-md">
            {data?.title}
          </span>
          <span className="d-block font-weight-light text-sm mb-3">
            {data?.description}
          </span>
          {data?.meta?.origin.map((country) => (
            <span className="mr-1 bg-light px-2 py-1 rounded-lg">
              {country}
            </span>
          ))}
        </div>
        <div className="ml-auto">
          <div className="d-flex align-items-center">
            {preview ? (
              <div className="mr-3">Preview Mode</div>
            ) : (
              <span className="d-block font-weight-normal text-xs mb-3">
                Last Updated:
                <span className="font-weight-bold text-xs">
                  {" "}
                  {moment(data?.updated_at).format("ll")}
                </span>
                <span className="btn btn-xs text-xs bg-light mx-1">
                  {data?.status}
                </span>
              </span>
            )}
            {preview && (
              <button
                className={cn("btn btn-sm btn-outline-success")}
                type="button"
                onClick={handleTogglePreview}
              >
                Edit Mode
              </button>
            )}
          </div>
        </div>
      </div>
      <div style={{ margin: "0 auto", maxWidth: 650, maxHeight: 500 }}>
        <div
          className="pl-2 pt-4 pb-1"
          style={{ margin: 0, zIndex: 99999, marginLeft: 1 }}
        >
          <Questions
            assessmentId={id}
            isLoading={isLoading}
            questions={list}
            editMode={preview}
          />
          {panel}
        </div>
      </div>
    </div>
  );
}

AssessmentQuestionsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default AssessmentQuestionsContainer;
