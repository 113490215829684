import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import GenericDetail from "modules/common/components/GenericDetail";
import { useSelectTraceExport } from '../hooks';

function TraceExportData({ id, print }) {
  const history = useHistory();
  const [isLoading, data] = useSelectTraceExport(id);
  const handleView = () => {
    history.push(`/cases/list/${data?.case_id}`);
  };
  const handlePrint = () => {
    history.push(`/export-contact-tracing/${data?.id}/print`);
  };

  const caseDetailFormat = React.useMemo(() => ([
    { label: 'Name', key: 'name' },
    { label: 'Case Number', key: 'case_number' },
    { label: 'Registrant Number', key: 'registrant_number' },
    { label: 'Mobile Number', key: 'mobile_number' },
  ]), []);
  const exportDetailsFormat = React.useMemo(() => ([
    { label: 'Date Exported', key: 'created_at_human' },
    { label: 'Scope', key: 'export_range_human' },
    { label: 'Total Number of Contacts', key: 'total_traced_unique' },
  ]), []);

  return (
    <div className="d-flex p-3">
      <div className="flex-grow-1">
        <GenericDetail
          isLoading={isLoading}
          labelWidth="140px"
          format={caseDetailFormat}
          data={data}
        />
      </div>
      <div className="flex-grow-1">
        <GenericDetail
          isLoading={isLoading}
          labelWidth="200px"
          format={exportDetailsFormat}
          data={data}
        />
      </div>
      {!print ? (
        <div className="px-3 d-print-none d-flex flex-column">
          <button className="btn btn-outline-primary btn-sm mb-1" type="button" onClick={handlePrint} disabled={!data?.id}>
            <i className="fa fa-print mr-1" />Print Export
          </button>
          <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleView} disabled={!data?.id}>
            <i className="fa fa-eye mr-1" />View Case
          </button>
        </div>
      ) : null}
    </div>
  )
}

TraceExportData.defaultProps = {
  print: false,
};

TraceExportData.propTypes = {
  id: PropTypes.string.isRequired,
  print: PropTypes.bool
}

export default TraceExportData
