import React from "react";

import "../styles/main.scss";

export default () => (
  <footer className="landing__footer-container">
    <div className="landing__footer-paragraph-container landing-container">
      <p>&copy; 2021 Safe Travels Marianas v1.2.0. All rights reserved.</p>
      <p>
        For More Info Visit:{" "}
        <a href="mailto:InfoVisit:Info@safetravelsmarianas.com">
          Info@safetravelsmarianas.com
        </a>
      </p>
    </div>
  </footer>
);
