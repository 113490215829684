import React from "react";
import { motion } from "framer-motion";

import { useIntersectionObserver } from "modules/common/helper";

import Step1 from "assets/img/landing/register/travelers/traveler-step-1@2x.png";
import Step2 from "assets/img/landing/register/travelers/traveler-step-2@2x.png";
import Step3 from "assets/img/landing/register/travelers/traveler-step-3@2x.png";
import Step4 from "assets/img/landing/register/travelers/traveler-step-4@2x.png";
import Step5 from "assets/img/landing/register/travelers/traveler-step-5@2x.png";

import { cardVariants } from "../home/Why";

import "../../styles/main.scss";

const stepsRow1 = [
  {
    title: "Step 1",
    description: `Log In to https://www.safetravelsmarianas.com/ and click to Proceed`,
    img: Step1,
  },
  {
    title: "Step 2",
    description: `Enter your mobile number and you will receive 6 digit OTP , verify and proceed to next step`,
    img: Step2,
  },
  {
    title: "Step 3",
    description: `Enter Your Information`,
    img: Step3,
  },
];

const stepsRow2 = [
  {
    title: "Step 4",
    description: `Update your health check & Save your QR code to gallery/ web favourites`,
    img: Step4,
  },
  {
    title: "Step 5",
    description: `Scan In QR codes of entered/ exited establish route`,
    img: Step5,
  },
];

export default () => {
  const [control, ref] = useIntersectionObserver();
  return (
    <section ref={ref} className="landing__how-travelers-parent-container">
      <div className="landing__how-travelers-container landing-container">
        <h1 className="landing__how-travelers-title landing-heading-1">
          For Travelers And Non-Travelers
        </h1>
        <div className="landing__how-travelers-cards-container--1">
          {stepsRow1.map(({ title, description, img }) => (
            <figure
              className="landing__how-travelers-card-container"
              key={title}
            >
              <motion.img
                src={img}
                alt={title}
                className="landing__how-travelers-card-img"
                variants={cardVariants}
                initial="hidden"
                animate={control}
                transition={{ duration: 0.6 }}
              />
              <figcaption className="landing__how-travelers-card-caption-container">
                <h4 className="landing__how-travelers-card-caption-subtitle landing-heading-4">
                  {title}
                </h4>
                <p className="landing__how-travelers-card-caption-paragraph landing-paragraph">
                  {description}
                </p>
              </figcaption>
            </figure>
          ))}
        </div>
        <RowTwo />
      </div>
    </section>
  );
};

function RowTwo() {
  const [control, ref] = useIntersectionObserver();
  return (
    <div ref={ref} className="landing__how-travelers-cards-container--2">
      {stepsRow2.map(({ title, description, img }) => (
        <figure className="landing__how-travelers-card-container" key={title}>
          <motion.img
            src={img}
            alt={title}
            className="landing__how-travelers-card-img"
            variants={cardVariants}
            initial="hidden"
            animate={control}
            transition={{ duration: 0.6 }}
          />
          <figcaption className="landing__how-travelers-card-caption-container">
            <h4 className="landing__how-travelers-card-caption-subtitle landing-heading-4">
              {title}
            </h4>
            <p className="landing__how-travelers-card-caption-paragraph landing-paragraph">
              {description}
            </p>
          </figcaption>
        </figure>
      ))}
    </div>
  );
}
