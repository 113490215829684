import { useEffect } from 'react';
import { useApiGet } from 'react-reqq';
import * as actions from './actions';
import * as c from './constants';

export const useAuth = () => {
  const data = useApiGet(`${c.AUTHENTICATION}`, {});
  useEffect(() => {
    actions.checkAuth();
  }, []);
  return data;
};

export const useProfile = () => {
  const data = useApiGet(`${c.AUTHENTICATION}`, {});
  return data;
};
