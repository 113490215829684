import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { render } from "react-dom";
import { store, history } from "index";
import _ from "lodash";
import { useApiGet } from "react-reqq";
import {
  AUTHENTICATION,
  SUPER_ADMIN_ACCESS_KEY,
  // ADMIN_ACCESS_KEY,
} from "modules/auth/constants";
import { AclContext } from "../context";

const modalUniqueID = "my-modal-marker";

const ModalContent = ({ opt, close }) => {
  const { profile } = useApiGet(AUTHENTICATION, {});
  const isSuperAdmin =
    _.get(profile, "access_control") === SUPER_ADMIN_ACCESS_KEY;
  // const isAdmin = profile.access_control === ADMIN_ACCESS_KEY;

  const perms = _.get(profile, "acl.permission") || [];
  const acl = React.useCallback(
    (id) => {
      if (isSuperAdmin) return true;
      if (typeof id === "string") {
        return perms.indexOf(id) > -1;
      }
      if (Array.isArray(id)) {
        return perms.some((i) => id.includes(i));
      }
      return false;
    },
    [perms]
  );

  const [isFullscreen, setIsFullScreen] = React.useState(
    opt.fullScreen || false
  );
  const renderContent = () => {
    if (typeof opt.content === "function") return opt.content(close);
    return opt.content;
  };
  const [content, setContent] = React.useState(renderContent());
  const toggleFullscreen = (e) => {
    e.preventDefault();
    setIsFullScreen(!isFullscreen);
    setContent(null);
    setTimeout(() => {
      setContent(renderContent());
    }, 100);
  };
  const handleClose = (e) => {
    e.preventDefault();
    close();
  };
  React.useEffect(() => {
    setContent(renderContent());
  }, [opt.content]);
  return (
    <AclContext.Provider value={{ acl }}>
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block", paddingRight: 15 }}
      >
        <div
          className={
            isFullscreen
              ? "modal-dialog modal-full"
              : `modal-dialog ${opt.className}`
          }
          role="document"
        >
          <div className="modal-content">
            {!!opt.title && typeof opt.title === "string" && (
              <div className={`modal-header ${opt.headerClassName}`}>
                <h5 className="modal-title">
                  <span>{opt.title}</span>
                </h5>
                <div className="ml-auto mr-0">
                  {opt.fullscreenToggle && (
                    <a
                      className="py-1 px-2 border rounded"
                      href="/"
                      onClick={toggleFullscreen}
                    >
                      {isFullscreen ? (
                        <i className="fa fa-compress mr-1" />
                      ) : (
                        <i className="fa fa-expand mr-1" />
                      )}
                      Toggle Fullscreen
                    </a>
                  )}
                  {opt.closeToggle && (
                    <a
                      // className="py-1 px-2 border rounded ml-2"
                      href="/"
                      onClick={handleClose}
                    >
                      <i
                        className="fa fa-times mr-1 my-1"
                        style={{ fontSize: 16 }}
                      />
                      {/* Close */}
                    </a>
                  )}
                </div>
              </div>
            )}
            {typeof opt.title === "function" && (
              <div className={`modal-header ${opt.headerClassName}`}>
                {opt.title()}
              </div>
            )}
            {content}
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show${
          !opt.backdrop ? " bg-hidden" : ""
        }`}
      />
    </AclContext.Provider>
  );
};

ModalContent.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  close: PropTypes.func.isRequired,
};

export const showModal = (options = {}) => {
  const opt = {
    title: "Modal",
    content: "Modal Message",
    headerClassName: "",
    className: "",
    backdrop: false,
    blinkTitle: false,
    fullscreenToggle: false,
    closeToggle: false,
    ...options,
  };
  const rootElement = document.getElementById(modalUniqueID);
  if (!rootElement) {
    alert("Alert Marker not found!"); // eslint-disable-line
    return;
  }

  document.body.className = "modal-open";
  const prevElem = document.activeElement;
  const close = () => {
    document.body.className = "";
    render(<div id={modalUniqueID} />, rootElement);
    setTimeout(() => {
      try {
        prevElem.focus();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };
  render(
    <Provider store={store}>
      <Router history={history}>
        <ModalContent opt={opt} close={close} />
      </Router>
    </Provider>,
    rootElement
  );
};

export const ModalMarker = () => <div id={modalUniqueID} />;
