/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-extra-boolean-cast */
import React, {
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { debounce, isEmpty } from "lodash";
import "./style.css";

const parseBadgeValue = (val) => {
  if (typeof val === "string") return val;
  if ("label" in val) return val.label;
  if (Array.isArray(val)) return val.join(", ");
  return val;
};

export const useDebounceEffect = (fn, value, timeout = 500) => {
  const fnRef = useRef(fn);
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);
  const debounceChange = useCallback(
    debounce((v) => {
      fnRef.current(v);
    }, timeout),
    []
  );
  useEffect(() => {
    debounceChange(value);
  }, [debounceChange, value]);
};

const FilterWrapper = forwardRef(
  ({ isLoading, children, onChange, value, keyLabel, placeholder }, ref) => {
    const [dropdown, setDropdown] = useState(false);
    const [withAdvance, badges] = useMemo(() => {
      const arr = [];
      let temp = false;
      // eslint-disable-next-line
      for (const [key, val] of Object.entries(value)) {
        if (!isEmpty(val)) {
          if (key !== "keyword") temp = true;
          arr.push(
            `${keyLabel[key] || keyLabel?.default}: ${parseBadgeValue(val)}`
          );
        }
      }
      return [temp, arr];
    }, [value, keyLabel]);
    const handleOpenAdvance = () => {
      setDropdown(true);
    };
    const handleCloseAdvance = () => {
      setDropdown(false);
    };
    const handleClear = () => {
      onChange((state) => ({ ...state, keyword: "" }));
    };
    useImperativeHandle(ref, () => ({ setDropdown }), [setDropdown]);
    return (
      <div className="position-relative">
        <div className="filter-wrapper">
          <div className="icon">
            {isLoading ? (
              <div>
                <i className="fa fa-spin fa-spinner" />
              </div>
            ) : (
              <div>
                <i className="fa fa-search" />
              </div>
            )}
          </div>
          {dropdown ? <div className="fill">Choose filter</div> : null}
          {withAdvance ? (
            <div className="badges" onClick={() => setDropdown(true)}>
              {React.Children.toArray(
                badges.map((item) => (
                  <div className="badge badge-primary">{item}</div>
                ))
              )}
            </div>
          ) : (
            <input
              className="search"
              placeholder={placeholder || "Filter..."}
              onChange={({ target }) =>
                onChange((state) => ({ ...state, keyword: target.value }))
              }
              style={{ opacity: dropdown || withAdvance ? 0 : 100 }}
              value={value.keyword}
            />
          )}
          <div className="action">
            {value.keyword && !children ? (
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={handleClear}
                type="button"
              >
                Clear
              </button>
            ) : null}
            {!!children ? (
              <>
                {dropdown ? (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    type="button"
                    onClick={handleCloseAdvance}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    type="button"
                    onClick={handleOpenAdvance}
                  >
                    Filter
                  </button>
                )}
              </>
            ) : null}
          </div>
        </div>
        {dropdown ? (
          <div className="filter-popover">
            <div className="panel">{children}</div>
          </div>
        ) : null}
      </div>
    );
  }
);

FilterWrapper.defaultProps = {
  placeholder: "",
  isLoading: false,
  children: null,
};

FilterWrapper.propTypes = {
  children: PropTypes.instanceOf(Object),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool,
  keyLabel: PropTypes.instanceOf(Object).isRequired,
  placeholder: PropTypes.string,
};

export default FilterWrapper;
