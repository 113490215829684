import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import FormInput from "modules/common/form/FormInput";
import FormSurveyOptions from "../components/FormSurveyOptions";
import FormSurveyFields from "../components/FormSurveyFields";
import { useGenerateReports } from "../hooks";

function GenerateSurveyReports({ defaultForm, onClose }) {
  const [isLoading, generate] = useGenerateReports();
  const [form, setForm] = React.useState({
    file_name: "",
    specified_columns: [],
    report_type: "",
    ...defaultForm,
  });
  const handleExport = () => {
    const payload = {
      file_name: form?.file_name || "",
      specified_columns: form?.specified_columns || [],
      report_type: form?.report_type?.value,
    };

    generate(_.omitBy(payload, _.isEmpty), onClose);
  };

  return (
    <div className="modal-body">
      <div className="w-full">
        <div className="w-full py-2">
          <FormSurveyOptions
            name="report_type"
            onChange={setForm}
            value={form?.report_type}
          />
        </div>
        <div className="w-full py-2">
          <FormInput
            name="file_name"
            className="form-control"
            onChange={setForm}
            value={form?.file_name}
            placeholder="Enter file name"
          />
        </div>

        <div className="w-full py-2">
          <FormSurveyFields
            name="specified_columns"
            onChange={setForm}
            report_type={form?.report_type || {}}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary mr-1"
          type="button"
          disabled={isLoading}
          onClick={handleExport}
        >
          {isLoading ? "Please wait..." : "Generate"}
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

GenerateSurveyReports.defaultProps = {
  defaultForm: {},
};

GenerateSurveyReports.propTypes = {
  defaultForm: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
};

export default GenerateSurveyReports;
