import dayjs from "dayjs";
import { each, sum, startCase } from "lodash";
import { req } from "react-reqq";
import {
  BAR_CHART,
  COUNT_CARD,
  DASHBOARD_TABLE,
  PIE_CHART,
} from "../../modules/dashboard-v2/constants";

// const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const dayStart = dayjs().startOf("day").format("YYYY-MM-DD");
// const dayEnd = dayjs().endOf("day").format("YYYY-MM-DD");

const pastWeekStart = dayjs().subtract(2, "week").format("YYYY-MM-DD");
const pastWeekEnd = dayjs().subtract(1, "week").format("YYYY-MM-DD");
// const pastWeekEnd = dayjs().format("YYYY-MM-DD");

const monthStart = dayjs().startOf("month").format("YYYY-MM-DD");
const monthEnd = dayjs().endOf("month").format("YYYY-MM-DD");

const pastMonthStart = dayjs().subtract(2, "month").format("YYYY-MM-DD");
const pastMonthEnd = dayjs().subtract(1, "month").format("YYYY-MM-DD");
export const count = {
  travelers_total: () => {
    req.get({
      key: `${COUNT_CARD}/travelers_total`,
      url: "/api/v2/mng/registrants/graph/date",
      transform: (res) => {
        const resData = res?.data;
        const dataValues = Object.values(resData);
        const statCount = sum(dataValues);
        return { value: statCount };
      },
    });
  },
  travelers_weekly: () => {
    req.get({
      key: `${COUNT_CARD}/travelers_weekly`,
      url: `/api/v2/mng/registrants/graph/date?date_of_arrival_from=${pastWeekStart}&date_of_arrival_to=${pastWeekEnd}`,
      transform: (res) => {
        const resData = res?.data;
        const dataValues = Object.values(resData);
        const statCount = sum(dataValues);
        return { value: statCount };
      },
    });
  },
  travelers_monthly: () => {
    req.get({
      key: `${COUNT_CARD}/travelers_monthly`,
      url: `/api/v2/mng/registrants/graph/date?date_of_arrival_from=${pastMonthStart}&date_of_arrival_to=${pastMonthEnd}`,
      transform: (res) => {
        const resData = res?.data;
        const dataValues = Object.values(resData);
        const statCount = sum(dataValues);
        return { value: statCount };
      },
    });
  },
  travelers_incoming: () => {
    req.get({
      key: `${COUNT_CARD}/travelers_incoming`,
      url: `/api/v2/mng/registrants/graph/date?date_of_arrival_from=${dayStart}`,
      transform: (res) => {
        const resData = res?.data;
        const dataValues = Object.values(resData);
        const statCount = sum(dataValues);
        return { value: statCount };
      },
    });
  },
  total_visitors: () => {
    req.get({
      key: `${COUNT_CARD}/total_visitors`,
      url: `/api/v1/mng/total/reports`,
      transform: (res) => {
        const resData = res?.data;
        const dataValues = Object.values(resData);
        const statCount = sum(dataValues);
        return { value: statCount };
      },
    });
  },
  good_condition: () => {
    req.get({
      key: `${COUNT_CARD}/good_condition`,
      url: `/api/v1/mng/total/reports`,
      transform: (res) => {
        const statCount = res?.data?.good_count;
        return { value: statCount };
      },
    });
  },
  mild_condition: () => {
    req.get({
      key: `${COUNT_CARD}/mild_condition`,
      url: `/api/v1/mng/total/reports`,
      transform: (res) => {
        const statCount = res?.data?.mild_count;
        return { value: statCount };
      },
    });
  },
  severe_condition: () => {
    req.get({
      key: `${COUNT_CARD}/severe_condition`,
      url: `/api/v1/mng/total/reports`,
      transform: (res) => {
        const statCount = res?.data?.severe_count;
        return { value: statCount };
      },
    });
  },
};

export const table = {
  top_by_origin: () => {
    req.get({
      key: `${DASHBOARD_TABLE}/top_by_origin`,
      url: "/api/v1/mng/registrants/graph/origin",
      transform: (res) => {
        const chart = [];
        each(res.data, (v, k) => {
          chart.push({
            label: k,
            count: v,
          });
        });
        return { table: chart };
      },
    });
  },
};

export const histogram = {
  histogram_travelers: () => {
    req.get({
      key: `${BAR_CHART}/histogram_travelers`,
      url: `/api/v1/mng/registrants/graph/date?date_of_arrival_from=${monthStart}&date_of_arrival_to=${monthEnd}`,
      transform: (res) => {
        const chart = [];
        each(res.data, (v, k) => {
          chart.push({
            label: k,
            count: v,
          });
        });
        const keys = ["count"];
        return { chart, keys };
      },
    });
  },
  age_group: () => {
    req.get({
      key: `${BAR_CHART}/age_group`,
      url: "/api/v1/mng/registrants/graph/age",
      transform: (res) => {
        const chart = [];
        each(res.data, (v, k) => {
          chart.push({
            label: k,
            count: v,
          });
        });
        const keys = ["male", "female", "count"];
        return { chart, keys };
      },
    });
  },
  gender: () => {
    req.get({
      key: `${BAR_CHART}/gender`,
      url: "/api/v1/mng/registrants/graph/gender",
      transform: (res) => {
        const chart = [];
        each(res.data, (v, k) => {
          chart.push({
            label: k,
            count: v,
          });
        });
        const keys = ["count"];
        return { chart, keys };
      },
    });
  },
  symptoms: () => {
    req.get({
      key: `${BAR_CHART}/symptoms`,
      url: "/api/v2/mng/stat/symptom/summary",
      transform: (res) => {
        const chart = [];
        each(res.data, (v, k) => {
          chart.push({
            label: startCase(k, "_", ""),
            count: v,
          });
        });
        const keys = ["count"];
        return { chart, keys };
      },
    });
  },
};

export const pie = {
  condition: () => {
    req.get({
      key: `${PIE_CHART}/condition`,
      url: `/api/v1/mng/total/reports`,
      transform: (res) => {
        const statCount = res?.data;
        return { value: statCount };
      },
      onSuccess: (res) => {
        const { data } = res?.response;
        req.set(`${PIE_CHART}/condition`, {
          chart: [
            { id: "Good", label: "Good", value: data?.good_count },
            { id: "Mild", label: "Mild", value: data?.mild_count },
            { id: "Severe", label: "Severe", value: data?.severe_count },
          ],
        });
      },
    });
  },
};
