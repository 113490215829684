/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import _ from 'lodash';
// import PropTypes from 'prop-types';

const handleFocusNext = (nextId) => (e) => {
  if (e.key === 'Enter') {
    const elem = document.getElementById(nextId);
    if (elem) {
      e.preventDefault();
      e.stopPropagation();
      if (typeof elem.focus === 'function') elem.focus();
    }
  }
};

export default (WrappedComponent) => {
  const hocForm = (p) => {
    const [formData, setFormData] = useState({});
    const handleChangeInput = (key) => ({ target }) => {
      setFormData({ ...formData, [key]: target.value });
    };
    const handleChangeSelect = ({ key, nextId }) => ({ target }) => {
      setFormData({ ...formData, [key]: target.value });
      if (nextId) {
        const elem = document.getElementById(nextId);
        if (elem && typeof elem.focus === 'function') elem.focus();
      }
    };
    const createLabel = useCallback(({ label }, { required }) => (
      <small>{label}{!required && <sup>(optional)</sup>}</small>
    ), []);
    const createInput = useCallback((config = {}, props = {}) => (
      <div className="form-group">
        {createLabel(config, props)}
        <input
          className="form-control"
          onChange={handleChangeInput(config.key)}
          value={_.get(formData, `${config.key}`) || ''}
          onKeyPress={config.nextId ? handleFocusNext(config.nextId) : null}
          {...props}
        />
      </div>
    ), [formData]);
    const createSelect = useCallback((config = {}, options, props = {}) => (
      <div className="form-group">
        {createLabel(config, props)}
        <select
          className="form-control"
          onChange={handleChangeSelect(config)}
          value={_.get(formData, `${config.key}`) || ''}
          {...props}
        >
          {!_.isEmpty(props.placeholder)
            ? <option value="">{props.placeholder}</option>
            : <option value="">- Select -</option>}
          {options.map((opt) => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </select>
      </div>
    ), [formData]);
    const createDateRange = useCallback((config = {}, options, props = {}) => (
      <div className="form-group">
        {createLabel(config, props)}
        date range here
      </div>
    ), [formData]);
    return (
      <WrappedComponent
        {...p}
        createInput={createInput}
        createSelect={createSelect}
        createDateRange={createDateRange}
        formData={formData}
        setFormData={setFormData}
      />
    );
  };
  return React.memo(hocForm);
};
