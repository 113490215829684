import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatasetManagement from "./DatasetManagement";

function NestedDatasetManagement({
  code,
  label,
  subLabel,
  subChildLabel,
  subLabelPrefix,
  subChildLabelPrefix,
  hasSubChild,
}) {
  const [selected, setSelected] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);

  useEffect(() => {
    setSelectedChild(null);
  }, [selected]);

  return (
    <div className="row mx-0 flex-grow-1">
      <div
        className="d-flex flex-column"
        style={{ width: `calc(${hasSubChild ? "33%" : "50%"} - 20px)` }}
      >
        <DatasetManagement
          code={code}
          label={label}
          noBackground
          selected={selected}
          onRowSelect={setSelected}
          noOverflow
        />
      </div>
      <div className="d-flex align-items-center" style={{ width: "24px" }}>
        <i className="fa fa-arrow-right fa-2x" style={{ fontSize: "17px" }} />
      </div>
      <div
        className="d-flex flex-column"
        style={{ width: `calc(${hasSubChild ? "33%" : "50%"} - 20px)` }}
      >
        {selected ? (
          <>
            <div className="pt-3">
              <h4>
                {subLabelPrefix}
                <b>{selected?.title || "- no selected -"}</b>
              </h4>
            </div>
            <DatasetManagement
              code={`${code}_${selected?.slug}`}
              label={subLabel}
              noBackground
              selected={selectedChild}
              onRowSelect={setSelectedChild}
              noOverflow
            />
          </>
        ) : (
          <div className="text-center p-5 font-italic text-muted">
            <div>No Selected</div>
            <div>Please select an item on the left panel.</div>
          </div>
        )}
      </div>
      {hasSubChild && (
        <>
          <div className="d-flex align-items-center" style={{ width: "24px" }}>
            <i
              className="fa fa-arrow-right fa-2x"
              style={{ fontSize: "17px" }}
            />
          </div>
          <div
            className="d-flex flex-column"
            style={{ width: "calc(33% - 12px)" }}
          >
            {selectedChild ? (
              <>
                <div className="pt-3">
                  <h4>
                    {subChildLabelPrefix}
                    <b>{selectedChild?.title || "- no selected -"}</b>
                  </h4>
                </div>
                <DatasetManagement
                  code={`${code}_${selectedChild?.slug}`}
                  label={subChildLabel}
                  noBackground
                  noOverflow
                />
              </>
            ) : (
              <div className="text-center p-5 font-italic text-muted">
                <div>No Selected</div>
                <div>Please select an item on the left panel.</div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

NestedDatasetManagement.defaultProps = {
  subLabelPrefix: "",
  subChildLabelPrefix: "",
  subChildLabel: "",
  hasSubChild: false,
};

NestedDatasetManagement.propTypes = {
  code: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  subChildLabelPrefix: PropTypes.string,
  subChildLabel: PropTypes.string,
  subLabelPrefix: PropTypes.string,
  hasSubChild: PropTypes.bool,
};

export default NestedDatasetManagement;
