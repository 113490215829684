import { req } from "react-reqq";

import { formatDate } from "modules/common/helper";

const transformRegistrant = (raw) => {
  const attributes = raw?.attributes;
  console.log(attributes)
  return {
    registrant_id: raw?.id,
    id: attributes?.uuid,
    photo_url: (attributes?.photo_url || "").split("::")[1],
    first_name: attributes?.first_name,
    middle_name: attributes?.middle_name,
    last_name: attributes?.last_name,
    full_name: attributes?.full_name,
    latest_trip_mobile_number: attributes?.latest_trip_mobile_number,

    gender: attributes?.gender,
    birth_date: formatDate(attributes?.birth_date),

    age: String(attributes?.age),

    mobile_number: attributes?.mobile_number,
    email: attributes?.email,
    company_name: attributes?.company_name,

    health_status: attributes?.health_status,
    health_symptoms: (attributes?.symptoms || []).filter(
      (x) => x !== "NONE_OF_THE_ABOVE"
    ),

    current_trip_id: attributes?.latest_visit_history_uuid,
    passport_country: attributes?.passport_country,
    passport: attributes?.passport, // passport no.
  };
};

export const listRegistrant = (code, params) => {
  req.get({
    key: `REGISTRANT_LIST/${code}`,
    url: "/api/v2/mng/registrants",
    params: {
      status: "REGISTERED",
      order_by: "name",
      sort_by: "asc",
      ...params,
    },
    transform: (res) => {
      return (res.data || []).map(transformRegistrant);
    },
    onSuccess: (res) => {
      req.set(`REGISTRANT_PAGER/${code}`, res?.response?.meta?.pagination);
    },
  });
};

export const showRegistrantById = (id) => {
  req.get({
    key: "REGISTRANT_BY_ID",
    url: `/api/v2/mng/registrants/${id}`,
    transform: (res) => {
      return transformRegistrant(res.data);
    },
  });
};
