import {
  buildDateArray,
  capByDate,
  formatDate,
  formatNumber,
} from "modules/common/helper";
import moment from "moment";
import { each } from "lodash";
import { req } from "react-reqq";
import { transformTraceQuickDashboard } from "./transformer";
import * as c from "./constants";

export const countCard = {
  total_users: () => {
    req.get({
      key: `${c.COUNT_CARD}/total_users`,
      url: "/api/v1/mng/total/registrants",
      transform: (res) => {
        const count = res?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_registrants: () => {
    req.get({
      key: `${c.COUNT_CARD}/total_registrants`,
      url: "/api/v1/mng/registrants",
      params: {
        status: "REGISTERED",
        per_page: 1,
      },
      transform: (res) => {
        const count = res?.meta?.pagination?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_registrant_family: () => {
    req.get({
      key: `${c.COUNT_CARD}/total_registrant_family`,
      url: "/api/v1/mng/logbook/dashboard/total-registrant-logs",
      params: {
        status: "REGISTERED",
        per_page: 1,
      },
      transform: (res) => {
        const count = res?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_cases: (params) => {
    req.get({
      key: `${c.COUNT_CARD}/total_cases`,
      url: "/api/v1/mng/case-monitoring/dashboard/count",
      params: {
        ...params,
        is_from_cdrs: 1,
      },
      transform: (res) => {
        const count = res?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_government: () => {
    req.get({
      key: `${c.COUNT_CARD}/total_government`,
      url: "/api/v1/mng/administrators",
      params: {
        category: "GOVERNMENT",
        per_page: 1,
      },
      transform: (res) => {
        const count = res?.meta?.pagination?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_establishments: () => {
    req.get({
      key: `${c.COUNT_CARD}/total_establishments`,
      url: "/api/v1/mng/administrators",
      params: {
        category: "PRIVATE",
        per_page: 1,
      },
      transform: (res) => {
        const count = res?.meta?.pagination?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_visitors: () => {
    req.get({
      key: `${c.COUNT_CARD}/total_visitors`,
      url: "/api/v1/mng/logbook/dashboard/total-visitor",
      params: {
        per_page: 1,
      },
      transform: (res) => {
        const count = res?.total_visitor_count ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_scans: (params) => {
    req.get({
      key: `${c.COUNT_CARD}/total_scans`,
      url: "/api/v1/mng/logbook/dashboard/total-scan",
      params,
      transform: (res) => {
        const count = res?.total_scan_count ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  total_usage: (params) => {
    req.get({
      key: `${c.COUNT_CARD}/total_usage`,
      url: "/api/v1/mng/logbook/dashboard/total-usage",
      params,
      transform: (res) => {
        const count = res?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },

  close_contact: (params) => {
    req.get({
      key: `${c.COUNT_CARD}/close_contact`,
      url: "/api/v1/mng/case-traces/dashboard/total-contact",
      params,
      transform: (res) => {
        const count = res?.total ?? 0;
        return { value: formatNumber(count, 0) };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  gaen_status: () => {
    req.set(`${c.COUNT_CARD}/gaen_status`, { value: "GAEN STATUS" });
  },
};

export const lineChart = {
  visitors: (params) => {
    req.get({
      key: `${c.LINE_CHART}/visitors_a`,
      url: "/api/v1/mng/logbook/dashboard/unique-visitor-count-per-day",
      params,
      onSuccess: (resA) => {
        const count2 = resA?.response?.data || [];
        req.get({
          key: `${c.LINE_CHART}/visitors`,
          url: "/api/v1/mng/logbook/dashboard/visitor-count",
          // url: "/api/v1/mng/logbook/dashboard/visitor-count-per-day",
          params,
          transform: (res) => {
            let total = 0;
            const table = [];
            const MAP_RESPONSE_LABEL = {
              day: "YYYY-MM-DD",
              month: "MMMM YYYY",
            };
            const data2 = [];
            const data = buildDateArray(
              params.from,
              params.to,
              MAP_RESPONSE_LABEL[params.type],
              params.type
            ).map((d) => {
              const row = (res?.data || []).find((x) => {
                return x.date === d;
              });
              const row2 = count2.find((x) => {
                return x.date === d;
              });
              table.push({
                label: formatDate(d, c.MAP_DATE_FORMAT_BY_TYPE[params.type]),
                count: formatNumber(row?.count, 0),
                count2: formatNumber(row2.count, 0),
              });
              total += row?.count || 0;

              data2.push({
                x: formatDate(d, c.MAP_DATE_FORMAT_BY_TYPE_SHORT[params.type]),
                y: capByDate(
                  row2?.count ?? null,
                  d,
                  moment().subtract(1, params.type)
                ),
              });
              return {
                x: formatDate(d, c.MAP_DATE_FORMAT_BY_TYPE_SHORT[params.type]),
                y: capByDate(
                  row?.count ?? null,
                  d,
                  moment().subtract(1, params.type)
                ),
              };
            });
            const chart = [
              {
                id: "Visitors",
                data,
              },
              {
                id: "Unique",
                data: data2,
              },
            ];
            return {
              chart,
              table,
              total: formatNumber(total, 0),
            };
          },
          cache: c.CACHE_ENABLED,
          onError: () => {
            req.set(`${c.LINE_CHART}/visitors`, { chart: [], table: [] });
          },
        });
      },
      cache: c.CACHE_ENABLED,
      onError: () => {
        req.set(`${c.LINE_CHART}/visitors`, { chart: [], table: [] });
      },
    });
  },
  cases: (params) => {
    req.get({
      key: `${c.LINE_CHART}/cases`,
      url: "/api/v1/mng/case-monitoring/dashboard/aggs-case-by-entry",
      params: {
        type: "day",
        is_from_cdrs: 1,
        ...params,
      },
      transform: (res) => {
        const table = [];
        const chart = [
          {
            id: "Cases",
            data: (res?.data || []).map((x) => {
              table.push({
                label: formatDate(
                  x.label,
                  c.MAP_DATE_FORMAT_BY_TYPE[params.type]
                ),
                count: formatNumber(x.count, 0),
              });
              return {
                x: formatDate(
                  x.label,
                  c.MAP_DATE_FORMAT_BY_TYPE_SHORT[params.type]
                ),
                y: capByDate(
                  x.count,
                  x.label,
                  moment().subtract(1, params.type)
                ),
              };
            }),
          },
        ];
        return {
          chart,
          table,
          total: formatNumber(res?.total, 0),
        };
      },
      cache: c.CACHE_ENABLED,
    });
  },
  // contacts: () => {
  //   req.set(`${c.LINE_CHART}/contacts`, {
  //     chart: [
  //       {
  //         id: "Predictive",
  //         data: [{ x: 'Jan 1', y: 100 }, { x: 'Jan 2', y: 120 }, { x: 'Jan 3', y: 130 }, { x: 'Jan 4', y: 150 }, { x: 'Jan 5', y: 110 }],
  //       },
  //       {
  //         id: "Preventive",
  //         data: [{ x: 'Jan 1', y: 110 }, { x: 'Jan 2', y: 120 }, { x: 'Jan 3', y: 120 }, { x: 'Jan 4', y: 130 }, { x: 'Jan 5', y: 90 }],
  //       },
  //     ],
  //     table: [],
  //   });
  // },
  scans_not_allowed: (params) => {
    req.get({
      key: `${c.LINE_CHART}/scans_not_allowed`,
      url: "/api/v1/mng/case-traces/dashboard/total-prevented-individual",
      params,
      transform: (res) => {
        const table = [];
        let total = 0;
        const chart = [
          {
            id: "Prevented Visits",
            data: (res?.data || []).map((x) => {
              table.push({
                label: formatDate(
                  x.date,
                  c.MAP_DATE_FORMAT_BY_TYPE[params.type]
                ),
                count: formatNumber(x.count, 0),
              });
              total += x.count ?? 0;
              return {
                x: formatDate(
                  x.date,
                  c.MAP_DATE_FORMAT_BY_TYPE_SHORT[params.type]
                ),
                y: capByDate(
                  x.count,
                  x.date,
                  moment().subtract(1, params.type)
                ),
              };
            }),
          },
        ];
        return {
          chart,
          table,
          total: formatNumber(total, 0),
        };
      },
      cache: c.CACHE_ENABLED,
    });
  },
};

export const barChart = {
  age_group: () => {
    // LOGBOOK
    // req.get({
    //   key: `${c.BAR_CHART}/age_group`,
    //   url: "/api/v1/mng/logbook/dashboard/age-gender",
    //   transform: (res) => {
    //     const chart = res.map(({ age_bracket, ...rest }) => ({
    //       // ...rest,
    //       count: (rest.male ?? 0) + (rest.female ?? 0) + (rest.count ?? 0),
    //       label: age_bracket,
    //     }));
    //     const keys = ["male", "female", "count"];
    //     return { chart, keys };
    //   },
    //   cache: c.CACHE_ENABLED,
    // });

    // CASE
    req.get({
      key: `${c.BAR_CHART}/age_group`,
      url: "/api/v1/mng/case-monitoring-aggs/age_groups",
      transform: (res) => {
        const chart = [];
        each(res.data, (v, k) => {
          chart.push({
            label: k,
            count: v.doc_count,
          });
        });
        const keys = ["male", "female", "count"];
        return { chart, keys };
      },
      cache: c.CACHE_ENABLED,
    });
  },
};

const getPercentage = (v, t) => {
  return `${formatNumber((v / t) * 100)}%`;
};

export const pieChart = {
  source: async () => {
    req.set(`LOADING/get/${c.PIE_CHART}/source`, true);
    const [cdrs, staysafe] = await Promise.all([
      new Promise((resolve) => {
        req.get({
          key: `${c.PIE_CHART}/source/a`,
          url: "/api/v1/mng/case-monitoring",
          params: { is_from_cdrs: 1, per_page: 1 },
          cache: c.CACHE_ENABLED,
          onSuccess: (res) => {
            const count = res?.response?.meta?.pagination?.total ?? 0;
            resolve(count);
          },
          onError: () => {
            resolve(0);
          },
        });
      }),
      new Promise((resolve) => {
        req.get({
          key: `${c.PIE_CHART}/source/b`,
          url: "/api/v1/mng/case-monitoring",
          params: { is_from_cdrs: 0, per_page: 1 },
          cache: c.CACHE_ENABLED,
          onSuccess: (res) => {
            const count = res?.response?.meta?.pagination?.total ?? 0;
            resolve(count);
          },
          onError: () => {
            resolve(0);
          },
        });
      }),
    ]);
    const total = cdrs + staysafe;
    const chart = [
      {
        id: "CDRS",
        label: "CDRS",
        percentage: getPercentage(cdrs, total),
        value: cdrs,
      },
      {
        id: "STAYSAFE",
        label: "STAYSAFE",
        percentage: getPercentage(staysafe, total),
        value: staysafe,
      },
    ];
    req.set(`${c.PIE_CHART}/source`, { chart });
    req.set(`LOADING/get/${c.PIE_CHART}/source`, false);
  },
  case_status: async () => {
    const [
      total_probable,
      total_suspect,
      total_confirmed,
      total_cleared,
      total_others,
    ] = await Promise.all([
      new Promise((resolve) => {
        req.get({
          key: `${c.PIE_CHART}/case_status/0001`,
          url: "/api/v1/mng/case-monitoring/dashboard/count",
          params: { case_status_code: "0001" },
          cache: c.CACHE_ENABLED,
          onSuccess: (res) => {
            const count = res?.response?.total ?? 0;
            resolve(count);
          },
          onError: () => {
            resolve(0);
          },
        });
      }),
      new Promise((resolve) => {
        req.get({
          key: `${c.PIE_CHART}/case_status/0002`,
          url: "/api/v1/mng/case-monitoring/dashboard/count",
          params: { case_status_code: "0002" },
          cache: c.CACHE_ENABLED,
          onSuccess: (res) => {
            const count = res?.response?.total ?? 0;
            resolve(count);
          },
          onError: () => {
            resolve(0);
          },
        });
      }),
      new Promise((resolve) => {
        req.get({
          key: `${c.PIE_CHART}/case_status/0003`,
          url: "/api/v1/mng/case-monitoring/dashboard/count",
          params: { case_status_code: "0003" },
          cache: c.CACHE_ENABLED,
          onSuccess: (res) => {
            const count = res?.response?.total ?? 0;
            resolve(count);
          },
          onError: () => {
            resolve(0);
          },
        });
      }),
      new Promise((resolve) => {
        req.get({
          key: `${c.PIE_CHART}/case_status/0004`,
          url: "/api/v1/mng/case-monitoring/dashboard/count",
          params: { case_status_code: "0004" },
          cache: c.CACHE_ENABLED,
          onSuccess: (res) => {
            const count = res?.response?.total ?? 0;
            resolve(count);
          },
          onError: () => {
            resolve(0);
          },
        });
      }),
      new Promise((resolve) => {
        req.get({
          key: `${c.PIE_CHART}/case_status/0005`,
          url: "/api/v1/mng/case-monitoring/dashboard/count",
          params: { case_status_code: "0005" },
          cache: c.CACHE_ENABLED,
          onSuccess: (res) => {
            const count = res?.response?.total ?? 0;
            resolve(count);
          },
          onError: () => {
            resolve(0);
          },
        });
      }),
    ]);
    const total =
      total_probable +
      total_suspect +
      total_confirmed +
      total_cleared +
      total_others;
    req.set(`${c.PIE_CHART}/case_status`, {
      chart: [
        {
          id: "PROBABLE",
          label: "PROBABLE",
          percentage: getPercentage(total_probable, total),
          value: total_probable,
        },
        {
          id: "SUSPECT",
          label: "SUSPECT",
          percentage: getPercentage(total_suspect, total),
          value: total_suspect,
        },
        {
          id: "CONFIRMED",
          label: "CONFIRMED",
          percentage: getPercentage(total_confirmed, total),
          value: total_confirmed,
        },
        {
          id: "CLEARED",
          label: "CLEARED",
          percentage: getPercentage(total_cleared, total),
          value: total_cleared,
        },
        {
          id: "INITIAL",
          label: "INITIAL",
          percentage: getPercentage(total_others, total),
          value: total_others,
        },
      ],
    });
  },
};

export const traceQuickDashboard = (params) => {
  req.get({
    key: c.TRACE_QUICK_DASHBOARD,
    url: "/api/v1/mng/case-traces/dashboard/total-contact-aggs",
    params: {
      ...params,
      page: 1,
      per_page: 100,
    },
    transform: transformTraceQuickDashboard(params),
    onSuccess: (res) => {
      let close_total = 0;
      let vul_total = 0;
      (res?.response?.data || []).map((x) => {
        close_total += x.close_contact_count ?? 0;
        vul_total += x.vulnerable_close_contact_count ?? 0;
        return null;
      });
      req.get({
        key: `${c.TRACE_QUICK_DASHBOARD}/matched_distinct`,
        url: "/api/v1/mng/case-traces/dashboard/distinct-match-scan-count",
        params,
        onSuccess: (res2) => {
          const matched_distinct = res2?.response?.total ?? 0;
          req.set(`${c.COUNT_CARD}/trace_matched_distinct`, {
            value: formatNumber(matched_distinct, 0),
          });
          req.set(`${c.HUMAN_RATIO}/positive`, {
            ratio: Math.floor(close_total / matched_distinct),
          });
          req.set(`${c.HUMAN_RATIO}/vulnerable`, {
            ratio: Math.floor(vul_total / matched_distinct),
          });
        },
        cache: c.CACHE_ENABLED,
      });
    },
    onError: () => {
      req.set(c.TRACE_QUICK_DASHBOARD, null);
      req.set(`${c.COUNT_CARD}/trace_total_scans`, { value: "-" });
      req.set(`${c.COUNT_CARD}/trace_positive_cases`, { value: "-" });
      req.set(`${c.COUNT_CARD}/trace_matched_entries`, { value: "-" });
      // req.set(`${c.COUNT_CARD}/trace_matched_distinct`, { value: "-" });
      req.set(`${c.COUNT_CARD}/trace_close_total`, { value: "-" });
      req.set(`${c.COUNT_CARD}/trace_vul_total`, { value: "-" });
      req.set(`${c.BAR_CHART}/trace_matched`, null);
      req.set(`${c.LINE_CHART}/trace_close`, null);

      req.set(`${c.HUMAN_RATIO}/positive`, null);
      req.set(`${c.HUMAN_RATIO}/vulnerable`, null);
    },
    cache: c.CACHE_ENABLED,
  });

  // req.set(c.TRACE_QUICK_DASHBOARD, {
  //   table: [
  //     {
  //       label: "May 7 2021",
  //       scans: 100,
  //       cases: 20,
  //       matched: 10,
  //       close: 40,
  //       vul: 12,
  //     },
  //     {
  //       label: "May 6 2021",
  //       scans: 120,
  //       cases: 20,
  //       matched: 3,
  //       close: 10,
  //       vul: 5,
  //     },
  //     {
  //       label: "May 5 2021",
  //       scans: 105,
  //       cases: 20,
  //       matched: 5,
  //       close: 26,
  //       vul: 8,
  //     },
  //     {
  //       label: "May 4 2021",
  //       scans: 110,
  //       cases: 20,
  //       matched: 8,
  //       close: 34,
  //       vul: 3,
  //     },
  //     {
  //       label: "May 3 2021",
  //       scans: 102,
  //       cases: 20,
  //       matched: 2,
  //       close: 12,
  //       vul: 1,
  //     },
  //   ],
  // });
};
