import { omit } from "lodash";
import { transformIncluded } from "modules/common/helper";

export const transformAssessment = (data) => {
  return {
    id: data?.id,
    title: data?.attributes?.title || "",
    code: data?.attributes?.code || "",
    status: data?.attributes?.status || "",
    validity_status: data?.attributes?.validity_status || "",
    description: data?.attributes?.description || "",
    starts_at: data?.attributes?.starts_at || "",
    ends_at: data?.attributes?.ends_at || "",
    updated_at: data?.attributes?.updated_at || "",
    is_system_defined: `${data?.attributes?.is_system_defined}` === "1",
    meta: {
      origin: data?.meta?.origin || [],
    },
  };
};

export const payloadAssessment = ({ is_system_defined, ...rest }) => ({
  ...rest,
  is_system_defined: is_system_defined ? "1" : "0",
});

export const transformAssessmentQuestion = (raw, included = [], sequence) => {
  const data = transformIncluded(raw, included);
  return {
    id: data?.id,
    question: data?.attributes?.question || "",
    type: data?.attributes?.type || "",
    sequence: sequence || data?.attributes?.sequence || "1",
    note: data?.attributes?.note || "",
    options: data?.attributes?.options || [],
    required: `${data?.attributes?.is_required}` === "1",
    meta: {
      code: data?.attributes?.extra?.code || "",
      clear_child_answer: data?.attributes?.extra?.clear_child_answer || false,
      max_date: data?.attributes?.extra?.max_date || "",
      min_date: data?.attributes?.extra?.min_date || "",
      parent_id: data?.attributes?.extra?.parent_id || "",
      parent_visibility_id: data?.attributes?.extra?.parent_visibility_id || "",
      parent_visibility_multiple_answer:
        data?.attributes?.extra?.parent_visibility_multiple_answer || [],
      readonly_id: data?.attributes?.extra?.readonly_id || "",
      readonly_multiple_answer:
        data?.attributes?.extra?.readonly_multiple_answer || [],
      parent_answer: data?.attributes?.extra?.parent_answer || "",
      parent_multiple_answer:
        data?.attributes?.extra?.parent_multiple_answer || [],
      input_type: data?.attributes?.extra?.input_type || "",
      input_mask: data?.attributes?.extra?.input_mask || "",
      input_pattern: data?.attributes?.extra?.input_pattern || "",
      input_pattern_label: data?.attributes?.extra?.input_pattern_label || "",
      group: data?.attributes?.group || "",
    },
  };
};

export const payloadAssessmentQuestion = ({ required, id, ...data }) => ({
  ...data,
  group: data?.meta?.group || "",
  meta: omit(data?.meta || {}, ["group"]),
  is_required: required ? "1" : "0",
});

const ASSESSMENT_STATUS = {
  WAITING_FOR_RESPONSE: "Waiting for Response",
  RESUBMIT: "Resubmit",
  SUBMITTED: "Submitted",
};

export const transformForm = (raw, included) => {
  const data = transformIncluded(raw, included);
  return {
    id: data?.id,
    assessment_id: data?.attributes?.assessment_uuid,
    assessment_code: data?.included?.assessment?.attributes?.code,
    title: data?.included?.assessment?.attributes?.title || "",
    description: data?.included?.assessment?.attributes?.description || "",
    status: data?.attributes?.status || "",
    status_human: ASSESSMENT_STATUS[data?.attributes?.status] || "",
  };
};
