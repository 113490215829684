import { debounce, each } from 'lodash';
import { FORM_ANSWERS, FORM_ITEM } from 'modules/assessment/constants';
import Cookie from 'js-cookie';
import { req } from 'react-reqq';
import { ASSESSMENT_ANSWER } from './constants';

export const debounceSave = debounce((form_id, payload, onSuccess, onError) => {
  const token = Cookie.get('_token');
  const appointment_number = (window.location.pathname || '').split('/')[2];
  req.put({
    key: `${ASSESSMENT_ANSWER}/${payload.assessment_question_uuid}`,
    url: !token
      ? `/api/v1/pub/appointments/${appointment_number}/forms/${form_id}/answer`
      : `/api/v1/mng/forms/${form_id}/answer`,
    payload,
    onSuccess,
    onError,
  });
}, 150);

export const setAnswer = (value, onUpdate) => {
  req.set(FORM_ANSWERS, (state) => {
    const oldState = { ...(state[FORM_ANSWERS] || {}) };

    const form_id = state[FORM_ITEM]?.id;

    const ans = Object.keys(value);
    const onSuccess = () => {
      if (onUpdate) onUpdate(true);
    };
    const onFail = () => {
      const revertValue = {};
      each(value, (v, k) => {
        revertValue[k] = null;
      });
      setTimeout(() => {
        req.set(FORM_ANSWERS, {
          ...oldState,
          ...revertValue,
        });
      }, 50);
      if (onUpdate) onUpdate(false);
    };
    debounceSave(
      form_id,
      {
        assessment_question_uuid: ans[0],
        answer: value[ans[0]],
        geoloc: null, // TODO
      },
      onSuccess,
      onFail
    );
    return {
      ...oldState,
      ...value,
    };
  });
};
