import React, { useState, useRef, useEffect, useMemo } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";

import Logo from "assets/img/landing/home/header/main-logo@2x.png";
import Hamburger from "assets/img/landing/hamburger-menu.svg";
import {
  useWindowScrollPositions,
  useWindowDimensions,
  useOnClickOutside,
} from "modules/common/helper";

import { useAuth } from "modules/auth/hooks";

import "../styles/main.scss";

export default () => {
  const [isActive, setIsActive] = useState(false);
  const { scrollY } = useWindowScrollPositions();
  const { width } = useWindowDimensions();

  const clickRef = useRef(null);

  const onClose = () => setIsActive(false);

  useOnClickOutside(clickRef, onClose);

  return (
    <div
      ref={clickRef}
      className={`landing__nav-parent-container ${
        scrollY >= 110 ? "landing__nav-scroll-bg" : ""
      }`}
    >
      <nav className="landing__nav-container">
        {width <= 900 && (
          <NavLink className="landing__nav-logo-container" to="/">
            <img className="landing__nav-logo" src={Logo} alt="Nav" />
          </NavLink>
        )}

        <DesktopNav />

        <MobileNav isActive={isActive} />

        <button
          className="landing__nav-mobile-btn-menu-container"
          type="button"
          onClick={() => setIsActive(!isActive)}
        >
          <img
            className="landing__nav-mobile-btn-menu-icon"
            src={Hamburger}
            alt="menu"
          />
        </button>

        {width > 900 && <NavButtons />}
      </nav>
    </div>
  );
};

function NavButtons() {
  const { isAuthenticated } = useAuth();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const currentPath = history.location.pathname;

  const btnClass = useMemo(
    () =>
      width <= 900 ? "landing__nav-mobile-link" : "landing__nav-phone-service",
    [width]
  );

  if (isAuthenticated) {
    return (
      <>
        {currentPath === "/" ? (
          <NavLink to="/dashboard" className={btnClass}>
            Dashboard
          </NavLink>
        ) : (
          <div className="landing__nav-mobile-btn-container">
            <a
              href="https://www.safetravelsmarianas.com/contact-us"
              className={btnClass}
            >
              Buy Phone Service
            </a>

            <NavLink to="/dashboard" className={btnClass}>
              Dashboard
            </NavLink>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {currentPath === "/" ? (
        <NavLink to="signin" className={btnClass}>
          Sign In
        </NavLink>
      ) : (
        <a
          href="https://www.safetravelsmarianas.com/contact-us"
          className={btnClass}
        >
          Buy Phone Service
        </a>
      )}
    </>
  );
}

function DesktopNav() {
  return (
    <ul className="landing__nav-ul-container">
      <li className="landing__nav-li">
        <NavLink className="landing__nav-link" to="/">
          <figure className="landing__nav-logo-container">
            <img className="landing__nav-logo" src={Logo} alt="home" />
          </figure>
        </NavLink>
      </li>

      <li className="landing__nav-li">
        <NavLink className="landing__nav-link" to="how-to-register">
          How To Register
        </NavLink>
      </li>
      <li className="landing__nav-li">
        <NavLink className="landing__nav-link" to="faq">
          FAQ
        </NavLink>
      </li>
    </ul>
  );
}

const navVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: "auto" },
};

function MobileNav({ isActive }) {
  const control = useAnimation();
  const { scrollY } = useWindowScrollPositions();

  useEffect(() => {
    if (isActive) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [isActive]);

  return (
    <motion.ul
      variants={navVariants}
      initial="hidden"
      animate={control}
      transition={{ duration: 0.4 }}
      className={`landing__nav-mobile-ul-container ${
        scrollY >= 110 ? "landing__nav-mobile-ul-container-scrolled" : ""
      }`}
    >
      <li className="landing__nav-mobile-li">
        <NavLink className="landing__nav-mobile-link" to="how-to-register">
          How To Register
        </NavLink>
      </li>
      <li className="landing__nav-mobile-li">
        <NavLink className="landing__nav-mobile-link" to="faq">
          FAQ
        </NavLink>
      </li>

      <li className="landing__nav-mobile-li">
        <NavButtons />
      </li>
    </motion.ul>
  );
}

MobileNav.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
