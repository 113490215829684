import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  ADMIN_ACCESS_KEY,
  SUB_USER_ACCESS_KEY,
  AUTHENTICATION,
  SUPER_ADMIN_ACCESS_KEY,
} from "modules/auth/constants";
import { useApiLoading, useApiGet } from "react-reqq";
import { focusElement } from "modules/common/helper";
import ToolTip from "modules/common/components/ToolTip";
import { messageAlert } from "modules/common/components/Alert";
import { useAclModules, MODULES as rawModules } from "../acl";
import AclTemplateOptions from "../components/AclTemplateOptions";

import * as actions from "../actions";
import * as c from "../constants";

const ACCESS_KEY_BY_TYPE = {
  admin: ADMIN_ACCESS_KEY,
  subuser: SUB_USER_ACCESS_KEY,
};

const CONST_KEY = {
  admin: c.SELECT_ADMIN,
  subuser: c.SELECT_SUB_USER,
};

const ACTION_UPDATE_ACL = {
  admin: actions.updateAdminAcl,
  subuser: actions.updateSubUserAcl,
};

const WARNING_MESSAGE = {
  cases:
    "This account has no city bounds, all cases will be visible to this user.",
  "acl-contact-tracer":
    "This account has no city bounds, all tracers will be visible to this user.",
};

const logbookSubPermissions = [
  "lb-digital",
  "lb-list",
  "lb-entry-points",
  "lb-export",
];

export const orgModules = [
  "dashboard",
  "logbook",
  "contact-tracing",
  "content-management",
  "special-survey",
  "registrants",
  "reports",
  "trips",
];

export const companyModules = [
  "logbook",
  "lb-digital",
  "lb-entry-points",
  "lb-export",
  "lb-list",
  "scanner",
  "acl-sub-accounts",
];

export const mvaModules = [
  "special-survey",
];

function UserAccessControl({ data, type }) {
  const isOrganization = data?.attributes?.type === "ORGANIZATION";
  const isLoading = useApiLoading(CONST_KEY[type], "put");
  const [perms, setPerms] = React.useState([]);
  const modules = useAclModules(ACCESS_KEY_BY_TYPE[type]);

  const mapModules = useMemo(() => {
    const filteredCompanyModules = modules.filter((item) =>
      companyModules.includes(item.key)
    );

    const filteredOrgModules = modules?.filter((item) =>
      orgModules.includes(item.key)
    );

    return isOrganization ? filteredOrgModules : filteredCompanyModules;
  }, [isOrganization, modules]);

  const handleToggle =
    (key, parentKey) =>
    ({ target }) => {
      if (
        ["cases", "acl-contact-tracer"].indexOf(key) > -1 &&
        target.checked &&
        _.isEmpty(_.get(data, "attributes.municipality") || {})
      ) {
        messageAlert({
          title: "Warning!",
          content:
            WARNING_MESSAGE[key] ||
            "This account has no city bounds, Listing will not be filtered and show ALL data!",
          yesLabel: "Ok",
        });
      }

      if (
        !isOrganization &&
        !perms.includes("logbook") &&
        logbookSubPermissions.includes(key)
      ) {
        setPerms([...perms, "logbook", key]);
        return;
      }

      if (!isOrganization && perms.includes("logbook") && key === "logbook") {
        const filteredPerms = perms.filter(
          (item) => !logbookSubPermissions.includes(item) && item !== "logbook"
        );
        setPerms(filteredPerms);
        return;
      }
      setPerms((state) => {
        let newValue = target.checked
          ? state.concat(key)
          : state.filter((row) => row !== key);
        if (parentKey && target.checked && newValue.indexOf(parentKey) < 0) {
          newValue = newValue.concat([parentKey]);
        }
        return newValue;
      });
    };

  const handleReset = () => {
    setPerms(_.get(data, "attributes.acl.permission") || []);
  };
  const handleClear = () => {
    setPerms([]);
  };
  const handleSave = () => {
    const isInclude = logbookSubPermissions.some((item) =>
      perms.includes(item)
    );

    if (!isOrganization && perms.includes("logbook") && !isInclude) {
      messageAlert({
        title: "Warning!",
        content: "Please enable at least 1 logbook sub module",
        yesLabel: "Ok",
      });

      return;
    }
    const modulesKey = rawModules.map((item) => item?.key);
    const home_route =
      (mapModules.find((row) => perms.indexOf(row.key) > -1) || {}).path ||
      "/profile";
    const newPayload = {
      acl: {
        home_route,
        permission: perms?.filter((item) => modulesKey.includes(item)),
        template_name: "Custom",
      },
    };

    if (ACTION_UPDATE_ACL[type]) {
      ACTION_UPDATE_ACL[type](_.get(data, "attributes.uuid"), newPayload);
    }
  };
  const isChanged = () => {
    try {
      return !_.isEqual(
        (_.get(data, "attributes.acl.permission") || []).sort(),
        perms.sort()
      );
    } catch (err) {
      console.warn(err); // eslint-disable-line
      return true;
    }
  };
  const handleFocusSave = (e) => {
    e.preventDefault();
    focusElement("acl-save-button");
  };

  const { profile } = useApiGet(AUTHENTICATION, {});
  const isSuperAdmin = profile.access_control === SUPER_ADMIN_ACCESS_KEY;

  React.useEffect(() => {
    setPerms(_.get(data, "attributes.acl.permission") || []);
  }, [data]);

  return (
    <div className="p-1">
      {isSuperAdmin && (
        <div>
          <AclTemplateOptions onChange={setPerms} value={perms} />
        </div>
      )}
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <td colSpan="2">
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Access Control</span>
                {isChanged() && (
                  <a className="text-danger" href="/" onClick={handleFocusSave}>
                    Unsaved!
                  </a>
                )}
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {mapModules.length < 1 ? (
            <tr>
              <td colSpan="2" className="text-center">
                <small>There are no available modules.</small>
              </td>
            </tr>
          ) : (
            mapModules.map((item) => (
              <React.Fragment key={item.key}>
                <tr key={`${item.key}-main`}>
                  <td width="40px">
                    <div className="custom-control custom-switch">
                      <input
                        className="custom-control-input"
                        disabled={_.isEmpty(data)}
                        type="checkbox"
                        onChange={handleToggle(item.key)}
                        checked={perms.indexOf(item.key) > -1}
                        id={`toggle-${item.key}`}
                        value={perms.indexOf(item.key) > -1}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`toggle-${item.key}`}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <span>{item.name}</span>
                      {item.note && (
                        <span className="ml-auto">
                          <small className="text-muted">{item.note}</small>
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
                {(item.children || []).length > 0 && (
                  <tr key={`${item.key}-children`}>
                    <td aria-label="space" />
                    <td>
                      <div className="d-flex flex-wrap">
                        {(item.children || []).map((sub) => (
                          <label
                            key={`${item.key}-${sub.key}`}
                            className="mr-3 mb-1"
                          >
                            <input
                              disabled={_.isEmpty(data)}
                              type="checkbox"
                              onChange={handleToggle(sub.key, item.key)}
                              checked={perms.indexOf(sub.key) > -1}
                            />{" "}
                            {sub.name}
                          </label>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        {isChanged() && (
          <>
            <div className="position-relative mr-3">
              <ToolTip
                bounce
                show
                position="left"
                style={{ top: 0, left: -132 }}
                content="Click here to save!"
              />
              <button
                id="acl-save-button"
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSave}
                disabled={_.isEmpty(data) || isLoading}
              >
                Apply
              </button>
            </div>
            <button
              className="btn btn-outline-primary btn-sm mr-3"
              type="button"
              onClick={handleReset}
              disabled={_.isEmpty(data) || isLoading}
            >
              Reset
            </button>
          </>
        )}
        <button
          className="btn btn-outline-primary btn-sm"
          type="button"
          onClick={handleClear}
          disabled={_.isEmpty(data) || isLoading}
        >
          Clear
        </button>
      </div>
    </div>
  );
}

UserAccessControl.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
};

export default UserAccessControl;
