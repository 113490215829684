/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
// import iconMobile from "assets/img/icon-mobile.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isNumber } from "../helper";

function FormInputMobile({
  name,
  onChange,
  label,
  disableDropdown,
  value,
  countryCodeEditable,
  hasCountry,
  isReadOnly,
  required,
  ...props
}) {
  const handleChangeHasCountry = (val) => {
    onChange((state) => ({ ...state, [name]: val }));
  };

  const handleChange = ({ target }) => {
    if (isNumber(target.value)) {
      onChange((state) => ({ ...state, [name]: target.value }));
    }
  };

  return (
    <>
      {label && (
        <small htmlFor={name} className="form-label">
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </small>
      )}
      {isReadOnly ? (
        <p className="form-control-plaintext">{value || "-"}</p>
      ) : (
        <>
          {hasCountry ? (
            <div className="form-icon-right">
              <PhoneInput
                country="us"
                enableSearch
                value={value}
                searchPlaceholder="Search Country"
                jumpCursorToEnd
                disableDropdown={disableDropdown}
                className="form-custom form-custom"
                // placeholder="+639 XXXX XXXXX"
                onChange={handleChangeHasCountry}
                countryCodeEditable={countryCodeEditable}
                masks={{ ph: "...-...-...." }}
                priority={{ ph: 0 }}
                autocompleteSearch
                // disableCountryCode
                inputProps={{
                  id: name,
                  type: "tel",
                  autoComplete: "off",
                  ...props,
                }}
                searchClass="d-flex w-100"
                searchStyle={{
                  width: "80%",
                }}
              />
              {/* <span className="right">
                <img src={iconMobile} alt="Mobile" />
              </span> */}
            </div>
          ) : (
            <div className="form-icon-text">
              <span className="left">+63</span>
              <input
                type="tel"
                id={name}
                className="form-control form-custom"
                onChange={handleChange}
                value={value}
                placeholder="9XXXXXXXXX"
                maxLength="10"
                autoComplete="off"
                {...props}
              />
              {/* <span className="right">
                <img src={iconMobile} alt="Mobile" />
              </span> */}
            </div>
          )}
        </>
      )}
    </>
  );
}

FormInputMobile.defaultProps = {
  onChange: () => {},
  label: "Enter Mobile Number",
  disableDropdown: false,
  countryCodeEditable: false,
  hasCountry: true,
  isReadOnly: false,
  required: false,
};

FormInputMobile.propTypes = {
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  disableDropdown: PropTypes.bool,
  countryCodeEditable: PropTypes.bool,
  hasCountry: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  required: PropTypes.bool,
};

export default React.memo(FormInputMobile);
