import React from 'react';
import PropTypes from 'prop-types';

function InputSwitch({
  onChange, checked, onLabel, offLabel, disabledClassName, activeClassName, disabled,
}) {
  const handleChange = ({ target }) => {
    onChange(target.checked);
  };
  return (
    <label className="switch">
      <input type="checkbox" onChange={handleChange} checked={checked} disabled={disabled} />
      <span className={`slider round ${checked ? activeClassName : disabledClassName}`}>
        <span className="toggle">
          {disabled && <i className="fa fa-lock" />}
        </span>
        <span className="label-on">{onLabel}</span>
        <span className="label-off">{offLabel}</span>
      </span>
    </label>
  );
}

InputSwitch.propTypes = {
  disabledClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

InputSwitch.defaultProps = {
  disabledClassName: '',
  activeClassName: 'bg-success',
  onLabel: 'ON',
  offLabel: 'OFF',
  disabled: false,
};

export default React.memo(InputSwitch);
