import { req } from "react-reqq";
import {
  ToastError,
  ToastSuccess,
  ToastWarn,
} from "modules/common/components/toast";
import { each, get } from "lodash";
import Cookie from "js-cookie";
import {
  formatDate,
  formatNumber,
  transformIncluded,
} from "modules/common/helper";
import * as c from "./constants";

const transformList = (raw, included) => {
  const data = transformIncluded(raw, included);
  return {
    id: data?.id,
    uuid: data?.attributes?.uuid,
    download_count: data?.attributes?.download_count,
    created_at: formatDate(data?.attributes?.created_at, "MMM DD, YYYY hh:mmA"),
    date_of_arrival_from: formatDate(
      data?.attributes?.date_of_arrival_from,
      "MMM DD, YYYY"
    ),
    date_of_arrival_to: formatDate(
      data?.attributes?.date_of_arrival_to,
      "MMM DD, YYYY"
    ),
    created_at_human: formatDate(
      data?.attributes?.created_at_human,
      "MMM DD, YYYY hh:mmA"
    ),
    file_name: data?.attributes?.file_name,
    file_url: data?.attributes?.file_url,
    from: formatDate(data?.attributes?.from, "MMM DD, YYYY"),
    to: formatDate(data?.attributes?.to, "MMM DD, YYYY"),
    traveller_type: data?.attributes?.traveller_type,
    report_type: data?.attributes?.report_type,
    status: data?.attributes?.status,
    total_records: formatNumber(data?.attributes?.total_records, 0),
  };
};

const transformOptions = (raw) => ({
  label: raw?.attributes?.label,
  value: raw?.attributes?.value,
  data: raw,
});

export const surveyAssessmentLists = (params) => {
  req.get({
    key: "SEARCH_ORIGIN_COUNTRY",
    url: "/api/v1/mng/datasets",
    params: { ...params, per_page: 15, code: "COUNTRY" },
    onSuccess: (res) => {
      const newOptions = (get(res, "response.data") || []).map(
        transformOptions
      );
      return newOptions;
    },
  });
};

export const surveyReports = (params) => {
  req.get({
    key: c.SURVEY_REPORTS,
    url: "/api/v2/mng/reports",
    params,
    transform: (res) => {
      const data = (res?.data || []).map((item) =>
        transformList(item, res?.included || [])
      );
      req.set(c.PAGER_REPORTS, res?.meta?.pagination || {});
      req.set(c.FILTER_REPORTS, params);
      return data;
    },
  });
};

export const assessmentGraph = (uuid, onSuccess) => {
  req.get({
    key: c.ASSESSMENT_GRAPH,
    url: `/api/v1/mng/assessment_questions/${uuid}/graph`,
    transform: (res) => {
      const chart = [];
      each(res.data, (v, k) => {
        chart.push({
          id: `${k}/count`,
          label: k,
          value: v,
        });
      });
      const keys = ["count"];
      return { chart, keys };
    },
    onSuccess: (res) => {
      if (onSuccess) onSuccess(res?.response?.data);
    },
  });
};

export const generateSurveyReports = (payload, onSuccess) => {
  req.post({
    key: c.GENERATE_REPORTS,
    url: "/api/v2/mng/reports",
    payload,
    onSuccess: (res, state) => {
      ToastSuccess(res?.response?.message || "Success!");
      if (onSuccess) onSuccess();
      surveyReports(state[c.FILTER_REPORTS]);
    },
  });
};

export const downloadSelectedReports = (id, onSuccess) => {
  req.get({
    key: c.SELECT_REPORTS,
    url: `/api/v1/mng/reports/${id}/download`,
    transform: (res) => transformList(res?.data, res?.included || []),
    onSuccess,
  });
};

export const downloadReport = async (id, filename, onSuccess) => {
  try {
    ToastWarn("Creating download link...please wait");
    const url = `${process.env.REACT_APP_END_POINT}/api/v1/mng/reports/${id}/download`;
    const token = Cookie.get("token");
    req.set(`${c.SELECT_REPORTS}/is_downloading`, true);
    const raw = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (raw.status !== 200) {
      const res = await raw.json();
      ToastError(res.message || "Unable to download");
      req.set(`${c.SELECT_REPORTS}/is_downloading`, false);
      return;
    }
    if (onSuccess) onSuccess();
    const blob = await raw.blob();
    blob.fileName = filename || "download.csv";
    const output_url = await URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = output_url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    req.set(`${c.SELECT_REPORTS}/is_downloading`, false);
    // window.open(output_url, '_blank');
  } catch (err) {
    ToastError("Unable to download");
  }
};

export const removeSelectedSurveyReport = (id, onSuccess) =>
  req.remove({
    key: c.REMOVE_REPORTS,
    url: `/api/v1/mng/reports/${id}`,
    onSuccess: (res, state) => {
      ToastSuccess(res?.response?.message || "Removed!");
      if (onSuccess) onSuccess();
      surveyReports(state[c.FILTER_REPORTS]);
    },
  });

export const fieldsList = (params) => {
  req.get({
    key: "SELECT_SURVEY_FORM_FIELDS",
    url: "/api/v2/mng/dropdown/fields",
    params,
    onSuccess: (res) => {
      const newOptions = (get(res, "response.data") || []).map(
        transformOptions
      );
      return newOptions;
    },
  });
};
