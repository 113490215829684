import React from "react";
import PropTypes from "prop-types";
import FormUploadButton from "modules/common/form/FormUploadButton";
import FormInput from "modules/common/form/FormInput";
import Img from "modules/common/components/Img";
import _ from "lodash";
import { useApiLoading } from "react-reqq";
import { showAddressMapPicker } from "modules/common/form/FormAddressMap";
import FormAddressMap2 from "modules/common/form/FormAddressMap2";
// eslint-disable-next-line import/no-cycle
import { updateProfile } from "../actions";

const AddressPicker = ({ onChange, value }) => {
  const [form, setForm] = React.useState({
    address: value.address || "",
    geoloc: value.coordinates_geoloc || "",
    region: value.coordinates_region || "",
    province: value.coordinates_province || "",
    municipality: value.coordinates_municipality || "",
    barangay: value.coordinates_barangay || "",
  });
  React.useEffect(() => {
    onChange((state) => ({
      ...state,
      coordinates_geoloc: form.geoloc || "",
      coordinates_region: form.region || "",
      coordinates_province: form.province || "",
      coordinates_municipality: form.municipality || "",
      coordinates_barangay: form.barangay || "",
    }));
  }, [form]);
  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <small>Coordinates</small>
        {!_.isEmpty(form.geoloc) ? (
          <span className="badge badge-success">Map Pinned: Yes</span>
        ) : (
          <span className="badge badge-danger">Map Pinned: No</span>
        )}
      </div>
      <FormAddressMap2 onChange={setForm} value={form} />
    </>
  );
};

AddressPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

function ProfileModal({ data, onClose }) {
  const isLoading = useApiLoading("PROFILE", "put");
  const [form, setForm] = React.useState({
    logo: _.get(data, "logo") || "",
    branch: _.get(data, "branch") || "",
    address: _.get(data, "address") || "",
    representative_name: _.get(data, "representative_name") || "",

    coordinates_geoloc: _.get(data, "coordinates_geoloc") || "",
    coordinates_region: _.get(data, "coordinates_region") || "",
    coordinates_province: _.get(data, "coordinates_province") || "",
    coordinates_municipality: _.get(data, "coordinates_municipality") || "",
    coordinates_barangay: _.get(data, "coordinates_barangay") || "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile(form, onClose);
  };
  const handleOpenMapPicker = (e) => {
    e.preventDefault();
    showAddressMapPicker({
      onSubmit: (payload, closePicker) => {
        setForm((state) => ({
          ...state,
          coordinates_geoloc: payload.geoloc,
          coordinates_region: (payload?.region?.ADM1_PCODE || "").replace(
            "PH",
            ""
          ),
          coordinates_province: (payload?.province?.ADM2_PCODE || "").replace(
            "PH",
            ""
          ),
          coordinates_municipality: (
            payload?.municipality?.ADM3_PCODE || ""
          ).replace("PH", ""),
          coordinates_barangay: (payload?.barangay?.ADM4_PCODE || "").replace(
            "PH",
            ""
          ),
        }));
        closePicker();
      },
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="row">
          <div className="col-3">
            <div className="mb-1">
              <Img
                className="img w-100 img-fluid border p-1 rounded shadow-sm"
                src={form.logo}
                defaultSrc="https://via.placeholder.com/128?text=LOGO"
              />
            </div>
            <FormUploadButton
              name="logo"
              label="Logo"
              onChange={setForm}
              value={form.logo}
              resize="x128"
            />
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col form-group">
                <FormInput
                  name="branch"
                  label="Branch"
                  onChange={setForm}
                  value={form.branch}
                />
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <FormInput
                  name="address"
                  label="Address"
                  onChange={setForm}
                  value={form.address}
                />
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <AddressPicker
                  onChange={setForm}
                  value={_.pick(form, [
                    "address",
                    "coordinates_geoloc",
                    "coordinates_region",
                    "coordinates_province",
                    "coordinates_municipality",
                    "coordinates_barangay",
                  ])}
                />
              </div>
            </div>
            {false && (
              <>
                <div className="row">
                  <div className="col form-group">
                    <FormInput
                      name="address"
                      label="Address"
                      onChange={setForm}
                      value={form.address}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group">
                    <div className="mt-2 float-right">
                      <a href="/" onClick={handleOpenMapPicker}>
                        <i className="fa fa-map-marked-alt mr-1" />
                        Open Map
                      </a>
                    </div>
                    <div>
                      <small>Is Location Pinned</small>
                    </div>
                    {!_.isEmpty(form.coordinates_geoloc) ? (
                      <span className="badge badge-success">Yes</span>
                    ) : (
                      <span className="badge badge-danger">No</span>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col form-group">
                <FormInput
                  name="representative_name"
                  label="Representative's Name"
                  onChange={setForm}
                  value={form.representative_name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary mr-1"
          type="submit"
          disabled={isLoading}
        >
          Save
        </button>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </form>
  );
}

ProfileModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
};

ProfileModal.defaultProps = {
  data: {},
};

export default ProfileModal;
