import React from "react";
import PropTypes from "prop-types";
import { useApiGet, useApiLoading } from "react-reqq";
import * as actions from "../actions";
import * as c from "../constants";

function CountCard({ id, color, icon, label, onClick, filter, action: actionOverride }) {
  const handleOpenDetails = (e) => {
    e.preventDefault();
    onClick();
  };
  const { value } = useApiGet(`${c.COUNT_CARD}/${id}`, {});
  const isLoading = useApiLoading(`${c.COUNT_CARD}/${id}`, "get");
  React.useEffect(() => {
    const action = actionOverride[id];
    if (typeof action === "function") action(filter);
  }, [id, filter, actionOverride]);
  return (
    <div className={`card border-left-${color} shadow-sm h-100 py-2`}>
      <div className="card-body py-2 px-3">
        <h4
          className="mb-0 font-weight-bold text-gray-800 text-truncate"
          title={value ?? "-"}
        >
          {value ?? "-"}
        </h4>
        <div className="d-flex justify-content-between align-items-end">
          <div className="text-xs text-gray-600 text-uppercase flex-grow-1">
            <div className="d-flex justify-content-between">
              <span style={{ whiteSpace: "pre" }}>{label}</span>
              {onClick && (
                <a href="/" onClick={handleOpenDetails}>
                  Details
                </a>
              )}
            </div>
          </div>
          {icon && (
            <div className="mr-1" style={{ height: 28 }}>
              <div
                className="position-absolute p-2"
                style={{ top: 0, right: 0 }}
              >
                {isLoading ? (
                  <div className={`spinner-grow text-${color}`} />
                ) : (
                  <i className={`fa fa-2x fa-${icon} mr-2 text-${color}`} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CountCard.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  filter: PropTypes.instanceOf(Object),
  action: PropTypes.instanceOf(Object),
};

CountCard.defaultProps = {
  color: "primary",
  onClick: false,
  filter: {},
  action: actions.countCard,
};

export default React.memo(CountCard);
