/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import GenericTable from "modules/common/components/GenericTable";
import { HeaderTitle } from "modules/common/components/Header";
import { showModal } from "modules/common/components/AlertModal";
import Pagination from "modules/common/components/Pagination";
import { useApiLoading, useApiGet } from "react-reqq";
import _ from "lodash";
import { confirmAlert } from "modules/common/components/Alert";
import VaccineFilter from "../components/VaccineFilter";
import VaccineModal from "../modals/VaccineModal";
import * as actions from "../actions";
import * as c from "../constants";

function VaccineManagement() {
  const isLoading = useApiLoading(c.VACCINE_MANAGEMENT, "get");
  const [filter, setFilter] = React.useState({ page: 1 });
  const data = useApiGet(c.VACCINE_MANAGEMENT, []);
  const pager = useApiGet(`${c.VACCINE_MANAGEMENT}/pager`, []);

  const handleOpenAdd = () => {
    showModal({
      title: "Add Vaccine",
      content: (onClose) => <VaccineModal onClose={onClose} />,
    });
  };

  const handleOpenEdit = (row) => () => {
    showModal({
      title: "Edit Vaccine",
      content: (onClose) => <VaccineModal data={row} onClose={onClose} />,
    });
  };

  const handleDelete = (row) => () => {
    confirmAlert({
      content: "Are you sure you want to remove?",
      onYes: (onClose) => {
        actions.deleteVaccine(_.get(row, "id"), onClose);
      },
    });
  };

  const renderAction = React.useCallback(
    (row) => (
      <div>
        <button
          className="btn btn-outline-primary btn-sm mr-1"
          type="button"
          onClick={handleOpenEdit(row)}
        >
          <i className="fa fa-edit" />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          type="button"
          onClick={handleDelete(row)}
        >
          <i className="fa fa-trash" />
        </button>
      </div>
    ),
    []
  );

  const getTableFormat = React.useCallback(
    () => [
      { key: renderAction, label: "Action", width: 80 },
      { key: "attributes.label", label: "Name" },
      { key: "attributes.status", label: "Status", width: 100 },
    ],
    []
  );

  const handleSearch = (newFilter) => {
    setFilter(newFilter);
    actions.listVaccine(newFilter);
  };

  const handleChangePage = (newPage) => {
    setFilter({ ...filter, page: newPage });
    actions.listVaccine({ ...filter, page: newPage });
  };
  return (
    <>
      <Helmet>
        <title>Vaccine Management - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Vaccine Management</div>
      </HeaderTitle>
      <div className="bg-white rounded border shadow-sm m-2 flex-grow-1 d-flex flex-column">
        <div className="d-flex justify-content-between p-1">
          <VaccineFilter isLoading={isLoading} onSubmit={handleSearch} />
          <div>
            <button
              className="btn btn-primary mb-1"
              type="button"
              onClick={handleOpenAdd}
            >
              Add
            </button>
          </div>
        </div>
        <div className="box-fit-wrapper w-100 flex-grow-1">
          <div className="box-fit-overflow">
            <GenericTable
              isLoading={isLoading}
              format={getTableFormat()}
              data={data}
            />
          </div>
        </div>
        <Pagination
          data={pager}
          isLoading={isLoading}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
}

VaccineManagement.propTypes = {};

export default VaccineManagement;
