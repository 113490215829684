/* eslint-disable import/prefer-default-export */
import { useEffect, useCallback } from "react";
import { useApiGet, useApiLoading } from "react-reqq";

import * as actions from "./actions";
import * as c from "./constants";

export const useGlobalSettings = () => {
  const globalSettings = useApiGet(c.GLOBAL_SETTINGS, []);
  const isLoading = useApiLoading(c.GLOBAL_SETTINGS, "get");

  const fetchData = useCallback(() => {
    actions.getGlobalSettings({});
  }, []);

  useEffect(fetchData, [fetchData]);

  return [globalSettings, isLoading, fetchData];
};
