import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";
import { formatDate } from "../helper";

function DayPicker({
  name,
  onChange,
  value,
  format,
  dropdownStyle,
  labelWidth,
  increment,
  minDate,
}) {
  const [showPicker, setShowPicker] = React.useState(false);
  const handleSet = () => {
    console.log("open picker!"); // eslint-disable-line
    setShowPicker(true);
  };
  const handleCancelPicker = () => {
    setShowPicker(false);
  };
  const handleSetDate = (date) => {
    onChange((state) => ({
      ...state,
      [name]: moment(date).format("YYYY-MM-DD"),
    }));
    setShowPicker(false);
  };
  const handlePrev = () => {
    onChange((state) => ({
      ...state,
      [name]: moment(new Date(value))
        .subtract(increment[0], increment[1])
        .format("YYYY-MM-DD"),
    }));
  };
  const handleNext = () => {
    onChange((state) => ({
      ...state,
      [name]: moment(new Date(value))
        .add(increment[0], increment[1])
        .format("YYYY-MM-DD"),
    }));
  };
  return (
    <div className="position-relative">
      <div id={`day-picker-${name}`} className="btn-group mr-2">
        <button
          className="btn btn-sm btn-outline-primary"
          type="button"
          onClick={handlePrev}
        >
          <i className="fa fa-caret-left" />
        </button>
        <button
          className="btn btn-sm btn-outline-primary"
          type="button"
          onClick={handleSet}
          style={{ minWidth: labelWidth }}
        >
          {formatDate(value, format)}
        </button>
        <button
          className="btn btn-sm btn-outline-primary"
          type="button"
          onClick={handleNext}
        >
          <i className="fa fa-caret-right" />
        </button>
      </div>
      <div
        className={`dropdown-menu dropdown-menu-right shadow animated--grow-in${
          showPicker ? " show" : ""
        }`}
        style={dropdownStyle}
      >
        <DatePicker
          onChange={handleSetDate}
          value={value}
          minDate={minDate}
          inline
        />
        <button
          className="btn btn-outline-primary btn-sm btn-block"
          type="button"
          onClick={handleCancelPicker}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

DayPicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  format: PropTypes.string,
  minDate: PropTypes.string,
  dropdownStyle: PropTypes.instanceOf(Object),
  labelWidth: PropTypes.number,
  increment: PropTypes.instanceOf(Array),
};

DayPicker.defaultProps = {
  minDate: null,
  format: "dddd, MMM DD, YYYY",
  dropdownStyle: {},
  labelWidth: 160,
  increment: [1, "day"],
};

export default DayPicker;
