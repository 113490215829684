import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { useScrollToTop } from "modules/common/helper";

import Template from "modules/landing/components/Template";
import Installation from "modules/landing/components/faq/Installation";
import General from "modules/landing/components/faq/General";
import Establishment from "modules/landing/components/faq/Establishment";
import Foreign from "modules/landing/components/faq/Foreign";

import "../../styles/main.scss";

const labels = [
  "Installation",
  "General",
  "Establishment Users",
  "Foreign Traveler/ Resident / Non-Resident Users",
];

export default () => {
  const [label, setLabels] = useState("Installation");
  useScrollToTop();
  return (
    <>
      <Helmet>
        <title>FAQ - {process.env.REACT_APP_TITLE}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Template>
        <section className="landing__how-faq-container landing-container">
          <ul className="landing__how-faq-ul-container">
            {labels.map((item) => (
              <li key={item} className="landing__how-faq-li">
                <button
                  key={item}
                  type="button"
                  className={`landing__how-faq-btn ${
                    item === label ? "landing__how-faq-btn-active" : ""
                  }`}
                  onClick={() => setLabels(item)}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
          {label === "Installation" && <Installation />}
          {label === "General" && <General />}
          {label === "Establishment Users" && <Establishment />}
          {label === "Foreign Traveler/ Resident / Non-Resident Users" && (
            <Foreign />
          )}
        </section>
      </Template>
    </>
  );
};
