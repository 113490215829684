import React from "react";
import { motion } from "framer-motion";

import { containerVariants } from "./Installation";

import "../../styles/main.scss";

const scanQrCode = [
  "Step 1: On your computer, open Chrome.",
  "Step 2: Go to a website you want to install.",
  "Step 3: At the top right of the address bar, click Install.",
  "Step 4: Follow the on-screen instructions to install the PWA.",
];

export default () => (
  <motion.div
    className="landing__how-faq-content-container"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    transition={{ duration: 1 }}
  >
    <div className="landing__how-faq-content-column-container">
      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">What is a QR Code?</h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          It is a machine-scannable image that can instantly be read using a
          smartphone camera. Every QR code consists of a number of black squares
          and dots which represent certain pieces of information to secure it
          anonymously.
        </p>
      </div>

      <div className="landing__how-faq-content-column-container ">
        <div className="landing__how-faq-inner-container">
          <h3 className="landing__how-faq-title">
            How to scan a QR Code on Android and iOS devices?
          </h3>
          {scanQrCode.map((label) => (
            <p key={label} className="landing__how-faq-paragraph">
              {label}
            </p>
          ))}
        </div>
      </div>

      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          Can I make a new QR code with the same cell phone number?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          QR codes are automatically generated and are accessed via your user
          account and verified with a One Time Password (“OTP”). A user can save
          their QR codes, however, it is the responsibility of the user to
        </p>
      </div>

      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          Why do I need an OTP each time I try to register?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          The OTP is a security feature that verifies your identity through your
          smartphone device as an authenticated user. Additionally, it verifies
          that you have a phone device that we will be able to contact you for
          any Contact Tracing alerts or concerns.
        </p>
      </div>
    </div>

    <div className="landing__how-faq-content-column-container landing__how-faq-content-general-column-container">
      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">How does a QR Code work?</h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          When your Smartphone scans this code, it translates that information
          into something that can be easily understood by humans.
        </p>
      </div>

      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          How many times can a QR Code be scanned?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          Upon entering an establishment you will scan the QR code at the point
          of entry. A user can scan an establishment once upon entry. If an
          establishment requires an exit scan, the user will be required to scan
          the QR code. The system is automated to detect scans if more than once
          over a period of time and will notify the user.
        </p>
      </div>

      <div className="landing__how-faq-inner-container">
        <h3 className="landing__how-faq-title">
          What is a one-time-pass code (OTP)?
        </h3>
        <p className="landing__how-faq-paragraph landing__how-faq-line-height">
          An OTP is a six-digit numerical code sent in real-time as SMS (Text
          Message) to your registered mobile number while performing the
          transaction. After registering on www.safetravelsmarianas.com, a
          one-time passcode (OTP) will be sent to your device before registering
          your account on the Safe Travels Marianas Software.
        </p>
      </div>
    </div>
  </motion.div>
);
