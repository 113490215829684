import React from "react";
import Helmet from "react-helmet";
import PropTypes from 'prop-types';
import { HeaderTitle } from "modules/common/components/Header";
import UserIntegration from "modules/integration/containers/UserIntegration";
import { useApiGet } from "react-reqq";
// import { ToastError } from "modules/common/components/toast";
import { MY_INTEGRATION } from "modules/integration/constants";
// import { showTryModal } from "modules/integration/modals/TryModal";

const METHOD_CLASSNAME = {
  POST: 'badge badge-warning',
  GET: 'badge badge-success',
}

const DocumentationItem = ({ method, label, url, headers, body, onTry }) => (
  <div>
    <div className="d-flex align-items-center form-group">
      <div className={METHOD_CLASSNAME[method] || METHOD_CLASSNAME.default}>{method}</div>
      <div className="font-weight-bold ml-2">{label}</div>
      {typeof onTry === 'function' ? <div className="ml-auto">
        <button className="btn btn-outline-primary btn-sm" type="button" onClick={onTry}>
          Try Me
        </button>
      </div> : null}
    </div>
    <div className="card p-2 bg-light form-group">
      {url}
    </div>
    {headers ? (
      <div>
        <div className="font-weight-bold">Headers</div>
        <div className="card p-2 bg-light form-group">
          <code>
            <pre className="mb-0">{JSON.stringify(headers, undefined, 2)}</pre>
          </code>
        </div>
      </div>
    ) : null}
    {body ? (
      <div>
        <div className="font-weight-bold">Body</div>
        <div className="card p-2 bg-light form-group">
          <code>
            <pre className="mb-0">{JSON.stringify(body, undefined, 2)}</pre>
          </code>
        </div>
      </div>
    ) : null}
  </div>
);

DocumentationItem.defaultProps = {
  headers: null,
  body: null,
  onTry: null,
};

DocumentationItem.propTypes = {
  method: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  headers: PropTypes.instanceOf(Object),
  body: PropTypes.instanceOf(Object),
  onTry: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ])
}

const DOCS = {
  USER_INFO: {
    method: "GET",
    label: "Get User Info",
    url: "https://saipan-ws-dev.multisyscorp.io/api/v1/integrator/scan-user",
    headers: {
      'X-TRAVEL-MARIANAS-KEY': '- API_KEY_HERE -',
      'X-TRAVEL-MARIANAS-SECRET': '- SECRET_HERE -',
    },
    body: {
      uuid: '- QR_CODE_VALUE_HERE -'
    },
  },
}

function DevelopersContainer() {
  const data = useApiGet(MY_INTEGRATION, {});
  const isEnabled = data?.attributes?.status === "ACTIVE";
  // const handleOpenTry = (code) => () => {
  //   if (!DOCS[code]) {
  //     ToastError("Not found!");
  //     return;
  //   }
  //   showTryModal(DOCS[code]);
  // };

  // useEffect(() => {
  //   showTryModal(DOCS.USER_INFO);
  // }, []);

  return (
    <>
      <Helmet>
        <title>Developers - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">Developers</div>
      </HeaderTitle>
      <div className="mx-0 d-flex flex-column align-items-center">
        <div className="bg-white rounded border shadow m-2 col-md-6 py-3">
          <UserIntegration />
        </div>
        {isEnabled ? <div className="bg-white rounded border shadow m-2 col-md-6 py-3">
          <DocumentationItem
            method="POST"
            label="Authentication"
            url="https://saipan-ws-dev.multisyscorp.io/api/v1/integrator/auth"
            headers={{
              'X-TRAVEL-MARIANAS-KEY': '- API_KEY_HERE -',
              'X-TRAVEL-MARIANAS-SECRET': '- SECRET_HERE -',
            }}
          />
          <hr />
          <DocumentationItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...DOCS.USER_INFO}
            // onTry={handleOpenTry('USER_INFO')}
          />
          <hr />
          <DocumentationItem
            method="GET"
            label="Get Health Declaration"
            url="https://saipan-ws-dev.multisyscorp.io/api/v1/integrator/scan-health-dec"
            headers={{
              'X-TRAVEL-MARIANAS-KEY': '- API_KEY_HERE -',
              'X-TRAVEL-MARIANAS-SECRET': '- SECRET_HERE -',
            }}
            body={{
              uuid: '- QR_CODE_VALUE_HERE -'
            }}
          />
          <hr />
          <DocumentationItem
            method="GET"
            label="Get Customs"
            url="https://saipan-ws-dev.multisyscorp.io/api/v1/integrator/scan-customs"
            headers={{
              'X-TRAVEL-MARIANAS-KEY': '- API_KEY_HERE -',
              'X-TRAVEL-MARIANAS-SECRET': '- SECRET_HERE -',
            }}
            body={{
              uuid: '- QR_CODE_VALUE_HERE -'
            }}
          />
          <hr />
          <DocumentationItem
            method="GET"
            label="Get MVA"
            url="https://saipan-ws-dev.multisyscorp.io/api/v1/integrator/scan-mva"
            headers={{
              'X-TRAVEL-MARIANAS-KEY': '- API_KEY_HERE -',
              'X-TRAVEL-MARIANAS-SECRET': '- SECRET_HERE -',
            }}
            body={{
              uuid: '- QR_CODE_VALUE_HERE -'
            }}
          />
        </div> : null}
      </div>
    </>
  );
}

DevelopersContainer.propTypes = {};

export default DevelopersContainer;
