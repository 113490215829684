import { req } from "react-reqq";
import _ from "lodash";
import { ToastSuccess } from "modules/common/components/toast";
import * as c from "./constants";

const slugify = (str) => (str || "").split(" ").join(" ").toUpperCase();

const transformDataset = (raw) => ({
  id: `${raw?.id ?? ""}`,
  code: raw?.attributes?.code,
  slug: slugify(raw?.attributes?.title),
  title: raw?.attributes?.title,
  description: raw?.attributes?.description,
  sort_priority: raw?.attributes?.sort_priority,
  status: raw?.attributes?.status,
  status_label: c.DATASET_STATUS?.[raw?.attributes.status]?.label || "Unknown",
});

export const listDataset = (code, params, onSuccess) =>
  req.get({
    key: `${c.DATASET}_${code}`,
    url: "/api/v1/mng/datasets",
    params: { code, ...params },
    transform: (res) => {
      req.set(
        `${c.DATASET}_${code}/pager`,
        _.get(res, "meta.pagination") || {}
      );
      return (res.data || []).map(transformDataset);
    },
    onSuccess: (res) => {
      const data = _.get(res, "response.data");
      if (onSuccess) onSuccess(data);
    },
  });

export const addDataset = (code, payload, onSuccess) =>
  req.post({
    key: `${c.DATASET}_${code}`,
    url: "/api/v1/mng/datasets",
    payload: { code, ...payload },
    onSuccess: (res, state) => {
      ToastSuccess("Created!");
      listDataset(code, state[`${c.DATASET}_${code}`]);
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });

export const updateDataset = (code, id, payload, onSuccess) => {
  req.put({
    key: `${c.DATASET}_${code}`,
    url: `/api/v1/mng/datasets/${id}`,
    payload: { code, ...payload },
    onSuccess: (res) => {
      ToastSuccess("Updated!");

      if (typeof onSuccess === "function") onSuccess(res);
      req.set(`${c.DATASET}_${code}`, (state) => {
        const oldList = _.get(state, `${c.DATASET}_${code}`) || [];
        return oldList.map((item) => {
          return _.get(item, "id") === id
            ? {
                ...item,
                ...payload,
                status_label: c.DATASET_STATUS?.[payload?.status]?.label,
              }
            : item;
        });
      });
    },
  });
};

export const deleteDataset = (code, id, onSuccess) => {
  req.remove({
    key: `${c.DATASET}_${code}`,
    url: `/api/v1/mng/datasets/${id}`,
    onSuccess: (res) => {
      ToastSuccess("Removed!");
      if (typeof onSuccess === "function") onSuccess(res);
      req.set(`${c.DATASET}_${code}`, (state) => {
        const oldList = _.get(state, `${c.DATASET}_${code}`) || [];
        return oldList.filter((item) => `${item.id}` !== `${id}`);
      });
    },
  });
};
