import React from "react";
import { req } from "react-reqq";
import PropTypes from "prop-types";
import { debounce, get } from "lodash";
import AsyncSelect from "react-select/async";
import { surveyAssessmentLists } from "../actions";

const transformOptions = (raw) => {
  return {
    label: raw?.attributes?.label,
    value: raw?.attributes?.value,
    data: raw,
  };
};

function FormSurveyOptions({ name, onChange, value }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleChange = (e) => {
    onChange((state) => ({
      ...state,
      [name]: e,
    }));
  };
  const loadTripOptions = React.useCallback(
    debounce((search, callback) => {
      setIsLoading(true);
      req.get({
        key: "SEARCH_SURVEY_ASSESSMENTS",
        url: "/api/v2/mng/dropdown/report_type",
        params: { keyword: search, per_page: 15 },
        onSuccess: (res) => {
          setIsLoading(false);
          const newOptions = (get(res, "response.data") || []).map(
            transformOptions
          );
          callback(newOptions);
        },
      });
    }, 500),
    []
  );

  React.useEffect(() => {
    surveyAssessmentLists();
  }, []);

  return (
    <AsyncSelect
      placeholder="Select Special Survey..."
      isLoading={isLoading}
      cacheOptions
      onChange={handleChange}
      noOptionsMessage={() => "Type(Name) to search..."}
      defaultOptions
      // defaultOptions={transformOptions(list)}
      loadOptions={loadTripOptions}
      value={value}
      isClearable
    />
  );
}

FormSurveyOptions.defaultProps = {
  value: null,
};

FormSurveyOptions.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default FormSurveyOptions;
