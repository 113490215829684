import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useApiLoading } from "react-reqq";
import img_brand from "assets/img/logo_vertical.svg";
import staticRegistration from "assets/img/graphic-registration.svg";
import { VERSION_NUMBER } from "modules/common/constants";
import { showModal } from "modules/common/components/AlertModal";
import { scrollTo } from "modules/common/helper";
import * as actions from "../actions";
import * as c from "../constants";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";
import ChangePasswordModal from "../modals/ChangePasswordModal";

function Login() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const isLoading = useApiLoading(c.LOGIN, "post");
  const handleChangeInput =
    (key) =>
    ({ target }) => {
      setForm({ ...form, [key]: target.value });
    };
  const handleLogin = (e) => {
    e.preventDefault();
    actions.login(form, ({ is_first_login }) => {
      if (+is_first_login === 1) {
        showModal({
          title: "Change Password",
          content: (onClose) => (
            <ChangePasswordModal
              value={{ old_password: form.password }}
              onClose={onClose}
            />
          ),
        });
      }
    });
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    showModal({
      type: "Forgot Password",
      content: (onClose) => <ForgotPasswordModal onClose={onClose} />,
    });
  };
  React.useEffect(() => {
    scrollTo("login-form");
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - {process.env.REACT_APP_TITLE}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="login-account">
        <div className="container-fluid h-100 d-flex align-items-center">
          <div className="row w-100">
            <div className="col-lg-4 d-flex">
              <div id="login-form" className="login-form">
                <form onSubmit={handleLogin}>
                  <div className="login-header">
                    <img
                      className="logo"
                      src={img_brand}
                      alt={process.env.REACT_APP_TITLE}
                    />
                  </div>
                  <div className="text-center">
                    <h4>Admin Access</h4>
                  </div>
                  <div className="login-box">
                    <div className="form-group mb-2">
                      <div className="floating-form w-100">
                        <input
                          autoFocus
                          className="floating-input form-control"
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          value={form.email}
                          autoComplete="off"
                          onChange={handleChangeInput("email")}
                        />
                        {/* defaultValue={form.email} */}
                        <label
                          className="floating-label"
                          data-content="Email Address"
                        >
                          <span className="hidden -visually">
                            Email Address
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="text-right">
                      <a href="/" onClick={handleForgotPassword}>
                        Forgot Password?
                      </a>
                    </div>
                    <div className="form-group mb-3">
                      <div className="floating-form w-100">
                        <input
                          className="floating-input form-control"
                          name="password"
                          type="password"
                          placeholder="Password"
                          autoComplete="off"
                          onChange={handleChangeInput("password")}
                          value={form.password}
                        />
                        {/* defaultValue={form.email} */}
                        <label
                          className="floating-label"
                          data-content="Password"
                        >
                          <span className="hidden -visually">Password</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <button
                        className="btn btn-primary py-3 font-weight-bold text-uppercase text-white btn-md btn-block"
                        type="submit"
                        disabled={isLoading}
                      >
                        Login as Admin
                      </button>
                    </div>
                  </div>
                  <div className="text-center">
                    {/* <small>
                      Technical Support for LGU Access and Digital Logbook
                      <br />
                      Hotline: (+63) 956 981 8214 / (+63) 931 947 4777
                      <br />
                      Email: help@safetravelsmarianas.com
                      <br />
                    </small>
                    <br /> */}
                    <small>
                      ©2024 Powered by AP Solution | {process.env.REACT_APP_TITLE}{" "}
                      {VERSION_NUMBER}
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-none d-lg-inline col">
              <img src={staticRegistration} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
