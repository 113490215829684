import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useApiGet, useApiLoading } from "react-reqq";
import { useRouteMatch, useHistory, Route, Switch } from "react-router-dom";
import GenericList from "modules/common/components/GenericList";
import { showModal } from "modules/common/components/AlertModal";
import { removeNull, removeFalsyValue } from "modules/common/helper";
import _ from "lodash";
// import { AUTHENTICATION, ADMIN_ACCESS_KEY } from "modules/auth/constants";
// import { AUTHENTICATION } from "modules/auth/constants";
// import { messageAlert } from "modules/common/components/Alert";
import FormSelect from "modules/common/form/FormSelect";
import Helmet from "react-helmet";
import { HeaderTitle } from "modules/common/components/Header";
import UserFilter from "../components/UserFilter";
import UserModal from "../modals/UserModal";
import * as actions from "../actions";
import * as c from "../constants";
import UserSelected from "./UserSelected";
import UserNoSelected from "../components/UserNoSelected";

export const userTypeOptions = [
  { label: "Admin", value: "ORGANIZATION" },
  { label: "Establishment", value: "COMPANY" },
];

const TITLE = {
  admin: "Access Control",
  subuser: "Sub Accounts",
};

const CONST_KEY = {
  admin: c.LIST_ADMIN,
  subuser: c.LIST_SUB_USER,
};

const ACTION_LIST = {
  admin: actions.listAdmin,
  subuser: actions.listSubUser,
};

function UsersContainer({ type, filter: defaultFilter }) {
  // const { profile } = useApiGet(AUTHENTICATION, {});
  const match = useRouteMatch();
  const sub_match = useRouteMatch(`${match.path}/:user_id`);
  const history = useHistory();
  const isLoading = useApiLoading(CONST_KEY[type], "get");
  const list = useApiGet(CONST_KEY[type], []);
  const pager = useApiGet(`${CONST_KEY[type]}/pager`, []);
  const [filter, setFilter] = useState({ page: 1, per_page: 15 });

  const handleSelectRow = (row) => {
    history.push(`${match.url}/${_.get(row, "attributes.uuid")}`);
  };
  const handleOpenAddModal = () => {
    // if (
    //   [ADMIN_ACCESS_KEY].indexOf(profile.access_control) > -1 &&
    //   pager.total >= profile.sub_account_max_count
    // ) {
    //   messageAlert({
    //     content: "You have reached the maximum number of sub accounts",
    //     yesLabel: "Ok",
    //   });
    //   return;
    // }
    showModal({
      title: "Add User",
      className: "modal-lg",
      content: (onClose) => (
        <UserModal
          value={{ attributes: defaultFilter }}
          match={match}
          type={type}
          filter={removeNull(filter)}
          onClose={onClose}
        />
      ),
    });
  };
  const fetchList = (newFilter) => {
    setFilter({ ...defaultFilter, ...filter, ...newFilter });
    if (ACTION_LIST[type]) {
      ACTION_LIST[type](
        removeFalsyValue({ ...defaultFilter, ...filter, ...newFilter })
      );
    }
  };

  const handleChangePage = (page) => {
    setFilter({ ...defaultFilter, ...filter, page });
    if (ACTION_LIST[type]) {
      ACTION_LIST[type](
        removeFalsyValue({ ...defaultFilter, ...filter, page })
      );
    }
  };

  const renderRow = React.useCallback(
    (row) => (
      <div className="p-2 w-100 text-uppercase">
        <div>{_.get(row, "attributes.name")}</div>
        <div className="text-muted">
          <small>
            <b>{_.get(row, "attributes.branch")}</b> -{" "}
            {_.get(row, "attributes.representative_name")}
          </small>
        </div>
      </div>
    ),
    []
  );
  useEffect(() => {
    if (type !== "admin" && !filter?.type) return;
    fetchList({ type: filter?.type });
  }, [type, filter?.type]);
  return (
    <>
      <Helmet>
        <title>
          {TITLE[type]} - {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <HeaderTitle>
        <div className="font-weight-bold text-gray-600">{TITLE[type]}</div>
      </HeaderTitle>
      <div className="row mx-0 flex-grow-1">
        <div className="col-3 pl-0 d-flex flex-column">
          <div className="border rounded bg-white w-100 d-flex flex-column mb-2 mx-2">
            <div className="p-3">
              <button
                className="btn btn-primary btn-block"
                type="button"
                onClick={handleOpenAddModal}
                disabled={isLoading}
              >
                <i className="fa fa-plus mr-1" />
                Add User
                {/* {[ADMIN_ACCESS_KEY].indexOf(profile.access_control) > -1 && (
                  <span className="ml-2">
                    ({pager.total || "0"} of {profile.sub_account_max_count})
                  </span>
                )} */}
              </button>
            </div>
          </div>
          <div className="border rounded bg-white w-100 d-flex flex-column with-arrow mx-2 flex-grow-1">
            {type === "admin" && (
              <div className="px-3 pt-3">
                <FormSelect
                  name="type"
                  onChange={setFilter}
                  placeholder="All"
                  value={filter?.type || ""}
                  options={userTypeOptions}
                />
              </div>
            )}

            <UserFilter isLoading={isLoading} onSubmit={fetchList} />
            <GenericList
              selected={_.get(sub_match, "params.user_id") || ""}
              selectedKey="attributes.uuid"
              onSelectRow={handleSelectRow}
              list={list}
              pager={pager}
              onChangePage={handleChangePage}
              renderRow={renderRow}
            />
          </div>
        </div>
        <div className="col-9 px-0 d-flex flex-column">
          <Switch>
            <Route exact path={match.path} component={UserNoSelected} />
            <Route path={`${match.path}/:user_id`}>
              <UserSelected type={type} filter={filter} list={list} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}

UsersContainer.propTypes = {
  type: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object),
};

UsersContainer.defaultProps = {
  filter: {},
};

export default UsersContainer;
