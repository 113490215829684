import React from "react";
// import PropTypes from 'prop-types';
import { ToastSuccess } from "modules/common/components/toast";
import { isEmpty } from "lodash";
import { useApiGet } from "react-reqq";
import { getMyIntegration } from "../actions";
import { MY_INTEGRATION } from "../constants";

function UserIntegration() {
  const data = useApiGet(MY_INTEGRATION, {});
  const isEnabled = data?.attributes?.status === "ACTIVE";

  React.useEffect(() => {
    getMyIntegration();
  }, []);

  const handleCopyToClipboard = (value) => (e) => {
    e.preventDefault();
    const input = document.createElement("input");
    input.setAttribute("value", value);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    ToastSuccess("Copied to clipboard!");
  };

  return (
    <div>
      <div className="text-center">
        <label className="text-gray-600">API Integration</label>
      </div>
      {/* <div className="text-center">
        <small>
          Technical Support for LGU Access and Digital Logbook
          <br />
          Hotline: (+63) 956 981 8214 / (+63) 931 947 4777
          <br />
          Email: help@safetravelsmarianas.com
          <br />
        </small>
      </div> */}
      {!isEmpty(data) ? (
        <div className="px-3 pt-3 rounded">
          <div className="text-center">
            {isEnabled ? (
              <span className="badge badge-success">Status: Enabled</span>
            ) : (
              <span className="badge badge-danger">
                Status: Disabled By Admin
              </span>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            {!isEnabled ? (
              <pre className="mb-0">
                <code>
                  <span className="d-inline-block" style={{ width: 70 }}>
                    API KEY:
                  </span>
                  <span
                    className="badge badge-light"
                    style={{ color: "#d6d6d6" }}
                  >
                    {data?.attributes?.key}
                  </span>
                </code>
              </pre>
            ) : (
              <pre className="mb-0">
                <code>
                  <span className="d-inline-block" style={{ width: 70 }}>
                    API KEY:
                  </span>
                  <span className="badge badge-dark">
                    {data?.attributes?.key}
                  </span>
                </code>
              </pre>
            )}
            <button
              className="btn btn-info btn-sm"
              type="button"
              onClick={handleCopyToClipboard(data?.attributes?.key)}
              disabled={!isEnabled}
            >
              <i className="fa fa-clipboard" /> Copy
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            {!isEnabled ? (
              <pre className="mb-0">
                <code>
                  <span className="d-inline-block" style={{ width: 70 }}>
                    Secret:
                  </span>
                  <strike className="" style={{ color: "#d6d6d6" }}>
                    {data?.attributes?.secret}
                  </strike>
                </code>
              </pre>
            ) : (
              <pre className="mb-0">
                <code>
                  <span className="d-inline-block" style={{ width: 70 }}>
                    Secret:
                  </span>
                  <span className="text-danger">
                    {data?.attributes?.secret}
                  </span>
                </code>
              </pre>
            )}
            <button
              className="btn btn-info btn-sm"
              type="button"
              onClick={handleCopyToClipboard(data?.attributes?.secret)}
              disabled={!isEnabled}
            >
              <i className="fa fa-clipboard" /> Copy
            </button>
          </div>
        </div>
      ) : (
        <div className="px-3 pt-3 rounded d-flex align-items-center justify-content-between">
          <pre className="mb-0">
            <code>
              <span className="d-inline-block" style={{ width: 70 }}>
                API KEY:
              </span>
              <span className="badge badge-light" style={{ color: "#d6d6d6" }}>
                Not enabled by administrator
              </span>
            </code>
          </pre>
        </div>
      )}
    </div>
  );
}

UserIntegration.propTypes = {};

export default UserIntegration;
