import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from "draft-js-import-html";

function FormEditor({ name, onChange, value, label }) {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const handleChange = (newState) => {
    setEditorState(newState);
    onChange((form) => ({
      ...form,
      [name]: draftToHtml(convertToRaw(newState.getCurrentContent())),
    }));
  };
  React.useEffect(() => {
    if (
      _.isEqual(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
        value
      )
    )
      return;
    setEditorState(EditorState.createWithContent(stateFromHTML(value)));
  }, [value]);
  return (
    <>
      {label && <small>{label}</small>}
      <Editor
        editorState={editorState}
        editorClassName="draft-editor-body"
        onEditorStateChange={handleChange}
        stripPastedStyles
      />
    </>
  );
}

FormEditor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

FormEditor.defaultProps = {
  label: "",
};

export default FormEditor;
