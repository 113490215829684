import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useGlobalSettings } from "modules/data-source/hooks";
import FormToggleSwitch from "modules/common/form/FormToggleSwitch";
import * as actions from "modules/data-source/actions";

const SwitchRow = ({ row }) => {
  const [isActive, setIsActive] = useState(!!get(row, "typed_value", false));
  return (
    <>
      <FormToggleSwitch
        toggled={isActive}
        onToggle={(value) => {
          setIsActive(value);
          actions.updateGlobalSettings(row?.code, {
            value: value ? "true" : "false",
          });
        }}
        label={row?.code}
        id={row?.code}
      />
    </>
  );
};

SwitchRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

function FormQuestionnaires() {
  const [globalSettings, isLoading] = useGlobalSettings();

  return (
    <table className="table table-striped table-sm">
      <thead>
        <tr>
          <td colSpan="2">
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Questionnaires</span>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="2" className="text-center">
              <small>There are no available questionnaires.</small>
            </td>
          </tr>
        ) : (
          globalSettings.map((item) => (
            <React.Fragment key={item.code}>
              <tr key={`${item.code}-main`} className="d-flex row-indent">
                <td width="40px">
                  <SwitchRow row={item} />
                </td>
                <td>
                  <div>
                    <span>{item.human_label}</span>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))
        )}
      </tbody>
    </table>
  );
}

FormQuestionnaires.propTypes = {};

export default FormQuestionnaires;
