import React from "react";
import { Helmet } from "react-helmet";

import Template from "modules/landing/components/Template";
import { useScrollToTop } from "modules/common/helper";

import RegisterSteps from "modules/landing/components/register/RegisterSteps";
import TravelerSteps from "modules/landing/components/register/TravelersSteps";

import BusinessSteps from "modules/landing/components/register/BusinessSteps";

import "../../styles/main.scss";

export default () => {
  useScrollToTop();
  return (
    <>
      <Helmet>
        <title>Register - {process.env.REACT_APP_TITLE}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Template>
        <RegisterSteps />
        <TravelerSteps />
        <BusinessSteps />
      </Template>
    </>
  );
};
