/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import _, { isEqual } from "lodash";
import { AutoSizer, Table, Column } from "react-virtualized";

const toValue = (val, default_value) =>
  typeof val === "undefined" ? default_value : val;

function GenericTable({
  // className, selectedKey,
  data,
  format,
  isLoading,
  rowHeight,
  selected,
  onRowClick,
  headerHeight,
}) {
  const getRowStyle = React.useCallback(
    ({ index }) => {
      if (!!selected && index === data.findIndex((x) => isEqual(x, selected)))
        return {
          background: "#cbd1e1",
        };
      if (index >= 0) return { cursor: "pointer" };
      return {};
    },
    [selected]
  );
  return (
    <div className="h-100 w-100 pr-2">
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowCount={data.length}
            onRowClick={onRowClick}
            rowStyle={onRowClick ? getRowStyle : {}}
            noRowsRenderer={() => (
              <div className="text-center mt-4">
                {isLoading ? (
                  <span>
                    <span className="spinner-bar" />
                  </span>
                ) : (
                  "There are no items."
                )}
              </div>
            )}
            rowGetter={({ index }) => data[index]}
          >
            {format.map((c) => (
              <Column
                key={c.label}
                label={c.label}
                dataKey={c.key}
                width={c.width || 100}
                flexGrow={!c.width ? 1 : 0}
                className={c.className || ""}
                headerRenderer={({ label }) => (
                  <span
                    className={c.className || ""}
                    style={{
                      display: "block",
                      whiteSpace: "pre",
                      lineHeight: "14px",
                    }}
                    title={label}
                  >
                    {label}
                  </span>
                )}
                cellRenderer={({ rowData, rowIndex }) =>
                  typeof c.key === "function" ? (
                    <span title={_.get(rowData, c.key)}>
                      {c.key(rowData, rowIndex)}
                    </span>
                  ) : (
                    <span title={_.get(rowData, c.key)}>
                      {toValue(_.get(rowData, c.key), "-")}
                    </span>
                  )
                }
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
  // return (
  //   <table className={className}>
  //     <thead>
  //       <tr>
  //         {format.map((c) => (
  //           <th key={c.label} className={c.className || ''} style={{ width: c.width || 'initial' }}>
  //             <small>{c.label}</small>
  //           </th>
  //         ))}
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {data.length < 1
  //         ? (
  //           <tr className="text-center">
  //             <td colSpan={format.length}>There are no items.</td>
  //           </tr>
  //         ) : data.map((row, i) => (
  //           <tr key={row[selectedKey]}>
  //             {format.map((c) => (
  //               <td
  //                 key={`${row[selectedKey]}-${c.label}`}
  //                 className={c.className || ''}
  //                 style={{ width: c.width || 'initial' }}
  //               >
  //                 {typeof c.key === 'function' ? c.key(row, i) : <small className="text-truncate" title={_.get(row, c.key) || '-'}>{_.get(row, c.key) || '-'}</small>}
  //               </td>
  //             ))}
  //           </tr>
  //         ))}
  //     </tbody>
  //   </table>
  // );
}

GenericTable.propTypes = {
  // className: PropTypes.string,
  // selectedKey: PropTypes.string,
  data: PropTypes.instanceOf(Array).isRequired,
  format: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Object),
  ]),
  isLoading: PropTypes.bool,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
};

GenericTable.defaultProps = {
  // className: 'table table-sm table-striped table-fixed-header',
  // selectedKey: 'id',
  selected: null,
  onRowClick: null,
  isLoading: false,
  rowHeight: 33,
  headerHeight: 33,
};

export default GenericTable;
