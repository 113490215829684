import React from "react";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";

function VaccinationPassportModal({ profile, medicalCertificateList }) {
  const medicalCertificateListSorted = medicalCertificateList?.sort(
    (a, b) => a?.dose - b?.dose
  );
  const patientNumber = medicalCertificateListSorted?.find(
    (pn) => pn?.patient_number
  );

  return (
    <div className="container p-3">
      <div className="row p-2">
        <div className="col-4 text-center">
          <QRCode value={profile.id} size={116} />
        </div>
        <div className="col-8 d-flex flex-column align-items-left px-3">
          <p className="row font-weight-bold mb-0" style={{ fontSize: 23 }}>
            {profile?.full_name}
          </p>
          <div className="row d-flex justify-content-between">
            <p className="font-weight-normal mb-0">Date of Birth</p>
            <p className="font-weight-bold  mb-0">{profile?.birth_date}</p>
          </div>
          <div className="row d-flex justify-content-between">
            <p className="font-weight-normal mb-0">Patient Number</p>
            <p className="font-weight-bold  mb-0">
              {patientNumber?.patient_number}
            </p>
          </div>
        </div>
      </div>
      <hr className="my-2" />
      {medicalCertificateListSorted?.map((med) => {
        return (
          <div className="row p-2" key={med?.dose}>
            <div className="col-12 text-center font-weight-bold my-2">
              {" "}
              <span>
                {med?.dose}
                <sup>{med?.dose === "1" ? "st" : "nd"}</sup>
              </span>{" "}
              Dose Covid-19
            </div>
            <div className="col-6">
              <p className="my-1">Product Name / Lot #</p>
            </div>
            <div className="col-6 font-weight-bold text-center">
              {med?.name} / {med?.lot_number}
            </div>
            <div className="col-6">
              <p className="my-1">Date Taken</p>
            </div>
            <div className="col-6 font-weight-bold text-center">
              {med?.document_issuance_date}
            </div>
            <div className="col-6">
              <p className="my-1">Healthcare Professional / Site</p>
            </div>
            <div className="col-6 font-weight-bold text-center">
              {med?.establishment}
            </div>
          </div>
        );
      })}
    </div>
  );
}

VaccinationPassportModal.defaultProps = {
  profile: {},
  medicalCertificateList: [],
};

VaccinationPassportModal.propTypes = {
  profile: PropTypes.instanceOf(Object),
  medicalCertificateList: PropTypes.instanceOf(Array),
};

export default VaccinationPassportModal;
