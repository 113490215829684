import React from "react";
import PropTypes from "prop-types";

const STYLE = {
  top: { left: "calc(50% - 6.4px)" },
  bottom: { left: "calc(50% - 6.4px)" },
  left: { top: "calc(50% - 6.4px)" },
  right: { top: "calc(50% - 6.4px)" },
};

function ToolTip({ bounce, show, position, content, style }) {
  if (!show) return null;
  return (
    <div
      className={bounce ? "animate bounce" : ""}
      style={{ position: "absolute", height: 0, width: 0 }}
    >
      <div
        className={`tooltip bs-tooltip-${position}${show ? " fade show" : ""}`}
        role="tooltip"
        style={style}
      >
        <div className="arrow" style={STYLE[position] || {}} />
        <div className="tooltip-inner">{content}</div>
      </div>
    </div>
  );
}

ToolTip.propTypes = {
  style: PropTypes.instanceOf(Object),
  bounce: PropTypes.bool,
  show: PropTypes.bool,
  position: PropTypes.string,
  content: PropTypes.string,
};

ToolTip.defaultProps = {
  style: {},
  bounce: false,
  show: true,
  position: "top",
  content: "Tooltip",
};

export default React.memo(ToolTip);
